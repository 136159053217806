import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { 
  Check, 
  X, 
  AlertTriangle, 
  Clock, 
  MessageCircle,
  Filter,
  ChevronDown,
  ChevronUp,
  RefreshCcw,
  Loader2 
} from 'lucide-react';
import { Card, CardContent } from '../../../components/ui/card';
import { Button } from '../../../components/ui/button';
import { getMessages } from '../../../services/contact/messageService';
import { testFirestore } from '../../../services/contact/testFirestore';

const MESSAGE_STATUS = {
  NEW: 'new',
  PENDING_REVIEW: 'pending_review',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  SPAM: 'spam'
};

const STATUS_ICONS = {
  [MESSAGE_STATUS.NEW]: <Clock className="w-5 h-5 text-blue-500" />,
  [MESSAGE_STATUS.PENDING_REVIEW]: <AlertTriangle className="w-5 h-5 text-yellow-500" />,
  [MESSAGE_STATUS.APPROVED]: <Check className="w-5 h-5 text-green-500" />,
  [MESSAGE_STATUS.REJECTED]: <X className="w-5 h-5 text-red-500" />,
  [MESSAGE_STATUS.SPAM]: <AlertTriangle className="w-5 h-5 text-red-500" />
};

const STATUS_LABELS = {
  [MESSAGE_STATUS.NEW]: 'New',
  [MESSAGE_STATUS.PENDING_REVIEW]: 'Pending Review',
  [MESSAGE_STATUS.APPROVED]: 'Approved',
  [MESSAGE_STATUS.REJECTED]: 'Rejected',
  [MESSAGE_STATUS.SPAM]: 'Spam'
};

const MESSAGES_PER_PAGE = 10;

const MessageList = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({
    status: 'all',
    orderDirection: 'desc'
  });

  useEffect(() => {
    loadMessages(true);
  }, [filter]);

  const loadMessages = async (isInitial = false) => {
    try {
      setLoading(true);
      setError(null);

      // Create base query
      const messagesRef = collection(db, 'contact_messages');
      let baseQuery = query(
        messagesRef,
        orderBy('createdAt', filter.orderDirection),
        limit(MESSAGES_PER_PAGE)
      );

      // Add status filter if not 'all'
      if (filter.status !== 'all') {
        baseQuery = query(baseQuery, where('status', '==', filter.status));
      }

      // Add pagination if not initial load
      if (!isInitial && lastDoc) {
        baseQuery = query(baseQuery, startAfter(lastDoc));
      }

      const snapshot = await getDocs(baseQuery);
      const newMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      if (isInitial) {
        setMessages(newMessages);
      } else {
        setMessages(prev => [...prev, ...newMessages]);
      }

      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === MESSAGES_PER_PAGE);

    } catch (err) {
      console.error('Failed to load messages:', err);
      setError('Failed to load messages');
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (newFilter) => {
    setFilter(prev => ({
      ...prev,
      ...newFilter
    }));
    setLastDoc(null);
    setHasMore(true);
  };

  const runTest = async () => {
    try {
      const count = await testFirestore();
      alert(`Found ${count} documents`);
    } catch (error) {
      alert(`Test failed: ${error.message}`);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    if (timestamp.toDate) {
      timestamp = timestamp.toDate();
    }
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading && !messages.length) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-brand-blue" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Filters */}
      <Card>
        <CardContent className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <select
                value={filter.status}
                onChange={(e) => handleFilterChange({ status: e.target.value })}
                className="rounded-lg border-gray-300 text-sm"
              >
                <option value="all">All Messages</option>
                {Object.entries(STATUS_LABELS).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleFilterChange({
                  orderDirection: filter.orderDirection === 'asc' ? 'desc' : 'asc'
                })}
              >
                {filter.orderDirection === 'asc' ? (
                  <ChevronUp className="w-4 h-4 mr-2" />
                ) : (
                  <ChevronDown className="w-4 h-4 mr-2" />
                )}
                Date
              </Button>
              <Button 
                onClick={runTest}
                variant="default"
                className="bg-blue-500 text-white hover:bg-blue-600"
              >
                Test Firestore Connection
              </Button>
            </div>
            <Button
              variant="outline"
              size="sm"
              onClick={() => loadMessages(true)}
              disabled={loading}
            >
              {loading ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <RefreshCcw className="w-4 h-4" />
              )}
              <span className="ml-2">Refresh</span>
            </Button>
          </div>
        </CardContent>
      </Card>

      {error && (
        <Card>
          <CardContent className="p-4 text-red-500 flex items-center gap-2">
            <AlertTriangle className="w-5 h-5" />
            {error}
          </CardContent>
        </Card>
      )}

      {/* Messages List */}
      <Card>
        <CardContent className="p-0">
          <div className="divide-y divide-gray-200">
            {messages.map((message) => (
              <Link
                key={message.id}
                to={`/admin/contact/messages/${message.id}`}
                className="block p-4 hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center gap-3">
                      <MessageCircle className="w-5 h-5 text-gray-400" />
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {message.subject || 'No Subject'}
                      </p>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-gray-500">
                        From: {message.name} ({message.email})
                      </p>
                    </div>
                  </div>
                  <div className="ml-4 flex flex-col items-end space-y-2">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {STATUS_ICONS[message.status]}
                      <span className="ml-1">{STATUS_LABELS[message.status]}</span>
                    </span>
                    <time className="text-xs text-gray-500">
                      {formatDate(message.createdAt)}
                    </time>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Load More */}
      {hasMore && messages.length > 0 && (
        <div className="text-center pt-4">
          <Button
            variant="outline"
            onClick={() => loadMessages(false)}
            disabled={loading}
          >
            {loading ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin mr-2" />
                Loading...
              </>
            ) : (
              'Load More'
            )}
          </Button>
        </div>
      )}

      {/* Empty State */}
      {!loading && messages.length === 0 && (
        <Card>
          <CardContent className="p-12 text-center">
            <MessageCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              No messages found
            </h3>
            <p className="text-gray-500">
              There are no messages matching your current filters.
            </p>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default MessageList;