// src/components/information/ForHotels.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Hotel,
  BarChart,
  Globe,
  Video,
  LineChart,
  Award,
  Star,
  Users,
  Calendar,
  Share2,
  Leaf,
  MessageSquare,
  TrendingUp,
  Shield,
  BadgeCheck,
  Mail,
  Phone
} from 'lucide-react';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import { ROUTES } from '../../config/paths';

const ForHotels = () => {
  const features = [
    {
      icon: Star,
      title: "Personalized Hotel Ratings",
      description: "Our AI-driven system ensures your property shines for the right audience, highlighting your unique strengths to matched travelers."
    },
    {
      icon: TrendingUp,
      title: "Dynamic Rating System",
      description: "Real-time rating adjustments based on guest feedback, social media trends, and seasonal factors."
    },
    {
      icon: Globe,
      title: "Global Reach",
      description: "Connect with millions of travelers worldwide searching for their perfect hotel match."
    },
    {
      icon: Video,
      title: "Video Review Marketplace",
      description: "Partner with influencers to create authentic video content that showcases your property."
    },
    {
      icon: BarChart,
      title: "Advanced Analytics",
      description: "Gain deep insights into guest preferences and behavior patterns to optimize your offerings."
    },
    {
      icon: Shield,
      title: "Trust & Credibility",
      description: "Build trust through verified reviews and transparent rating systems."
    }
  ];

  const exclusiveFeatures = [
    {
      icon: Award,
      title: "Premium Visibility",
      description: "Get featured placement in search results and curated lists."
    },
    {
      icon: Calendar,
      title: "Event Management",
      description: "Promote your venue for conferences, weddings, and special events."
    },
    {
      icon: Share2,
      title: "Social Integration",
      description: "Automatically share updates across all major social platforms."
    },
    {
      icon: Leaf,
      title: "Sustainability Program",
      description: "Highlight your eco-friendly initiatives with our green certification."
    },
    {
      icon: MessageSquare,
      title: "Direct Communication",
      description: "Engage with guests through our integrated messaging system."
    },
    {
      icon: BadgeCheck,
      title: "Quality Assurance",
      description: "Regular audits ensure rating accuracy and maintain high standards."
    }
  ];

  const steps = [
    {
      number: "01",
      title: "Register Your Property",
      description: "Quick and easy registration process with comprehensive property profile setup."
    },
    {
      number: "02",
      title: "Choose Your Plan",
      description: "Flexible subscription options tailored to your property's needs and goals."
    },
    {
      number: "03",
      title: "Optimize Your Presence",
      description: "Enhance your profile with high-quality content and unique selling points."
    },
    {
      number: "04",
      title: "Engage With Guests",
      description: "Build relationships through personalized interactions and responsive service."
    }
  ];

  return (
    <div className="max-w-7xl mx-auto p-8">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
          Transform Your Hotel's Digital Presence
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
          Join HotelsRating.com to showcase your property to millions of travelers through personalized ratings and authentic content.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Button size="lg" className="bg-brand-blue hover:bg-brand-blue/90">
            Register Your Hotel
          </Button>
          <Button size="lg" variant="outline">
            Schedule a Demo
          </Button>
        </div>
      </div>

      {/* Key Features */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Why Partner with HotelsRating.com?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <Card key={index}>
              <CardContent className="p-6">
                <feature.icon className="w-12 h-12 text-brand-blue mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      {/* How It Works */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Getting Started is Easy
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              <div className="bg-brand-blue/5 rounded-lg p-6">
                <div className="text-4xl font-bold text-brand-blue mb-4">
                  {step.number}
                </div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
              {index < steps.length - 1 && (
                <div className="hidden lg:block absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2">
                  <div className="w-8 h-0.5 bg-gray-300"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      {/* Exclusive Features */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Exclusive Features for Hotels
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {exclusiveFeatures.map((feature, index) => (
            <div key={index} className="flex items-start gap-4">
              <div className="bg-brand-blue/10 p-3 rounded-lg">
                <feature.icon className="w-6 h-6 text-brand-blue" />
              </div>
              <div>
                <h3 className="font-semibold mb-2">{feature.title}</h3>
                <p className="text-sm text-gray-600">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Success Stats */}
      <section className="mb-20 bg-gray-900 text-white rounded-lg p-12">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Proven Results</h2>
          <p className="text-gray-300">Join hundreds of successful hotels on our platform</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="text-center">
            <div className="text-4xl font-bold text-brand-blue mb-2">30%</div>
            <p className="text-gray-300">Average Booking Increase</p>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-brand-blue mb-2">2M+</div>
            <p className="text-gray-300">Monthly Active Users</p>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-brand-blue mb-2">95%</div>
            <p className="text-gray-300">Partner Satisfaction</p>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-brand-blue mb-2">150+</div>
            <p className="text-gray-300">Countries Reached</p>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="text-center mb-20">
        <h2 className="text-3xl font-bold mb-12">Ready to Get Started?</h2>
        <div className="max-w-2xl mx-auto">
          <p className="text-xl text-gray-600 mb-8">
            Join the leading hotels already benefiting from our platform. Contact us today to learn more about how we can help grow your business.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8">
            <Button size="lg" className="bg-brand-blue hover:bg-brand-blue/90">
              Register Now
            </Button>
            <Link to={ROUTES.CONTACT}>
              <Button size="lg" variant="outline">
                Contact Sales
              </Button>
            </Link>
          </div>
          <div className="flex flex-col sm:flex-row gap-8 justify-center text-gray-600">
            <div className="flex items-center justify-center gap-2">
              <Mail className="w-5 h-5" />
              <span>hotels@hotelsrating.com</span>
            </div>
            <div className="flex items-center justify-center gap-2">
              <Phone className="w-5 h-5" />
              <span>+1 (555) 123-4567</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForHotels;