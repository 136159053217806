// src/pages/search/SearchResultsPage.jsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../hooks/useToast';
import { 
  MapPin, Calendar, Users, Filter, Loader2, Star, 
  SortAsc, ArrowUpDown, Wifi, Timer, Search
} from 'lucide-react';
import { format } from 'date-fns';
import { Button } from '../../components/ui/button';
import { Card, CardContent } from '../../components/ui/card';
import { Slider } from '../../components/ui/slider';
import { Switch } from '../../components/ui/switch';
import { Label } from '../../components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetFooter,
} from '../../components/ui/sheet';
import { FEATURES_CONFIG } from '../../config/preferences';
import SearchForm from '../../components/search/SearchForm';
import HotelCard from '../../components/hotels/HotelCard';
import hotelService from '../../services/api/hotels';

const SORT_OPTIONS = {
  RECOMMENDED: 'recommended',
  PRICE_LOW: 'price_low',
  PRICE_HIGH: 'price_high',
  RATING: 'rating',
  DISTANCE: 'distance'
};

const SearchResultsPage = () => {
  const [searchParams] = useSearchParams();
  const { currentUser } = useAuth();
  const { toast } = useToast();
  
  // Состояния
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.RECOMMENDED);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(new Date());

  // Фильтры
  const [filters, setFilters] = useState({
    priceRange: [0, 1000],
    rating: 0,
    features: {},
    distance: 10, // km from city center
    propertyTypes: []
  });

  // Параметры поиска из URL
  const parseDate = (dateStr) => {
    if (!dateStr) return new Date();
    const date = new Date(dateStr);
    return isNaN(date.getTime()) ? new Date() : date;
  };
  
  const searchData = useMemo(() => ({
    destination: searchParams.get('destination'),
    destinationCode: searchParams.get('destinationCode'),
    checkIn: parseDate(searchParams.get('checkIn')),
    checkOut: parseDate(searchParams.get('checkOut')),
    guests: parseInt(searchParams.get('guests')) || 1,
    preferences: searchParams.get('preferences') 
      ? JSON.parse(searchParams.get('preferences'))
      : currentUser?.preferences
  }), [searchParams, currentUser?.preferences]);

  // Загрузка отелей
  const fetchHotels = useCallback(async (shouldResetFilters = false) => {
    setLoading(true);
    setError(null);
  
    try {
      if (!searchData.destination) {
        throw new Error('Please specify a destination');
      }
  
      console.log('Searching hotels with params:', {
        destination: searchData.destinationCode || searchData.destination,
        checkIn: searchData.checkIn,
        checkOut: searchData.checkOut,
        guests: searchData.guests,
        preferences: searchData.preferences,
        filters: shouldResetFilters ? {} : filters
      });
  
      const result = await hotelService.searchHotels({
        destination: searchData.destinationCode || searchData.destination,
        checkIn: searchData.checkIn,
        checkOut: searchData.checkOut,
        guests: searchData.guests,
        preferences: searchData.preferences,
        filters: shouldResetFilters ? {} : filters
      });
  
      console.log('Search result:', result);
  
      if (!result || !Array.isArray(result.hotels)) {
        throw new Error('Invalid response from hotel service');
      }
  
      setHotels(result.hotels);
      setFilteredHotels(result.hotels);
  
      // Обновляем диапазон цен на основе реальных данных
      if (result.hotels.length > 0 && shouldResetFilters) {
        const prices = result.hotels.map(hotel => hotel.price?.current?.amount || 0);
        const minPrice = Math.floor(Math.min(...prices));
        const maxPrice = Math.ceil(Math.max(...prices));
        setFilters(prev => ({
          ...prev,
          priceRange: [minPrice, maxPrice]
        }));
      }
  
      setLastRefresh(new Date());
    } catch (err) {
      console.error('Error fetching hotels:', err);
      setError(err.message || 'Failed to load hotels');
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  }, [searchData, filters, toast]);

  // Инициальная загрузка
  useEffect(() => {
    console.log('Search data changed:', searchData);
    if (searchData.destination) {
      console.log('Starting hotel search...');
      fetchHotels(true);
    } else {
      console.log('No destination specified');
    }
  }, [searchData.destination, fetchHotels]);

  // Применение фильтров и сортировки
  const applyFiltersAndSort = useCallback(() => {
    if (!hotels.length) return;

    let filtered = hotelService.filterHotels(hotels, filters);

    // Сортировка
    filtered = [...filtered].sort((a, b) => {
      switch (sortBy) {
        case SORT_OPTIONS.PRICE_LOW:
          return (a.price?.current?.amount || 0) - (b.price?.current?.amount || 0);
        case SORT_OPTIONS.PRICE_HIGH:
          return (b.price?.current?.amount || 0) - (a.price?.current?.amount || 0);
        case SORT_OPTIONS.RATING:
          return (b.rating?.stars || 0) - (a.rating?.stars || 0);
        case SORT_OPTIONS.DISTANCE:
          return (
            parseFloat(a.location?.nearby?.cityCenter) || 0) - 
            (parseFloat(b.location?.nearby?.cityCenter) || 0
          );
        case SORT_OPTIONS.RECOMMENDED:
        default:
          return (b.personalScore || 0) - (a.personalScore || 0);
      }
    });

    setFilteredHotels(filtered);
    setFiltersApplied(true);
  }, [hotels, filters, sortBy]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [applyFiltersAndSort]);

  // Обработчики
  const handleFilterChange = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleFilterReset = () => {
    setFilters({
      priceRange: [0, Math.max(...hotels.map(h => h.price?.current?.amount || 0))],
      rating: 0,
      features: {},
      distance: 10,
      propertyTypes: []
    });
    setFiltersApplied(false);
  };

  const handleRefresh = () => {
    fetchHotels();
  };

  // Компоненты фильтров
  const FiltersContent = () => (
    <div className="space-y-6">
      {/* Сортировка */}
      <div className="space-y-4">
        <Label>Sort By</Label>
        <Select value={sortBy} onValueChange={setSortBy}>
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={SORT_OPTIONS.RECOMMENDED}>
              <div className="flex items-center">
                <Star className="w-4 h-4 mr-2" />
                Recommended
              </div>
            </SelectItem>
            <SelectItem value={SORT_OPTIONS.PRICE_LOW}>
              <div className="flex items-center">
                <SortAsc className="w-4 h-4 mr-2" />
                Price: Low to High
              </div>
            </SelectItem>
            <SelectItem value={SORT_OPTIONS.PRICE_HIGH}>
              <div className="flex items-center">
                <SortAsc className="w-4 h-4 mr-2 rotate-180" />
                Price: High to Low
              </div>
            </SelectItem>
            <SelectItem value={SORT_OPTIONS.RATING}>
              <div className="flex items-center">
                <Star className="w-4 h-4 mr-2" />
                Rating
              </div>
            </SelectItem>
            <SelectItem value={SORT_OPTIONS.DISTANCE}>
              <div className="flex items-center">
                <MapPin className="w-4 h-4 mr-2" />
                Distance from Center
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* Price Range */}
      <div className="space-y-4">
        <Label>Price Range (per night)</Label>
        <Slider
          value={filters.priceRange}
          onValueChange={(value) => handleFilterChange('priceRange', value)}
          min={0}
          max={Math.max(...hotels.map(h => h.price?.current?.amount || 0))}
          step={10}
        />
        <div className="flex justify-between text-sm text-gray-600">
          <span>${filters.priceRange[0]}</span>
          <span>${filters.priceRange[1]}</span>
        </div>
      </div>

      {/* Rating Filter */}
      <div className="space-y-4">
        <Label>Minimum Rating</Label>
        <Slider
          value={[filters.rating]}
          onValueChange={(value) => handleFilterChange('rating', value[0])}
          min={0}
          max={5}
          step={0.5}
        />
        <div className="flex items-center text-sm text-gray-600">
          <Star className="w-4 h-4 mr-1" /> {filters.rating}+
        </div>
      </div>

      {/* Distance Filter */}
      <div className="space-y-4">
        <Label>Distance from City Center</Label>
        <Slider
          value={[filters.distance]}
          onValueChange={(value) => handleFilterChange('distance', value[0])}
          min={0}
          max={20}
          step={0.5}
        />
        <div className="text-sm text-gray-600">
          Up to {filters.distance} km
        </div>
      </div>

      {/* Features */}
      <div className="space-y-4">
        <Label>Features</Label>
        <div className="space-y-4">
          {Object.entries(FEATURES_CONFIG).map(([key, config]) => {
            const Icon = config.icon;
            return (
              <div key={key} className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Icon className="w-4 h-4 text-gray-500" />
                  <span className="text-sm">{config.name}</span>
                </div>
                <Switch
                  checked={filters.features[key] || false}
                  onCheckedChange={(checked) => 
                    handleFilterChange('features', { 
                      ...filters.features, 
                      [key]: checked 
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  if (loading && !hotels.length) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Search Header */}
      <div className="bg-white border-b sticky top-0 z-30">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <SearchForm />
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Search Summary */}
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 mb-4">
              {filteredHotels.length} hotels in {searchData.destination}
            </h1>
            <div className="flex flex-wrap gap-4 text-sm text-gray-600">
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4" />
                <span>{format(searchData.checkIn, 'PP')} - {format(searchData.checkOut, 'PP')}</span>
              </div>
              <div className="flex items-center gap-2">
                <Users className="w-4 h-4" />
                <span>{searchData.guests} guests</span>
              </div>
              <div className="flex items-center gap-2">
                <Timer className="w-4 h-4" />
                <span>Last updated: {format(lastRefresh, 'HH:mm')}</span>
              </div>
            </div>
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={handleRefresh}
            disabled={loading}
          >
            {loading ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              'Refresh'
            )}
          </Button>
        </div>

        <div className="flex items-center justify-between mb-6">
          {/* Sort Selector (Desktop) */}
          <div className="hidden md:block">
            <Select value={sortBy} onValueChange={setSortBy}>
              <SelectTrigger className="w-[200px]">
                <ArrowUpDown className="w-4 h-4 mr-2" />
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {/* ... содержимое такое же, как в FiltersContent ... */}
              </SelectContent>
            </Select>
          </div>

          {/* Mobile Filters Button */}
          <Sheet open={isFilterSheetOpen} onOpenChange={setIsFilterSheetOpen}>
            <SheetTrigger asChild>
              <Button variant="outline" className="lg:hidden">
                <Filter className="w-4 h-4 mr-2" /> 
                {filtersApplied ? 'Filters Applied' : 'Filters'}
              </Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Filters</SheetTitle>
                <SheetDescription>
                  Adjust your search filters
                </SheetDescription>
              </SheetHeader>
              <div className="mt-6">
                <FiltersContent />
              </div>
              <SheetFooter className="mt-6">
                <Button
                  variant="outline"
                  onClick={handleFilterReset}
                >
                  Reset Filters
                </Button>
              </SheetFooter>
            </SheetContent>
          </Sheet>
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Desktop Filters */}
          <div className="hidden lg:block w-64">
            <div className="sticky top-28">
              <Card>
              <CardContent className="p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="font-semibold">Filters</h3>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={handleFilterReset}
                      disabled={!filtersApplied}
                    >
                      Reset
                    </Button>
                  </div>
                  <FiltersContent />
                </CardContent>
              </Card>
            </div>
          </div>

          {/* Results Grid */}
          <div className="flex-1">
            {error ? (
              <Card className="p-6 text-center">
                <div className="text-red-500 mb-4">{error}</div>
                <Button onClick={() => fetchHotels(true)}>Try Again</Button>
              </Card>
            ) : filteredHotels.length === 0 ? (
              <Card className="p-6">
                <div className="text-center py-8">
                  <div className="mb-4 text-gray-400">
                    <Search className="w-12 h-12 mx-auto mb-4" />
                    <h3 className="text-lg font-semibold">No hotels found</h3>
                  </div>
                  <p className="text-gray-600 mb-6">
                    Try adjusting your search criteria or filters
                  </p>
                  <Button onClick={handleFilterReset}>Reset Filters</Button>
                </div>
              </Card>
            ) : (
              <>
                {loading && (
                  <div className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-lg">
                    <Loader2 className="w-4 h-4 animate-spin" />
                  </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6">
                  {filteredHotels.map(hotel => (
                    <HotelCard 
                      key={hotel.id} 
                      hotel={hotel}
                      searchParams={{
                        checkIn: searchData.checkIn,
                        checkOut: searchData.checkOut,
                        guests: searchData.guests
                      }}
                    />
                  ))}
                </div>
                
                {/* Load More (if needed) */}
                {filteredHotels.length >= 20 && (
                  <div className="text-center mt-8">
                    <Button 
                      variant="outline"
                      onClick={() => {/* Implement load more logic */}}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Loader2 className="w-4 h-4 animate-spin mr-2" />
                          Loading...
                        </>
                      ) : (
                        'Load More Results'
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* Summary Information */}
        <div className="mt-12">
          <Card className="bg-gray-50/50">
            <CardContent className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-sm text-gray-600">
                <div>
                  <h4 className="font-semibold text-gray-900 mb-2">About the results</h4>
                  <ul className="space-y-2">
                    <li>• Total hotels found: {hotels.length}</li>
                    <li>• Filtered results: {filteredHotels.length}</li>
                    <li>• Price range: ${Math.min(...hotels.map(h => h.price?.current?.amount || 0))} - ${Math.max(...hotels.map(h => h.price?.current?.amount || 0))}</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-2">Applied filters</h4>
                  <ul className="space-y-2">
                    {filters.rating > 0 && (
                      <li>• Minimum rating: {filters.rating}⭐</li>
                    )}
                    {Object.entries(filters.features)
                      .filter(([_, value]) => value)
                      .map(([key]) => (
                        <li key={key}>• {FEATURES_CONFIG[key].name}</li>
                      ))
                    }
                    {filters.distance < 20 && (
                      <li>• Within {filters.distance}km from center</li>
                    )}
                  </ul>
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-2">Search details</h4>
                  <ul className="space-y-2">
                    <li>• Check-in: {format(searchData.checkIn, 'PP')}</li>
                    <li>• Check-out: {format(searchData.checkOut, 'PP')}</li>
                    <li>• Length of stay: {
                      Math.ceil((searchData.checkOut - searchData.checkIn) / (1000 * 60 * 60 * 24))
                    } nights</li>
                    <li>• Guests: {searchData.guests}</li>
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SearchResultsPage;