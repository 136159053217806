// src/components/shared/BetaConsent.jsx
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import { Link } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';
import { ROUTES } from '../../config/paths';

const BetaConsent = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem('betaConsent');
    if (!hasAccepted) {
      setIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('betaConsent', 'true');
    localStorage.setItem('cookieConsent', 'true');
    setIsOpen(false);
  };

  const handleDecline = () => {
    // Redirect to a landing page or show additional warning
    localStorage.setItem('betaConsent', 'false');
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-xl">
            <AlertTriangle className="h-6 w-6 text-yellow-500" />
            Beta Version Notice
          </DialogTitle>
          <DialogDescription className="space-y-4 pt-4">
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    This website is currently running in <strong>beta testing mode</strong>. 
                    By continuing to use this site, you acknowledge and agree to the following:
                  </p>
                </div>
              </div>
            </div>

            <ul className="list-disc pl-5 space-y-2 text-sm text-gray-600">
              <li>The website is in development and testing phase</li>
              <li>Some features may be incomplete or not working as intended</li>
              <li>You may encounter bugs, errors, or unexpected behavior</li>
              <li>The service may be interrupted or unavailable at times</li>
              <li>Data may be reset or modified during the testing period</li>
            </ul>

            <p className="text-sm text-gray-600">
              By clicking "Accept & Continue", you agree not to hold us liable for any issues, 
              losses, or inconveniences that may arise from using this beta version. You also consent 
              to our use of cookies and agree to our{' '}
              <Link
                to={ROUTES.PRIVACY}
                className="text-primary hover:underline"
                onClick={() => setIsOpen(false)}
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                to={ROUTES.COOKIES}
                className="text-primary hover:underline"
                onClick={() => setIsOpen(false)}
              >
                Cookie Policy
              </Link>.
            </p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex-col sm:flex-row gap-2 sm:gap-0">
          <Button
            variant="outline"
            onClick={handleDecline}
            className="sm:mr-2 border-red-500 text-red-500 hover:bg-red-50"
          >
            Decline & Exit
          </Button>
          <Button
            onClick={handleAccept}
            className="bg-primary hover:bg-primary-600"
          >
            Accept & Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BetaConsent;