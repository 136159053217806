// src/components/booking/constants.js

// Статусы бронирования
export const BOOKING_STATUS = {
    PENDING: 'pending',
    CONFIRMED: 'confirmed',
    CANCELLED: 'cancelled',
    COMPLETED: 'completed',
    PAYMENT_REQUIRED: 'payment_required',
    IN_PROGRESS: 'in_progress'
  };
  
  // Названия статусов для отображения
  export const STATUS_LABELS = {
    [BOOKING_STATUS.PENDING]: 'Pending',
    [BOOKING_STATUS.CONFIRMED]: 'Confirmed',
    [BOOKING_STATUS.CANCELLED]: 'Cancelled',
    [BOOKING_STATUS.COMPLETED]: 'Completed',
    [BOOKING_STATUS.PAYMENT_REQUIRED]: 'Payment Required',
    [BOOKING_STATUS.IN_PROGRESS]: 'In Progress'
  };
  
  // Цвета для статусов
  export const STATUS_COLORS = {
    [BOOKING_STATUS.PENDING]: 'text-yellow-500',
    [BOOKING_STATUS.CONFIRMED]: 'text-green-500',
    [BOOKING_STATUS.CANCELLED]: 'text-red-500',
    [BOOKING_STATUS.COMPLETED]: 'text-blue-500',
    [BOOKING_STATUS.PAYMENT_REQUIRED]: 'text-orange-500',
    [BOOKING_STATUS.IN_PROGRESS]: 'text-purple-500'
  };
  
  // Иконки для статусов
  export const STATUS_ICONS = {
    [BOOKING_STATUS.PENDING]: 'Clock',
    [BOOKING_STATUS.CONFIRMED]: 'CheckCircle',
    [BOOKING_STATUS.CANCELLED]: 'XCircle',
    [BOOKING_STATUS.COMPLETED]: 'BadgeCheck',
    [BOOKING_STATUS.PAYMENT_REQUIRED]: 'CreditCard',
    [BOOKING_STATUS.IN_PROGRESS]: 'Calendar'
  };
  
  // Типы номеров
  export const ROOM_TYPES = {
    STANDARD: 'standard',
    DELUXE: 'deluxe',
    SUITE: 'suite',
    FAMILY: 'family',
    BUSINESS: 'business'
  };
  
  // Названия типов номеров
  export const ROOM_TYPE_LABELS = {
    [ROOM_TYPES.STANDARD]: 'Standard Room',
    [ROOM_TYPES.DELUXE]: 'Deluxe Room',
    [ROOM_TYPES.SUITE]: 'Suite',
    [ROOM_TYPES.FAMILY]: 'Family Room',
    [ROOM_TYPES.BUSINESS]: 'Business Room'
  };
  
  // Типы гостей
  export const GUEST_TYPES = {
    ADULT: 'adult',
    CHILD: 'child',
    INFANT: 'infant'
  };
  
  // Ограничения
  export const BOOKING_LIMITS = {
    MAX_ADULTS: 4,
    MAX_CHILDREN: 4,
    MAX_ROOMS: 3,
    MIN_NIGHTS: 1,
    MAX_NIGHTS: 30
  };
  
  // Политики отмены
  export const CANCELLATION_POLICIES = {
    FREE_CANCELLATION: 'free_cancellation',
    NON_REFUNDABLE: 'non_refundable',
    PARTIAL_REFUND: 'partial_refund'
  };
  
  // Сообщения для политик отмены
  export const CANCELLATION_MESSAGES = {
    [CANCELLATION_POLICIES.FREE_CANCELLATION]: 'Free cancellation until 24 hours before check-in',
    [CANCELLATION_POLICIES.NON_REFUNDABLE]: 'Non-refundable',
    [CANCELLATION_POLICIES.PARTIAL_REFUND]: 'Partial refund available'
  };
  
  // Способы оплаты
  export const PAYMENT_METHODS = {
    CREDIT_CARD: 'credit_card',
    DEBIT_CARD: 'debit_card',
    PAYPAL: 'paypal',
    BANK_TRANSFER: 'bank_transfer'
  };
  
  // Валюты
  export const CURRENCIES = {
    USD: 'USD',
    EUR: 'EUR',
    GBP: 'GBP'
  };
  
  // Часовые пояса для времени заезда/выезда
  export const CHECK_TIMES = {
    DEFAULT_CHECK_IN: '15:00',
    DEFAULT_CHECK_OUT: '12:00',
    EARLY_CHECK_IN: '13:00',
    LATE_CHECK_OUT: '14:00'
  };
  
  // События бронирования
  export const BOOKING_EVENTS = {
    CREATED: 'booking_created',
    CONFIRMED: 'booking_confirmed',
    CANCELLED: 'booking_cancelled',
    MODIFIED: 'booking_modified',
    COMPLETED: 'booking_completed'
  };
  
  // Форматы дат
  export const DATE_FORMATS = {
    DISPLAY: 'PPP',
    API: 'yyyy-MM-dd',
    TIME: 'HH:mm',
    FULL: 'PPP HH:mm'
  };
  
  // Путь в Firestore
  export const FIRESTORE_PATHS = {
    BOOKINGS: 'bookings',
    USERS: 'users',
    HOTELS: 'hotels'
  };
  
  // Сортировка бронирований
  export const SORT_OPTIONS = {
    DATE_ASC: 'date_asc',
    DATE_DESC: 'date_desc',
    PRICE_ASC: 'price_asc',
    PRICE_DESC: 'price_desc',
    STATUS: 'status'
  };
  
  // Фильтры бронирований
  export const FILTER_OPTIONS = {
    ALL: 'all',
    UPCOMING: 'upcoming',
    PAST: 'past',
    CANCELLED: 'cancelled'
  };
  
  // Уведомления
  export const NOTIFICATION_TYPES = {
    BOOKING_CONFIRMATION: 'booking_confirmation',
    CANCELLATION: 'cancellation',
    REMINDER: 'reminder',
    MODIFICATION: 'modification'
  };
  
  // URL пути для роутинга
  export const BOOKING_ROUTES = {
    NEW: '/booking/new',
    CONFIRMATION: '/booking/confirmation',
    DETAILS: '/booking/:id',
    HISTORY: '/booking/history',
    MODIFY: '/booking/:id/modify'
  };
  
  // Названия полей бронирования для форм
  export const FORM_FIELDS = {
    CHECK_IN: 'checkIn',
    CHECK_OUT: 'checkOut',
    GUESTS: 'guests',
    ROOM_TYPE: 'roomType',
    SPECIAL_REQUESTS: 'specialRequests'
  };
  
  export default {
    BOOKING_STATUS,
    STATUS_LABELS,
    STATUS_COLORS,
    STATUS_ICONS,
    ROOM_TYPES,
    ROOM_TYPE_LABELS,
    GUEST_TYPES,
    BOOKING_LIMITS,
    CANCELLATION_POLICIES,
    CANCELLATION_MESSAGES,
    PAYMENT_METHODS,
    CURRENCIES,
    CHECK_TIMES,
    BOOKING_EVENTS,
    DATE_FORMATS,
    FIRESTORE_PATHS,
    SORT_OPTIONS,
    FILTER_OPTIONS,
    NOTIFICATION_TYPES,
    BOOKING_ROUTES,
    FORM_FIELDS
  };