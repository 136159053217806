// src/pages/admin/contact/MessageDetails.jsx

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import MessageDetailsComponent from '../../../components/admin/contact/MessageDetails';
import { ROUTES } from '../../../config/paths';

const MessageDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <button
          onClick={() => navigate(ROUTES.ADMIN_CONTACT_MESSAGES)}
          className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <ChevronLeft className="w-4 h-4 mr-1" />
          Назад к списку
        </button>
        <div className="text-sm text-gray-500">
          ID: {id}
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <div className="p-6">
          <h1 className="text-xl font-semibold text-gray-900 mb-6">
            Детали сообщения
          </h1>
          <MessageDetailsComponent />
        </div>
      </div>
    </div>
  );
};

export default MessageDetailsPage;