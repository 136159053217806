import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Slider } from "../ui/slider";
import { Switch } from "../ui/switch";
import { Button } from "../ui/button";
import { Settings, Plus } from 'lucide-react';
import { PRESET_CATEGORIES } from '../../config/TravelPresets';
import { 
  CRITERIA_CONFIG, 
  FEATURES_CONFIG, 
  DEFAULT_PREFERENCES 
} from '../../config/preferences';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import CriteriaMapping from './CriteriaMapping';

// Компонент диалога для добавления пользовательского критерия
const CustomCriteriaDialog = ({ onAddCriterion }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [criterionName, setCriterionName] = useState('');
  const [criterionDescription, setCriterionDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (criterionName.trim() && criterionDescription.trim()) {
      onAddCriterion({
        id: criterionName.toLowerCase().replace(/\s+/g, '_'),
        name: criterionName,
        description: criterionDescription,
        value: 50
      });
      setCriterionName('');
      setCriterionDescription('');
      setIsOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button 
          variant="outline" 
          className="w-full flex items-center gap-2 p-4 border-dashed"
        >
          <Plus className="w-5 h-5" />
          <span>Add New Criterion</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Custom Criterion</DialogTitle>
          <DialogDescription>
            Add a new criterion to customize your hotel preferences.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="criterionName">Criterion Name</Label>
            <Input
              id="criterionName"
              value={criterionName}
              onChange={(e) => setCriterionName(e.target.value)}
              placeholder="e.g., Ocean View"
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="criterionDescription">Description</Label>
            <Input
              id="criterionDescription"
              value={criterionDescription}
              onChange={(e) => setCriterionDescription(e.target.value)}
              placeholder="Describe what this criterion means"
              required
            />
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button type="submit">Add Criterion</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

// Основной компонент PreferencesManager
const PreferencesManager = ({ 
  mode = 'profile',
  onSave,
  onPresetSelect,
  showPresets = false,
  initialPreferences = null,
  isLoading: externalLoading = false
}) => {
  const { currentUser } = useAuth();
  const [internalLoading, setInternalLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [activePreset, setActivePreset] = useState(null);
  const [isCustomMode, setIsCustomMode] = useState(false);
  
  const isLoading = externalLoading || internalLoading;

  // Инициализация предпочтений
  const [preferences, setPreferences] = useState(() => {
    if (initialPreferences) {
      return {
        criteria: { ...DEFAULT_PREFERENCES.criteria, ...initialPreferences.criteria },
        features: { ...DEFAULT_PREFERENCES.features, ...initialPreferences.features },
        customCriteria: { ...initialPreferences.customCriteria }
      };
    }
    return { 
      ...DEFAULT_PREFERENCES,
      customCriteria: {}
    };
  });

  // Загрузка сохраненных предпочтений
  useEffect(() => {
    if (initialPreferences) {
      setPreferences({
        criteria: { ...DEFAULT_PREFERENCES.criteria, ...initialPreferences.criteria },
        features: { ...DEFAULT_PREFERENCES.features, ...initialPreferences.features },
        customCriteria: { ...initialPreferences.customCriteria }
      });
    } else if (mode === 'profile' && currentUser?.preferences) {
      setPreferences({
        criteria: { ...DEFAULT_PREFERENCES.criteria, ...currentUser.preferences.criteria },
        features: { ...DEFAULT_PREFERENCES.features, ...currentUser.preferences.features },
        customCriteria: { ...currentUser.preferences.customCriteria }
      });
    }
  }, [initialPreferences, currentUser, mode]);

  // Проверка соответствия пресету
  const checkIfMatchesPreset = (newPreferences) => {
    for (const category of Object.values(PRESET_CATEGORIES)) {
      for (const preset of category.presets) {
        const criteriaMatch = Object.entries(preset.criteria).every(
          ([key, value]) => newPreferences.criteria[key] === value
        );
        
        const featuresMatch = Object.entries(preset.mandatoryFeatures).every(
          ([key, value]) => newPreferences.features[key] === value
        );

        if (criteriaMatch && featuresMatch) {
          return preset;
        }
      }
    }
    return null;
  };

  // Обработчики изменений
  const handleCriteriaChange = (criterion, value) => {
    const newPreferences = {
      ...preferences,
      criteria: {
        ...preferences.criteria,
        [criterion]: value[0]
      }
    };
    
    setPreferences(newPreferences);
    setHasChanges(true);
    
    const matchingPreset = checkIfMatchesPreset(newPreferences);
    setActivePreset(matchingPreset);
    setIsCustomMode(!matchingPreset);
  };

  const handleFeatureToggle = (feature) => {
    const newPreferences = {
      ...preferences,
      features: {
        ...preferences.features,
        [feature]: !preferences.features[feature]
      }
    };
    
    setPreferences(newPreferences);
    setHasChanges(true);
    
    const matchingPreset = checkIfMatchesPreset(newPreferences);
    setActivePreset(matchingPreset);
    setIsCustomMode(!matchingPreset);
  };

  const handlePresetSelect = (preset) => {
    setPreferences({
      ...preferences,
      criteria: preset.criteria,
      features: preset.mandatoryFeatures
    });
    setHasChanges(true);
    setActivePreset(preset);
    setIsCustomMode(false);
    if (onPresetSelect) {
      onPresetSelect(preset.id);
    }
  };

  const handleAddCustomCriterion = (newCriterion) => {
    setPreferences(prev => ({
      ...prev,
      criteria: {
        ...prev.criteria,
        [newCriterion.id]: newCriterion.value
      },
      customCriteria: {
        ...prev.customCriteria,
        [newCriterion.id]: {
          name: newCriterion.name,
          description: newCriterion.description
        }
      }
    }));
    setHasChanges(true);
    setIsCustomMode(true);
  };

  const handleSave = async () => {
    if (!hasChanges || isLoading) return;

    setInternalLoading(true);
    setError('');

    try {
      await onSave(preferences);
      setHasChanges(false);
    } catch (error) {
      setError('Failed to save preferences. Please try again.');
      console.error('Save preferences error:', error);
    } finally {
      setInternalLoading(false);
    }
  };

  return (
    <div className="space-y-8">
      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-sm text-red-600">
          {error}
        </div>
      )}

      {/* Текущий режим */}
      <div className="flex items-center gap-2 text-sm font-medium">
        <Settings className="w-4 h-4" />
        <span>Current Mode:</span>
        {activePreset ? (
          <span className="text-brand-blue">{activePreset.title}</span>
        ) : isCustomMode ? (
          <span className="text-purple-600">Custom Settings</span>
        ) : (
          <span className="text-gray-600">Default Settings</span>
        )}
      </div>

      {/* Пресеты */}
      {showPresets && (
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-gray-900">
            Choose a Travel Style
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(PRESET_CATEGORIES).map(([categoryKey, category]) => (
              category.presets.map(preset => {
                const Icon = preset.icon;
                const isActive = activePreset?.id === preset.id;
                return (
                  <Button
                    key={preset.id}
                    variant={isActive ? "default" : "outline"}
                    className={`w-full justify-start p-4 h-auto ${
                      isActive ? 'bg-brand-blue text-white' : `${preset.color} bg-opacity-5 hover:bg-opacity-10`
                    }`}
                    onClick={() => handlePresetSelect(preset)}
                    disabled={isLoading}
                  >
                    <div className="flex items-start space-x-3">
                      <Icon className="w-5 h-5 mt-1" />
                      <div className="text-left">
                        <div className="font-semibold">{preset.title}</div>
                        <div className={`text-sm ${isActive ? 'text-white/80' : 'text-gray-500'} line-clamp-2`}>
                          {preset.description}
                        </div>
                      </div>
                    </div>
                  </Button>
                );
              })
            ))}
          </div>
        </div>
      )}

      {/* Критерии важности */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-900">
          Criteria Importance
        </h3>
        <div className="space-y-6">
          {/* Маппинг критериев */}
          <CriteriaMapping
            preferences={preferences}
            onCriteriaChange={handleCriteriaChange}
            isLoading={isLoading}
          />

          {/* Кнопка добавления нового критерия */}
          <div className="pt-4">
            <CustomCriteriaDialog onAddCriterion={handleAddCustomCriterion} />
          </div>
        </div>
      </div>

      {/* Обязательные функции */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-900">
          Must-Have Features
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {Object.entries(FEATURES_CONFIG).map(([key, config]) => (
            <div key={key} className="flex items-center justify-between">
              <div>
                <div className="flex items-center gap-2">
                  <config.icon className="w-5 h-5 text-gray-500" />
                  <p className="text-sm font-medium text-gray-900">
                    {config.name}
                  </p>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  {config.description}
                </p>
              </div>
              <Switch
                checked={preferences.features[key]}
                onCheckedChange={() => handleFeatureToggle(key)}
                disabled={isLoading}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Кнопки действий */}
      <div className="flex justify-end gap-4">
        {mode === 'profile' && (
          <Button
            variant="outline"
            onClick={() => {
              setPreferences({
                ...DEFAULT_PREFERENCES,
                customCriteria: {}
              });
              setActivePreset(null);
              setIsCustomMode(false);
              setHasChanges(true);
            }}
            disabled={isLoading}
          >
            Reset to Default
          </Button>
        )}
        <Button
          onClick={handleSave}
          disabled={!hasChanges || isLoading}
        >
          {isLoading ? (
            <div className="flex items-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Saving preferences...
            </div>
          ) : (
            'Save Preferences'
          )}
        </Button>
      </div>
    </div>
  );
};

export default PreferencesManager;