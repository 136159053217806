// src/components/layout/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../config/paths';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Для разных типов пользователей */}
          <div>
            <h4 className="font-bold mb-4">Information</h4>
            <ul className="space-y-2">
              <li><Link to={ROUTES.FOR_TRAVELERS} className="hover:text-gray-300">For Travelers</Link></li>
              <li><Link to={ROUTES.FOR_HOTELS} className="hover:text-gray-300">For Hotels</Link></li>
              <li><Link to={ROUTES.FOR_BLOGGERS} className="hover:text-gray-300">For Travel Bloggers</Link></li>
              <li><Link to={ROUTES.FOR_INVESTORS} className="hover:text-gray-300">For Investors</Link></li>
            </ul>
          </div>

          {/* Поддержка */}
          <div>
            <h4 className="font-bold mb-4">Support</h4>
            <ul className="space-y-2">
              <li><Link to={ROUTES.HELP} className="hover:text-gray-300">Help Center</Link></li>
              <li><Link to={ROUTES.CONTACT} className="hover:text-gray-300">Contact Us</Link></li>
            </ul>
          </div>

          {/* Правовая информация */}
          <div>
            <h4 className="font-bold mb-4">Legal</h4>
            <ul className="space-y-2">
              <li><Link to={ROUTES.PRIVACY} className="hover:text-gray-300">Privacy Policy</Link></li>
              <li><Link to={ROUTES.TERMS} className="hover:text-gray-300">Terms of Service</Link></li>
              <li><Link to={ROUTES.COOKIES} className="hover:text-gray-300">Cookie Policy</Link></li>
              <li><Link to={ROUTES.DISCLAIMER} className="hover:text-gray-300">Disclaimer</Link></li>
              <li><Link to={ROUTES.AFFILIATE_DISCLOSURE} className="hover:text-gray-300">Affiliate Disclosure</Link></li>
              <li><Link to={ROUTES.COMMENT_POLICY} className="hover:text-gray-300">Comment Policy</Link></li>
              <li><Link to={ROUTES.REFUND_POLICY} className="hover:text-gray-300">Refund Policy</Link></li>
            </ul>
          </div>

          {/* Соцсети и контакты */}
          <div>
            <h4 className="font-bold mb-4">Connect with Us</h4>
            <div className="space-y-4">
              <div className="space-x-4">
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" 
                   className="hover:text-gray-300">Twitter</a>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" 
                   className="hover:text-gray-300">Facebook</a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" 
                   className="hover:text-gray-300">Instagram</a>
              </div>
              <div className="text-sm">
                <p>Email: contact@hotelsrating.com</p>
                <p className="mt-2">© {new Date().getFullYear()} HotelsRating. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;