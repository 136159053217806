// src/pages/admin/bookings/AdminBookingDetailsPage.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { 
  ArrowLeft,
  Download,
  Edit,
  Mail,
  Phone,
  History,
  AlertTriangle,
  User,
  Hotel,
  Calendar,
  Clock,
  CreditCard,
  Building,
  CheckCircle,
  XCircle
} from 'lucide-react';
import { useToast } from '../../../hooks/useToast';
import { Button } from '../../../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../../../components/ui/card';
import { Separator } from '../../../components/ui/separator';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../../components/ui/dialog';
import { ROUTES } from '../../../config/paths';
import BookingService from '../../../services/booking/BookingService';
import BookingStatus from '../../../components/booking/BookingStatus';
import { Booking } from '../../../types/booking';

export const AdminBookingDetailsPage = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [booking, setBooking] = useState<Booking | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<'confirmed' | 'cancelled' | null>(null);
  const [statusComment, setStatusComment] = useState('');
  const [processingStatus, setProcessingStatus] = useState(false);

  useEffect(() => {
    if (bookingId) {
      loadBookingDetails();
    }
  }, [bookingId]);

  const loadBookingDetails = async () => {
    try {
      setLoading(true);
      setError(null);

      const bookingData = await BookingService.getBooking(bookingId!);
      setBooking(bookingData);

    } catch (error) {
      console.error('Error loading booking:', error);
      setError(error instanceof Error ? error.message : 'Failed to load booking details');
      toast({
        title: "Error",
        description: "Failed to load booking details",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async () => {
    if (!selectedStatus || !booking) return;

    try {
      setProcessingStatus(true);
      await BookingService.updateBookingStatus(booking.id, selectedStatus, statusComment);
      
      toast({
        title: "Status Updated",
        description: "Booking status has been successfully updated"
      });

      await loadBookingDetails();
      setIsStatusDialogOpen(false);

    } catch (error) {
      console.error('Error updating status:', error);
      toast({
        title: "Error",
        description: "Failed to update booking status",
        variant: "destructive"
      });
    } finally {
      setProcessingStatus(false);
    }
  };

  const handleExport = async () => {
    try {
      await BookingService.exportBookings({
        format: 'pdf',
        filters: { bookingId: booking?.id }
      });
      
      toast({
        title: "Success",
        description: "Booking details exported successfully"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to export booking details",
        variant: "destructive"
      });
    }
  };

  if (loading) {
    return (
      <div className="p-6">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
        </div>
      </div>
    );
  }

  if (error || !booking) {
    return (
      <div className="p-6">
        <Card>
          <CardContent className="p-6">
            <div className="text-center">
              <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
              <h3 className="text-lg font-medium mb-2">
                {error || 'Booking not found'}
              </h3>
              <Button
                variant="outline"
                onClick={() => navigate(ROUTES.ADMIN_BOOKINGS)}
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Back to Bookings
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-start mb-6">
        <div className="space-y-1">
          <Button 
            variant="ghost" 
            onClick={() => navigate(ROUTES.ADMIN_BOOKINGS)}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Bookings
          </Button>
          <h1 className="text-2xl font-bold">Booking Details</h1>
          <p className="text-gray-600">
            Reference: {booking.bookingReference}
          </p>
        </div>
        <div className="flex gap-3">
          <Button variant="outline" onClick={handleExport}>
            <Download className="w-4 h-4 mr-2" />
            Export
          </Button>
          <Button onClick={() => setIsStatusDialogOpen(true)}>
            <Edit className="w-4 h-4 mr-2" />
            Update Status
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Status */}
          <Card>
            <CardContent className="p-6">
              <BookingStatus 
                status={booking.status}
                message={booking.statusMessage}
                lastUpdated={booking.updatedAt}
              />
            </CardContent>
          </Card>

          {/* Hotel Details */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Hotel className="w-5 h-5" />
                Hotel Details
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div>
                <h3 className="font-medium">{booking.hotelDetails.name}</h3>
                <p className="text-gray-600">{booking.hotelDetails.address}</p>
              </div>
              <div>
                <h4 className="font-medium">Room</h4>
                <p className="text-gray-600">{booking.roomDetails.name}</p>
                <p className="text-sm text-gray-500">{booking.roomDetails.type}</p>
              </div>
            </CardContent>
          </Card>

          {/* Booking Details */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Calendar className="w-5 h-5" />
                Booking Details
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="font-medium">Check-in</h4>
                  <p className="text-gray-600">
                    {format(new Date(booking.checkIn), 'PPP')}
                  </p>
                </div>
                <div>
                  <h4 className="font-medium">Check-out</h4>
                  <p className="text-gray-600">
                    {format(new Date(booking.checkOut), 'PPP')}
                  </p>
                </div>
              </div>
              <div>
                <h4 className="font-medium">Guests</h4>
                <p className="text-gray-600">
                  {booking.guests.adults} Adults
                  {booking.guests.children > 0 && `, ${booking.guests.children} Children`}
                </p>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Guest Information */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <User className="w-5 h-5" />
                Guest Information
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center gap-2">
                <Mail className="w-4 h-4 text-gray-400" />
                <a href={`mailto:${booking.userDetails.email}`} className="text-brand-blue hover:underline">
                  {booking.userDetails.email}
                </a>
              </div>
              {booking.userDetails.phone && (
                <div className="flex items-center gap-2">
                  <Phone className="w-4 h-4 text-gray-400" />
                  <a href={`tel:${booking.userDetails.phone}`} className="text-brand-blue hover:underline">
                    {booking.userDetails.phone}
                  </a>
                </div>
              )}
            </CardContent>
          </Card>

          {/* Payment Details */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <CreditCard className="w-5 h-5" />
                Payment Details
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">Room rate</span>
                  <span>${booking.price.base}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Taxes & fees</span>
                  <span>${booking.price.tax}</span>
                </div>
                <Separator className="my-2" />
                <div className="flex justify-between font-medium">
                  <span>Total</span>
                  <span className="text-brand-blue">${booking.price.total}</span>
                </div>
                <div className="text-xs text-gray-500 mt-2">
                  Created on {format(new Date(booking.createdAt), 'PPP')}
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Status History */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <History className="w-5 h-5" />
                Status History
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {booking.statusHistory.map((history, index) => (
                  <div key={index} className="text-sm">
                    <div className="font-medium">{history.status}</div>
                    <div className="text-gray-500">
                      {format(new Date(history.timestamp), 'PPP p')}
                    </div>
                    {history.comment && (
                      <div className="text-gray-600 mt-1">{history.comment}</div>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Status Update Dialog */}
      <Dialog open={isStatusDialogOpen} onOpenChange={setIsStatusDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update Booking Status</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="grid grid-cols-2 gap-4">
              <Button
                variant={selectedStatus === 'confirmed' ? 'default' : 'outline'}
                onClick={() => setSelectedStatus('confirmed')}
                className="flex items-center gap-2"
              >
                <CheckCircle className="w-4 h-4" />
                Confirm
              </Button>
              <Button
                variant={selectedStatus === 'cancelled' ? 'default' : 'outline'}
                onClick={() => setSelectedStatus('cancelled')}
                className="flex items-center gap-2"
              >
                <XCircle className="w-4 h-4" />
                Cancel
              </Button>
            </div>
            <textarea
              placeholder="Add a comment (optional)"
              className="w-full p-2 border rounded-md"
              value={statusComment}
              onChange={(e) => setStatusComment(e.target.value)}
              rows={3}
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsStatusDialogOpen(false)}
              disabled={processingStatus}
            >
              Cancel
            </Button>
            <Button
              onClick={handleStatusChange}
              disabled={!selectedStatus || processingStatus}
            >
              {processingStatus ? 'Updating...' : 'Update Status'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminBookingDetailsPage;