// src/services/api/amadeus/error-handler.js
import { ERROR_MESSAGES } from './constants';
import { enhanceError } from './utils';

export function handleApiError(error, endpoint) {
  console.error(`API Error (${endpoint}):`, error);

  // Если это ответ API с ошибкой
  if (error.errors?.[0]) {
    const apiError = error.errors[0];
    const message = ERROR_MESSAGES[apiError.code] || 
      `${apiError.title}: ${apiError.detail || ''}`;
    
    return enhanceError(new Error(message), endpoint);
  }

  // Если это ошибка HTTP
  if (error.response?.status) {
    const message = ERROR_MESSAGES[error.response.status] || 
      `Server responded with status ${error.response.status}`;
    
    return enhanceError(new Error(message), endpoint);
  }

  // Для любых других ошибок
  return enhanceError(
    new Error(error.message || 'An error occurred while processing your request'),
    endpoint
  );
}