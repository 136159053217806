import {
  Wifi,
  Coffee,
  Waves,
  Flower2,
  Dumbbell,
  UtensilsCrossed,
  ParkingCircle,
  Plane,
  Building2,
  Hotel,
  Dog,
  Baby,
  Accessibility,
  ShieldCheck,
  MapPin,
  DollarSign,
  Home,
  Package,
  HeartHandshake,
  Sparkles,
  Palette,
  Star
} from 'lucide-react';

export const DEFAULT_PREFERENCES = {
  criteria: {
    location: 50,
    price: 50,
    comfort: 50,
    amenities: 50,
    service: 50,
    cleanliness: 50,
    food: 50,
    atmosphere: 50
  },
  features: {
    wifi: false,
    parking: false,
    pool: false,
    spa: false,
    gym: false,
    restaurant: false,
    roomService: false,
    airportTransfer: false,
    businessFacilities: false,
    luxuryRoom: false,
    petFriendly: false,
    familyFriendly: false,
    accessibility: false,
    security: false
  },
  customCriteria: {} // Добавляем поддержку пользовательских критериев
};

export const CRITERIA_CONFIG = {
  location: {
    name: 'Location',
    description: 'Proximity to attractions, transportation, and city center',
    icon: MapPin
  },
  price: {
    name: 'Price',
    description: 'Value for money and overall cost',
    icon: DollarSign
  },
  comfort: {
    name: 'Comfort',
    description: 'Room comfort, bed quality, and amenities',
    icon: Home
  },
  amenities: {
    name: 'Amenities',
    description: 'Available facilities and services',
    icon: Package
  },
  service: {
    name: 'Service',
    description: 'Staff responsiveness and hospitality',
    icon: HeartHandshake
  },
  cleanliness: {
    name: 'Cleanliness',
    description: 'Overall cleanliness and hygiene',
    icon: Sparkles
  },
  food: {
    name: 'Food & Beverage',
    description: 'Restaurant quality and dining options',
    icon: UtensilsCrossed
  },
  atmosphere: {
    name: 'Atmosphere',
    description: 'Overall ambiance and design',
    icon: Palette
  }
};

export const FEATURES_CONFIG = {
  wifi: {
    name: 'Wi-Fi',
    description: 'High-speed internet access',
    icon: Wifi
  },
  parking: {
    name: 'Parking',
    description: 'On-site parking facilities',
    icon: ParkingCircle
  },
  pool: {
    name: 'Swimming Pool',
    description: 'Pool access',
    icon: Waves
  },
  spa: {
    name: 'Spa Services',
    description: 'Spa and wellness facilities',
    icon: Flower2
  },
  gym: {
    name: 'Fitness Center',
    description: 'Gym and exercise facilities',
    icon: Dumbbell
  },
  restaurant: {
    name: 'Restaurant',
    description: 'On-site dining options',
    icon: UtensilsCrossed
  },
  roomService: {
    name: 'Room Service',
    description: '24/7 room service availability',
    icon: Coffee
  },
  airportTransfer: {
    name: 'Airport Transfer',
    description: 'Airport shuttle service',
    icon: Plane
  },
  businessFacilities: {
    name: 'Business Facilities',
    description: 'Conference rooms and business center',
    icon: Building2
  },
  luxuryRoom: {
    name: 'Luxury Rooms',
    description: 'Premium room amenities',
    icon: Hotel
  },
  petFriendly: {
    name: 'Pet Friendly',
    description: 'Pets allowed',
    icon: Dog
  },
  familyFriendly: {
    name: 'Family Friendly',
    description: 'Child-friendly facilities',
    icon: Baby
  },
  accessibility: {
    name: 'Accessibility',
    description: 'Facilities for disabled guests',
    icon: Accessibility
  },
  security: {
    name: 'Security',
    description: '24/7 security service',
    icon: ShieldCheck
  }
};