import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { 
  Mail, 
  Phone, 
  MapPin, 
  MessageSquare, 
  User, 
  Send,
  AlertCircle,
  HelpCircle,
  Flag,
  Star,
  Bug,
  Coffee
} from 'lucide-react';
import { useToast } from '../../hooks/useToast';
import contactService from '../../services/contactService';

const INQUIRY_TYPES = {
  GENERAL: {
    value: 'general',
    label: 'General Inquiry',
    icon: HelpCircle,
    description: 'General questions about our services'
  },
  SUPPORT: {
    value: 'support',
    label: 'Technical Support',
    icon: Bug,
    description: 'Issues with website functionality'
  },
  FEEDBACK: {
    value: 'feedback',
    label: 'Feedback & Suggestions',
    icon: MessageSquare,
    description: 'Share your ideas and feedback'
  },
  REPORT: {
    value: 'report',
    label: 'Report an Issue',
    icon: Flag,
    description: 'Report inappropriate content or behavior'
  },
  BUSINESS: {
    value: 'business',
    label: 'Business Inquiry',
    icon: Coffee,
    description: 'Partnership and business opportunities'
  },
  REVIEW: {
    value: 'review',
    label: 'Review Appeal',
    icon: Star,
    description: 'Appeal regarding a review decision'
  }
};

export default function ContactPage() {
  const { currentUser } = useAuth();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    name: currentUser?.displayName || '',
    email: currentUser?.email || '',
    type: '',
    subject: '',
    message: '',
    attachments: []
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.type) newErrors.type = 'Please select inquiry type';
    if (!formData.subject.trim()) newErrors.subject = 'Subject is required';
    if (!formData.message.trim()) newErrors.message = 'Message is required';
    if (formData.message.length < 20) newErrors.message = 'Message must be at least 20 characters';
    
    console.log('Validation errors:', newErrors); // Отладочный лог
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm() || isSubmitting) return;
  
    setIsSubmitting(true);
    
    try {
      const messageData = {
        ...formData,
        createdAt: new Date().toISOString(), // Убедимся, что это поле существует
        status: 'new' // Добавим явный статус
      };
  
      const result = await contactService.submitMessage(messageData);
      
      console.log('Message submitted:', result); // Отладочный лог
  
      toast({
        title: "Message Sent",
        description: "Thank you for your message. We'll get back to you soon!"
      });
  
      setFormData({
        name: currentUser?.displayName || '',
        email: currentUser?.email || '',
        type: '',
        subject: '',
        message: '',
        attachments: []
      });
  
    } catch (error) {
      console.error('Message submission error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to send message. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + formData.attachments.length > 3) {
      toast({
        title: "Error",
        description: "Maximum 3 files allowed",
        variant: "destructive"
      });
      return;
    }

    const validFiles = files.filter(file => {
      if (file.size > 5 * 1024 * 1024) {
        toast({
          title: "Error",
          description: `File ${file.name} exceeds 5MB limit`,
          variant: "destructive"
        });
        return false;
      }
      return true;
    });

    setFormData(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...validFiles]
    }));
  };

  const removeAttachment = (index) => {
    setFormData(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Contact Us
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Have a question or feedback? We'd love to hear from you. 
            Choose a category below and we'll make sure your message gets to the right team.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Contact Form */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-lg shadow-sm border p-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Contact Type Selection */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {Object.values(INQUIRY_TYPES).map(({ value, label, icon: Icon, description }) => (
                    <div
                      key={value}
                      className={`
                        relative p-4 rounded-lg border cursor-pointer transition-colors
                        ${formData.type === value 
                          ? 'border-brand-blue bg-brand-blue/5 text-brand-blue' 
                          : 'hover:border-gray-300'
                        }
                      `}
                      onClick={() => setFormData(prev => ({ ...prev, type: value }))}
                    >
                      <div className="flex items-start gap-3">
                        <Icon className={`w-5 h-5 mt-0.5 ${formData.type === value ? 'text-brand-blue' : 'text-gray-400'}`} />
                        <div>
                          <div className="font-medium">{label}</div>
                          <div className="text-sm text-gray-500">{description}</div>
                        </div>
                      </div>
                      {errors.type && value === '' && (
                        <div className="text-red-500 text-sm mt-1">{errors.type}</div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Name & Email */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Your Name
                    </label>
                    <div className="mt-1 relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <User className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        value={formData.name}
                        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        className={`
                          block w-full pl-10 pr-3 py-2 border rounded-lg focus:ring-2 focus:ring-brand-blue focus:border-brand-blue
                          ${errors.name ? 'border-red-500' : 'border-gray-300'}
                        `}
                        placeholder="John Doe"
                      />
                      {errors.name && (
                        <div className="text-red-500 text-sm mt-1">{errors.name}</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <div className="mt-1 relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Mail className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="email"
                        value={formData.email}
                        onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                        className={`
                          block w-full pl-10 pr-3 py-2 border rounded-lg focus:ring-2 focus:ring-brand-blue focus:border-brand-blue
                          ${errors.email ? 'border-red-500' : 'border-gray-300'}
                        `}
                        placeholder="you@example.com"
                      />
                      {errors.email && (
                        <div className="text-red-500 text-sm mt-1">{errors.email}</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Subject */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Subject
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={formData.subject}
                      onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                      className={`
                        block w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-brand-blue focus:border-brand-blue
                        ${errors.subject ? 'border-red-500' : 'border-gray-300'}
                      `}
                      placeholder="Brief description of your inquiry"
                    />
                    {errors.subject && (
                      <div className="text-red-500 text-sm mt-1">{errors.subject}</div>
                    )}
                  </div>
                </div>

                {/* Message */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows={6}
                      value={formData.message}
                      onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                      className={`
                        block w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-brand-blue focus:border-brand-blue
                        ${errors.message ? 'border-red-500' : 'border-gray-300'}
                      `}
                      placeholder="Please provide as much detail as possible..."
                    />
                    {errors.message && (
                      <div className="text-red-500 text-sm mt-1">{errors.message}</div>
                    )}
                  </div>
                </div>

                {/* File Attachments */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Attachments (optional)
                  </label>
                  <div className="mt-1">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      multiple
                      accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                      className="block w-full text-sm text-gray-500
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-lg file:border-0
                        file:text-sm file:font-medium
                        file:bg-brand-blue/10 file:text-brand-blue
                        hover:file:bg-brand-blue/20"
                    />
                    <p className="text-xs text-gray-500 mt-1">
                      Max 3 files. Maximum size per file: 5MB. Supported formats: JPG, PNG, PDF, DOC
                    </p>
                  </div>
                  {formData.attachments.length > 0 && (
                    <div className="mt-3 space-y-2">
                      {formData.attachments.map((file, index) => (
                        <div key={index} className="flex items-center gap-2 text-sm">
                          <div className="flex-1 truncate">{file.name}</div>
                          <button
                            type="button"
                            onClick={() => removeAttachment(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {/* Submit Button */}
                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`
                      inline-flex items-center px-6 py-3 border border-transparent rounded-lg
                      text-base font-medium text-white bg-brand-blue 
                      hover:bg-brand-blue/90 focus:outline-none focus:ring-2 
                      focus:ring-offset-2 focus:ring-brand-blue
                      disabled:opacity-50 disabled:cursor-not-allowed
                      transition-colors duration-200
                    `}
                  >
                    {isSubmitting ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Sending...
                      </>
                    ) : (
                      <>
                        <Send className="w-5 h-5 mr-2" />
                        Send Message
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Contact Information */}
          <div className="lg:col-span-1">
            <div className="bg-white rounded-lg shadow-sm border p-6 space-y-6">
              <h2 className="text-xl font-semibold text-gray-900">
                Contact Information
              </h2>

              <div className="space-y-4">
              <div className="flex items-start gap-3">
                  <Mail className="w-5 h-5 text-brand-blue mt-1" />
                  <div>
                    <h3 className="font-medium text-gray-900">Email</h3>
                    <p className="text-gray-600">
                      General inquiries: contact@hotelsrating.com<br />
                      Support: support@hotelsrating.com<br />
                      Business: partners@hotelsrating.com
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <AlertCircle className="w-5 h-5 text-brand-blue mt-1" />
                  <div>
                    <h3 className="font-medium text-gray-900">Response Time</h3>
                    <p className="text-gray-600">
                      We aim to respond to all inquiries within 24-48 hours during business days.
                    </p>
                  </div>
                </div>
              </div>

              {/* FAQ Section */}
              <div className="mt-8 pt-6 border-t">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                  Frequently Asked Questions
                </h2>
                
                <div className="space-y-4">
                  <div className="group">
                    <h3 className="font-medium text-gray-900">What's your refund policy?</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      We process refunds within 5-7 business days. Visit our refund policy page for more details.
                    </p>
                  </div>

                  <div className="group">
                    <h3 className="font-medium text-gray-900">How do I update my profile?</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      You can update your profile information in your account settings after logging in.
                    </p>
                  </div>

                  <div className="group">
                    <h3 className="font-medium text-gray-900">How do I become a partner?</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      For partnership inquiries, please email partners@hotelsrating.com with your business details.
                    </p>
                  </div>
                </div>
              </div>

              {/* Social Media Links */}
              <div className="mt-8 pt-6 border-t">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                  Connect With Us
                </h2>
                
                <div className="flex space-x-4">
                  <a
                    href="https://twitter.com/hotelsrating"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-brand-blue transition-colors"
                  >
                    Twitter
                  </a>
                  <a
                    href="https://facebook.com/hotelsrating"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-brand-blue transition-colors"
                  >
                    Facebook
                  </a>
                  <a
                    href="https://instagram.com/hotelsrating"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-brand-blue transition-colors"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://linkedin.com/company/hotelsrating"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-brand-blue transition-colors"
                  >
                    LinkedIn
                  </a>
                </div>
              </div>

              {/* Quick Help Box */}
              <div className="mt-8 bg-gray-50 rounded-lg p-4">
                <div className="flex items-center gap-3 text-brand-blue">
                  <HelpCircle className="w-5 h-5" />
                  <h3 className="font-medium">Need Quick Help?</h3>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  Check our comprehensive help center for immediate answers to common questions.
                </p>
                <button
                  onClick={() => navigate('/help')}
                  className="mt-3 w-full text-center py-2 px-4 border border-brand-blue text-brand-blue rounded-lg hover:bg-brand-blue hover:text-white transition-colors duration-200"
                >
                  Visit Help Center
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}