// src/components/legal/AffiliateDisclosure.jsx
export const AffiliateDisclosure = () => {
    return (
      <div className="max-w-4xl mx-auto p-8">
        <h1 className="text-3xl font-bold mb-8">Affiliate Disclosure for HotelsRating.com</h1>
        <div className="text-sm text-gray-600 mb-8">
          Effective Date: 25/11/2024
        </div>
        
        <div className="prose prose-blue max-w-none space-y-6">
          <p>
            At HotelsRating.com, we believe in transparency and building trust with our users. 
            This Affiliate Disclosure explains how we may earn commissions or other forms of compensation 
            from affiliate links and partnerships featured on our website.
          </p>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Affiliate Relationships</h2>
            <p>
              HotelsRating.com participates in affiliate marketing programs, which means we may receive 
              commissions when you click on links or make purchases through links to third-party websites. 
              These commissions help us maintain and improve our services at no additional cost to you.
            </p>
            <p className="mt-4">Examples of affiliate relationships include:</p>
            <ul className="list-disc pl-6">
              <li>Hotel booking platforms</li>
              <li>Travel services and products</li>
              <li>Other related service providers</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Compensation</h2>
            <p>
              The compensation we receive through affiliate links may influence the content, topics, 
              or recommendations made on HotelsRating.com. However, we are committed to providing 
              honest and unbiased information to our users.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">3. No Additional Cost to You</h2>
            <p>
              When you click on an affiliate link and make a purchase or booking, the price you pay 
              remains the same as if you had visited the website directly. Our commission comes 
              directly from the third-party provider.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Accuracy and Independence</h2>
            <p>
              We strive to provide accurate information about the products and services we recommend. 
              However, we cannot guarantee the accuracy, availability, or suitability of third-party 
              services. The decision to engage with any affiliate service is entirely yours, and we 
              recommend reviewing the terms and policies of the third-party provider before proceeding.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Sponsored Content</h2>
            <p>
              Occasionally, HotelsRating.com may feature sponsored content or partnerships with third 
              parties. Sponsored content will always be clearly identified as such. While we may receive 
              compensation for sponsored content, we ensure that it aligns with our values and interests.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Compliance with Laws and Regulations</h2>
            <p>HotelsRating.com complies with all relevant affiliate marketing regulations, including:</p>
            <ul className="list-disc pl-6">
              <li><strong>FTC Disclosure Guidelines (USA):</strong> Ensuring transparency about our affiliate relationships</li>
              <li><strong>GDPR (EU):</strong> Protecting the privacy of users while engaging with affiliate links</li>
              <li><strong>Other Applicable Laws:</strong> Adhering to regional requirements regarding affiliate marketing and disclosures</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
            <p>HotelsRating.com is not responsible for any issues that arise from your interactions with third-party websites, including but not limited to:</p>
            <ul className="list-disc pl-6">
              <li>Accuracy of information on third-party websites</li>
              <li>Customer service issues</li>
              <li>Disputes regarding purchases or bookings</li>
            </ul>
            <p className="mt-4">
              We encourage you to thoroughly review the terms, conditions, and policies of any third-party 
              website before making a transaction.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">8. Updates to This Affiliate Disclosure</h2>
            <p>
              We may update this Affiliate Disclosure from time to time to reflect changes in our affiliate 
              partnerships or legal requirements. Updates will be posted on this page with a revised 
              "Effective Date." Your continued use of HotelsRating.com constitutes your acceptance of the 
              updated Affiliate Disclosure.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">9. Contact Information</h2>
            <p>If you have questions or concerns about this Affiliate Disclosure, please contact us:</p>
            <div className="mt-4">
              <p>HotelsRating.com</p>
              <p>Email: dataprotection@hotelsrating.com</p>
            </div>
          </section>
  
          <div className="mt-8 text-sm text-gray-600">
            By using HotelsRating.com and clicking on affiliate links, you acknowledge and agree to this 
            Affiliate Disclosure.
          </div>
        </div>
      </div>
    );
  };