// src/services/contact/testFirestore.js
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';

export const testFirestore = async () => {
  try {
    // Простой запрос ко всей коллекции
    const querySnapshot = await getDocs(collection(db, 'contact_messages'));
    
    console.log('Connection success!');
    console.log('Total documents:', querySnapshot.size);
    
    // Выводим содержимое каждого документа
    querySnapshot.forEach((doc) => {
      console.log('Document ID:', doc.id);
      console.log('Document data:', doc.data());
    });

    return querySnapshot.size;
  } catch (error) {
    console.error('Firestore test failed:', error);
    throw error;
  }
};