import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Home, Video, Map, User, Menu, X, 
  Settings, Search, LogOut
} from 'lucide-react';
import { IMAGES, ROUTES } from '../../config/paths';
import { useAuth } from '../../contexts/AuthContext';

const Navigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { currentUser, logout } = useAuth();

  const navLinks = [
    { to: ROUTES.HOME, icon: Home, label: "Home" },
    { to: ROUTES.RATING, icon: Settings, label: "Personalize" },
    { to: ROUTES.REVIEWS, icon: Video, label: "Reviews" },
    { to: ROUTES.MAP, icon: Map, label: "Map" },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      // Дополнительные действия после выхода, если нужны
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <Link 
            to={ROUTES.HOME}
            className="flex items-center gap-3"
          >
            <img 
              src={IMAGES.LOGO} 
              alt="HotelsRating Logo" 
              className="w-8 h-8"
            />
            <div className="flex items-baseline">
              <span className="text-2xl font-bold text-brand-red">Hotels</span>
              <span className="text-2xl font-bold text-brand-blue">Rating</span>
            </div>
          </Link>

          {/* Search Bar */}
          <div className={`absolute left-0 w-full px-4 bg-white transition-all duration-300 ${
            isSearchOpen ? 'top-16' : '-top-full'
          }`}>
            <div className="relative max-w-2xl mx-auto py-4">
              <input
                type="text"
                placeholder="Search hotels, locations, or features..."
                className="w-full px-4 py-2 rounded-lg border focus:ring-2 focus:ring-brand-blue focus:border-brand-blue shadow-sm"
              />
              <button className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-brand-blue transition-colors duration-300">
                <Search size={20} />
              </button>
            </div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <button
              onClick={() => setIsSearchOpen(!isSearchOpen)}
              className="text-gray-700 hover:text-brand-blue transition-colors duration-300"
            >
              <Search size={20} />
            </button>
            
            {navLinks.map(({ to, icon: Icon, label }) => (
              <Link
                key={to}
                to={to}
                className="flex items-center gap-2 text-gray-700 hover:text-brand-blue transition-colors duration-300"
              >
                <Icon size={20} />
                <span>{label}</span>
              </Link>
            ))}

            {currentUser ? (
              <div className="flex items-center gap-4">
                <Link
                  to={ROUTES.PROFILE}
                  className="flex items-center gap-2 text-gray-700 hover:text-brand-blue transition-colors duration-300"
                >
                  <User size={20} />
                  <span className="truncate max-w-[120px]">
                    {currentUser.displayName || currentUser.email}
                  </span>
                </Link>
                <button
                  onClick={handleLogout}
                  className="flex items-center gap-2 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition-all duration-300"
                >
                  <LogOut size={20} />
                  <span className="hidden sm:inline">Logout</span>
                </button>
              </div>
            ) : (
              <Link
                to={ROUTES.LOGIN}
                className="flex items-center gap-2 bg-brand-blue text-white px-4 py-2 rounded-lg hover:bg-brand-blue/90 transition-all duration-300 transform hover:-translate-y-0.5 hover:shadow-lg"
              >
                <User size={20} />
                <span>Sign In</span>
              </Link>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-gray-700"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden transition-all duration-300 ${
        isMobileMenuOpen ? 'max-h-screen' : 'max-h-0'
      } overflow-hidden bg-white border-t`}>
        <div className="px-4 py-2 space-y-2">
          <button
            onClick={() => setIsSearchOpen(!isSearchOpen)}
            className="w-full text-left py-2 text-gray-700 hover:text-brand-blue transition-colors duration-300"
          >
            <Search size={20} className="inline mr-2" />
            Search
          </button>
          
          {navLinks.map(({ to, icon: Icon, label }) => (
            <Link
              key={to}
              to={to}
              className="flex items-center gap-2 w-full py-2 text-gray-700 hover:text-brand-blue transition-colors duration-300"
            >
              <Icon size={20} />
              {label}
            </Link>
          ))}

          {currentUser ? (
            <>
              <Link 
                to={ROUTES.PROFILE} 
                className="flex items-center gap-2 w-full py-2 text-gray-700"
              >
                <User size={20} className="inline mr-2" />
                {currentUser.displayName || currentUser.email}
              </Link>
              <button
                onClick={handleLogout}
                className="flex items-center gap-2 w-full py-2 text-red-600"
              >
                <LogOut size={20} className="inline mr-2" />
                Logout
              </button>
            </>
          ) : (
            <Link 
              to={ROUTES.LOGIN} 
              className="flex items-center gap-2 w-full py-2 text-gray-700"
            >
              <User size={20} className="inline mr-2" />
              Sign In
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;