// src/services/api/hotels/utils/cityUtils.js
import amadeusApiService from '../../amadeus';

/**
 * Маппинг популярных городов к их IATA кодам
 */
const CITY_MAPPING = {
  // Европа
  'paris': 'PAR',
  'london': 'LON',
  'rome': 'ROM',
  'madrid': 'MAD',
  'berlin': 'BER',
  'amsterdam': 'AMS',
  'barcelona': 'BCN',
  'milan': 'MIL',
  'vienna': 'VIE',
  'prague': 'PRG',
  'lisbon': 'LIS',
  'brussels': 'BRU',
  'copenhagen': 'CPH',
  'stockholm': 'STO',
  'athens': 'ATH',

  // Азия
  'tokyo': 'TYO',
  'beijing': 'BJS',
  'shanghai': 'SHA',
  'hong kong': 'HKG',
  'singapore': 'SIN',
  'bangkok': 'BKK',
  'seoul': 'SEL',
  'dubai': 'DXB',
  'delhi': 'DEL',
  'mumbai': 'BOM',

  // Америка
  'new york': 'NYC',
  'los angeles': 'LAX',
  'chicago': 'CHI',
  'toronto': 'YTO',
  'vancouver': 'YVR',
  'san francisco': 'SFO',
  'miami': 'MIA',
  'las vegas': 'LAS',
  'sao paulo': 'SAO',
  'mexico city': 'MEX',

  // Австралия и Океания
  'sydney': 'SYD',
  'melbourne': 'MEL',
  'brisbane': 'BNE',
  'auckland': 'AKL',

  // Африка
  'cairo': 'CAI',
  'johannesburg': 'JNB',
  'casablanca': 'CMN',
  'cape town': 'CPT'
};

/**
 * Кэш для хранения результатов поиска городов
 */
const cityCache = new Map();
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 часа

/**
 * Извлекает код города из строки назначения
 * 
 * @param {string} destination - Название города или его код
 * @returns {Promise<string>} IATA код города
 * @throws {Error} Если код города не может быть определен
 */
export async function extractCityCode(destination) {
  if (!destination) {
    throw new Error('Destination is required');
  }

  // Проверяем формат IATA кода (три заглавные буквы)
  if (/^[A-Z]{3}$/.test(destination)) {
    return destination;
  }

  // Если передан код в нижнем регистре, преобразуем его
  if (/^[a-z]{3}$/.test(destination)) {
    return destination.toUpperCase();
  }

  const normalized = destination.toLowerCase().trim();

  // Проверяем кэш
  const cacheKey = `city_${normalized}`;
  const cached = getCachedCity(cacheKey);
  if (cached) {
    return cached;
  }

  // Поиск в маппинге популярных городов
  for (const [city, code] of Object.entries(CITY_MAPPING)) {
    if (normalized.includes(city)) {
      setCachedCity(cacheKey, code);
      return code;
    }
  }

  // Поиск через API
  try {
    const searchResult = await amadeusApiService.searchLocations(normalized);
    
    if (!searchResult.data || searchResult.data.length === 0) {
      throw new Error(`No results found for "${destination}"`);
    }

    // Ищем точное совпадение
    const exactMatch = searchResult.data.find(location => 
      location.name.toLowerCase() === normalized ||
      location.address?.cityName?.toLowerCase() === normalized
    );

    if (exactMatch?.iataCode) {
      setCachedCity(cacheKey, exactMatch.iataCode);
      return exactMatch.iataCode;
    }

    // Если точного совпадения нет, берем первый результат
    if (searchResult.data[0]?.iataCode) {
      const code = searchResult.data[0].iataCode;
      setCachedCity(cacheKey, code);
      return code;
    }

    throw new Error(`Could not determine IATA code for "${destination}"`);
  } catch (error) {
    console.error('Error searching for city:', error);
    throw new Error(`Failed to get city code for "${destination}": ${error.message}`);
  }
}

/**
 * Получает закэшированный код города
 * 
 * @param {string} key - Ключ кэша
 * @returns {string|null} Код города или null
 */
function getCachedCity(key) {
  const cached = cityCache.get(key);
  if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
    return cached.code;
  }
  return null;
}

/**
 * Сохраняет код города в кэш
 * 
 * @param {string} key - Ключ кэша
 * @param {string} code - Код города
 */
function setCachedCity(key, code) {
  cityCache.set(key, {
    code,
    timestamp: Date.now()
  });

  // Очистка кэша по таймеру
  setTimeout(() => {
    cityCache.delete(key);
  }, CACHE_DURATION);
}

/**
 * Нормализует название города
 * 
 * @param {string} cityName - Название города
 * @returns {string} Нормализованное название
 */
export function normalizeCity(cityName) {
  return cityName
    .toLowerCase()
    .trim()
    .replace(/\s+/g, ' ') // Заменяем множественные пробелы на один
    .replace(/[^a-z\s]/g, ''); // Удаляем все кроме букв и пробелов
}

/**
 * Проверяет, является ли строка валидным IATA кодом
 * 
 * @param {string} code - Проверяемый код
 * @returns {boolean} Результат проверки
 */
export function isValidIataCode(code) {
  return /^[A-Z]{3}$/.test(code);
}

/**
 * Возвращает список популярных городов с их кодами
 * 
 * @returns {Array<{city: string, code: string}>} Массив городов и их кодов
 */
export function getPopularCities() {
  return Object.entries(CITY_MAPPING).map(([city, code]) => ({
    city: city.charAt(0).toUpperCase() + city.slice(1),
    code
  }));
}

/**
 * Очищает кэш городов
 */
export function clearCityCache() {
  cityCache.clear();
}

/**
 * Получает статистику кэша
 * 
 * @returns {Object} Статистика кэша
 */
export function getCacheStats() {
  return {
    size: cityCache.size,
    items: Array.from(cityCache.entries()).map(([key, value]) => ({
      key,
      code: value.code,
      age: Math.round((Date.now() - value.timestamp) / 1000) + 's'
    }))
  };
}