// src/pages/information/ForTravelersPage.jsx
import React from 'react';
import ForTravelers from '../../components/information/ForTravelers';

const ForTravelersPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <ForTravelers />
    </div>
  );
};

export default ForTravelersPage;