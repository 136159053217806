// src/components/information/ForInvestors.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  TrendingUp,
  Brain,
  DollarSign,
  BarChart,
  Users,
  Briefcase,
  Handshake,
  Rocket,
  Database,
  Target,
  Globe,
  LineChart,
  Settings,
  Mail,
  Phone,
  PieChart,
  Award,
  Building
} from 'lucide-react';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import { ROUTES } from '../../config/paths';

const ForInvestors = () => {
  const investmentHighlights = [
    {
      icon: TrendingUp,
      title: "Market Leadership",
      description: "Leading the transformation in personalized hotel ratings and recommendations"
    },
    {
      icon: Brain,
      title: "AI-Powered Platform",
      description: "Advanced algorithms delivering dynamic, personalized hotel ratings"
    },
    {
      icon: DollarSign,
      title: "Multiple Revenue Streams",
      description: "Diversified income from bookings, subscriptions, advertising, and services"
    },
    {
      icon: BarChart,
      title: "High Growth Potential",
      description: "Positioned in a $1.5T+ market with strong growth trajectory"
    },
    {
      icon: Users,
      title: "Strong Team",
      description: "Led by industry veterans with proven track records"
    },
    {
      icon: Briefcase,
      title: "Scalable Model",
      description: "Highly scalable platform with global market potential"
    }
  ];

  const opportunities = [
    {
      icon: Building,
      title: "Equity Partnership",
      description: "Become a shareholder and participate in our growth journey",
      features: [
        "Direct stake in company",
        "Voting rights",
        "Dividend potential",
        "Exit opportunities"
      ]
    },
    {
      icon: Handshake,
      title: "Strategic Partnership",
      description: "Collaborate strategically to expand market reach",
      features: [
        "Market expansion",
        "Technology sharing",
        "Co-branding opportunities",
        "Joint ventures"
      ]
    },
    {
      icon: Rocket,
      title: "Growth Capital",
      description: "Fuel our expansion and technology development",
      features: [
        "Platform enhancement",
        "Market expansion",
        "Team growth",
        "Product development"
      ]
    }
  ];

  const metrics = [
    { value: "$1.5T+", label: "Market Size" },
    { value: "200%", label: "YoY Growth" },
    { value: "5", label: "Revenue Streams" },
    { value: "150+", label: "Countries" }
  ];

  return (
    <div className="max-w-7xl mx-auto p-8">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
          Invest in the Future of Travel Experience
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
          Join us in revolutionizing how travelers choose their perfect hotel through AI-driven personalization and dynamic ratings.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Button size="lg" className="bg-brand-blue hover:bg-brand-blue/90">
            Investment Deck
          </Button>
          <Button size="lg" variant="outline">
            Schedule Call
          </Button>
        </div>
      </div>

      {/* Key Metrics */}
      <section className="mb-20 bg-gray-900 text-white rounded-lg p-12">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {metrics.map((metric, index) => (
            <div key={index} className="text-center">
              <div className="text-3xl md:text-4xl font-bold text-brand-blue mb-2">
                {metric.value}
              </div>
              <div className="text-gray-300">{metric.label}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Investment Highlights */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Why Invest in HotelsRating.com?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {investmentHighlights.map((highlight, index) => (
            <Card key={index}>
              <CardContent className="p-6">
                <highlight.icon className="w-12 h-12 text-brand-blue mb-4" />
                <h3 className="text-xl font-semibold mb-2">{highlight.title}</h3>
                <p className="text-gray-600">{highlight.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      {/* Revenue Model */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Diverse Revenue Streams
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <PieChart className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Booking Commissions</h3>
                <p className="text-gray-600">Revenue from direct hotel bookings through platform integration</p>
              </div>
            </div>
            <div className="flex items-start gap-4">
              <Users className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Subscription Plans</h3>
                <p className="text-gray-600">Recurring revenue from hotel and professional subscriptions</p>
              </div>
            </div>
            <div className="flex items-start gap-4">
              <Target className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Targeted Advertising</h3>
                <p className="text-gray-600">Premium placements and promotional opportunities</p>
              </div>
            </div>
          </div>
          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <Settings className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Professional Services</h3>
                <p className="text-gray-600">Video production and analytics services for hotels</p>
              </div>
            </div>
            <div className="flex items-start gap-4">
              <Globe className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Events & Education</h3>
                <p className="text-gray-600">Revenue from industry conferences and workshops</p>
              </div>
            </div>
            <div className="flex items-start gap-4">
              <Database className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Data Insights</h3>
                <p className="text-gray-600">Monetization of industry analytics and trends</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Investment Opportunities */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Investment Opportunities
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {opportunities.map((opportunity, index) => (
            <Card key={index}>
              <CardContent className="p-6">
                <opportunity.icon className="w-12 h-12 text-brand-blue mb-4" />
                <h3 className="text-xl font-semibold mb-2">{opportunity.title}</h3>
                <p className="text-gray-600 mb-4">{opportunity.description}</p>
                <ul className="space-y-2">
                  {opportunity.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center gap-2 text-sm text-gray-600">
                      <Award className="w-4 h-4 text-brand-blue" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      {/* Use of Funds */}
      <section className="mb-20 bg-gray-50 rounded-lg p-12">
        <h2 className="text-3xl font-bold text-center mb-12">
          How We'll Use Your Investment
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <div className="h-4 bg-brand-blue rounded-full mb-2" style={{width: '80%'}} />
            <h3 className="font-semibold mb-1">Technology Development</h3>
            <p className="text-gray-600">Platform enhancement and AI capabilities</p>
          </div>
          <div>
            <div className="h-4 bg-brand-blue rounded-full mb-2" style={{width: '60%'}} />
            <h3 className="font-semibold mb-1">Marketing & Growth</h3>
            <p className="text-gray-600">User acquisition and brand development</p>
          </div>
          <div>
            <div className="h-4 bg-brand-blue rounded-full mb-2" style={{width: '40%'}} />
            <h3 className="font-semibold mb-1">Team Expansion</h3>
            <p className="text-gray-600">Hiring key personnel and experts</p>
          </div>
          <div>
            <div className="h-4 bg-brand-blue rounded-full mb-2" style={{width: '20%'}} />
            <h3 className="font-semibold mb-1">Operations</h3>
            <p className="text-gray-600">Infrastructure and operational costs</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="text-center mb-20">
        <h2 className="text-3xl font-bold mb-8">Ready to Explore Investment Opportunities?</h2>
        <div className="max-w-2xl mx-auto">
          <p className="text-xl text-gray-600 mb-8">
            Join us in revolutionizing the travel industry with AI-driven personalization and dynamic ratings.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8">
            <Button size="lg" className="bg-brand-blue hover:bg-brand-blue/90">
              Request Investment Deck
            </Button>
            <Link to={ROUTES.CONTACT}>
              <Button size="lg" variant="outline">
                Schedule Meeting
              </Button>
            </Link>
          </div>
          <div className="flex flex-col sm:flex-row gap-8 justify-center text-gray-600">
            <div className="flex items-center justify-center gap-2">
              <Mail className="w-5 h-5" />
              <span>investors@hotelsrating.com</span>
            </div>
            <div className="flex items-center justify-center gap-2">
              <Phone className="w-5 h-5" />
              <span>+1 (555) 123-4567</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForInvestors;