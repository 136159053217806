// src/pages/contact/ContactPage.jsx
import React from 'react';
import ContactForm from '../../components/contact/ContactForm';
import { useAuth } from '../../contexts/AuthContext';

const ContactPage = () => {
  const { currentUser } = useAuth();

  return (
    <ContactForm 
      initialData={{
        name: currentUser?.displayName || '',
        email: currentUser?.email || ''
      }}
    />
  );
};

export default ContactPage;