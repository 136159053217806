// src/pages/Homepage.jsx
import React from 'react';
import { Star, Video, Map, Link as LinkIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button } from '../components/ui/button';
import { Card, CardContent } from '../components/ui/card';
import SearchForm from '../components/search/SearchForm';
import PresetBanners from '../components/PresetBanners';
import { ROUTES } from '../config/paths';
import { IMAGES } from '../config/paths';

const Homepage = () => {
  const { currentUser } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative bg-primary-600">
        {/* Gradient Background */}
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={IMAGES.BACKGROUNDS.HERO}
          />
          <div className="absolute inset-0 bg-primary-600 mix-blend-multiply" />
        </div>
        
        {/* Content */}
        <div className="relative pt-20 pb-32">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center space-y-6 text-white">
              <h1 className="text-4xl md:text-5xl font-bold">
                Personalized Hotels Rating Just For You
              </h1>
              <p className="text-xl text-blue-100 max-w-2xl mx-auto">
                Discover hotels that match your unique preferences and travel style
              </p>
            </div>

            {/* Search Form Container */}
            <div className="relative z-10 w-full max-w-4xl mx-auto mt-12">
              <div className="transform translate-y-8">
                <SearchForm />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Spacing for form overlap */}
      <div className="h-16" /> {/* Adjust this value based on your form height */}

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Personalized Ratings */}
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-4">
                <div className="text-brand-blue">
                  <Star className="w-8 h-8" />
                </div>
                <h3 className="text-xl font-semibold">Personal Rating System</h3>
                <p className="text-gray-600">
                  Define what matters most to you in a hotel and get personalized recommendations
                </p>
                <Link to={ROUTES.RATING}>
                  <Button variant="outline" className="w-full">
                    Set your preferences
                    <LinkIcon className="w-4 h-4 ml-2" />
                  </Button>
                </Link>
              </div>
            </CardContent>
          </Card>

          {/* Video Reviews */}
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-4">
                <div className="text-brand-blue">
                  <Video className="w-8 h-8" />
                </div>
                <h3 className="text-xl font-semibold">Video Reviews</h3>
                <p className="text-gray-600">
                  Watch authentic video reviews from trusted travel bloggers
                </p>
                <Link to={ROUTES.REVIEWS}>
                  <Button variant="outline" className="w-full">
                    Watch reviews
                    <LinkIcon className="w-4 h-4 ml-2" />
                  </Button>
                </Link>
              </div>
            </CardContent>
          </Card>

          {/* Interactive Map */}
          <Card>
            <CardContent className="pt-6">
              <div className="space-y-4">
                <div className="text-brand-blue">
                  <Map className="w-8 h-8" />
                </div>
                <h3 className="text-xl font-semibold">Interactive Map</h3>
                <p className="text-gray-600">
                  Find and filter hotels by location and your preferences
                </p>
                <Link to={ROUTES.MAP}>
                  <Button variant="outline" className="w-full">
                    Explore map
                    <LinkIcon className="w-4 h-4 ml-2" />
                  </Button>
                </Link>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Rest of the components */}
      <PresetBanners />

      {/* CTA Section */}
      <div className="bg-gray-900 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <div className="space-y-8">
            <h2 className="text-3xl md:text-4xl font-bold">
              Start Your Personalized Hotel Journey
            </h2>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto">
              Join thousands of travelers who've found their perfect stay using our personalized rating system
            </p>
            {!currentUser && (
              <Link to={ROUTES.REGISTER}>
                <Button size="lg" variant="default" className="bg-white text-brand-blue hover:bg-white/90">
                  Create Free Account
                  <LinkIcon className="w-4 h-4 ml-2" />
                </Button>
              </Link>
            )}
            {currentUser && (
              <Link to={ROUTES.PROFILE}>
                <Button size="lg" variant="default" className="bg-white text-brand-blue hover:bg-white/90">
                  View Your Profile
                  <LinkIcon className="w-4 h-4 ml-2" />
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* Trust Indicators */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center space-y-4">
            <p className="text-gray-600">Trusted by travelers worldwide</p>
            <div className="flex flex-wrap justify-center items-center gap-8">
              <div className="text-gray-400">5000+ Hotels</div>
              <div className="text-gray-400">10000+ Reviews</div>
              <div className="text-gray-400">98% Satisfaction</div>
            </div>
          </div>
        </div>
      </div>

      {/* Portal container for popovers */}
      <div id="portal-root" />
    </div>
  );
};

export default Homepage;