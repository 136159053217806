// src/pages/admin/contact/SpamSettings.jsx

import React from 'react';
import { AlertTriangle } from 'lucide-react';
import SpamSettingsComponent from '../../../components/admin/contact/SpamSettings';

const SpamSettingsPage = () => {
  return (
    <div className="space-y-6">
      <div className="flex items-start justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">
            Настройки защиты от спама
          </h1>
          <p className="mt-2 text-sm text-gray-600">
            Управление фильтрами и правилами обработки сообщений
          </p>
        </div>
      </div>

      {/* Предупреждение для администраторов */}
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex items-start">
          <AlertTriangle className="h-5 w-5 text-yellow-400 mt-0.5" />
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Важное примечание
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Изменение этих настроек может повлиять на обработку всех входящих сообщений.
                Рекомендуется тщательно тестировать новые правила перед применением.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Основные настройки */}
      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <div className="p-6">
          <SpamSettingsComponent />
        </div>
      </div>
    </div>
  );
};

export default SpamSettingsPage;