// src/components/profile/ProfileFavorites.jsx
import React from 'react';

const ProfileFavorites = () => {
  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900">Favorite Hotels</h3>
      <p className="text-gray-500">Your saved hotels will appear here.</p>
    </div>
  );
};

export default ProfileFavorites;