// src/components/hotels/detail/index.js
import PropTypes from 'prop-types';

// Импортируем все компоненты для переэкспорта
import HeaderInfo from './HeaderInfo';
import MainContent from './MainContent';
import Sidebar from './Sidebar';
import LoadingState from './LoadingState';
import GallerySkeleton from './GallerySkeleton';
import AmenitiesTab from './AmenitiesTab';
import LocationTab from './LocationTab';
import OverviewTab from './OverviewTab';
import RoomsTab from './RoomsTab';
import ReviewsTab from './ReviewsTab';
import PolicySection from './PolicySection';
import FaqSection from './FaqSection';

// Экспортируем все компоненты
export {
 HeaderInfo,
 MainContent,
 Sidebar,
 LoadingState,
 GallerySkeleton,
 AmenitiesTab,
 LocationTab,
 OverviewTab,
 RoomsTab,
 ReviewsTab,
 PolicySection,
 FaqSection
};

// Дополнительные типы и константы, если они нужны
export const HOTEL_TABS = {
 OVERVIEW: 'overview',
 ROOMS: 'rooms',
 AMENITIES: 'amenities',
 LOCATION: 'location',
 REVIEWS: 'reviews'
};

// Можно также добавить общие типы для компонентов
export const hotelDetailPropTypes = {
 id: PropTypes.string.isRequired,
 name: PropTypes.string.isRequired,
 description: PropTypes.string,
 images: PropTypes.arrayOf(PropTypes.string),
 rating: PropTypes.shape({
   stars: PropTypes.number,
   category: PropTypes.string
 }),
 price: PropTypes.shape({
   current: PropTypes.shape({
     amount: PropTypes.number,
     currency: PropTypes.string
   }),
   original: PropTypes.shape({
     amount: PropTypes.number,
     currency: PropTypes.string
   })
 }),
 location: PropTypes.shape({
   address: PropTypes.shape({
     lines: PropTypes.arrayOf(PropTypes.string),
     city: PropTypes.string,
     postal: PropTypes.string,
     country: PropTypes.string
   }),
   coordinates: PropTypes.shape({
     latitude: PropTypes.number,
     longitude: PropTypes.number
   }),
   nearby: PropTypes.object
 }),
 details: PropTypes.shape({
   checkIn: PropTypes.string,
   checkOut: PropTypes.string,
   roomTypes: PropTypes.arrayOf(
     PropTypes.shape({
       name: PropTypes.string,
       size: PropTypes.string,
       occupancy: PropTypes.number,
       bedType: PropTypes.string,
       price: PropTypes.number,
       amenities: PropTypes.object,
       images: PropTypes.arrayOf(PropTypes.string)
     })
   ),
   policies: PropTypes.object,
   amenities: PropTypes.object,
   languages: PropTypes.arrayOf(PropTypes.string),
   additionalNotes: PropTypes.string
 }),
 features: PropTypes.object,
 contact: PropTypes.shape({
   phone: PropTypes.string,
   email: PropTypes.string,
   website: PropTypes.string
 })
};

// Общие утилиты для компонентов детальной страницы
export const utils = {
 formatPrice: (amount, currency = 'EUR') => {
   return new Intl.NumberFormat('en-US', {
     style: 'currency',
     currency: currency,
     minimumFractionDigits: 0,
     maximumFractionDigits: 0
   }).format(amount);
 },

 getFormattedAddress: (location) => {
   if (!location?.address) return 'Address not available';

   const parts = [
     location.address.lines?.join(', '),
     location.address.city,
     location.address.postal,
     location.address.country
   ].filter(Boolean);

   return parts.join(', ');
 },

 getAmenityIcon: (amenityKey) => {
   const iconMapping = {
     wifi: Wifi,
     parking: Car,
     restaurant: Utensils,
     pool: Waves,
     gym: Dumbbell,
     spa: Bath,
     roomService: Coffee
     // Добавьте другие маппинги по необходимости
   };
   return iconMapping[amenityKey] || Info;
 }
};

// Экспорт по умолчанию, если нужен
export default {
 HeaderInfo,
 MainContent,
 Sidebar,
 LoadingState,
 GallerySkeleton,
 AmenitiesTab,
 LocationTab,
 OverviewTab,
 RoomsTab,
 ReviewsTab,
 PolicySection,
 FaqSection,
 HOTEL_TABS,
 hotelDetailPropTypes,
 utils
};