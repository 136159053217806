// src/components/information/ForTravelers.jsx

import React from 'react';
import { 
  Star, 
  Users, 
  MapPin, 
  Globe,
  Plane,
  Coffee,
  Hotel,
  Sun,
  Camera,
  Shield,
  Settings,
  Heart
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import { ROUTES } from '../../config/paths';

const ForTravelers = () => {
  return (
    <div className="max-w-7xl mx-auto p-8">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          Your Perfect Hotel Experience Starts Here
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Discover hotels that truly match your preferences with our personalized rating system, designed specifically for modern travelers like you.
        </p>
      </div>

      {/* Key Features */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
        <Card>
          <CardContent className="p-6">
            <Star className="w-12 h-12 text-brand-blue mb-4" />
            <h3 className="text-xl font-semibold mb-2">Personalized Ratings</h3>
            <p className="text-gray-600">
              Get hotel recommendations based on your unique preferences, travel style, and past experiences.
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <Users className="w-12 h-12 text-brand-blue mb-4" />
            <h3 className="text-xl font-semibold mb-2">Travel Types</h3>
            <p className="text-gray-600">
              Whether it's family, business, romantic, or adventure travel - find hotels that match your journey's purpose.
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <MapPin className="w-12 h-12 text-brand-blue mb-4" />
            <h3 className="text-xl font-semibold mb-2">Location Intelligence</h3>
            <p className="text-gray-600">
              Discover hotels in the perfect location with detailed information about nearby attractions and amenities.
            </p>
          </CardContent>
        </Card>
      </div>

      {/* How It Works */}
      <section className="mb-16">
        <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="bg-brand-blue/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <Settings className="w-8 h-8 text-brand-blue" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Set Your Preferences</h3>
            <p className="text-gray-600">
              Tell us what matters most to you in a hotel stay.
            </p>
          </div>

          <div className="text-center">
            <div className="bg-brand-blue/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <Star className="w-8 h-8 text-brand-blue" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Get Personalized Ratings</h3>
            <p className="text-gray-600">
              Receive custom hotel ratings based on your preferences.
            </p>
          </div>

          <div className="text-center">
            <div className="bg-brand-blue/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
              <Heart className="w-8 h-8 text-brand-blue" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Book with Confidence</h3>
            <p className="text-gray-600">
              Choose hotels you'll love based on accurate, personalized recommendations.
            </p>
          </div>
        </div>
      </section>

      {/* Travel Categories */}
      <section className="mb-16">
        <h2 className="text-3xl font-bold text-center mb-12">Find Hotels For Any Type of Travel</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <Card>
            <CardContent className="p-6 text-center">
              <Plane className="w-10 h-10 text-brand-blue mx-auto mb-4" />
              <h3 className="font-semibold mb-2">Business Travel</h3>
              <p className="text-sm text-gray-600">
                Hotels with excellent work facilities and convenient locations
              </p>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="p-6 text-center">
              <Users className="w-10 h-10 text-brand-blue mx-auto mb-4" />
              <h3 className="font-semibold mb-2">Family Vacations</h3>
              <p className="text-sm text-gray-600">
                Family-friendly accommodations with suitable amenities
              </p>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="p-6 text-center">
              <Heart className="w-10 h-10 text-brand-blue mx-auto mb-4" />
              <h3 className="font-semibold mb-2">Romantic Getaways</h3>
              <p className="text-sm text-gray-600">
                Perfect settings for couples and special occasions
              </p>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="p-6 text-center">
              <Sun className="w-10 h-10 text-brand-blue mx-auto mb-4" />
              <h3 className="font-semibold mb-2">Adventure Travel</h3>
              <p className="text-sm text-gray-600">
                Unique stays for the adventurous spirit
              </p>
            </CardContent>
          </Card>
        </div>
      </section>

      {/* Features in Detail */}
      <section className="mb-16">
        <h2 className="text-3xl font-bold text-center mb-12">Everything You Need for the Perfect Stay</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="flex gap-4">
              <Camera className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Video Reviews</h3>
                <p className="text-gray-600">Watch authentic video reviews from trusted travelers.</p>
              </div>
            </div>

            <div className="flex gap-4">
              <Globe className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Cultural Preferences</h3>
                <p className="text-gray-600">Find hotels that match your cultural and dietary requirements.</p>
              </div>
            </div>

            <div className="flex gap-4">
              <Shield className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Verified Reviews</h3>
                <p className="text-gray-600">Trust in our community of genuine traveler reviews.</p>
              </div>
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex gap-4">
              <Coffee className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Amenity Preferences</h3>
                <p className="text-gray-600">Filter hotels based on the amenities that matter to you.</p>
              </div>
            </div>

            <div className="flex gap-4">
              <MapPin className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Interactive Maps</h3>
                <p className="text-gray-600">Explore hotels with our interactive neighborhood maps.</p>
              </div>
            </div>

            <div className="flex gap-4">
              <Hotel className="w-6 h-6 text-brand-blue flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-1">Real-Time Updates</h3>
                <p className="text-gray-600">Get the latest information about hotel conditions and services.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="text-center bg-gray-900 text-white rounded-lg p-12">
        <h2 className="text-3xl font-bold mb-6">
          Start Your Personalized Hotel Search Today
        </h2>
        <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
          Join thousands of satisfied travelers who've found their perfect hotel match through our platform.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Link to={ROUTES.REGISTER}>
            <Button size="lg" className="bg-brand-blue hover:bg-brand-blue/90">
              Create Free Account
            </Button>
          </Link>
          <Link to={ROUTES.SEARCH}>
            <Button size="lg" variant="outline" className="text-white border-white hover:bg-white/10">
              Start Searching
            </Button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default ForTravelers;