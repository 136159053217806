import React from 'react';
import { Settings } from 'lucide-react';
import { Slider } from "../ui/slider";
import { CRITERIA_CONFIG } from '../../config/preferences';

const CriteriaMapping = ({ 
  preferences, 
  onCriteriaChange, 
  isLoading 
}) => {
  return (
    <div className="space-y-6">
      {/* Стандартные критерии */}
      {Object.entries(CRITERIA_CONFIG).map(([key, config]) => {
        const Icon = config.icon;
        return (
          <div key={key} className="space-y-2">
            <div className="flex justify-between items-start">
              <div className="flex items-center gap-2">
                <Icon className="w-5 h-5 text-gray-500" />
                <div>
                  <label className="text-sm font-medium text-gray-900">
                    {config.name}
                  </label>
                  <p className="text-xs text-gray-500">
                    {config.description}
                  </p>
                </div>
              </div>
              <span className="text-sm font-medium text-gray-500">
                {preferences.criteria[key]}%
              </span>
            </div>
            <Slider
              value={[preferences.criteria[key]]}
              onValueChange={(value) => onCriteriaChange(key, value)}
              max={100}
              step={1}
              className="w-full"
              disabled={isLoading}
            />
          </div>
        );
      })}
      
      {/* Пользовательские критерии */}
      {Object.entries(preferences.customCriteria || {}).map(([key, config]) => (
        <div key={key} className="space-y-2">
          <div className="flex justify-between items-start">
            <div className="flex items-center gap-2">
              <Settings className="w-5 h-5 text-gray-500" />
              <div>
                <label className="text-sm font-medium text-gray-900">
                  {config.name}
                </label>
                <p className="text-xs text-gray-500">
                  {config.description}
                </p>
              </div>
            </div>
            <span className="text-sm font-medium text-gray-500">
              {preferences.criteria[key]}%
            </span>
          </div>
          <Slider
            value={[preferences.criteria[key]]}
            onValueChange={(value) => onCriteriaChange(key, value)}
            max={100}
            step={1}
            className="w-full"
            disabled={isLoading}
          />
        </div>
      ))}
    </div>
  );
};

export default CriteriaMapping;