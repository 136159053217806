// src/components/information/ForBloggers.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Camera,
  Users,
  DollarSign,
  Award,
  Key,
  Shield,
  UserCheck,
  Search,
  FileText,
  Pencil,
  Globe,
  TrendingUp,
  Network,
  Video,
  BadgeCheck,
  Mail,
  Phone,
  Trophy
} from 'lucide-react';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import { ROUTES } from '../../config/paths';

const ForBloggers = () => {
  const benefits = [
    {
      icon: Globe,
      title: "Expand Your Reach",
      description: "Share your content with thousands of travelers worldwide on our platform"
    },
    {
      icon: DollarSign,
      title: "Monetize Your Content",
      description: "Earn competitive fees creating authentic video reviews and content"
    },
    {
      icon: Award,
      title: "Build Your Portfolio",
      description: "Showcase your work with prestigious hotels and boost your credibility"
    },
    {
      icon: Key,
      title: "Exclusive Access",
      description: "Connect directly with hotel managers seeking quality content creators"
    },
    {
      icon: Shield,
      title: "Showcase Authenticity",
      description: "Maintain your creative voice while building trust with your audience"
    },
    {
      icon: Network,
      title: "Grow Your Network",
      description: "Connect with industry professionals and fellow content creators"
    }
  ];

  const steps = [
    {
      icon: UserCheck,
      title: "Create Your Profile",
      description: "Build a compelling profile showcasing your expertise and style"
    },
    {
      icon: Search,
      title: "Browse Projects",
      description: "Find hotels seeking content creators for reviews and promotions"
    },
    {
      icon: FileText,
      title: "Submit Proposals",
      description: "Pitch your creative ideas directly to hotels"
    },
    {
      icon: Video,
      title: "Create Content",
      description: "Produce authentic reviews and engaging content"
    },
    {
      icon: TrendingUp,
      title: "Grow Your Brand",
      description: "Build your reputation and expand your influence"
    }
  ];

  const requirements = [
    "Quality content production skills",
    "Engaging storytelling ability",
    "Professional photography/videography",
    "Active social media presence",
    "Reliable communication",
    "Travel enthusiasm and expertise"
  ];

  return (
    <div className="max-w-7xl mx-auto p-8">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
          Turn Your Travel Content Into Success
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
          Join HotelsRating.com's community of travel content creators and connect with hotels seeking authentic storytellers like you.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Button size="lg" className="bg-brand-blue hover:bg-brand-blue/90">
            Join as Creator
          </Button>
          <Button size="lg" variant="outline">
            View Opportunities
          </Button>
        </div>
      </div>

      {/* Key Benefits */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Why Join HotelsRating.com?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <Card key={index}>
              <CardContent className="p-6">
                <benefit.icon className="w-12 h-12 text-brand-blue mb-4" />
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      {/* How It Works */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Your Journey to Success
        </h2>
        <div className="space-y-8">
          {steps.map((step, index) => (
            <div key={index} className="flex items-start gap-6 relative">
              <div className="bg-brand-blue/10 p-4 rounded-lg">
                <step.icon className="w-8 h-8 text-brand-blue" />
              </div>
              <div className="flex-1">
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
              <div className="absolute left-8 top-16 bottom-0 w-px bg-gray-200 -z-10" />
            </div>
          ))}
        </div>
      </section>

      {/* Creator Requirements */}
      <section className="mb-20 bg-gray-900 text-white rounded-lg p-12">
        <h2 className="text-3xl font-bold text-center mb-8">
          What Hotels Are Looking For
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {requirements.map((req, index) => (
            <div key={index} className="flex items-center gap-4">
              <BadgeCheck className="w-6 h-6 text-brand-blue" />
              <span>{req}</span>
            </div>
          ))}
        </div>
      </section>

      {/* Success Stories */}
      <section className="mb-20">
        <h2 className="text-3xl font-bold text-center mb-12">
          Creator Success Stories
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <Card>
            <CardContent className="p-6">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-16 h-16 bg-gray-200 rounded-full" />
                <div>
                  <h3 className="font-semibold">Sarah Thompson</h3>
                  <p className="text-sm text-gray-600">Travel & Lifestyle Creator</p>
                </div>
              </div>
              <p className="text-gray-600">
                "Joining HotelsRating.com transformed my content creation career. I've collaborated with luxury hotels worldwide and doubled my income."
              </p>
              <div className="mt-4 flex items-center gap-2 text-sm text-brand-blue">
                <Trophy className="w-4 h-4" />
                <span>Top Creator 2024</span>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="p-6">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-16 h-16 bg-gray-200 rounded-full" />
                <div>
                  <h3 className="font-semibold">Mike Chen</h3>
                  <p className="text-sm text-gray-600">Video Content Specialist</p>
                </div>
              </div>
              <p className="text-gray-600">
                "The platform's professional network and steady stream of opportunities helped me establish my brand in the travel industry."
              </p>
              <div className="mt-4 flex items-center gap-2 text-sm text-brand-blue">
                <Video className="w-4 h-4" />
                <span>Best Video Content 2024</span>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="p-6">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-16 h-16 bg-gray-200 rounded-full" />
                <div>
                  <h3 className="font-semibold">Emma Rodriguez</h3>
                  <p className="text-sm text-gray-600">Travel Photographer</p>
                </div>
              </div>
              <p className="text-gray-600">
                "From boutique hotels to luxury resorts, every project through HotelsRating.com has helped expand my portfolio."
              </p>
              <div className="mt-4 flex items-center gap-2 text-sm text-brand-blue">
                <Camera className="w-4 h-4" />
                <span>Featured Photographer</span>
              </div>
            </CardContent>
          </Card>
        </div>
      </section>

      {/* Call to Action */}
      <section className="text-center mb-20">
        <h2 className="text-3xl font-bold mb-8">Ready to Start Your Journey?</h2>
        <div className="max-w-2xl mx-auto">
          <p className="text-xl text-gray-600 mb-8">
            Join our community of successful content creators and start collaborating with top hotels worldwide.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8">
            <Button size="lg" className="bg-brand-blue hover:bg-brand-blue/90">
              Create Creator Account
            </Button>
            <Link to={ROUTES.CONTACT}>
              <Button size="lg" variant="outline">
                Contact Support
              </Button>
            </Link>
          </div>
          <div className="flex flex-col sm:flex-row gap-8 justify-center text-gray-600">
            <div className="flex items-center justify-center gap-2">
              <Mail className="w-5 h-5" />
              <span>creators@hotelsrating.com</span>
            </div>
            <div className="flex items-center justify-center gap-2">
              <Phone className="w-5 h-5" />
              <span>+1 (555) 123-4567</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForBloggers;