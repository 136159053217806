// src/services/booking/BookingService.ts
import { db } from '../../config/firebase';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  getDoc, 
  getDocs,
  query,
  where,
  orderBy,
  doc,
  QuerySnapshot,
  DocumentReference 
} from 'firebase/firestore';
import { getHotelOffers } from '../api/amadeus';
import { 
  Booking,
  BookingGuests,
  CreateBookingParams,
  BookingSearchParams,
  UpdateBookingParams,
  BookingStats,
  BookingExportOptions,
  StatusHistoryItem,
} from '../../types/booking';
import { BOOKING_STATUS } from '../../components/booking/constants';

class BookingService {
  private readonly collection: string = 'bookings';

  /**
   * Создает новое бронирование
   */
  async createBooking(bookingData: CreateBookingParams): Promise<Booking> {
    try {
      // Проверяем доступность отеля
      const isAvailable = await this.checkAvailability(
        bookingData.hotelId,
        bookingData.checkIn,
        bookingData.checkOut,
        bookingData.guests
      );

      if (!isAvailable) {
        throw new Error('Hotel is not available for selected dates');
      }

      const bookingRef = collection(db, this.collection);
      const bookingId = this.generateBookingReference();
      
      const initialStatus: StatusHistoryItem = {
        status: BOOKING_STATUS.PENDING,
        timestamp: new Date().toISOString(),
        comment: 'Booking created'
      };

      const booking: Omit<Booking, 'id'> = {
        bookingReference: bookingId,
        userId: bookingData.userId,
        hotelId: bookingData.hotelId,
        roomId: bookingData.roomId,
        status: BOOKING_STATUS.PENDING,
        checkIn: new Date(bookingData.checkIn).toISOString(),
        checkOut: new Date(bookingData.checkOut).toISOString(),
        guests: bookingData.guests,
        price: bookingData.price,
        hotelDetails: bookingData.hotelDetails,
        roomDetails: bookingData.roomDetails,
        userDetails: bookingData.userDetails,
        statusHistory: [initialStatus],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        specialRequests: bookingData.specialRequests
      };

      const docRef = await addDoc(bookingRef, booking);
      
      return {
        id: docRef.id,
        ...booking
      };

    } catch (error) {
      console.error('Error creating booking:', error);
      throw error;
    }
  }

  /**
   * Получает бронирование по ID
   */
  async getBooking(bookingId: string): Promise<Booking> {
    try {
      const bookingRef = doc(db, this.collection, bookingId);
      const bookingDoc = await getDoc(bookingRef);
      
      if (!bookingDoc.exists()) {
        throw new Error('Booking not found');
      }
      
      return {
        id: bookingDoc.id,
        ...bookingDoc.data()
      } as Booking;

    } catch (error) {
      console.error('Error getting booking:', error);
      throw error;
    }
  }

  /**
   * Получает все бронирования пользователя
   */
  async getUserBookings(userId: string): Promise<Booking[]> {
    try {
      const q = query(
        collection(db, this.collection),
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Booking[];

    } catch (error) {
      console.error('Error getting user bookings:', error);
      throw error;
    }
  }

  /**
   * Поиск бронирований
   */
  async searchBookings(params: BookingSearchParams): Promise<Booking[]> {
    try {
      let q = collection(db, this.collection);

      // Применяем фильтры
      if (params.userId) {
        q = query(q, where('userId', '==', params.userId));
      }
      if (params.status) {
        q = query(q, where('status', '==', params.status));
      }
      if (params.dateFrom) {
        q = query(q, where('checkIn', '>=', params.dateFrom));
      }
      if (params.dateTo) {
        q = query(q, where('checkOut', '<=', params.dateTo));
      }

      // Сортировка
      q = query(q, orderBy(params.sortBy || 'createdAt', params.sortOrder || 'desc'));

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Booking[];

    } catch (error) {
      console.error('Error searching bookings:', error);
      throw error;
    }
  }

  /**
   * Обновляет статус бронирования
   */
  async updateBookingStatus(
    bookingId: string, 
    status: keyof typeof BOOKING_STATUS, 
    comment: string = ''
  ): Promise<void> {
    try {
      const bookingRef = doc(db, this.collection, bookingId);
      const bookingDoc = await getDoc(bookingRef);

      if (!bookingDoc.exists()) {
        throw new Error('Booking not found');
      }

      const booking = bookingDoc.data() as Booking;
      const statusHistory: StatusHistoryItem[] = [...booking.statusHistory, {
        status,
        timestamp: new Date().toISOString(),
        comment
      }];

      await updateDoc(bookingRef, {
        status,
        statusHistory,
        updatedAt: new Date().toISOString()
      });

    } catch (error) {
      console.error('Error updating booking status:', error);
      throw error;
    }
  }

  /**
   * Проверяет доступность отеля
   */
  private async checkAvailability(
    hotelId: string,
    checkIn: Date | string,
    checkOut: Date | string,
    guests: BookingGuests
  ): Promise<boolean> {
    try {
      const offers = await getHotelOffers(hotelId, {
        checkInDate: new Date(checkIn),
        checkOutDate: new Date(checkOut),
        adults: guests.adults,
        children: guests.children || 0
      });

      return offers && offers.length > 0;

    } catch (error) {
      console.error('Error checking availability:', error);
      throw error;
    }
  }

  /**
   * Генерирует референсный номер бронирования
   */
  private generateBookingReference(): string {
    const prefix = 'BK';
    const timestamp = Date.now().toString(36);
    const random = Math.random().toString(36).substring(2, 7).toUpperCase();
    return `${prefix}-${timestamp}-${random}`;
  }

  /**
   * Получает статистику бронирований
   */
  async getBookingStats(): Promise<BookingStats> {
    try {
      const bookings = await this.searchBookings({});
      
      const total = bookings.length;
      const byStatus = bookings.reduce((acc, booking) => {
        acc[booking.status] = (acc[booking.status] || 0) + 1;
        return acc;
      }, {} as Record<keyof typeof BOOKING_STATUS, number>);

      const totalRevenue = bookings.reduce((sum, booking) => 
        sum + booking.price.total, 0);

      return {
        total,
        byStatus,
        averageStayLength: 0,
        totalRevenue,
        cancellationRate: (byStatus.cancelled || 0) / total,
        popularRoomTypes: {}
      };

    } catch (error) {
      console.error('Error getting booking stats:', error);
      throw error;
    }
  }

  /**
   * Экспортирует бронирования
   */
  async exportBookings(options: BookingExportOptions): Promise<any> {
    try {
      const bookings = await this.searchBookings({
        dateFrom: options.dateRange?.from,
        dateTo: options.dateRange?.to,
        ...options.filters
      });

      // Здесь должна быть реализация экспорта в выбранный формат
      console.log('Exporting bookings:', bookings.length);
      
      return bookings;

    } catch (error) {
      console.error('Error exporting bookings:', error);
      throw error;
    }
  }
}

const bookingService = new BookingService();
export default bookingService;