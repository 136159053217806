// src/pages/PersonalizationPage.jsx
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import PreferencesManager from '../components/preferences/PreferencesManager';
import { Toast } from "../components/ui/toast"; // Убедитесь, что у вас есть этот компонент

const PersonalizationPage = () => {
  const { updateUserPreferences, currentUser } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSave = async (preferences) => {
    setIsSaving(true);
    setError('');
    setShowSuccess(false);

    try {
      await updateUserPreferences(preferences);
      setShowSuccess(true);
      // Скрываем сообщение об успехе через 3 секунды
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error('Error saving preferences:', error);
      setError('Failed to save preferences. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="space-y-6">
        {/* Заголовок */}
        <div>
          <h1 className="text-3xl font-bold text-gray-900">
            Personalize Your Hotel Experience
          </h1>
          <p className="mt-2 text-gray-600">
            Tell us your preferences to get personalized hotel recommendations
          </p>
        </div>

        {/* Сообщения */}
        {error && (
          <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-sm text-red-600">
            {error}
          </div>
        )}
        {showSuccess && (
          <div className="p-4 bg-green-50 border border-green-200 rounded-lg text-sm text-green-600">
            Your preferences have been saved successfully!
          </div>
        )}

        {/* Менеджер предпочтений */}
        <PreferencesManager
          mode="setup"
          showPresets={true}
          onSave={handleSave}
          isLoading={isSaving}
          initialPreferences={currentUser?.preferences}
        />
      </div>
    </div>
  );
};

export default PersonalizationPage;