// src/components/admin/contact/SpamSettings.jsx

import React, { useState, useEffect } from 'react';
import { 
  Shield, 
  Save,
  Plus,
  Trash2,
  AlertTriangle,
  RefreshCcw,
  Settings,
  Info
} from 'lucide-react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { FIRESTORE_PATHS, MODERATION_CONFIG } from '../../../components/contact/constants';

const SpamSettings = () => {
  const [settings, setSettings] = useState({
    spamKeywords: [],
    autoModeration: {
      enabled: true,
      minRecaptchaScore: 0.5,
      autoApproveScore: 0.8,
      autoRejectScore: 0.2,
      maxLinksCount: 2,
      maxRepeatedChars: 4,
      maxUppercasePercentage: 70,
    },
    rateLimiting: {
      enabled: true,
      maxAttempts: 5,
      timeWindow: 60, // минуты
      blockDuration: 24, // часы
    },
    notifications: {
      notifyAdmin: true,
      notifyUser: true,
    }
  });

  const [newKeyword, setNewKeyword] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      setLoading(true);
      setError(null);

      const settingsRef = doc(db, FIRESTORE_PATHS.SPAM_PATTERNS, 'settings');
      const settingsDoc = await getDoc(settingsRef);

      if (settingsDoc.exists()) {
        setSettings(prevSettings => ({
          ...prevSettings,
          ...settingsDoc.data()
        }));
      }
    } catch (error) {
      console.error('Error loading spam settings:', error);
      setError('Не удалось загрузить настройки');
    } finally {
      setLoading(false);
    }
  };

  const saveSettings = async () => {
    try {
      setSaving(true);
      setError(null);
      setSuccessMessage(null);

      const settingsRef = doc(db, FIRESTORE_PATHS.SPAM_PATTERNS, 'settings');
      await setDoc(settingsRef, settings, { merge: true });

      setSuccessMessage('Настройки успешно сохранены');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error) {
      console.error('Error saving spam settings:', error);
      setError('Не удалось сохранить настройки');
    } finally {
      setSaving(false);
    }
  };

  const addKeyword = () => {
    if (!newKeyword.trim()) return;
    
    setSettings(prev => ({
      ...prev,
      spamKeywords: [...new Set([...prev.spamKeywords, newKeyword.trim()])]
    }));
    setNewKeyword('');
  };

  const removeKeyword = (keyword) => {
    setSettings(prev => ({
      ...prev,
      spamKeywords: prev.spamKeywords.filter(k => k !== keyword)
    }));
  };

  const handleChange = (section, field, value) => {
    setSettings(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-gray-500">Загрузка настроек...</div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-sm rounded-lg">
      {/* Заголовок */}
      <div className="p-4 border-b border-gray-200">
        <h2 className="text-lg font-medium text-gray-900 flex items-center">
          <Shield className="w-5 h-5 mr-2" />
          Настройки защиты от спама
        </h2>
      </div>

      <div className="p-6 space-y-8">
        {/* Сообщения об ошибках и успехе */}
        {error && (
          <div className="p-4 bg-red-50 text-red-700 rounded-lg flex items-center">
            <AlertTriangle className="w-5 h-5 mr-2" />
            {error}
          </div>
        )}
        {successMessage && (
          <div className="p-4 bg-green-50 text-green-700 rounded-lg flex items-center">
            <CheckCircle className="w-5 h-5 mr-2" />
            {successMessage}
          </div>
        )}

        {/* Спам-слова */}
        <section>
          <h3 className="text-md font-medium text-gray-900 mb-4">Спам-слова</h3>
          <div className="space-y-4">
            <div className="flex gap-2">
              <input
                type="text"
                value={newKeyword}
                onChange={(e) => setNewKeyword(e.target.value)}
                placeholder="Добавить спам-слово..."
                className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                onClick={addKeyword}
                disabled={!newKeyword.trim()}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                <Plus className="w-4 h-4 mr-2" />
                Добавить
              </button>
            </div>
            <div className="flex flex-wrap gap-2">
              {settings.spamKeywords.map((keyword) => (
                <span
                  key={keyword}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800"
                >
                  {keyword}
                  <button
                    onClick={() => removeKeyword(keyword)}
                    className="ml-2 text-gray-500 hover:text-gray-700"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </span>
              ))}
            </div>
          </div>
        </section>

        {/* Автомодерация */}
        <section>
          <h3 className="text-md font-medium text-gray-900 mb-4 flex items-center">
            <Settings className="w-5 h-5 mr-2" />
            Настройки автомодерации
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={settings.autoModeration.enabled}
                  onChange={(e) => handleChange('autoModeration', 'enabled', e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">Включить автомодерацию</span>
              </label>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm text-gray-700 mb-1">
                    Минимальный reCAPTCHA score
                  </label>
                  <input
                    type="number"
                    min="0"
                    max="1"
                    step="0.1"
                    value={settings.autoModeration.minRecaptchaScore}
                    onChange={(e) => handleChange('autoModeration', 'minRecaptchaScore', parseFloat(e.target.value))}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 mb-1">
                    Максимум ссылок в сообщении
                  </label>
                  <input
                    type="number"
                    min="0"
                    value={settings.autoModeration.maxLinksCount}
                    onChange={(e) => handleChange('autoModeration', 'maxLinksCount', parseInt(e.target.value))}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            
            {/* Rate Limiting */}
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">Ограничение частоты</h4>
              <div className="space-y-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.rateLimiting.enabled}
                    onChange={(e) => handleChange('rateLimiting', 'enabled', e.target.checked)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">Включить ограничение</span>
                </label>
                <div>
                  <label className="block text-sm text-gray-700 mb-1">
                    Максимум попыток
                  </label>
                  <input
                    type="number"
                    min="1"
                    value={settings.rateLimiting.maxAttempts}
                    onChange={(e) => handleChange('rateLimiting', 'maxAttempts', parseInt(e.target.value))}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 mb-1">
                    Временное окно (минуты)
                  </label>
                  <input
                    type="number"
                    min="1"
                    value={settings.rateLimiting.timeWindow}
                    onChange={(e) => handleChange('rateLimiting', 'timeWindow', parseInt(e.target.value))}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Уведомления */}
        <section>
          <h3 className="text-md font-medium text-gray-900 mb-4">Настройки уведомлений</h3>
          <div className="space-y-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={settings.notifications.notifyAdmin}
                onChange={(e) => handleChange('notifications', 'notifyAdmin', e.target.checked)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">Уведомлять администратора о спаме</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={settings.notifications.notifyUser}
                onChange={(e) => handleChange('notifications', 'notifyUser', e.target.checked)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">Уведомлять пользователя о блокировке</span>
            </label>
          </div>
        </section>

        {/* Кнопки действий */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={loadSettings}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <RefreshCcw className="w-4 h-4 mr-2" />
            Сбросить
          </button>
          <button
            onClick={saveSettings}
            disabled={saving}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            <Save className="w-4 h-4 mr-2" />
            {saving ? 'Сохранение...' : 'Сохранить'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpamSettings;