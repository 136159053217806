// src/components/hotels/detail/RoomsTab.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { 
 Users,
 Maximize2,
 BedDouble,
 Wifi,
 Tv,
 Wind,
 Coffee,
 Bath,
 Info
} from 'lucide-react';
import { Card, CardContent } from '../../ui/card';
import { Button } from '../../ui/button';
import {
 Tooltip,
 TooltipContent,
 TooltipProvider,
 TooltipTrigger,
} from '../../ui/tooltip';

// Конфигурация иконок для удобств в комнате
const ROOM_AMENITIES_ICONS = {
 wifi: { icon: Wifi, label: 'Free WiFi' },
 tv: { icon: Tv, label: 'Flat-screen TV' },
 airConditioning: { icon: Wind, label: 'Air conditioning' },
 coffeemaker: { icon: Coffee, label: 'Coffee maker' },
 privateBathroom: { icon: Bath, label: 'Private bathroom' }
};

const RoomAmenityIcon = ({ icon: Icon, label }) => (
 <TooltipProvider>
   <Tooltip>
     <TooltipTrigger asChild>
       <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
         <Icon className="w-4 h-4 text-gray-600" />
       </div>
     </TooltipTrigger>
     <TooltipContent>
       <p>{label}</p>
     </TooltipContent>
   </Tooltip>
 </TooltipProvider>
);

const RoomCard = ({ room, currency = 'EUR', onSelect }) => {
 const {
   name,
   description,
   size,
   occupancy,
   bedType,
   price,
   amenities = {},
   images = [],
   availability,
   cancellationPolicy
 } = room;

 return (
   <Card className="overflow-hidden">
     <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
       {/* Room Image */}
       <div className="relative md:col-span-1">
         <img
           src={images[0] || "/api/placeholder/400/300"}
           alt={name}
           className="w-full h-48 md:h-full object-cover"
         />
         {images.length > 1 && (
           <div className="absolute bottom-2 right-2 bg-black/60 text-white px-2 py-1 rounded text-xs">
             +{images.length - 1} photos
           </div>
         )}
       </div>

       {/* Room Details */}
       <div className="p-6 md:col-span-2 space-y-4">
         {/* Room Header */}
         <div className="flex justify-between items-start">
           <div>
             <h3 className="text-lg font-semibold">{name}</h3>
             <p className="text-sm text-gray-600">{description}</p>
           </div>
           <div className="text-right">
             <div className="text-sm text-gray-600">Price per night</div>
             <div className="text-xl font-bold text-brand-blue">
               {currency} {price.toFixed(2)}
             </div>
           </div>
         </div>

         {/* Room Specs */}
         <div className="flex flex-wrap gap-4 text-sm text-gray-600">
           {size && (
             <div className="flex items-center gap-1">
               <Maximize2 className="w-4 h-4" />
               <span>{size} m²</span>
             </div>
           )}
           {occupancy && (
             <div className="flex items-center gap-1">
               <Users className="w-4 h-4" />
               <span>Up to {occupancy} guests</span>
             </div>
           )}
           {bedType && (
             <div className="flex items-center gap-1">
               <BedDouble className="w-4 h-4" />
               <span>{bedType}</span>
             </div>
           )}
         </div>

         {/* Room Amenities */}
         <div className="flex flex-wrap gap-3">
           {Object.entries(amenities).map(([key, value]) => {
             if (!value || !ROOM_AMENITIES_ICONS[key]) return null;
             const { icon, label } = ROOM_AMENITIES_ICONS[key];
             return (
               <RoomAmenityIcon 
                 key={key} 
                 icon={icon} 
                 label={label} 
               />
             );
           })}
         </div>

         {/* Availability & Policies */}
         <div className="flex items-center justify-between pt-4 border-t">
           <div className="space-y-2">
             {availability && (
               <div className="flex items-center gap-2 text-sm">
                 <div className={`w-2 h-2 rounded-full ${
                   availability === 'available' ? 'bg-green-500' : 'bg-yellow-500'
                 }`} />
                 <span className="capitalize">{availability}</span>
               </div>
             )}
             {cancellationPolicy && (
               <TooltipProvider>
                 <Tooltip>
                   <TooltipTrigger className="flex items-center gap-1 text-sm text-brand-blue">
                     <Info className="w-4 h-4" />
                     Cancellation Policy
                   </TooltipTrigger>
                   <TooltipContent>
                     <p className="max-w-xs">{cancellationPolicy}</p>
                   </TooltipContent>
                 </Tooltip>
               </TooltipProvider>
             )}
           </div>
           
           {/* Action Buttons */}
           <div className="space-x-2">
             {availability === 'available' ? (
               <>
                 <Button 
                   variant="outline" 
                   size="sm"
                   onClick={() => onSelect(room, 'details')}
                 >
                   Details
                 </Button>
                 <Button 
                   size="sm"
                   onClick={() => onSelect(room, 'book')}
                 >
                   Book Now
                 </Button>
               </>
             ) : (
               <Button 
                 variant="outline" 
                 size="sm"
                 onClick={() => onSelect(room, 'waitlist')}
               >
                 Join Waitlist
               </Button>
             )}
           </div>
         </div>
       </div>
     </div>
   </Card>
 );
};

const RoomsTab = ({ hotel, onRoomSelect }) => {
 const { roomTypes = [] } = hotel.details || {};

 if (roomTypes.length === 0) {
   return (
     <Card>
       <CardContent className="p-6">
         <div className="text-center text-gray-500 py-8">
           No rooms information available
         </div>
       </CardContent>
     </Card>
   );
 }

 return (
   <div className="space-y-6">
     {roomTypes.map((room, index) => (
       <RoomCard
         key={index}
         room={room}
         currency={hotel.price?.current?.currency}
         onSelect={onRoomSelect}
       />
     ))}

     {/* Room Notes */}
     {hotel.details?.roomNotes && (
       <Card>
         <CardContent className="p-4">
           <div className="flex items-start gap-2 text-sm text-gray-600">
             <Info className="w-4 h-4 mt-0.5 flex-shrink-0" />
             <p>{hotel.details.roomNotes}</p>
           </div>
         </CardContent>
       </Card>
     )}
   </div>
 );
};

RoomAmenityIcon.propTypes = {
 icon: PropTypes.elementType.isRequired,
 label: PropTypes.string.isRequired
};

RoomCard.propTypes = {
 room: PropTypes.shape({
   name: PropTypes.string.isRequired,
   description: PropTypes.string,
   size: PropTypes.string,
   occupancy: PropTypes.number,
   bedType: PropTypes.string,
   price: PropTypes.number.isRequired,
   amenities: PropTypes.object,
   images: PropTypes.arrayOf(PropTypes.string),
   availability: PropTypes.oneOf(['available', 'limited', 'unavailable']),
   cancellationPolicy: PropTypes.string
 }).isRequired,
 currency: PropTypes.string,
 onSelect: PropTypes.func.isRequired
};

RoomsTab.propTypes = {
 hotel: PropTypes.shape({
   details: PropTypes.shape({
     roomTypes: PropTypes.arrayOf(PropTypes.object),
     roomNotes: PropTypes.string
   }),
   price: PropTypes.shape({
     current: PropTypes.shape({
       currency: PropTypes.string
     })
   })
 }).isRequired,
 onRoomSelect: PropTypes.func.isRequired
};

export default RoomsTab;