// src/components/legal/RefundPolicy.jsx
export const RefundPolicy = () => {
    return (
      <div className="max-w-4xl mx-auto p-8">
        <h1 className="text-3xl font-bold mb-8">Refund Policy for HotelsRating.com</h1>
        <div className="text-sm text-gray-600 mb-8">
          Effective Date: 25/11/2024
        </div>
        
        <div className="prose prose-blue max-w-none space-y-6">
          <p>
            At HotelsRating.com, we are committed to providing high-quality services and user satisfaction. 
            This Refund Policy outlines the terms and conditions under which refunds may be issued. 
            By using our website and services, you agree to this policy.
          </p>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Scope of This Refund Policy</h2>
            <p>This policy applies to:</p>
            <ul className="list-disc pl-6">
              <li>Paid subscriptions or premium features on HotelsRating.com.</li>
              <li>Services directly purchased through our website (e.g., advertising or video production services).</li>
              <li>Commission-based services where applicable, as outlined in specific agreements.</li>
            </ul>
            <p className="mt-4">
              This policy does not cover third-party bookings or purchases made through affiliate links or external platforms. 
              For such cases, please refer to the refund policies of the respective third-party providers.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Eligibility for Refunds</h2>
            <p>Refunds may be granted under the following circumstances:</p>
            <ul className="list-disc pl-6">
              <li><strong>Service Not Delivered</strong>: If a paid service is not delivered within the specified time frame due to our fault.</li>
              <li><strong>Technical Issues</strong>: If technical issues on our platform prevent you from accessing paid features or services and we are unable to resolve the issue within a reasonable period.</li>
              <li><strong>Duplicate Payments</strong>: If you are charged multiple times for the same service.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Non-Refundable Items</h2>
            <p>The following are not eligible for refunds:</p>
            <ul className="list-disc pl-6">
              <li>Fees for services that have already been fully rendered or completed.</li>
              <li>Payments made for third-party services (e.g., hotel bookings, affiliate transactions).</li>
              <li>Cancellations initiated by the user after a service has commenced or been partially fulfilled.</li>
              <li>Refund requests made beyond 7 days after the date of purchase.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Refund Request Process</h2>
            <p>To request a refund:</p>
            <ol className="list-decimal pl-6">
              <li><strong>Contact Us</strong>: Submit a request via email at support@hotelsrating.com or through our support portal.</li>
              <li><strong>Provide Documentation</strong>: Include proof of payment, your account details, and a detailed explanation of the issue.</li>
              <li><strong>Processing Time</strong>: Refund requests will be reviewed within 7 business days. If approved, refunds will be processed to the original payment method used for the transaction within 10-14 business days.</li>
            </ol>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Partial Refunds</h2>
            <p>In some cases, partial refunds may be issued, such as:</p>
            <ul className="list-disc pl-6">
              <li>When a portion of a service has been rendered.</li>
              <li>When a refund is requested after the initial cooling-off period but within the service term.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Cooling-Off Period</h2>
            <p>
              Where required by law (e.g., EU Consumer Rights Directive), users may have the right to cancel certain services 
              within 14 days of purchase without penalty. This applies only to specific services as mandated by applicable regulations.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">7. Disputes and Chargebacks</h2>
            <p>
              If you have a dispute regarding a charge, we encourage you to contact us directly to resolve the matter before 
              initiating a chargeback with your financial institution. Unauthorized chargebacks may result in account 
              suspension or termination.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
            <p>
              HotelsRating.com's liability for refunds is limited to the amount paid by the user for the specific service in question. 
              We are not responsible for:
            </p>
            <ul className="list-disc pl-6">
              <li>Indirect, incidental, or consequential damages arising from the use of our services.</li>
              <li>Refunds or disputes related to third-party providers or affiliates.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">9. Updates to This Refund Policy</h2>
            <p>
              We may update this Refund Policy from time to time to reflect changes in our services or legal requirements. 
              Updates will be posted on this page with a revised "Effective Date." Your continued use of HotelsRating.com 
              constitutes acceptance of the updated Refund Policy.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">10. Contact Information</h2>
            <p>If you have questions or need assistance regarding refunds, please contact us:</p>
            <div className="mt-4">
              <p>HotelsRating.com</p>
              <p>Email: support@hotelsrating.com</p>
            </div>
          </section>
  
          <div className="mt-8 text-sm text-gray-600">
            By purchasing or using services on HotelsRating.com, you acknowledge and agree to this Refund Policy.
          </div>
        </div>
      </div>
    );
  };