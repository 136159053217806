// src/components/booking/BookingSummary.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Calendar,
  Users,
  Building2,
  ArrowRight,
  Clock,
  ChevronRight,
  CreditCard,
  MapPin
} from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { ROUTES } from '../../config/paths';
import { Booking } from '../../types/booking';
import BookingStatus from './BookingStatus';

interface BookingSummaryProps {
  booking: Booking;
  showActions?: boolean;
  compact?: boolean;
  className?: string;
  onAction?: (booking: Booking) => void;
}

export const BookingSummary: React.FC<BookingSummaryProps> = ({
  booking,
  showActions = true,
  compact = false,
  className = '',
  onAction
}) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), compact ? 'PP' : 'PPP');
  };

  const calculateNights = () => {
    const checkIn = new Date(booking.checkIn);
    const checkOut = new Date(booking.checkOut);
    return Math.ceil((checkOut.getTime() - checkIn.getTime()) / (1000 * 60 * 60 * 24));
  };

  const handleViewDetails = () => {
    if (onAction) {
      onAction(booking);
    } else {
      navigate(ROUTES.getBookingDetailsPath(booking.id));
    }
  };

  const handlePayment = () => {
    navigate(ROUTES.BOOKING_PAYMENT.replace(':bookingId', booking.id));
  };

  return (
    <Card className={className}>
      <CardContent className={compact ? "p-4" : "p-6"}>
        <div className="space-y-4">
          {/* Status and Reference */}
          <div className="flex items-start justify-between">
            <div>
              <BookingStatus
                status={booking.status}
                className="mb-2"
              />
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <Clock className="w-4 h-4" />
                <span>Booking ref: {booking.bookingReference}</span>
              </div>
            </div>
            {booking.price && (
              <div className="text-right">
                <div className="text-sm text-gray-600">Total price</div>
                <div className="text-lg font-bold text-brand-blue">
                  ${booking.price.total}
                </div>
              </div>
            )}
          </div>

          {/* Main Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Hotel and Room */}
            <div className="flex items-start gap-3">
              <Building2 className="w-5 h-5 text-gray-400 mt-1" />
              <div>
                <div className="font-medium">{booking.hotelDetails.name}</div>
                <div className="text-sm text-gray-600">{booking.roomDetails.name}</div>
                {!compact && booking.hotelDetails.address && (
                  <div className="flex items-center gap-1 text-sm text-gray-500 mt-1">
                    <MapPin className="w-4 h-4" />
                    {booking.hotelDetails.address}
                  </div>
                )}
              </div>
            </div>

            {/* Dates */}
            <div className="flex items-start gap-3">
              <Calendar className="w-5 h-5 text-gray-400 mt-1" />
              <div>
                <div className="flex items-center gap-2">
                  <span className="text-sm">{formatDate(booking.checkIn)}</span>
                  <ArrowRight className="w-4 h-4 text-gray-400" />
                  <span className="text-sm">{formatDate(booking.checkOut)}</span>
                </div>
                <div className="text-sm text-gray-600 mt-1">
                  {calculateNights()} nights
                </div>
                {!compact && (
                  <div className="text-sm text-gray-500 mt-1">
                    Check-in: {booking.hotelDetails.checkIn || '15:00'}
                    <br />
                    Check-out: {booking.hotelDetails.checkOut || '12:00'}
                  </div>
                )}
              </div>
            </div>

            {/* Guests */}
            <div className="flex items-start gap-3">
              <Users className="w-5 h-5 text-gray-400 mt-1" />
              <div className="text-sm text-gray-600">
                {booking.guests.adults} Adults
                {booking.guests.children > 0 && `, ${booking.guests.children} Children`}
              </div>
            </div>

            {/* Created Date */}
            {!compact && (
              <div className="text-sm text-gray-500">
                Booked on {formatDate(booking.createdAt)}
              </div>
            )}
          </div>

          {/* Actions */}
          {showActions && (
            <div className="flex flex-wrap gap-3 pt-4 border-t border-gray-200">
              <Button
                variant="outline"
                size={compact ? "sm" : "default"}
                onClick={handleViewDetails}
              >
                View Details
                <ChevronRight className="w-4 h-4 ml-1" />
              </Button>

              {booking.status === 'payment_required' && (
                <Button
                  size={compact ? "sm" : "default"}
                  onClick={handlePayment}
                >
                  <CreditCard className="w-4 h-4 mr-2" />
                  Complete Payment
                </Button>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default BookingSummary;