// src/components/admin/AdminSetup.jsx
import React from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from '../ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';

const AdminSetup = () => {
    const { currentUser } = useAuth();
    const [currentRole, setCurrentRole] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        const checkCurrentRole = async () => {
            if (!currentUser) return;
            
            const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
            if (userDoc.exists()) {
                setCurrentRole(userDoc.data().role);
            }
        };

        checkCurrentRole();
    }, [currentUser]);

    const makeAdmin = async () => {
        if (!currentUser) {
            alert('Please login first');
            return;
        }

        setIsLoading(true);
        try {
            await setDoc(doc(db, 'users', currentUser.uid), {
                role: 'admin',
                updatedAt: new Date().toISOString()
            }, { merge: true });
            setCurrentRole('admin');
            alert('Admin role set successfully');
        } catch (error) {
            console.error('Error setting admin role:', error);
            alert('Error setting admin role');
        } finally {
            setIsLoading(false);
        }
    };

    if (!currentUser) {
        return (
            <div className="flex min-h-screen items-center justify-center">
                <Card className="w-[400px]">
                    <CardHeader>
                        <CardTitle>Admin Setup</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-red-500">Please login first</p>
                    </CardContent>
                </Card>
            </div>
        );
    }

    return (
        <div className="flex min-h-screen items-center justify-center">
            <Card className="w-[400px]">
                <CardHeader>
                    <CardTitle>Admin Setup</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                    <div className="space-y-2">
                        <p><strong>User ID:</strong> {currentUser.uid}</p>
                        <p><strong>Email:</strong> {currentUser.email}</p>
                        <p><strong>Current Role:</strong> {currentRole || 'none'}</p>
                    </div>

                    <Button 
                        onClick={makeAdmin} 
                        disabled={isLoading || currentRole === 'admin'}
                        className="w-full"
                    >
                        {isLoading ? 'Setting up...' : 'Make Admin'}
                    </Button>

                    {currentRole === 'admin' && (
                        <p className="text-green-500 text-sm text-center">
                            ✓ You are now an admin
                        </p>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default AdminSetup;