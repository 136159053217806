// src/pages/legal/BetaTermsPage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { AlertTriangle, ArrowLeft } from 'lucide-react';
import { ROUTES } from '../../config/paths';

const BetaTermsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-8">
          <Link 
            to={ROUTES.HOME} 
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Home
          </Link>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="flex items-center gap-3 mb-6">
            <AlertTriangle className="w-8 h-8 text-yellow-500" />
            <h1 className="text-3xl font-bold text-gray-900">Beta Testing Terms</h1>
          </div>

          <div className="prose prose-blue max-w-none">
            <div className="text-sm text-gray-500 mb-8">
              Last Updated: {new Date().toLocaleDateString()}
            </div>

            <section className="mb-8">
              <h2 className="text-xl font-semibold mb-4">1. Beta Testing Period</h2>
              <p>
                HotelsRating is currently in beta testing phase. During this period, 
                users have access to early features and functionality that are still 
                under development and evaluation.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold mb-4">2. Nature of Beta Services</h2>
              <p>Users acknowledge and agree that:</p>
              <ul className="list-disc pl-5 space-y-2">
                <li>The service is in development and testing phase</li>
                <li>Features may be incomplete or partially implemented</li>
                <li>Bugs, errors, and technical issues may occur</li>
                <li>Service stability is not guaranteed</li>
                <li>Data may be reset or modified without notice</li>
                <li>Features may be added, modified, or removed</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold mb-4">3. No Warranty</h2>
              <p>
                The beta service is provided "AS IS" without any warranty of any kind. 
                We make no guarantees about the service's reliability, availability, 
                or suitability for any purpose.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold mb-4">4. Limitation of Liability</h2>
              <p>
                By using the beta service, you agree not to hold HotelsRating liable 
                for any damages, losses, or inconveniences that may arise from:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Service interruptions or downtime</li>
                <li>Data loss or corruption</li>
                <li>Technical issues or bugs</li>
                <li>Changes to functionality or features</li>
                <li>Decisions made based on beta features</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold mb-4">5. Feedback and Reporting</h2>
              <p>
                We encourage users to report issues and provide feedback during the 
                beta testing period. This helps us improve the service for everyone.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold mb-4">6. Updates and Changes</h2>
              <p>
                These Beta Testing Terms may be updated at any time. Continued use 
                of the service constitutes acceptance of any changes.
              </p>
            </section>

            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-8">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    By using HotelsRating during the beta period, you acknowledge 
                    that you have read, understood, and agreed to these Beta Testing Terms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetaTermsPage;