// src/components/booking/BookingStatus.tsx
import React from 'react';
import { 
  Clock, 
  CheckCircle, 
  XCircle, 
  AlertTriangle, 
  Calendar,
  CreditCard,
  BadgeCheck,
  Info
} from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { cn } from '../../lib/utils';
import { BOOKING_STATUS } from './constants';

interface StatusConfig {
  label: string;
  icon: typeof Clock;
  color: string;
  bgColor: string;
  borderColor: string;
  description: string;
}

interface StatusHistoryItem {
  status: keyof typeof BOOKING_STATUS;
  timestamp: string;
  comment?: string;
  updatedBy?: string;
}

interface StatusAction {
  label: string;
  onClick: () => void;
  variant?: 'default' | 'outline' | 'destructive';
  icon?: typeof Clock;
  disabled?: boolean;
}

interface BookingStatusProps {
  status: keyof typeof BOOKING_STATUS;
  message?: string;
  lastUpdated?: string;
  actions?: StatusAction[];
  className?: string;
  showTooltip?: boolean;
}

const STATUS_CONFIG: Record<keyof typeof BOOKING_STATUS, StatusConfig> = {
  pending: {
    label: 'Pending Confirmation',
    icon: Clock,
    color: 'text-yellow-500',
    bgColor: 'bg-yellow-50',
    borderColor: 'border-yellow-200',
    description: 'Your booking is being processed'
  },
  confirmed: {
    label: 'Confirmed',
    icon: CheckCircle,
    color: 'text-green-500',
    bgColor: 'bg-green-50',
    borderColor: 'border-green-200',
    description: 'Your booking has been confirmed'
  },
  cancelled: {
    label: 'Cancelled',
    icon: XCircle,
    color: 'text-red-500',
    bgColor: 'bg-red-50',
    borderColor: 'border-red-200',
    description: 'This booking has been cancelled'
  },
  completed: {
    label: 'Completed',
    icon: BadgeCheck,
    color: 'text-blue-500',
    bgColor: 'bg-blue-50',
    borderColor: 'border-blue-200',
    description: 'Your stay has been completed'
  },
  payment_required: {
    label: 'Payment Required',
    icon: CreditCard,
    color: 'text-orange-500',
    bgColor: 'bg-orange-50',
    borderColor: 'border-orange-200',
    description: 'Payment is required to confirm your booking'
  },
  in_progress: {
    label: 'Stay in Progress',
    icon: Calendar,
    color: 'text-purple-500',
    bgColor: 'bg-purple-50',
    borderColor: 'border-purple-200',
    description: 'Your stay is currently active'
  }
};

export const BookingStatus: React.FC<BookingStatusProps> = ({
  status,
  message,
  lastUpdated,
  actions = [],
  className = '',
  showTooltip = true
}) => {
  const config = STATUS_CONFIG[status] || STATUS_CONFIG.pending;
  const Icon = config.icon;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const StatusContent = () => (
    <div className={cn(
      'rounded-lg border p-4',
      config.bgColor,
      config.borderColor,
      className
    )}>
      <div className="flex items-start gap-3">
        <div className={cn(
          'mt-0.5 p-1.5 rounded-full',
          config.bgColor
        )}>
          <Icon className={cn('w-5 h-5', config.color)} />
        </div>

        <div className="flex-1 min-w-0">
          {/* Status and Timestamp */}
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium">{config.label}</h4>
            {lastUpdated && (
              <span className="text-xs text-gray-500">
                Updated: {formatDate(lastUpdated)}
              </span>
            )}
          </div>

          {/* Message */}
          {message && (
            <p className="mt-1 text-sm text-gray-600">{message}</p>
          )}

          {/* Actions */}
          {actions.length > 0 && (
            <div className="flex flex-wrap gap-2 mt-4">
              {actions.map((action, index) => (
                <Button
                  key={index}
                  variant={action.variant || "outline"}
                  size="sm"
                  onClick={action.onClick}
                  disabled={action.disabled}
                >
                  {action.icon && <action.icon className="w-4 h-4 mr-2" />}
                  {action.label}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (!showTooltip) {
    return <StatusContent />;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            <StatusContent />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{config.description}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

// Экспортируем предварительно настроенные компоненты для удобства
export const PendingStatus = (props: Omit<BookingStatusProps, 'status'>) => (
  <BookingStatus status="pending" {...props} />
);

export const ConfirmedStatus = (props: Omit<BookingStatusProps, 'status'>) => (
  <BookingStatus status="confirmed" {...props} />
);

export const CancelledStatus = (props: Omit<BookingStatusProps, 'status'>) => (
  <BookingStatus status="cancelled" {...props} />
);

export const CompletedStatus = (props: Omit<BookingStatusProps, 'status'>) => (
  <BookingStatus status="completed" {...props} />
);

export const PaymentRequiredStatus = (props: Omit<BookingStatusProps, 'status'>) => (
  <BookingStatus status="payment_required" {...props} />
);

export const InProgressStatus = (props: Omit<BookingStatusProps, 'status'>) => (
  <BookingStatus status="in_progress" {...props} />
);

export { STATUS_CONFIG };
export default BookingStatus;