// src/pages/booking/BookingConfirmationPage.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import {
  CheckCircle,
  AlertTriangle,
  Calendar,
  Clock,
  CreditCard,
  MapPin,
  Mail,
  Phone,
  Download,
  Share2
} from 'lucide-react';
import { format } from 'date-fns';
import { Button } from '../../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Separator } from '../../components/ui/separator';
import { ROUTES } from '../../config/paths';
import { Booking } from '../../types/booking';
import BookingService from '../../services/booking/BookingService';
import BookingConfirmation from '../../components/booking/BookingConfirmation';

export const BookingConfirmationPage = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [booking, setBooking] = useState<Booking | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    loadBookingDetails();
  }, [bookingId]);

  const loadBookingDetails = async () => {
    try {
      if (!bookingId) {
        throw new Error('Booking ID is required');
      }

      setLoading(true);
      setError(null);

      const bookingDetails = await BookingService.getBooking(bookingId);
      setBooking(bookingDetails);

    } catch (error) {
      console.error('Error loading booking:', error);
      setError(error instanceof Error ? error.message : 'Failed to load booking');
      toast({
        title: "Error",
        description: "Unable to load booking details",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);
      await BookingService.downloadConfirmation(bookingId!);
      toast({
        title: "Success",
        description: "Confirmation downloaded successfully"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to download confirmation",
        variant: "destructive"
      });
    } finally {
      setDownloading(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleShare = async () => {
    try {
      const bookingUrl = window.location.href;
      await navigator.clipboard.writeText(bookingUrl);
      toast({
        title: "Success",
        description: "Booking link copied to clipboard"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to copy booking link",
        variant: "destructive"
      });
    }
  };

  // Loading State
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-3xl mx-auto px-4">
          <Card>
            <CardContent className="p-6">
              <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  // Error State
  if (error || !booking) {
    return (
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-3xl mx-auto px-4">
          <Card>
            <CardContent className="p-6">
              <div className="text-center">
                <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
                <h2 className="text-lg font-semibold mb-2">
                  {error || 'Booking not found'}
                </h2>
                <div className="space-y-2">
                  <Button
                    onClick={() => navigate(ROUTES.BOOKING_HISTORY)}
                    variant="outline"
                  >
                    <Calendar className="w-4 h-4 mr-2" />
                    View All Bookings
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4">
        <BookingConfirmation
          booking={booking}
          onDownload={handleDownload}
          onPrint={handlePrint}
        />

        {/* Additional Actions */}
        <div className="flex flex-col sm:flex-row gap-4 mt-6">
          <Button
            variant="outline"
            className="flex-1"
            onClick={handleShare}
          >
            <Share2 className="w-4 h-4 mr-2" />
            Share Booking
          </Button>

          {booking.hotelDetails.phone && (
            <Button
              variant="outline"
              className="flex-1"
              onClick={() => window.location.href = `tel:${booking.hotelDetails.phone}`}
            >
              <Phone className="w-4 h-4 mr-2" />
              Call Hotel
            </Button>
          )}

          {booking.hotelDetails.email && (
            <Button
              variant="outline"
              className="flex-1"
              onClick={() => window.location.href = `mailto:${booking.hotelDetails.email}`}
            >
              <Mail className="w-4 h-4 mr-2" />
              Email Hotel
            </Button>
          )}
        </div>

        {/* Travel Information */}
        <Card className="mt-6">
          <CardHeader>
            <CardTitle className="text-lg">Important Information</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="text-sm">
              <h3 className="font-medium">Check-in Instructions</h3>
              <ul className="list-disc list-inside mt-2 space-y-1 text-gray-600">
                <li>Check-in time starts at {booking.hotelDetails.checkIn || '15:00'}</li>
                <li>Minimum check-in age is 18</li>
                <li>Please present a valid ID and credit card at check-in</li>
              </ul>
            </div>

            <div className="text-sm">
              <h3 className="font-medium">Special Instructions</h3>
              <p className="mt-2 text-gray-600">
                Please contact the hotel in advance if you plan to arrive after 18:00.
                Contact details can be found in your confirmation email.
              </p>
            </div>
          </CardContent>
        </Card>

        {/* Support Info */}
        <div className="text-center mt-8">
          <p className="text-sm text-gray-500">
            Need help with your booking?{' '}
            <a href={ROUTES.SUPPORT} className="text-brand-blue hover:underline">
              Contact our support team
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmationPage;