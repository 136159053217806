import { 
    Home, Briefcase, Users, Heart, Compass, Star,
    Music, Camera, Map, Anchor, Plane, Trophy, Building,
    Dog, User
  } from 'lucide-react';
  
  export const PRESET_CATEGORIES = {
    popular: {
      title: "Popular Travel Styles",
      presets: [
        {
          id: 'leisure',
          title: "Leisure Travel",
          description: "Perfect for relaxed vacations and sightseeing",
          icon: Home,
          color: "bg-blue-500",
          image: "/images/presets/leisure-travel.webp",
          criteria: {
            location: 80,
            cleanliness: 85,
            service: 90,
            comfort: 90,
            valueForMoney: 75,
            facilities: 85,
            food: 85,
            internet: 70
          },
          mandatoryFeatures: {
            wifi: true,
            parking: false,
            roomService: true,
            gym: false,
            pool: true
          }
        },
        {
          id: 'business',
          title: "Business Travel",
          description: "Optimized for work-related stays",
          icon: Briefcase,
          color: "bg-gray-700",
          image: "/images/presets/business-travel.webp",
          criteria: {
            location: 90,
            cleanliness: 85,
            service: 80,
            comfort: 85,
            valueForMoney: 70,
            facilities: 85,
            food: 75,
            internet: 100
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: true,
            gym: true,
            pool: false
          }
        },
        {
          id: 'family',
          title: "Family Travel",
          description: "Ideal for traveling with children",
          icon: Users,
          color: "bg-green-500",
          image: "/images/presets/family-travel.webp",
          criteria: {
            location: 85,
            cleanliness: 95,
            service: 85,
            comfort: 90,
            valueForMoney: 80,
            facilities: 90,
            food: 85,
            internet: 70
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: false,
            gym: false,
            pool: true
          }
        }
      ]
    },
    experiences: {
      title: "Unique Experiences",
      presets: [
        {
          id: 'romantic',
          title: "Romantic Getaway",
          description: "Perfect for couples and honeymoons",
          icon: Heart,
          color: "bg-pink-500",
          image: "/images/presets/romantic-getaway.webp",
          criteria: {
            location: 85,
            cleanliness: 90,
            service: 95,
            comfort: 95,
            valueForMoney: 70,
            facilities: 90,
            food: 90,
            internet: 60
          },
          mandatoryFeatures: {
            wifi: true,
            parking: false,
            roomService: true,
            gym: false,
            pool: true
          }
        },
        {
          id: 'adventure',
          title: "Adventure Travel",
          description: "For active and outdoor-focused stays",
          icon: Compass,
          color: "bg-orange-500",
          image: "/images/presets/adventure-travel.webp",
          criteria: {
            location: 95,
            cleanliness: 75,
            service: 70,
            comfort: 70,
            valueForMoney: 80,
            facilities: 85,
            food: 75,
            internet: 60
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: false,
            gym: true,
            pool: false
          }
        },
        {
          id: 'luxury',
          title: "Luxury Experience",
          description: "For premium and high-end stays",
          icon: Star,
          color: "bg-purple-500",
          image: "/images/presets/luxury-experience.webp",
          criteria: {
            location: 95,
            cleanliness: 100,
            service: 100,
            comfort: 100,
            valueForMoney: 60,
            facilities: 95,
            food: 95,
            internet: 90
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: true,
            gym: true,
            pool: true
          }
        }
      ]
    },
    special: {
      title: "Special Interest",
      presets: [
        {
          id: 'party',
          title: "Party & Nightlife",
          description: "Perfect for party-goers and night entertainment",
          icon: Music,
          color: "bg-indigo-500",
          image: "/images/presets/party-nightlife.webp",
          criteria: {
            location: 90,
            cleanliness: 75,
            service: 70,
            comfort: 70,
            valueForMoney: 80,
            facilities: 85,
            food: 80,
            internet: 70
          },
          mandatoryFeatures: {
            wifi: true,
            parking: false,
            roomService: true,
            gym: false,
            pool: false
          }
        },
        {
          id: 'instagram',
          title: "Instagrammable Places",
          description: "Picture-perfect locations for your social media",
          icon: Camera,
          color: "bg-rose-500",
          image: "/images/presets/instagrammable-places.webp",
          criteria: {
            location: 95,
            cleanliness: 90,
            service: 80,
            comfort: 85,
            valueForMoney: 70,
            facilities: 90,
            food: 85,
            internet: 85
          },
          mandatoryFeatures: {
            wifi: true,
            parking: false,
            roomService: true,
            gym: false,
            pool: true
          }
        },
        {
          id: 'worldWonders',
          title: "World Wonders",
          description: "Experience the most unique and extraordinary places",
          icon: Map,
          color: "bg-amber-500",
          image: "/images/presets/world-wonders.webp",
          criteria: {
            location: 100,
            cleanliness: 80,
            service: 85,
            comfort: 85,
            valueForMoney: 70,
            facilities: 85,
            food: 80,
            internet: 75
          },
          mandatoryFeatures: {
            wifi: true,
            parking: false,
            roomService: true,
            gym: false,
            pool: false
          }
        }
      ]
    },
    premium: {
      title: "Premium Services",
      presets: [
        {
          id: 'yachts',
          title: "Yacht Friendly",
          description: "Luxury stays with marina access",
          icon: Anchor,
          color: "bg-cyan-500",
          image: "/images/presets/yacht-friendly.webp",
          criteria: {
            location: 95,
            cleanliness: 95,
            service: 95,
            comfort: 95,
            valueForMoney: 60,
            facilities: 100,
            food: 90,
            internet: 85
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: true,
            gym: true,
            pool: true
          }
        },
        {
          id: 'privateJets',
          title: "Private Aviation",
          description: "Accommodations catering to private jet travelers",
          icon: Plane,
          color: "bg-sky-500",
          image: "/images/presets/private-aviation.webp",
          criteria: {
            location: 95,
            cleanliness: 100,
            service: 100,
            comfort: 100,
            valueForMoney: 50,
            facilities: 100,
            food: 95,
            internet: 90
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: true,
            gym: true,
            pool: true
          }
        }
      ]
    },
    specialized: {
      title: "Specialized Stays",
      presets: [
        {
          id: 'pets',
          title: "Pet Friendly",
          description: "Perfect stays for you and your furry friends",
          icon: Dog,
          color: "bg-emerald-500",
          image: "/images/presets/pet-friendly.webp",
          criteria: {
            location: 75,
            cleanliness: 90,
            service: 85,
            comfort: 80,
            valueForMoney: 75,
            facilities: 85,
            food: 70,
            internet: 70
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: false,
            gym: false,
            pool: false
          }
        },
        {
          id: 'sports',
          title: "Sports & Athletics",
          description: "Ideal for sports enthusiasts and athletes",
          icon: Trophy,
          color: "bg-red-500",
          image: "/images/presets/sports-athletics.webp",
          criteria: {
            location: 80,
            cleanliness: 85,
            service: 75,
            comfort: 80,
            valueForMoney: 75,
            facilities: 100,
            food: 85,
            internet: 70
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: true,
            gym: true,
            pool: true
          }
        },
        {
          id: 'events',
          title: "Event Venues",
          description: "Hotels specializing in exhibitions and conferences",
          icon: Building,
          color: "bg-neutral-500",
          image: "/images/presets/event-venues.webp",
          criteria: {
            location: 85,
            cleanliness: 90,
            service: 85,
            comfort: 85,
            valueForMoney: 75,
            facilities: 100,
            food: 90,
            internet: 95
          },
          mandatoryFeatures: {
            wifi: true,
            parking: true,
            roomService: true,
            gym: false,
            pool: false
          }
        },
        {
          id: 'adultsOnly',
          title: "Adults Only",
          description: "Exclusive 18+ accommodations and entertainment",
          icon: User,
          color: "bg-violet-500",
          image: "/images/presets/adults-only.webp",
          criteria: {
            location: 85,
            cleanliness: 90,
            service: 90,
            comfort: 90,
            valueForMoney: 75,
            facilities: 90,
            food: 90,
            internet: 75
          },
          mandatoryFeatures: {
            wifi: true,
            parking: false,
            roomService: true,
            gym: true,
            pool: true
          }
        }
      ]
    }
  };
  
  export const CRITERIA_CATEGORIES = {
    location: {
      title: "Location",
      options: [
        "Near Beach",
        "City Center",
        "Near Airport",
        "Near Metro",
        "Eiffel Tower View",
        "Ocean View",
        "Mountain View",
        "Landmark View"
      ]
    },
    dining: {
      title: "Dining",
      options: [
        "Chinese Restaurant",
        "Japanese Cuisine",
        "Italian Restaurant",
        "French Cuisine",
        "24/7 Room Service",
        "Vegetarian Options",
        "Halal Food",
        "Kosher Food"
      ]
    },
    amenities: {
      title: "Amenities",
      options: [
        "Swimming Pool",
        "Spa",
        "Fitness Center",
        "Private Beach",
        "Tennis Court",
        "Golf Course",
        "Kids Club",
        "Business Center"
      ]
    },
    specialFeatures: {
      title: "Special Features",
      options: [
        "Pet Friendly",
        "Adults Only",
        "All Inclusive",
        "Casino",
        "Wedding Facilities",
        "Eco Friendly",
        "Historic Property",
        "Design Hotel"
      ]
    }
  };
  
  export default PRESET_CATEGORIES;