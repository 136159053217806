import React from 'react';

export const CommentPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Comment Policy for HotelsRating.com</h1>
      <div className="text-sm text-gray-600 mb-8">
        Effective Date: 25/11/2024
      </div>
      
      <div className="prose prose-blue max-w-none space-y-6">
        <p>
          HotelsRating.com is committed to fostering an engaging, respectful, and safe environment for discussions and user contributions. By posting comments or other content on our platform, you agree to this Comment Policy. Failure to comply may result in the removal of content or other actions as outlined below.
        </p>

        <section>
          <h2 className="text-2xl font-semibold mb-4">1. Purpose of the Comment Section</h2>
          <p>
            The comment section is designed to allow users to share their thoughts, reviews, and experiences about hotels and travel. All comments should contribute to constructive and respectful dialogue.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. User Responsibilities</h2>
          <p>By posting comments on HotelsRating.com, you agree that:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Accuracy:</strong> Your comments are truthful, accurate, and based on your personal experience.</li>
            <li><strong>Respect:</strong> You will not post content that is abusive, offensive, defamatory, or harassing.</li>
            <li><strong>Relevance:</strong> Your comments will remain on-topic and relevant to the discussion or review.</li>
            <li><strong>Compliance:</strong> Your comments comply with all applicable laws and regulations.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">3. Prohibited Content</h2>
          <p>The following types of content are strictly prohibited:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Hate Speech:</strong> Comments that promote discrimination, hatred, or violence based on race, ethnicity, religion, gender, sexual orientation, or other protected characteristics.</li>
            <li><strong>Defamatory Statements:</strong> False statements that could harm the reputation of individuals or entities.</li>
            <li><strong>Spam and Advertising:</strong> Unsolicited promotions, links, or advertisements unrelated to the discussion.</li>
            <li><strong>Illegal Content:</strong> Content that promotes or facilitates illegal activities.</li>
            <li><strong>Personal Information:</strong> Posting of private or sensitive information (e.g., phone numbers, addresses) about others without their consent.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. Moderation and Removal of Comments</h2>
          <p>HotelsRating.com reserves the right to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Moderate:</strong> Review, edit, or remove comments that violate this policy.</li>
            <li><strong>Ban Users:</strong> Restrict or ban users who repeatedly violate this policy.</li>
            <li><strong>Respond:</strong> Address reported comments within a reasonable timeframe.</li>
          </ul>
          <p className="mt-4">
            While we strive to monitor user comments, we cannot review every post. If you notice a violation of this policy, please report it to abuse@hotelsrating.com.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">5. Liability for User-Generated Content</h2>
          <p>
            HotelsRating.com is not liable for content posted by users. The views expressed in the comment section belong solely to the users and do not reflect our opinions or positions. Users are solely responsible for their comments and any consequences arising from them.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">6. Intellectual Property</h2>
          <p>
            By posting a comment, you grant HotelsRating.com a non-exclusive, royalty-free, perpetual license to use, reproduce, and display your comment in connection with the operation and promotion of the platform. You warrant that your comment does not infringe on the intellectual property rights of any third party.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">7. Reporting and Disputes</h2>
          <p>To report a comment that violates this policy, please email abuse@hotelsrating.com with the following details:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>URL or location of the comment.</li>
            <li>Description of the issue.</li>
            <li>Your contact information for follow-up.</li>
          </ul>
          <p className="mt-4">
            We will review all reports and take appropriate action within a reasonable timeframe.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">8. Changes to This Comment Policy</h2>
          <p>
            We may update this Comment Policy to reflect changes in our practices or legal requirements. Updates will be posted on this page with a revised "Effective Date." Your continued use of the comment section constitutes acceptance of the updated policy.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">9. Contact Information</h2>
          <p>
            If you have any questions or concerns about this Comment Policy, please contact us:
          </p>
          <div className="mt-4">
            <p>HotelsRating.com</p>
            <p>Email: abuse@hotelsrating.com</p>
          </div>
        </section>

        <div className="mt-8 text-sm text-gray-600 font-bold">
          By posting comments on HotelsRating.com, you acknowledge that you have read, understood, and agreed to this Comment Policy.
        </div>
      </div>
    </div>
  );
};

export default CommentPolicy;