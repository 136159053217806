// /src/config/apiConstants.js

export const AMADEUS_API = {
  // Базовые настройки API
  BASE_URL: import.meta.env.VITE_AMADEUS_API_URL || 'https://api.amadeus.com',
  VERSION: 'v3',
  
  // Эндпоинты API
  ENDPOINTS: {
    TOKEN: '/v1/security/oauth2/token',
    LOCATIONS: '/v1/reference-data/locations',
    HOTELS_BY_CITY: '/v1/reference-data/locations/hotels/by-city',
    HOTEL_SEARCH: '/v3/shopping/hotel-offers',
    HOTEL_DETAILS: '/v3/shopping/hotel-offers/by-hotel',
    HOTEL_OFFER: '/v3/shopping/hotel-offers'
  },

  // Учетные данные
  CREDENTIALS: {
    CLIENT_ID: import.meta.env.VITE_AMADEUS_CLIENT_ID,
    CLIENT_SECRET: import.meta.env.VITE_AMADEUS_CLIENT_SECRET
  },

  // Настройки кеширования
  CACHE: {
    TOKEN_TTL: 1800000,      // 30 минут
    SEARCH_TTL: 300000,      // 5 минут
    DETAILS_TTL: 1800000,    // 30 минут
    LOCATIONS_TTL: 86400000  // 24 часа
  },

  // Параметры запросов по умолчанию
  DEFAULTS: {
    CURRENCY: 'EUR',
    ADULTS: 2,
    ROOMS: 1,
    RADIUS: 50,
    RADIUS_UNIT: 'KM',
    SORT_OPTIONS: ['PRICE', 'DISTANCE', 'RATING'],
    VIEW: 'FULL'
  },

  // Доступные удобства для фильтрации
  AMENITIES: {
    WIFI: 'WIFI',
    POOL: 'SWIMMING_POOL',
    PARKING: 'PARKING',
    RESTAURANT: 'RESTAURANT',
    FITNESS: 'FITNESS_CENTER',
    BUSINESS: 'BUSINESS_CENTER',
    SPA: 'SPA',
    PETS: 'PETS_ALLOWED',
    AIRPORT_SHUTTLE: 'AIRPORT_SHUTTLE',
    ROOM_SERVICE: 'ROOM_SERVICE',
    BAR: 'BAR',
    KIDS_CLUB: 'KIDS_CLUB'
  },

  // Рейтинги отелей
  RATINGS: ['1', '2', '3', '4', '5'],

  // Максимальные значения
  LIMITS: {
    MAX_HOTELS_PER_REQUEST: 100,
    MAX_PRICE_RANGE: 10000,
    MAX_RADIUS: 100
  }
};

// Коды ошибок API
export const API_ERROR_CODES = {
  // Ошибки аутентификации
  AUTH: {
    TOKEN_EXPIRED: 'INVALID_TOKEN',
    INVALID_CREDENTIALS: 'INVALID_CLIENT',
    UNAUTHORIZED: 'UNAUTHORIZED'
  },

  // Ошибки запросов
  REQUEST: {
    RATE_LIMIT: 'RATE_LIMIT_EXCEEDED',
    INVALID_PARAMS: 'INVALID_PARAMETERS',
    NOT_FOUND: 'RESOURCE_NOT_FOUND',
    SERVER_ERROR: 'SERVER_ERROR'
  },

  // Ошибки данных
  DATA: {
    NO_AVAILABILITY: 'NO_AVAILABILITY',
    INVALID_HOTEL: 'INVALID_HOTEL_ID',
    INVALID_OFFER: 'INVALID_OFFER_ID'
  }
};

// Сообщения об ошибках
export const ERROR_MESSAGES = {
  [API_ERROR_CODES.AUTH.TOKEN_EXPIRED]: 'Session expired. Please try again.',
  [API_ERROR_CODES.AUTH.INVALID_CREDENTIALS]: 'Invalid API credentials.',
  [API_ERROR_CODES.AUTH.UNAUTHORIZED]: 'Unauthorized access.',
  [API_ERROR_CODES.REQUEST.RATE_LIMIT]: 'Too many requests. Please try again later.',
  [API_ERROR_CODES.REQUEST.INVALID_PARAMS]: 'Invalid search parameters.',
  [API_ERROR_CODES.REQUEST.NOT_FOUND]: 'Requested resource not found.',
  [API_ERROR_CODES.REQUEST.SERVER_ERROR]: 'Server error. Please try again later.',
  [API_ERROR_CODES.DATA.NO_AVAILABILITY]: 'No availability for selected dates.',
  [API_ERROR_CODES.DATA.INVALID_HOTEL]: 'Invalid hotel identifier.',
  [API_ERROR_CODES.DATA.INVALID_OFFER]: 'Invalid offer identifier.'
};