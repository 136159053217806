// src/pages/support/HelpCenterPage.jsx
import React from 'react';
import HelpCenter from '../../components/support/HelpCenter';

const HelpCenterPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <HelpCenter />
    </div>
  );
};

export default HelpCenterPage;