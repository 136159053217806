// src/components/profile/ProfilePreferences.jsx
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import PreferencesManager from '../preferences/PreferencesManager';

const ProfilePreferences = () => {
  const { updateUserPreferences } = useAuth();

  const handleSave = async (preferences) => {
    try {
      await updateUserPreferences(preferences);
    } catch (error) {
      console.error('Error saving preferences:', error);
      throw error;
    }
  };

  return (
    <div className="space-y-6">
      <PreferencesManager
        mode="profile"
        showPresets={false}
        onSave={handleSave}
      />
    </div>
  );
};

export default ProfilePreferences;