import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../config/paths';

const ComingSoon = ({ title }) => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center">
    <div className="text-center">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">{title}</h2>
      <p className="text-gray-600">This feature is coming soon!</p>
      <Link 
        to={ROUTES.HOME} 
        className="text-blue-600 hover:text-blue-700 mt-4 inline-block"
      >
        Return to Homepage
      </Link>
    </div>
  </div>
);

export default ComingSoon;