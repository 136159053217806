// src/services/api/hotels/utils/resultProcessor.js

/**
 * Обрабатывает результаты поиска отелей
 */
export async function processResults(hotels, preferences, filters) {
  // Дожидаемся разрешения Promise если hotels - промис
  const resolvedHotels = await Promise.resolve(hotels);

  if (!resolvedHotels || !Array.isArray(resolvedHotels)) {
    console.warn('Invalid hotels data:', resolvedHotels);
    return {
      hotels: [],
      totalCount: 0,
      filteredCount: 0
    };
  }

  try {
    // Применяем фильтры
    let results = [...resolvedHotels];
    
    if (filters && Object.keys(filters).length > 0) {
      results = results.filter(hotel => {
        // Фильтр по цене
        if (filters.priceRange && hotel.price?.current?.amount) {
          const [min, max] = filters.priceRange;
          const price = hotel.price.current.amount;
          if (price < min || price > max) return false;
        }

        // Фильтр по рейтингу
        if (filters.rating && hotel.rating?.stars) {
          if (hotel.rating.stars < filters.rating) return false;
        }

        // Фильтр по удобствам
        if (filters.amenities?.length > 0) {
          if (!hotel.amenities?.some(amenity => 
            filters.amenities.includes(amenity)
          )) return false;
        }

        return true;
      });
    }

    // Применяем персональные предпочтения и сортировку
    if (preferences) {
      results = results.map(hotel => ({
        ...hotel,
        personalScore: calculateHotelScore(hotel, preferences)
      })).sort((a, b) => b.personalScore - a.personalScore);
    }

    return {
      hotels: results,
      totalCount: resolvedHotels.length,
      filteredCount: results.length
    };
  } catch (error) {
    console.error('Error processing results:', error);
    return {
      hotels: resolvedHotels,
      totalCount: resolvedHotels.length,
      filteredCount: resolvedHotels.length
    };
  }
}

/**
 * Вычисляет персональный рейтинг отеля
 * @param {Object} hotel - Отель
 * @param {Object} preferences - Предпочтения
 * @returns {number} Рейтинг от 0 до 100
 */
function calculateHotelScore(hotel, preferences) {
  if (!hotel || !preferences) return 0;

  try {
    let score = hotel.rating?.stars ? (hotel.rating.stars * 20) : 50;

    // Учитываем соответствие предпочтениям
    if (preferences.amenities) {
      const matches = preferences.amenities.filter(amenity => 
        hotel.amenities?.includes(amenity)
      ).length;
      score += (matches / preferences.amenities.length) * 30;
    }

    // Нормализуем оценку до 100
    return Math.min(100, Math.round(score));
  } catch (error) {
    console.error('Error calculating hotel score:', error);
    return 0;
  }
}