// src/services/api/amadeus/utils.js
import { DEFAULT_PARAMS } from './constants';

/**
 * Форматирует дату в формат YYYY-MM-DD
 */
export const formatDate = (date) => {
  if (!date) return '';
  if (typeof date === 'string') return date;
  return date.toISOString().split('T')[0];
};

/**
 * Нормализует параметры поиска для API
 */
export const normalizeSearchParams = (params = {}) => {
  const {
    cityCode,
    checkInDate,
    checkOutDate,
    adults = DEFAULT_PARAMS.ADULTS,
    rooms = DEFAULT_PARAMS.ROOMS,
    radius = DEFAULT_PARAMS.RADIUS,
    ratings = [],
    amenities = [],
    priceRange = [],
    currency = DEFAULT_PARAMS.CURRENCY,
    ...otherParams
  } = params;

  return {
    cityCode,
    checkInDate: formatDate(checkInDate),
    checkOutDate: formatDate(checkOutDate),
    adults: Math.min(Math.max(1, adults), 9),
    rooms: Math.min(Math.max(1, rooms), 9),
    radius: Math.min(Math.max(1, radius), 50),
    ratings: ratings.filter(Boolean),
    amenities: normalizeAmenities(amenities),
    priceRange: normalizePriceRange(priceRange),
    currency,
    ...otherParams
  };
};

/**
 * Нормализует параметры предложений
 */
export const normalizeOfferParams = (params = {}) => {
  const {
    checkInDate,
    checkOutDate,
    adults = DEFAULT_PARAMS.ADULTS,
    rooms = DEFAULT_PARAMS.ROOMS,
    currency = DEFAULT_PARAMS.CURRENCY,
    ...otherParams
  } = params;

  return {
    checkInDate: formatDate(checkInDate),
    checkOutDate: formatDate(checkOutDate),
    adults: Math.min(Math.max(1, adults), 9),
    rooms: Math.min(Math.max(1, rooms), 9),
    currency,
    ...otherParams
  };
};

/**
 * Нормализует список удобств
 */
export const normalizeAmenities = (amenities = []) => {
  if (!Array.isArray(amenities)) return [];
  return amenities.filter(Boolean).map(amenity => 
    typeof amenity === 'string' ? amenity.toUpperCase() : amenity
  );
};

/**
 * Нормализует ценовой диапазон
 */
export const normalizePriceRange = (range = []) => {
  if (!Array.isArray(range) || range.length !== 2) return [];
  const [min, max] = range;
  if (!min || !max) return [];
  return [Math.max(0, min), Math.max(min, max)];
};

/**
 * Генерирует ключ для кэша
 */
export const generateCacheKey = (prefix, params) => {
  const normalizedParams = Object.entries(params || {})
    .filter(([_, value]) => value != null)
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([key, value]) => `${key}:${JSON.stringify(value)}`)
    .join('|');
    
  return `${prefix}:${normalizedParams}`;
};

/**
 * Улучшает объект ошибки дополнительной информацией
 */
export const enhanceError = (error, endpoint, params = {}) => {
  error.endpoint = endpoint;
  error.params = params;
  error.timestamp = new Date().toISOString();
  return error;
};

/**
 * Валидирует параметры запроса
 */
export const validateParams = (params = {}, required = []) => {
  const missing = required.filter(param => !params[param]);
  if (missing.length > 0) {
    throw new Error(`Missing required parameters: ${missing.join(', ')}`);
  }
  return true;
};