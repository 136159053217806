// src/components/hotels/detail/HeaderInfo.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { MapPin, Star, ArrowLeft } from 'lucide-react';
import { Button } from '../../ui/button';

const HeaderInfo = ({ hotel, onBack }) => {
 // Форматирование адреса
 const getFormattedAddress = () => {
   if (!hotel.location) return 'Address not available';
   
   if (typeof hotel.location.address === 'string') {
     return hotel.location.address;
   }

   const addressParts = [];
   if (hotel.location.address?.lines?.length) {
     addressParts.push(hotel.location.address.lines[0]);
   }
   if (hotel.location.address?.city) {
     addressParts.push(hotel.location.address.city);
   }
   if (hotel.location.address?.country) {
     addressParts.push(hotel.location.address.country);
   }

   return addressParts.length > 0 
     ? addressParts.join(', ') 
     : 'Address not available';
 };

 // Форматирование рейтинга
 const formatRating = () => {
   if (typeof hotel.rating === 'number') {
     return hotel.rating.toFixed(1);
   }
   if (hotel.rating?.stars) {
     return hotel.rating.stars.toFixed(1);
   }
   return 'N/A';
 };

 // Форматирование цены
 const formatPrice = () => {
   if (hotel.price?.current?.amount) {
     return `${hotel.price.current.currency || 'USD'} ${hotel.price.current.amount}`;
   }
   if (typeof hotel.price === 'number') {
     return `USD ${hotel.price}`;
   }
   return 'Price not available';
 };

 return (
   <div className="bg-white border-b sticky top-0 z-10">
     <div className="max-w-7xl mx-auto px-4 py-6">
       {/* Back Button */}
       <Button 
         variant="ghost" 
         size="sm" 
         className="mb-4"
         onClick={onBack}
       >
         <ArrowLeft className="w-4 h-4 mr-2" />
         Back to Search
       </Button>
       
       {/* Header Content */}
       <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start gap-4">
         {/* Left Side - Hotel Info */}
         <div>
           <h1 className="text-3xl font-bold text-gray-900 mb-2">
             {hotel.name || 'Hotel name not available'}
           </h1>
           <div className="flex items-center gap-4 text-gray-600 flex-wrap">
             {/* Address */}
             <div className="flex items-center gap-1">
               <MapPin className="w-4 h-4" />
               <span>{getFormattedAddress()}</span>
             </div>
             
             {/* Rating */}
             {hotel.rating && (
               <div className="flex items-center gap-1">
                 <Star className="w-4 h-4 text-yellow-400" />
                 <span>{formatRating()}</span>
                 <span className="text-gray-400">
                   ({hotel.reviews || 0} reviews)
                 </span>
               </div>
             )}

             {/* Additional Badges */}
             {hotel.features?.wifi && (
               <span className="text-xs bg-gray-100 px-2 py-1 rounded">
                 Free WiFi
               </span>
             )}
             {hotel.features?.breakfast && (
               <span className="text-xs bg-gray-100 px-2 py-1 rounded">
                 Breakfast Included
               </span>
             )}
           </div>
         </div>
         
         {/* Right Side - Price */}
         <div className="text-right">
           <div className="text-sm text-gray-600">Price per night from</div>
           <div className="text-3xl font-bold text-brand-blue">
             {formatPrice()}
           </div>
           {hotel.price?.original?.amount > hotel.price?.current?.amount && (
             <div className="text-sm text-red-500 line-through">
               Was {hotel.price.current.currency} {hotel.price.original.amount}
             </div>
           )}
         </div>
       </div>
     </div>
   </div>
 );
};

HeaderInfo.propTypes = {
 hotel: PropTypes.shape({
   name: PropTypes.string.isRequired,
   location: PropTypes.shape({
     address: PropTypes.oneOfType([
       PropTypes.string,
       PropTypes.shape({
         lines: PropTypes.arrayOf(PropTypes.string),
         city: PropTypes.string,
         country: PropTypes.string,
       })
     ])
   }),
   rating: PropTypes.oneOfType([
     PropTypes.number,
     PropTypes.shape({
       stars: PropTypes.number
     })
   ]),
   reviews: PropTypes.number,
   features: PropTypes.shape({
     wifi: PropTypes.bool,
     breakfast: PropTypes.bool
   }),
   price: PropTypes.oneOfType([
     PropTypes.number,
     PropTypes.shape({
       current: PropTypes.shape({
         amount: PropTypes.number,
         currency: PropTypes.string
       }),
       original: PropTypes.shape({
         amount: PropTypes.number,
         currency: PropTypes.string
       })
     })
   ])
 }).isRequired,
 onBack: PropTypes.func.isRequired
};

export default HeaderInfo;