import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../config/paths';
import { Home } from 'lucide-react';

const NotFound = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
      <div className="text-center max-w-md">
        {/* 404 Номер */}
        <div className="text-9xl font-bold text-brand-blue/20 select-none">
          404
        </div>

        {/* Заголовок и описание */}
        <h1 className="mt-4 text-3xl font-bold text-gray-900">
          Page Not Found
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          Sorry, we couldn't find the page you're looking for. The page might have been removed or doesn't exist.
        </p>

        {/* Кнопка возврата */}
        <Link 
          to={ROUTES.HOME}
          className="mt-8 inline-flex items-center gap-2 bg-brand-blue text-white px-6 py-3 rounded-lg hover:bg-brand-blue/90 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
        >
          <Home className="w-5 h-5" />
          <span>Return to Homepage</span>
        </Link>

        {/* Дополнительные ссылки */}
        <div className="mt-6 space-x-4 text-sm text-gray-500">
          <Link 
            to={ROUTES.HELP} 
            className="hover:text-brand-blue transition-colors"
          >
            Help Center
          </Link>
          <span>•</span>
          <Link 
            to={ROUTES.CONTACT} 
            className="hover:text-brand-blue transition-colors"
          >
            Contact Support
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;