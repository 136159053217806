// src/services/api/amadeus/endpoints.js

import { 
  formatDate,
  normalizeSearchParams,
  normalizeOfferParams,
  generateCacheKey,
  validateParams,
  enhanceError
} from './utils';
import { 
  convertHotelSearchResponse,
  convertHotelDetailsResponse,
  convertOffersResponse,
  convertOfferDetailsResponse,
  convertReviews 
} from './converters';
import { API_ENDPOINTS, DEFAULT_PARAMS } from './constants';

export const endpoints = {
  /**
   * Поиск локаций
   */
  async searchLocations(api, keyword) {
    console.log('Searching locations for:', keyword);
    const params = new URLSearchParams({ 
      keyword, 
      subType: 'CITY' 
    });
    return api.makeRequest(`/v1/reference-data/locations?${params}`);
  },

  /**
   * Поиск отелей
   */
  async searchHotels(api, {
    cityCode,
    checkInDate,
    checkOutDate,
    adults = 2,
    rooms = 1,
    currency = 'EUR'
  }) {
    try {
      console.log('Searching hotels for:', { cityCode, checkInDate, checkOutDate });

      // Получаем список отелей в городе
      const hotelsInCityResponse = await api.makeRequest(
        `/v1/reference-data/locations/hotels/by-city?cityCode=${cityCode}&radius=20&radiusUnit=KM`
      );

      console.log('Found hotels in city:', hotelsInCityResponse?.data?.length || 0);

      if (!hotelsInCityResponse?.data?.length) {
        return { data: [] };
      }

      // Берем первые 25 отелей
      const hotelIds = hotelsInCityResponse.data
        .slice(0, 25)
        .map(h => h.hotelId)
        .join(',');

      // Получаем предложения для отелей
      const params = new URLSearchParams({
        hotelIds,
        adults: String(adults),
        roomQuantity: String(rooms),
        currency,
        bestRateOnly: 'true',
        view: 'FULL'
      });

      if (checkInDate && checkOutDate) {
        params.append('checkInDate', formatDate(checkInDate));
        params.append('checkOutDate', formatDate(checkOutDate));
      }

      const offersResponse = await api.makeRequest(`/v3/shopping/hotel-offers?${params}`);
      console.log('Got offers response:', offersResponse?.data?.length || 0, 'hotels');

      return offersResponse;

    } catch (error) {
      console.error('Error in searchHotels:', error);
      throw error;
    }
  },

  /**
   * Получение деталей отеля
   */
  async getHotelDetails(api, hotelId, params = {}) {
    try {
      console.log('Getting details for hotel:', hotelId);

      // Получаем базовые данные и предложения
      const searchParams = new URLSearchParams({
        hotelIds: hotelId,
        adults: String(params.adults || 2),
        roomQuantity: String(params.rooms || 1),
        currency: params.currency || 'EUR',
        bestRateOnly: 'false',
        view: 'FULL'
      });

      if (params.checkInDate && params.checkOutDate) {
        searchParams.append('checkInDate', formatDate(params.checkInDate));
        searchParams.append('checkOutDate', formatDate(params.checkOutDate));
      }

      const response = await api.makeRequest(`/v3/shopping/hotel-offers?${searchParams}`);
      console.log('Hotel details response:', response?.data?.[0]?.hotel?.name);

      if (!response?.data?.[0]) {
        throw new Error(`Hotel ${hotelId} not found`);
      }

      const hotelData = response.data[0];

      // Получаем дополнительные данные параллельно
      const [amenitiesResponse, reviewsResponse] = await Promise.all([
        api.makeRequest(`/v1/reference-data/locations/hotels/${hotelId}/amenities`)
          .catch(error => {
            console.warn('Failed to get amenities:', error);
            return { data: { amenities: [] } };
          }),
        api.makeRequest(`/v1/reference-data/locations/hotels/${hotelId}/reviews?limit=10`)
          .catch(error => {
            console.warn('Failed to get reviews:', error);
            return { data: [] };
          })
      ]);

      // Объединяем все данные
      const enrichedData = {
        ...hotelData.hotel,
        hotelId,
        offers: hotelData.offers || [],
        amenities: amenitiesResponse.data?.amenities || [],
        reviews: reviewsResponse.data || [],
        available: true,
        lastUpdated: new Date().toISOString()
      };

      console.log('Returning enriched hotel data');
      return enrichedData;

    } catch (error) {
      console.error('Error getting hotel details:', error);
      throw error;
    }
  },

  /**
   * Получение предложений отеля
   */
  async getHotelOffers(api, hotelId, params = {}) {
    try {
      console.log('Getting offers for hotel:', hotelId);

      const searchParams = new URLSearchParams({
        hotelIds: hotelId,
        adults: String(params.adults || 2),
        roomQuantity: String(params.rooms || 1),
        currency: params.currency || 'EUR',
        bestRateOnly: 'false',
        view: 'FULL'
      });

      if (params.checkInDate && params.checkOutDate) {
        searchParams.append('checkInDate', formatDate(params.checkInDate));
        searchParams.append('checkOutDate', formatDate(params.checkOutDate));
      }

      const response = await api.makeRequest(`/v3/shopping/hotel-offers?${searchParams}`);
      console.log('Got offers response:', response?.data?.[0]?.offers?.length || 0, 'offers');

      if (!response?.data?.[0]) {
        return { data: { offers: [] } };
      }

      return {
        data: {
          hotel: response.data[0].hotel,
          offers: response.data[0].offers || []
        }
      };

    } catch (error) {
      console.error('Error getting hotel offers:', error);
      throw error;
    }
  },

  /**
   * Получение деталей конкретного предложения
   */
  async getOfferDetails(api, offerId) {
    try {
      if (!offerId) {
        throw new Error('Offer ID is required');
      }

      console.log('Getting offer details:', offerId);
      return api.makeRequest(`/v3/shopping/hotel-offers/${offerId}`);

    } catch (error) {
      console.error('Error getting offer details:', error);
      throw error;
    }
  },

  /**
   * Получение удобств отеля
   */
  async getHotelAmenities(api, hotelId) {
    try {
      console.log('Getting amenities for hotel:', hotelId);
      const response = await api.makeRequest(
        `/v1/reference-data/locations/hotels/${hotelId}/amenities`
      );

      return {
        data: {
          amenities: response.data || []
        }
      };

    } catch (error) {
      console.warn('Failed to get hotel amenities:', error);
      return { data: { amenities: [] } };
    }
  },

  /**
   * Получение отзывов об отеле
   */
  async getHotelReviews(api, hotelId, { page = 1, limit = 10, sort = 'recent' } = {}) {
    try {
      console.log('Getting reviews for hotel:', hotelId);
      const response = await api.makeRequest(
        `/v1/reference-data/locations/hotels/${hotelId}/reviews?` +
        `page=${page}&limit=${limit}&sort=${sort}`
      );

      return {
        data: {
          reviews: response.data || [],
          pagination: response.meta?.pagination || {}
        }
      };

    } catch (error) {
      console.warn('Failed to get hotel reviews:', error);
      return { 
        data: { 
          reviews: [], 
          pagination: { count: 0, total: 0 } 
        } 
      };
    }
  },

  /**
   * Получение истории цен (мок данные)
   */
  async getPriceHistory(api, hotelId) {
    try {
      console.log('Getting price history for hotel:', hotelId);
      
      const today = new Date();
      const historicalData = Array.from({ length: 90 }, (_, index) => {
        const date = new Date();
        date.setDate(today.getDate() - index);
        
        const basePrice = 200;
        const variation = basePrice * 0.4 * (Math.random() - 0.5);
        const price = Math.round(basePrice + variation);
     
        return {
          date: date.toISOString().split('T')[0],
          price,
          currency: 'EUR',
          available: Math.random() > 0.1
        };
      }).reverse();
     
      return {
        data: {
          prices: historicalData,
          currency: 'EUR'
        }
      };

    } catch (error) {
      console.warn('Failed to get price history:', error);
      return { data: { prices: [], currency: 'EUR' } };
    }
  },

  /**
   * Получение похожих отелей
   */
  async getSimilarHotels(api, hotelId, { limit = 3 } = {}) {
    try {
      console.log('Getting similar hotels for:', hotelId);

      const hotelDetails = await this.getHotelOffers(api, hotelId);
      if (!hotelDetails?.data?.hotel?.cityCode) {
        return { data: [] };
      }

      const cityCode = hotelDetails.data.hotel.cityCode;
      
      const cityHotels = await api.makeRequest(
        `/v1/reference-data/locations/hotels/by-city?cityCode=${cityCode}&radius=20&radiusUnit=KM`
      );
     
      if (!cityHotels?.data?.length) {
        return { data: [] };
      }
     
      const similarHotelIds = cityHotels.data
        .filter(hotel => hotel.hotelId !== hotelId)
        .slice(0, limit)
        .map(hotel => hotel.hotelId)
        .join(',');
     
      if (!similarHotelIds) {
        return { data: [] };
      }
     
      const response = await api.makeRequest(
        `/v3/shopping/hotel-offers?` +
        `hotelIds=${similarHotelIds}&` +
        `adults=2&` +
        `roomQuantity=1&` +
        `currency=EUR&` +
        `bestRateOnly=true&` +
        `view=FULL`
      );

      console.log('Found similar hotels:', response?.data?.length || 0);
      return { data: response.data || [] };

    } catch (error) {
      console.warn('Failed to get similar hotels:', error);
      return { data: [] };
    }
  },

  /**
   * Проверка существования отеля
   */
  async validateHotelId(api, hotelId) {
    try {
      if (!hotelId) {
        throw new Error('Hotel ID is required');
      }
      
      console.log('Validating hotel ID:', hotelId);
      const response = await api.makeRequest(
        `/v1/reference-data/locations/hotels/${hotelId}`
      );
     
      return response.data?.[0] || null;

    } catch (error) {
      console.warn('Failed to validate hotel ID:', error);
      return null;
    }
  }
};

export default endpoints;