// src/components/support/HelpCenter.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search,
  User,
  Hotel,
  Camera,
  DollarSign,
  MessageCircle,
  HelpCircle,
  FileText,
  Mail,
  Phone,
  ExternalLink,
  Book,
  MessageSquare,
  Globe,
  ChevronRight
} from 'lucide-react';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import { Input } from '../ui/input';
import { ROUTES } from '../../config/paths';

const HelpCenter = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const categories = [
    {
      title: "For Travelers",
      icon: User,
      topics: [
        "Using HotelsRating.com",
        "Managing Your Account",
        "Booking Questions",
        "Reporting Issues"
      ]
    },
    {
      title: "For Hotels",
      icon: Hotel,
      topics: [
        "Getting Started",
        "Subscription Plans",
        "Analytics Dashboard",
        "Responding to Reviews"
      ]
    },
    {
      title: "For Travel Bloggers",
      icon: Camera,
      topics: [
        "Creating a Profile",
        "Finding Opportunities",
        "Payment Questions",
        "Content Guidelines"
      ]
    },
    {
      title: "For Investors",
      icon: DollarSign,
      topics: [
        "General Inquiries",
        "Request Financial Information",
        "Partnership Opportunities",
        "Investment Process"
      ]
    }
  ];

  const faqs = [
    "How do I create an account?",
    "What payment methods are accepted?",
    "How do personalized ratings work?",
    "What is your review policy?",
    "How secure is the platform?",
    "Can I change my subscription plan?"
  ];

  const resources = [
    {
      icon: Book,
      title: "Guides & Tutorials",
      description: "Step-by-step instructions for all features"
    },
    {
      icon: MessageSquare,
      title: "Community Forum",
      description: "Connect and share with other users"
    },
    {
      icon: Globe,
      title: "Blog",
      description: "Tips, news, and industry updates"
    }
  ];

  return (
    <div className="max-w-7xl mx-auto p-8">
      {/* Hero Section with Search */}
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          How can we help you today?
        </h1>
        <div className="max-w-2xl mx-auto">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input
              type="text"
              placeholder="Search for help articles..."
              className="pl-10 h-12"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Help Categories */}
      <section className="mb-20">
        <h2 className="text-2xl font-bold mb-8">Help by Category</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {categories.map((category, index) => (
            <Card key={index} className="hover:shadow-md transition-shadow">
              <CardContent className="p-6">
                <category.icon className="w-8 h-8 text-brand-blue mb-4" />
                <h3 className="text-xl font-semibold mb-4">{category.title}</h3>
                <ul className="space-y-2">
                  {category.topics.map((topic, idx) => (
                    <li key={idx} className="flex items-center text-gray-600 hover:text-brand-blue">
                      <ChevronRight className="w-4 h-4 mr-2" />
                      <a href="#" className="text-sm">{topic}</a>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      {/* Popular FAQs */}
      <section className="mb-20">
        <h2 className="text-2xl font-bold mb-8">Frequently Asked Questions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {faqs.map((faq, index) => (
            <a
              key={index}
              href="#"
              className="flex items-center p-4 rounded-lg border hover:border-brand-blue hover:bg-brand-blue/5 transition-colors"
            >
              <HelpCircle className="w-5 h-5 text-brand-blue mr-3" />
              <span className="text-gray-700">{faq}</span>
            </a>
          ))}
        </div>
      </section>

      {/* Additional Resources */}
      <section className="mb-20">
        <h2 className="text-2xl font-bold mb-8">Additional Resources</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {resources.map((resource, index) => (
            <Card key={index} className="hover:shadow-md transition-shadow">
              <CardContent className="p-6">
                <resource.icon className="w-8 h-8 text-brand-blue mb-4" />
                <h3 className="font-semibold mb-2">{resource.title}</h3>
                <p className="text-gray-600 text-sm">{resource.description}</p>
                <Button variant="link" className="mt-4 p-0">
                  Learn more <ExternalLink className="w-4 h-4 ml-2" />
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      {/* Contact Support */}
      <section className="bg-gray-900 text-white rounded-lg p-12 mb-20">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold mb-4">Still Need Help?</h2>
          <p className="text-gray-300">
            Our support team is here to assist you
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <Mail className="w-8 h-8 mx-auto mb-4" />
            <h3 className="font-semibold mb-2">Email Support</h3>
            <p className="text-gray-300 text-sm mb-4">
              Response within 24-48 hours
            </p>
            <a href="mailto:support@hotelsrating.com" className="text-brand-blue hover:underline">
              support@hotelsrating.com
            </a>
          </div>
          <div className="text-center">
            <MessageCircle className="w-8 h-8 mx-auto mb-4" />
            <h3 className="font-semibold mb-2">Live Chat</h3>
            <p className="text-gray-300 text-sm mb-4">
              Available 24/7
            </p>
            <Button variant="outline" className="text-white border-white hover:bg-white/10">
              Start Chat
            </Button>
          </div>
          <div className="text-center">
            <Phone className="w-8 h-8 mx-auto mb-4" />
            <h3 className="font-semibold mb-2">Phone Support</h3>
            <p className="text-gray-300 text-sm mb-4">
              Mon-Fri, 9am-6pm EST
            </p>
            <a href="tel:+15551234567" className="text-brand-blue hover:underline">
              +1 (555) 123-4567
            </a>
          </div>
        </div>
      </section>

      {/* Support Ticket */}
      <section className="text-center">
        <h2 className="text-2xl font-bold mb-4">Create a Support Ticket</h2>
        <p className="text-gray-600 mb-8">
          Can't find what you're looking for? Submit a support ticket and we'll get back to you.
        </p>
        <Link to={ROUTES.CONTACT}>
          <Button size="lg" className="bg-brand-blue hover:bg-brand-blue/90">
            Submit Ticket
            <FileText className="w-4 h-4 ml-2" />
          </Button>
        </Link>
      </section>
    </div>
  );
};

export default HelpCenter;