// src/services/api/amadeus/index.js
import amadeusApi from './amadeusApiService';
import { 
  API_ERROR_CODES, 
  ERROR_MESSAGES,
  AMADEUS_API,
  CACHE_CONFIG,
  DEFAULT_PARAMS,
  AMENITIES,
  API_LIMITS 
} from './constants';
import { handleApiError } from './error-handler';
import * as utils from './utils';
import * as converters from './converters';
import * as endpoints from './endpoints';

// Реэкспортируем нужные методы из API
export const getHotelOffers = (hotelId, params) => amadeusApi.getHotelOffers(hotelId, params);
export const searchHotels = (params) => amadeusApi.searchHotels(params);
export const getHotelDetails = (hotelId, params) => amadeusApi.getHotelDetails(hotelId, params);

export {
  API_ERROR_CODES,
  ERROR_MESSAGES,
  AMADEUS_API,
  CACHE_CONFIG,
  DEFAULT_PARAMS,
  AMENITIES,
  API_LIMITS,
  utils,
  converters,
  endpoints,
  handleApiError
};

// Экспортируем инстанс API как default
export default amadeusApi;