// src/services/api/hotels/methods/scoringMethods.js

/**
 * Рассчитывает персональный рейтинг отеля на основе предпочтений пользователя
 * 
 * @param {Object} hotel - Данные отеля
 * @param {Object} preferences - Предпочтения пользователя
 * @returns {number} Оценка от 0 до 100
 */
export function calculateHotelScore(hotel, preferences) {
  // Если нет предпочтений, возвращаем базовый рейтинг
  if (!preferences) {
    return hotel.rating?.stars ? (hotel.rating.stars * 20) : 0;
  }

  // Веса для разных категорий оценки
  const weights = {
    features: 0.4,  // Важность обязательных функций
    criteria: 0.6   // Важность критериев оценки
  };

  // Оценка обязательных функций
  const featureScore = calculateFeaturesScore(hotel, preferences.features);
  
  // Оценка по критериям
  const criteriaScore = calculateCriteriaScore(hotel, preferences.criteria);

  // Итоговая оценка
  const score = (featureScore * weights.features) + (criteriaScore * weights.criteria);
  
  // Конвертируем в 100-балльную шкалу
  return Math.min(100, Math.round(score * 100));
}

/**
 * Рассчитывает оценку отеля по обязательным функциям
 * 
 * @param {Object} hotel - Данные отеля
 * @param {Object} requiredFeatures - Обязательные функции
 * @returns {number} Оценка от 0 до 1
 */
function calculateFeaturesScore(hotel, requiredFeatures) {
  // Если нет обязательных функций, возвращаем 1
  if (!requiredFeatures || Object.keys(requiredFeatures).length === 0) {
    return 1;
  }

  // Подсчитываем количество совпадающих функций
  const requiredCount = Object.entries(requiredFeatures)
    .filter(([_, required]) => required)
    .length;

  if (requiredCount === 0) return 1;

  const matchedCount = Object.entries(requiredFeatures)
    .filter(([feature, required]) => required && hotel.features[feature])
    .length;

  return matchedCount / requiredCount;
}

/**
 * Рассчитывает оценку отеля по критериям
 * 
 * @param {Object} hotel - Данные отеля
 * @param {Object} criteria - Критерии оценки
 * @returns {number} Оценка от 0 до 1
 */
function calculateCriteriaScore(hotel, criteria) {
  if (!criteria || Object.keys(criteria).length === 0) {
    return hotel.rating?.stars ? hotel.rating.stars / 5 : 0.5;
  }

  return Object.entries(criteria).reduce((total, [criterion, importance]) => {
    const weight = importance / 100;
    const criterionScore = getCriterionScore(hotel, criterion);
    return total + (criterionScore * weight);
  }, 0);
}

/**
 * Рассчитывает оценку по отдельному критерию
 * 
 * @param {Object} hotel - Данные отеля
 * @param {string} criterion - Название критерия
 * @returns {number} Оценка от 0 до 1
 */
function getCriterionScore(hotel, criterion) {
  // Базовая оценка на основе рейтинга отеля
  let score = hotel.rating?.stars ? hotel.rating.stars / 5 : 0.5;

  switch (criterion) {
    case 'comfort':
      score = calculateComfortScore(hotel, score);
      break;

    case 'location':
      score = calculateLocationScore(hotel, score);
      break;

    case 'facilities':
      score = calculateFacilitiesScore(hotel, score);
      break;

    case 'value':
      score = calculateValueScore(hotel, score);
      break;

    case 'cleanliness':
      // Предполагаем, что отели с высоким рейтингом имеют хорошую чистоту
      score = hotel.rating?.stars ? (hotel.rating.stars / 5) * 1.2 : score;
      break;

    case 'service':
      // Учитываем наличие различных сервисов
      if (hotel.features.roomService) score += 0.2;
      if (hotel.features.concierge) score += 0.1;
      if (hotel.features.frontdesk24h) score += 0.1;
      break;
  }

  // Нормализуем оценку от 0 до 1
  return Math.min(1, Math.max(0, score));
}

/**
 * Рассчитывает оценку комфорта
 */
function calculateComfortScore(hotel, baseScore) {
  let score = baseScore;

  // Учитываем различные удобства
  if (hotel.features.wifi) score += 0.1;
  if (hotel.features.airConditioning) score += 0.1;
  if (hotel.features.roomService) score += 0.2;
  if (hotel.features.spa) score += 0.1;
  if (hotel.features.gym) score += 0.1;

  // Учитываем тип отеля
  if (hotel.rating?.stars >= 4) score *= 1.1;

  return score;
}

/**
 * Рассчитывает оценку расположения
 */
function calculateLocationScore(hotel, baseScore) {
  let score = baseScore;

  if (hotel.location?.coordinates) {
    score += 0.2; // Бонус за наличие точных координат

    // Учитываем расстояние до центра города
    const distanceToCenter = parseFloat(hotel.location.nearby?.cityCenter);
    if (!isNaN(distanceToCenter)) {
      // Чем ближе к центру, тем выше оценка
      score += Math.max(0, 0.3 - (distanceToCenter * 0.05));
    }

    // Учитываем близость к транспорту
    if (hotel.location.nearby?.metro) score += 0.1;
    if (hotel.location.nearby?.trainStation) score += 0.1;
    if (hotel.location.nearby?.busStation) score += 0.05;
  }

  return score;
}

/**
 * Рассчитывает оценку удобств
 */
function calculateFacilitiesScore(hotel, baseScore) {
  let score = baseScore;

  // Подсчитываем количество доступных удобств
  const availableFacilities = Object.values(hotel.features).filter(Boolean).length;
  const totalFacilities = Object.keys(hotel.features).length;

  // Нормализуем оценку удобств
  const facilitiesScore = totalFacilities > 0 ? 
    availableFacilities / totalFacilities : 
    0.5;

  // Учитываем как базовую оценку, так и оценку удобств
  score = (score + facilitiesScore) / 2;

  return score;
}

/**
 * Рассчитывает оценку соотношения цена/качество
 */
function calculateValueScore(hotel, baseScore) {
  let score = baseScore;

  if (hotel.price?.current?.amount) {
    // Базовые ценовые диапазоны (можно настроить)
    const PRICE_RANGES = {
      budget: 100,
      moderate: 200,
      luxury: 500
    };

    const price = hotel.price.current.amount;
    const stars = hotel.rating?.stars || 3;

    // Рассчитываем ожидаемую цену на основе звезд
    const expectedPrice = stars * PRICE_RANGES.moderate / 3;
    
    // Сравниваем реальную цену с ожидаемой
    const priceRatio = expectedPrice / price;
    
    // Корректируем оценку на основе соотношения цена/качество
    score = score * Math.min(1.5, Math.max(0.5, priceRatio));
  }

  return score;
}

/**
 * Фильтрует отели по заданным критериям
 * 
 * @param {Array} hotels - Массив отелей
 * @param {Object} filters - Фильтры
 * @returns {Array} Отфильтрованные отели
 */
export function filterHotels(hotels, filters) {
  if (!filters || Object.keys(filters).length === 0) {
    return hotels;
  }

  return hotels.filter(hotel => {
    // Фильтр по цене
    if (filters.priceRange && hotel.price?.current) {
      const [min, max] = filters.priceRange;
      const price = hotel.price.current.amount;
      if (price < min || price > max) return false;
    }

    // Фильтр по рейтингу
    if (filters.rating && hotel.rating?.stars) {
      if (hotel.rating.stars < filters.rating) return false;
    }

    // Фильтр по расстоянию до центра
    if (filters.distance && hotel.location?.nearby?.cityCenter) {
      const distance = parseFloat(hotel.location.nearby.cityCenter);
      if (!isNaN(distance) && distance > filters.distance) return false;
    }

    // Фильтр по удобствам
    if (filters.features) {
      for (const [feature, required] of Object.entries(filters.features)) {
        if (required && !hotel.features[feature]) return false;
      }
    }

    // Фильтр по типу недвижимости
    if (filters.propertyTypes?.length > 0) {
      if (!filters.propertyTypes.includes(hotel.type)) return false;
    }

    return true;
  });
}