// src/services/api/amadeus/amadeusApiService.js
import amadeusCache from './cache';
import { handleApiError } from './error-handler';
import * as utils from './utils';
import { endpoints } from './endpoints';
import { API_ENDPOINTS, CACHE_CONFIG } from './constants';

class AmadeusApiService {
  constructor() {
    if (AmadeusApiService._instance) {
      return AmadeusApiService._instance;
    }
    
    this.initialize();
    AmadeusApiService._instance = this;
    return this;
  }

  initialize() {
    this.api = {
      accessToken: null,
      tokenExpiry: null,
      baseUrl: '/amadeus',
      clientId: import.meta.env.VITE_AMADEUS_API_KEY,
      clientSecret: import.meta.env.VITE_AMADEUS_API_SECRET,
      cache: amadeusCache,
      makeRequest: this.makeRequest.bind(this)
    };
  }

  async makeRequest(endpoint, options = {}) {
    try {
      const token = await this.getAccessToken();
      
      if (!token) {
        throw new Error('No valid access token available');
      }

      const fullUrl = `${this.api.baseUrl}${endpoint}`;
      console.log('Making API request to:', fullUrl);

      // Формируем заголовки
      const headers = new Headers({
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/vnd.amadeus+json'
      });

      const requestOptions = {
        ...options,
        headers
      };

      const response = await fetch(fullUrl, requestOptions);
      console.log('Response status:', response.status);

      const responseText = await response.text();
      
      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = JSON.parse(responseText);
          if (errorData.errors && errorData.errors[0]) {
            errorMessage = `${errorData.errors[0].title}: ${errorData.errors[0].detail || ''}`;
          } else {
            errorMessage = responseText;
          }
        } catch (e) {
          errorMessage = responseText;
        }
        throw new Error(`Request failed: ${response.status} ${errorMessage}`);
      }

      return JSON.parse(responseText);
    } catch (error) {
      console.error('Request error:', error);
      throw error;
    }
  }

  async getAccessToken() {
    try {
      // Проверяем существующий токен
      if (this.api.accessToken && this.api.tokenExpiry && Date.now() < this.api.tokenExpiry) {
        console.log('Using existing token, expires in:', Math.round((this.api.tokenExpiry - Date.now()) / 1000), 'seconds');
        return this.api.accessToken;
      }
  
      console.log('Requesting new access token...');
  
      const response = await fetch(`${this.api.baseUrl}/v1/security/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        body: new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: this.api.clientId,
          client_secret: this.api.clientSecret
        })
      });
  
      console.log('Token response status:', response.status);
      console.log('Token response headers:', Object.fromEntries(response.headers.entries()));
  
      const responseText = await response.text();
  
      // Проверяем, не получили ли мы HTML
      if (responseText.trim().toLowerCase().startsWith('<!doctype')) {
        console.error('Received HTML instead of JSON:', responseText);
        throw new Error('Server returned HTML instead of JSON. Proxy configuration might be incorrect.');
      }
  
      if (!response.ok) {
        throw new Error(`Token request failed: ${response.status} ${responseText}`);
      }
  
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error('Failed to parse token response:', e);
        console.error('Response text:', responseText);
        throw new Error(`Invalid JSON response: ${responseText.substring(0, 100)}...`);
      }
  
      if (!data.access_token) {
        console.error('No access token in response:', data);
        throw new Error('No access token received');
      }
  
      this.api.accessToken = data.access_token;
      this.api.tokenExpiry = Date.now() + ((data.expires_in || 1800) * 1000);
  
      console.log('New access token received, expires in:', data.expires_in, 'seconds');
      return this.api.accessToken;
  
    } catch (error) {
      console.error('Authentication error details:', {
        error: error.message,
        stack: error.stack
      });
      throw new Error(`Authentication error: ${error.message}`);
    }
  }

  async searchLocations(keyword) {
    try {
      return await endpoints.searchLocations(this.api, keyword);
    } catch (error) {
      throw handleApiError(error, 'searchLocations');
    }
  }

  async searchHotels(params) {
    try {
      return await endpoints.searchHotels(this.api, params);
    } catch (error) {
      throw handleApiError(error, 'searchHotels');
    }
  }

  async getHotelDetails(hotelId, params = {}) {
    try {
      return await endpoints.getHotelDetails(this.api, hotelId, params);
    } catch (error) {
      throw handleApiError(error, 'getHotelDetails');
    }
  }

  async getHotelOffers(hotelId, params = {}) {
    try {
      return await endpoints.getHotelOffers(this.api, hotelId, params);
    } catch (error) {
      throw handleApiError(error, 'getHotelOffers');
    }
  }

  async getHotelAmenities(hotelId) {
    try {
      return await endpoints.getHotelAmenities(this.api, hotelId);
    } catch (error) {
      throw handleApiError(error, 'getHotelAmenities');
    }
  }

  async getHotelReviews(hotelId, params = {}) {
    try {
      return await endpoints.getHotelReviews(this.api, hotelId, params);
    } catch (error) {
      throw handleApiError(error, 'getHotelReviews');
    }
  }

  async getPriceHistory(hotelId) {
    try {
      return await endpoints.getPriceHistory(this.api, hotelId);
    } catch (error) {
      throw handleApiError(error, 'getPriceHistory');
    }
  }

  async getSimilarHotels(hotelId, params = {}) {
    try {
      return await endpoints.getSimilarHotels(this.api, hotelId, params);
    } catch (error) {
      throw handleApiError(error, 'getSimilarHotels');
    }
  }

  async getOfferDetails(offerId) {
    try {
      return await endpoints.getOfferDetails(this.api, offerId);
    } catch (error) {
      throw handleApiError(error, 'getOfferDetails');
    }
  }

  async validateHotelId(hotelId) {
    try {
      return await endpoints.validateHotelId(this.api, hotelId);
    } catch (error) {
      throw handleApiError(error, 'validateHotelId');
    }
  }

  async getStatus() {
    try {
      const token = await this.getAccessToken();
      return {
        connected: Boolean(token),
        tokenExpiry: this.api.tokenExpiry ? new Date(this.api.tokenExpiry) : null,
        cacheSize: this.api.cache?.size || 0,
        cacheKeys: this.api.cache?.keys || []
      };
    } catch (error) {
      return {
        connected: false,
        error: error.message
      };
    }
  }

  clearCache() {
    if (this.api.cache?.clear) {
      this.api.cache.clear();
    }
    this.api.accessToken = null;
    this.api.tokenExpiry = null;
  }
}

// Создаем единственный экземпляр
const amadeusApi = new AmadeusApiService();
Object.freeze(amadeusApi);

export default amadeusApi;