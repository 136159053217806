// src/pages/booking/BookingHistoryPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { DayPicker } from 'react-day-picker';
import { 
  Calendar,
  Filter,
  Search,
  Loader2,
  ArrowUpDown,
  FileText,
  Clock,
  Hotel,
  AlertTriangle
} from 'lucide-react';
import { Card, CardContent } from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../../components/ui/sheet';
import { ROUTES } from '../../config/paths';
import { useToast } from '../../hooks/useToast';
import BookingService from '../../services/booking/BookingService';
import { Booking, BookingSearchParams } from '../../types/booking';
import BookingSummary from '../../components/booking/BookingSummary';
import { BOOKING_STATUS } from '../../components/booking/constants';

export const BookingHistoryPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  // State
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Filter states
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<keyof typeof BOOKING_STATUS | 'all'>('all');
  const [dateRange, setDateRange] = useState<{
    from: Date | null;
    to: Date | null;
  } | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Load bookings
  useEffect(() => {
    if (currentUser) {
      loadBookings();
    }
  }, [currentUser]);

  // Apply filters
  useEffect(() => {
    applyFilters();
  }, [bookings, searchTerm, statusFilter, dateRange, sortOrder]);

  const loadBookings = async () => {
    try {
      setLoading(true);
      setError(null);

      const searchParams: BookingSearchParams = {
        userId: currentUser?.uid,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      };

      const userBookings = await BookingService.getUserBookings(searchParams);
      setBookings(userBookings);

    } catch (error) {
      console.error('Error loading bookings:', error);
      setError(error instanceof Error ? error.message : 'Failed to load bookings');
      toast({
        title: "Error",
        description: "Failed to load bookings. Please try again.",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    let filtered = [...bookings];

    // Search filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(booking => 
        booking.hotelDetails.name.toLowerCase().includes(term) ||
        booking.bookingReference.toLowerCase().includes(term)
      );
    }

    // Status filter
    if (statusFilter !== 'all') {
      filtered = filtered.filter(booking => booking.status === statusFilter);
    }

    // Date range filter
    if (dateRange?.from && dateRange?.to) {
      filtered = filtered.filter(booking => {
        const bookingDate = new Date(booking.checkIn);
        return bookingDate >= dateRange.from! && bookingDate <= dateRange.to!;
      });
    }

    // Sort
    filtered.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === 'desc' ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
    });

    setFilteredBookings(filtered);
  };

  const handleViewDetails = (booking: Booking) => {
    navigate(ROUTES.getBookingDetailsPath(booking.id));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loader2 className="w-8 h-8 animate-spin text-brand-blue" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="space-y-6">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold">Booking History</h1>
            <p className="text-gray-600">View and manage your bookings</p>
          </div>
          <Button onClick={() => navigate(ROUTES.HOTEL_SEARCH)}>
            New Booking
          </Button>
        </div>

        {/* Filters */}
        <Card>
          <CardContent className="p-4">
            <div className="flex flex-wrap gap-4 items-center justify-between">
              {/* Search */}
              <div className="flex-1 min-w-[200px]">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                  <Input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search bookings..."
                    className="pl-10"
                  />
                </div>
              </div>

              {/* Desktop Filters */}
              <div className="hidden md:flex items-center gap-4">
                <Select 
                  value={statusFilter} 
                  onValueChange={(value) => setStatusFilter(value as keyof typeof BOOKING_STATUS | 'all')}
                >
                  <SelectTrigger className="w-[150px]">
                    <SelectValue placeholder="Status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Statuses</SelectItem>
                    <SelectItem value="confirmed">Confirmed</SelectItem>
                    <SelectItem value="pending">Pending</SelectItem>
                    <SelectItem value="cancelled">Cancelled</SelectItem>
                    <SelectItem value="completed">Completed</SelectItem>
                  </SelectContent>
                </Select>

                <Button
                  variant="outline"
                  onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
                >
                  <ArrowUpDown className="h-4 w-4 mr-2" />
                  {sortOrder === 'asc' ? 'Oldest first' : 'Newest first'}
                </Button>
              </div>

              {/* Mobile Filter Button */}
              <Sheet open={isFilterOpen} onOpenChange={setIsFilterOpen}>
                <SheetTrigger asChild>
                  <Button variant="outline" className="md:hidden">
                    <Filter className="h-4 w-4 mr-2" />
                    Filters
                  </Button>
                </SheetTrigger>
                <SheetContent>
                  <SheetHeader>
                    <SheetTitle>Filters</SheetTitle>
                  </SheetHeader>
                  <div className="space-y-4 mt-4">
                    <div className="space-y-2">
                      <label className="text-sm font-medium">Status</label>
                      <Select 
                        value={statusFilter} 
                        onValueChange={(value) => setStatusFilter(value as keyof typeof BOOKING_STATUS | 'all')}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">All Statuses</SelectItem>
                          <SelectItem value="confirmed">Confirmed</SelectItem>
                          <SelectItem value="pending">Pending</SelectItem>
                          <SelectItem value="cancelled">Cancelled</SelectItem>
                          <SelectItem value="completed">Completed</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm font-medium">Date Range</label>
                      <DayPicker
                        mode="range"
                        selected={dateRange}
                        onSelect={setDateRange}
                        numberOfMonths={1}
                      />
                    </div>
                  </div>
                </SheetContent>
              </Sheet>
            </div>
          </CardContent>
        </Card>

        {/* Bookings List */}
        {error ? (
          <Card>
            <CardContent className="p-6 text-center">
              <AlertTriangle className="w-12 h-12 mx-auto text-red-500 mb-4" />
              <p className="text-red-500">{error}</p>
            </CardContent>
          </Card>
        ) : filteredBookings.length === 0 ? (
          <Card>
            <CardContent className="p-6 text-center">
              <FileText className="w-12 h-12 mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium">No bookings found</h3>
              <p className="text-gray-500 mt-2">
                {bookings.length === 0 
                  ? "You haven't made any bookings yet"
                  : "No bookings match your filters"}
              </p>
            </CardContent>
          </Card>
        ) : (
          <div className="space-y-4">
            {filteredBookings.map((booking) => (
              <BookingSummary
                key={booking.id}
                booking={booking}
                showActions
                onAction={handleViewDetails}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingHistoryPage;