// src/components/hotels/detail/PolicySection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { 
 Card,
 CardContent 
} from '../../ui/card';
import {
 Accordion,
 AccordionContent,
 AccordionItem,
 AccordionTrigger,
} from "../../ui/accordion";
import { 
 Clock, 
 CreditCard, 
 Users, 
 Baby, 
 Dog,
 Cigarette,
 Car,
 BadgeCheck,
 AlertCircle
} from 'lucide-react';

const PolicyItem = ({ icon: Icon, title, children }) => (
 <div className="space-y-2">
   <div className="flex items-center gap-2">
     <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
       <Icon className="w-4 h-4 text-gray-600" />
     </div>
     <h4 className="font-medium">{title}</h4>
   </div>
   <div className="pl-10 text-gray-600">
     {children}
   </div>
 </div>
);

const ImportantNote = ({ children }) => (
 <div className="flex items-start gap-2 p-4 bg-yellow-50 rounded-lg mt-4">
   <AlertCircle className="w-5 h-5 text-yellow-600 flex-shrink-0 mt-0.5" />
   <div className="text-sm text-yellow-800">
     {children}
   </div>
 </div>
);

const PolicySection = ({ hotel }) => {
 const { policies = {} } = hotel.details || {};

 // Стандартные политики, если не предоставлены
 const defaultPolicies = {
   checkIn: '15:00',
   checkOut: '12:00',
   cancellation: 'Please contact the hotel for cancellation policy details.',
   payment: 'Credit card required for booking.',
   children: 'Children of all ages are welcome.',
   pets: 'Pets are not allowed.',
   smoking: 'Smoking is not permitted in any areas of the hotel.',
   parking: 'No information available about parking.'
 };

 // Объединяем предоставленные политики с дефолтными
 const allPolicies = { ...defaultPolicies, ...policies };

 const sections = [
   {
     id: 'check-in-out',
     title: 'Check-in/Check-out',
     icon: Clock,
     content: (
       <div className="space-y-2">
         <div>
           <span className="font-medium">Check-in:</span> from {allPolicies.checkIn}
         </div>
         <div>
           <span className="font-medium">Check-out:</span> until {allPolicies.checkOut}
         </div>
         {allPolicies.earlyCheckIn && (
           <div>
             <span className="font-medium">Early check-in:</span> {allPolicies.earlyCheckIn}
           </div>
         )}
         {allPolicies.lateCheckOut && (
           <div>
             <span className="font-medium">Late check-out:</span> {allPolicies.lateCheckOut}
           </div>
         )}
       </div>
     )
   },
   {
     id: 'cancellation',
     title: 'Cancellation Policy',
     icon: AlertCircle,
     content: allPolicies.cancellation
   },
   {
     id: 'payment',
     title: 'Payment Policy',
     icon: CreditCard,
     content: (
       <div className="space-y-2">
         <p>{allPolicies.payment}</p>
         {allPolicies.acceptedPaymentMethods && (
           <div>
             <div className="font-medium mt-2">Accepted payment methods:</div>
             <ul className="list-disc pl-5 mt-1">
               {allPolicies.acceptedPaymentMethods.map((method, index) => (
                 <li key={index}>{method}</li>
               ))}
             </ul>
           </div>
         )}
       </div>
     )
   },
   {
     id: 'guests',
     title: 'Guest Policies',
     icon: Users,
     content: (
       <div className="space-y-4">
         <div>
           {allPolicies.children && (
             <PolicyItem icon={Baby} title="Children">
               {allPolicies.children}
             </PolicyItem>
           )}
         </div>
         <div>
           {allPolicies.pets && (
             <PolicyItem icon={Dog} title="Pets">
               {allPolicies.pets}
             </PolicyItem>
           )}
         </div>
         <div>
           {allPolicies.smoking && (
             <PolicyItem icon={Cigarette} title="Smoking">
               {allPolicies.smoking}
             </PolicyItem>
           )}
         </div>
         <div>
           {allPolicies.parking && (
             <PolicyItem icon={Car} title="Parking">
               {allPolicies.parking}
             </PolicyItem>
           )}
         </div>
       </div>
     )
   }
 ];

 return (
   <Card id="policies-section">
     <CardContent className="p-6">
       <div className="flex items-center gap-2 mb-6">
         <BadgeCheck className="w-5 h-5 text-brand-blue" />
         <h3 className="text-xl font-semibold">Hotel Policies</h3>
       </div>

       <Accordion type="single" collapsible className="space-y-4">
         {sections.map(({ id, title, icon: Icon, content }) => (
           <AccordionItem key={id} value={id} className="border rounded-lg px-4">
             <AccordionTrigger className="hover:no-underline">
               <div className="flex items-center gap-3">
                 <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                   <Icon className="w-4 h-4 text-gray-600" />
                 </div>
                 <span className="font-medium">{title}</span>
               </div>
             </AccordionTrigger>
             <AccordionContent className="pl-11 text-gray-600">
               {content}
             </AccordionContent>
           </AccordionItem>
         ))}
       </Accordion>

       {/* Important Notes */}
       {hotel.details?.importantNotes && (
         <ImportantNote>
           {hotel.details.importantNotes}
         </ImportantNote>
       )}

       {/* Дополнительные примечания */}
       {hotel.details?.additionalPolicies && (
         <div className="mt-6 text-sm text-gray-600">
           <h4 className="font-medium text-gray-900 mb-2">Additional Policies</h4>
           <ul className="list-disc pl-5 space-y-1">
             {hotel.details.additionalPolicies.map((policy, index) => (
               <li key={index}>{policy}</li>
             ))}
           </ul>
         </div>
       )}
     </CardContent>
   </Card>
 );
};

PolicyItem.propTypes = {
 icon: PropTypes.elementType.isRequired,
 title: PropTypes.string.isRequired,
 children: PropTypes.node.isRequired
};

ImportantNote.propTypes = {
 children: PropTypes.node.isRequired
};

PolicySection.propTypes = {
 hotel: PropTypes.shape({
   details: PropTypes.shape({
     policies: PropTypes.shape({
       checkIn: PropTypes.string,
       checkOut: PropTypes.string,
       earlyCheckIn: PropTypes.string,
       lateCheckOut: PropTypes.string,
       cancellation: PropTypes.string,
       payment: PropTypes.string,
       acceptedPaymentMethods: PropTypes.arrayOf(PropTypes.string),
       children: PropTypes.string,
       pets: PropTypes.string,
       smoking: PropTypes.string,
       parking: PropTypes.string
     }),
     importantNotes: PropTypes.string,
     additionalPolicies: PropTypes.arrayOf(PropTypes.string)
   })
 }).isRequired
};

export default PolicySection;