// src/components/hotels/detail/FaqSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { 
 Card, 
 CardContent 
} from '../../ui/card';
import {
 Accordion,
 AccordionContent,
 AccordionItem,
 AccordionTrigger,
} from "../../ui/accordion";
import { 
 Clock, 
 CreditCard, 
 Wifi, 
 Car, 
 Users, 
 Baby,
 Dog,
 Coffee,
 Utensils,
 Waves,
 Dumbbell,
 Bath,
 Languages,
 Phone,
 HelpCircle,
} from 'lucide-react';

const DEFAULT_FAQS = [
 {
   category: 'Check-in & Check-out',
   icon: Clock,
   questions: [
     {
       q: 'What are the check-in and check-out times?',
       generateA: (hotel) => 
         `Check-in time starts at ${hotel.details?.checkIn || '15:00'} and check-out time is until ${hotel.details?.checkOut || '12:00'}.`
     },
     {
       q: 'Is early check-in or late check-out possible?',
       generateA: (hotel) => 
         hotel.details?.earlyCheckIn || hotel.details?.lateCheckOut || 
         'Please contact the hotel directly to inquire about early check-in or late check-out options. Additional charges may apply.'
     }
   ]
 },
 {
   category: 'Payment & Reservations',
   icon: CreditCard,
   questions: [
     {
       q: 'What payment methods are accepted?',
       generateA: (hotel) => {
         const methods = hotel.details?.paymentMethods || ['Credit Cards'];
         return `The hotel accepts: ${methods.join(', ')}.`;
       }
     },
     {
       q: 'Is a deposit required?',
       generateA: (hotel) =>
         hotel.details?.depositPolicy || 
         'Please contact the hotel directly for information about deposit requirements.'
     }
   ]
 },
 {
   category: 'Facilities & Amenities',
   icon: Wifi,
   questions: [
     {
       q: 'Is WiFi available?',
       generateA: (hotel) =>
         hotel.features?.wifi
           ? 'Yes, complimentary WiFi is available throughout the property.'
           : 'Please contact the hotel for information about internet access.'
     },
     {
       q: 'Is parking available?',
       generateA: (hotel) =>
         hotel.features?.parking
           ? `Yes, parking is available. ${hotel.details?.parkingDetails || ''}`
           : 'Parking information is not available.'
     },
     {
       q: 'What dining options are available?',
       generateA: (hotel) =>
         hotel.features?.restaurant
           ? `The hotel features on-site dining options. ${hotel.details?.diningDetails || ''}`
           : 'Please contact the hotel for information about dining options.'
     }
   ]
 },
 {
   category: 'Guest Policies',
   icon: Users,
   questions: [
     {
       q: 'Are children allowed?',
       generateA: (hotel) =>
         hotel.details?.childrenPolicy ||
         'Children of all ages are welcome. Please contact the hotel for specific policies regarding children.'
     },
     {
       q: 'Are pets allowed?',
       generateA: (hotel) =>
         hotel.details?.petPolicy ||
         'Please contact the hotel directly for their pet policy.'
     },
     {
       q: 'What languages are spoken at the property?',
       generateA: (hotel) => {
         const languages = hotel.details?.languages || ['English'];
         return `The staff speaks: ${languages.join(', ')}.`;
       }
     }
   ]
 },
 {
   category: 'Services & Support',
   icon: HelpCircle,
   questions: [
     {
       q: 'Is room service available?',
       generateA: (hotel) =>
         hotel.features?.roomService
           ? `Yes, room service is available. ${hotel.details?.roomServiceDetails || ''}`
           : 'Please contact the hotel for information about room service.'
     },
     {
       q: 'What are the cleaning and safety measures?',
       generateA: (hotel) =>
         hotel.details?.cleaningProtocols ||
         'The hotel follows enhanced cleaning and safety protocols. Please contact the hotel for specific details.'
     },
     {
       q: 'How can I contact the hotel directly?',
       generateA: (hotel) => {
         const contacts = [];
         if (hotel.contact?.phone) contacts.push(`Phone: ${hotel.contact.phone}`);
         if (hotel.contact?.email) contacts.push(`Email: ${hotel.contact.email}`);
         return contacts.length > 0
           ? `You can contact the hotel through: ${contacts.join(', ')}`
           : 'Please check your booking confirmation for contact details.';
       }
     }
   ]
 }
];

const FAQSection = ({ hotel }) => {
 // Генерация дополнительных FAQ на основе особенностей отеля
 const generateCustomFaqs = () => {
   const customFaqs = [];

   // Добавляем вопросы о спа, если есть
   if (hotel.features?.spa) {
     customFaqs.push({
       q: 'What spa services are available?',
       generateA: () => `The hotel features spa facilities. ${hotel.details?.spaDetails || 'Contact the hotel for specific spa services and treatments.'}`
     });
   }

   // Добавляем вопросы о фитнесе, если есть
   if (hotel.features?.gym) {
     customFaqs.push({
       q: 'Is there a fitness center?',
       generateA: () => `Yes, the hotel has a fitness center. ${hotel.details?.gymDetails || ''}`
     });
   }

   // Добавляем вопросы о бассейне, если есть
   if (hotel.features?.pool) {
     customFaqs.push({
       q: 'Does the hotel have a pool?',
       generateA: () => `Yes, the hotel has a pool. ${hotel.details?.poolDetails || ''}`
     });
   }

   return customFaqs;
 };

 const customFaqs = generateCustomFaqs();

 return (
   <Card>
     <CardContent className="p-6">
       <h3 className="text-xl font-semibold mb-6">
         Frequently Asked Questions
       </h3>

       <Accordion type="single" collapsible className="space-y-4">
         {DEFAULT_FAQS.map((category, categoryIndex) => (
           <AccordionItem 
             key={categoryIndex} 
             value={`category-${categoryIndex}`}
             className="border rounded-lg px-4"
           >
             <AccordionTrigger className="hover:no-underline">
               <div className="flex items-center gap-3">
                 <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                   <category.icon className="w-4 h-4 text-gray-600" />
                 </div>
                 <span className="font-medium">{category.category}</span>
               </div>
             </AccordionTrigger>
             <AccordionContent>
               <div className="space-y-4 pl-11">
                 {category.questions.map((faq, questionIndex) => (
                   <div key={questionIndex} className="space-y-2">
                     <h4 className="font-medium text-gray-900">
                       {faq.q}
                     </h4>
                     <p className="text-gray-600">
                       {faq.generateA(hotel)}
                     </p>
                   </div>
                 ))}
               </div>
             </AccordionContent>
           </AccordionItem>
         ))}

         {/* Custom FAQs based on hotel features */}
         {customFaqs.length > 0 && (
           <AccordionItem 
             value="custom-features" 
             className="border rounded-lg px-4"
           >
             <AccordionTrigger className="hover:no-underline">
               <div className="flex items-center gap-3">
                 <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                   <Coffee className="w-4 h-4 text-gray-600" />
                 </div>
                 <span className="font-medium">Special Features</span>
               </div>
             </AccordionTrigger>
             <AccordionContent>
               <div className="space-y-4 pl-11">
                 {customFaqs.map((faq, index) => (
                   <div key={index} className="space-y-2">
                     <h4 className="font-medium text-gray-900">
                       {faq.q}
                     </h4>
                     <p className="text-gray-600">
                       {faq.generateA()}
                     </p>
                   </div>
                 ))}
               </div>
             </AccordionContent>
           </AccordionItem>
         )}
       </Accordion>

       {/* Contact Information */}
       <div className="mt-6 p-4 bg-gray-50 rounded-lg">
         <div className="flex items-center gap-2 mb-2">
           <Phone className="w-4 h-4 text-gray-600" />
           <span className="font-medium">Still have questions?</span>
         </div>
         <p className="text-sm text-gray-600">
           Contact our front desk for any additional questions or special requests.
           {hotel.contact?.phone && ` Call us at ${hotel.contact.phone}`}
           {hotel.contact?.email && ` or email us at ${hotel.contact.email}`}.
         </p>
       </div>
     </CardContent>
   </Card>
 );
};

FAQSection.propTypes = {
 hotel: PropTypes.shape({
   details: PropTypes.shape({
     checkIn: PropTypes.string,
     checkOut: PropTypes.string,
     earlyCheckIn: PropTypes.string,
     lateCheckOut: PropTypes.string,
     paymentMethods: PropTypes.arrayOf(PropTypes.string),
     depositPolicy: PropTypes.string,
     parkingDetails: PropTypes.string,
     diningDetails: PropTypes.string,
     childrenPolicy: PropTypes.string,
     petPolicy: PropTypes.string,
     languages: PropTypes.arrayOf(PropTypes.string),
     roomServiceDetails: PropTypes.string,
     cleaningProtocols: PropTypes.string,
     spaDetails: PropTypes.string,
     gymDetails: PropTypes.string,
     poolDetails: PropTypes.string
   }),
   features: PropTypes.shape({
     wifi: PropTypes.bool,
     parking: PropTypes.bool,
     restaurant: PropTypes.bool,
     roomService: PropTypes.bool,
     spa: PropTypes.bool,
     gym: PropTypes.bool,
     pool: PropTypes.bool
   }),
   contact: PropTypes.shape({
     phone: PropTypes.string,
     email: PropTypes.string
   })
 }).isRequired
};

export default FAQSection;