import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateProfile as updateFirebaseProfile
} from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  getDoc, 
  updateDoc,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore';
import { 
  ref, 
  uploadBytes, 
  getDownloadURL, 
  deleteObject 
} from 'firebase/storage';
import { auth, db, storage } from '../config/firebase';

const ROLES = {
  ADMIN: 'admin',
  MODERATOR: 'moderator',
  USER: 'user'
};

const AuthContext = createContext();

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Вспомогательная функция для обновления данных пользователя в Firestore
  const updateUserData = async (uid, data) => {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, {
      ...data,
      updatedAt: new Date().toISOString()
    });
  };

  // Регистрация нового пользователя
  async function register(email, password, displayName) {
    try {
      setError('');
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      await updateFirebaseProfile(userCredential.user, {
        displayName: displayName
      });
  
      // Создаем документ пользователя в Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email,
        displayName,
        role: ROLES.USER,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        preferences: {},
        savedHotels: [],
        searchHistory: [],
        photoURL: null,
        phone: '',
        location: '',
        birthDate: '',
        bio: '',
        lastLogin: new Date().toISOString()
      });
  
      return userCredential.user;
    } catch (error) {
      setError(handleAuthError(error));
      throw error;
    }
  }

  // Вход пользователя
  async function login(email, password) {
    try {
      setError('');
      const result = await signInWithEmailAndPassword(auth, email, password);
      
      // Загружаем дополнительные данные пользователя
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      const userData = userDoc.data();
      
      // Обновляем время последнего входа
      await updateUserData(result.user.uid, {
        lastLogin: new Date().toISOString()
      });
      
      // Объединяем данные Firebase Auth и Firestore
      const userWithData = {
        ...result.user,
        ...userData
      };
      
      setCurrentUser(userWithData);
      return userWithData;
    } catch (error) {
      setError(handleAuthError(error));
      throw error;
    }
  }

  // Выход пользователя
  async function logout() {
    try {
      setError('');
      if (currentUser) {
        await updateUserData(currentUser.uid, {
          lastLogout: new Date().toISOString()
        });
      }
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      setError(handleAuthError(error));
      throw error;
    }
  }

  // Сброс пароля
  async function resetPassword(email) {
    try {
      setError('');
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      setError(handleAuthError(error));
      throw error;
    }
  }

  // Обновление профиля пользователя
  async function updateUserProfile(userData) {
    if (!currentUser) throw new Error('No authenticated user');
    
    try {
      setError('');
      const { avatarFile, ...updates } = userData;
      let photoURL = userData.photoURL;

      // Обработка аватара
      if (avatarFile instanceof File) {
        const avatarRef = ref(storage, `avatars/${currentUser.uid}`);
        
        // Удаляем старый аватар, если есть
        if (currentUser.photoURL) {
          try {
            await deleteObject(ref(storage, currentUser.photoURL));
          } catch (error) {
            console.warn('Error deleting old avatar:', error);
          }
        }
        
        // Загружаем новый аватар
        const snapshot = await uploadBytes(avatarRef, avatarFile);
        photoURL = await getDownloadURL(snapshot.ref);
        updates.photoURL = photoURL;
      }

      // Обновление профиля в Firebase Auth
      const authUpdates = {
        displayName: userData.displayName,
        ...(photoURL && { photoURL })
      };
      await updateFirebaseProfile(auth.currentUser, authUpdates);

      // Обновление данных в Firestore
      await updateUserData(currentUser.uid, updates);

      // Обновление локального состояния
      setCurrentUser(prev => ({
        ...prev,
        ...updates,
        photoURL: photoURL || prev.photoURL
      }));

      return true;
    } catch (error) {
      setError(handleAuthError(error));
      throw error;
    }
  }

  // Проверка прав администратора
  async function checkAdminRole(uid) {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      return userDoc.data()?.role === ROLES.ADMIN;
    } catch (error) {
      console.error('Error checking admin role:', error);
      return false;
    }
  }

  // Обработка ошибок
  function handleAuthError(error) {
    let message = 'An error occurred. Please try again.';
    switch (error.code) {
      case 'auth/email-already-in-use':
        message = 'This email is already registered.';
        break;
      case 'auth/weak-password':
        message = 'Password should be at least 6 characters.';
        break;
      case 'auth/invalid-email':
        message = 'Please enter a valid email address.';
        break;
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        message = 'Invalid email or password.';
        break;
      case 'auth/too-many-requests':
        message = 'Too many attempts. Please try again later.';
        break;
      default:
        message = error.message;
    }
    return message;
  }

  // Слушатель состояния аутентификации
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Загружаем дополнительные данные пользователя из Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          
          // Проверяем и обновляем время последнего входа
          if (userData && !userData.lastLogin) {
            await updateUserData(user.uid, {
              lastLogin: new Date().toISOString()
            });
          }
          
          setCurrentUser({
            ...user,
            ...userData,
            isAdmin: userData?.role === ROLES.ADMIN,
            isModerator: userData?.role === ROLES.MODERATOR
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
          setCurrentUser(user);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    error,
    register,
    login,
    logout,
    resetPassword,
    updateUserProfile,
    isAdmin: currentUser?.role === ROLES.ADMIN,
    isModerator: currentUser?.role === ROLES.MODERATOR,
    checkAdminRole,
    ROLES
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, useAuth, ROLES };