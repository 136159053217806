// src/components/profile/ProfileHistory.jsx
import React from 'react';

const ProfileHistory = () => {
  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-gray-900">Search History</h3>
      <p className="text-gray-500">Your recent searches will appear here.</p>
    </div>
  );
};

export default ProfileHistory;