// src/services/contact/firestore.js

import {
  collection,
  addDoc,
  updateDoc,
  getDoc,
  getDocs,
  doc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FIRESTORE_PATHS, MESSAGE_STATUS, MESSAGE_METADATA } from '../../components/contact/constants';

/**
 * Безопасное преобразование временной метки
 */
const safeTimestampToDate = (timestamp) => {
  if (!timestamp) return null;
  return timestamp.toDate ? timestamp.toDate() : timestamp;
};

/**
 * Безопасно получает timestamp в миллисекундах
 */
const getTimestampInMillis = (timestamp) => {
  if (!timestamp) return 0;
  if (timestamp.toMillis) return timestamp.toMillis();
  if (timestamp instanceof Date) return timestamp.getTime();
  if (typeof timestamp === 'string') return new Date(timestamp).getTime();
  return 0;
};

/**
 * Добавляет новое сообщение в Firestore
 */
const createMessage = async (messageData, metadata = {}) => {
  try {
    const messagesRef = collection(db, FIRESTORE_PATHS.MESSAGES);
    
    const messageDoc = {
      ...messageData,
      [MESSAGE_METADATA.CREATED_AT]: serverTimestamp(),
      [MESSAGE_METADATA.UPDATED_AT]: serverTimestamp(),
      [MESSAGE_METADATA.STATUS]: MESSAGE_STATUS.NEW,
      ...metadata
    };

    const docRef = await addDoc(messagesRef, messageDoc);
    console.log('Message created with ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error creating message:', error);
    throw new Error('Не удалось сохранить сообщение');
  }
};

/**
 * Обновляет статус сообщения
 */
const updateMessageStatus = async (messageId, status, moderatorId = null) => {
  try {
    console.log('Updating message status:', { messageId, status, moderatorId });
    const messageRef = doc(db, FIRESTORE_PATHS.MESSAGES, messageId);
    
    const updateData = {
      [MESSAGE_METADATA.STATUS]: status,
      [MESSAGE_METADATA.UPDATED_AT]: serverTimestamp(),
    };

    if (moderatorId) {
      updateData[MESSAGE_METADATA.MODERATED_BY] = moderatorId;
      updateData[MESSAGE_METADATA.MODERATED_AT] = serverTimestamp();
    }

    await updateDoc(messageRef, updateData);
    console.log('Message status updated successfully');
  } catch (error) {
    console.error('Error updating message status:', error);
    throw new Error('Не удалось обновить статус сообщения');
  }
};

/**
 * Получает сообщение по ID
 */
const getMessage = async (messageId) => {
  try {
    console.log('Getting message with ID:', messageId);
    
    const messageRef = doc(db, FIRESTORE_PATHS.MESSAGES, messageId);
    const messageSnap = await getDoc(messageRef);
    
    if (!messageSnap.exists()) {
      console.log('Message not found');
      throw new Error('Сообщение не найдено');
    }

    const data = messageSnap.data();
    console.log('Raw message data:', data);

    // Безопасное преобразование временных меток
    const formattedData = {
      id: messageSnap.id,
      ...data,
      [MESSAGE_METADATA.CREATED_AT]: safeTimestampToDate(data[MESSAGE_METADATA.CREATED_AT]),
      [MESSAGE_METADATA.UPDATED_AT]: safeTimestampToDate(data[MESSAGE_METADATA.UPDATED_AT]),
      [MESSAGE_METADATA.MODERATED_AT]: safeTimestampToDate(data[MESSAGE_METADATA.MODERATED_AT])
    };

    console.log('Formatted message data:', formattedData);
    return formattedData;

  } catch (error) {
    console.error('Error getting message:', error);
    throw new Error('Не удалось получить сообщение');
  }
};

/**
 * Получает список сообщений с пагинацией
 */
const getMessages = async ({
  status = null,
  startAfterDoc = null,
  pageSize = 20,
  orderByField = MESSAGE_METADATA.CREATED_AT,
  orderDirection = 'desc'
} = {}) => {
  try {
    console.log('Getting messages with params:', { status, pageSize, orderByField, orderDirection });
    
    let messagesQuery = collection(db, FIRESTORE_PATHS.MESSAGES);
    const queryConstraints = [];

    if (status) {
      queryConstraints.push(where(MESSAGE_METADATA.STATUS, '==', status));
    }

    queryConstraints.push(orderBy(orderByField, orderDirection));
    queryConstraints.push(limit(pageSize));

    if (startAfterDoc) {
      queryConstraints.push(startAfter(startAfterDoc));
    }

    const q = query(messagesQuery, ...queryConstraints);
    const querySnapshot = await getDocs(q);
    
    const messages = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        [MESSAGE_METADATA.CREATED_AT]: safeTimestampToDate(data[MESSAGE_METADATA.CREATED_AT]),
        [MESSAGE_METADATA.UPDATED_AT]: safeTimestampToDate(data[MESSAGE_METADATA.UPDATED_AT]),
        [MESSAGE_METADATA.MODERATED_AT]: safeTimestampToDate(data[MESSAGE_METADATA.MODERATED_AT])
      };
    });

    console.log('Retrieved messages count:', messages.length);
    
    return {
      messages,
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
      hasMore: querySnapshot.docs.length === pageSize
    };
  } catch (error) {
    console.error('Error getting messages:', error);
    throw new Error('Не удалось получить список сообщений');
  }
};

/**
 * Получает статистику по сообщениям
 */
const getMessagesStats = async () => {
  try {
    console.log('Getting messages statistics');
    
    const stats = {
      total: 0,
      byStatus: {},
      dailyStats: [],
      averageResponseTime: 0
    };

    // Инициализируем счетчики для всех статусов
    Object.values(MESSAGE_STATUS).forEach(status => {
      stats.byStatus[status] = 0;
    });

    const messagesSnap = await getDocs(collection(db, FIRESTORE_PATHS.MESSAGES));
    console.log('Total documents found:', messagesSnap.size);

    let totalResponseTime = 0;
    let responsesCount = 0;
    const dailyGroups = {};

    messagesSnap.forEach(doc => {
      const data = doc.data();
      stats.total++;

      // Подсчет по статусам
      const status = data[MESSAGE_METADATA.STATUS];
      if (status) {
        stats.byStatus[status] = (stats.byStatus[status] || 0) + 1;
      }

      // Подсчет среднего времени ответа
      const createdAt = data[MESSAGE_METADATA.CREATED_AT];
      const moderatedAt = data[MESSAGE_METADATA.MODERATED_AT];

      if (createdAt && moderatedAt) {
        const createdTime = getTimestampInMillis(createdAt);
        const moderatedTime = getTimestampInMillis(moderatedAt);
        
        if (createdTime && moderatedTime) {
          const responseTime = moderatedTime - createdTime;
          totalResponseTime += responseTime;
          responsesCount++;
        }
      }

      // Группировка по дням
      if (data[MESSAGE_METADATA.CREATED_AT]) {
        const timestamp = safeTimestampToDate(data[MESSAGE_METADATA.CREATED_AT]);
        if (timestamp) {
          const date = timestamp.toISOString().split('T')[0];
          
          if (!dailyGroups[date]) {
            dailyGroups[date] = {
              date,
              total: 0,
              [MESSAGE_STATUS.APPROVED]: 0,
              [MESSAGE_STATUS.REJECTED]: 0,
              [MESSAGE_STATUS.SPAM]: 0
            };
          }

          dailyGroups[date].total++;
          if (status) {
            dailyGroups[date][status] = (dailyGroups[date][status] || 0) + 1;
          }
        }
      }
    });

    if (responsesCount > 0) {
      stats.averageResponseTime = Math.round(totalResponseTime / responsesCount);
    }

    stats.dailyStats = Object.values(dailyGroups).sort((a, b) => 
      new Date(a.date) - new Date(b.date)
    );

    console.log('Calculated statistics:', stats);
    return stats;

  } catch (error) {
    console.error('Error getting messages stats:', error);
    throw new Error('Не удалось получить статистику сообщений');
  }
};

/**
 * Обновляет метаданные сообщения
 */
const updateMessageMetadata = async (messageId, metadata) => {
  try {
    console.log('Updating message metadata:', { messageId, metadata });

    // Удаляем undefined значения из metadata
    const cleanMetadata = Object.entries(metadata).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    // Добавляем поле обновления
    cleanMetadata[MESSAGE_METADATA.UPDATED_AT] = serverTimestamp();

    const messageRef = doc(db, FIRESTORE_PATHS.MESSAGES, messageId);
    await updateDoc(messageRef, cleanMetadata);
    
    console.log('Message metadata updated successfully');
  } catch (error) {
    console.error('Error updating message metadata:', error);
    throw new Error('Не удалось обновить метаданные сообщения');
  }
};

export {
  createMessage,
  updateMessageStatus,
  getMessage,
  getMessages,
  getMessagesStats,
  updateMessageMetadata
};