// src/components/legal/Disclaimer.jsx
export const Disclaimer = () => {
    return (
      <div className="max-w-4xl mx-auto p-8">
        <h1 className="text-3xl font-bold mb-8">Disclaimer for HotelsRating.com</h1>
        <div className="text-sm text-gray-600 mb-8">
          Effective Date: 25/11/2024
        </div>
        
        <div className="prose prose-blue max-w-none space-y-6">
          <p>
            HotelsRating.com ("we," "us," or "our") provides this website as a platform for information, 
            user-generated content, and services related to hotel ratings and travel. By using this website, 
            you agree to the terms of this Disclaimer. If you do not agree, please refrain from using HotelsRating.com.
          </p>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. General Information Disclaimer</h2>
            <ul className="list-disc pl-6">
              <li>The content provided on HotelsRating.com is for general informational purposes only.</li>
              <li>While we strive to ensure the accuracy of the information presented, we make no guarantees, 
              representations, or warranties of any kind, express or implied, about the completeness, accuracy, 
              reliability, or availability of the content.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">2. No Professional Advice</h2>
            <ul className="list-disc pl-6">
              <li>The information on HotelsRating.com does not constitute professional advice, whether legal, 
              financial, or otherwise.</li>
              <li>Users should seek professional guidance tailored to their specific circumstances before making 
              decisions based on the content of this website.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">3. User-Generated Content</h2>
            <ul className="list-disc pl-6">
              <li>HotelsRating.com allows users to post reviews, ratings, and other content.</li>
              <li>We do not endorse or verify the accuracy of user-generated content and are not responsible for 
              opinions, statements, or claims made by users.</li>
              <li>Users are solely responsible for the content they post, and by submitting content, they agree to 
              indemnify us from any claims arising from such content.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Third-Party Links and Services</h2>
            <ul className="list-disc pl-6">
              <li>HotelsRating.com may contain links to third-party websites or services for your convenience.</li>
              <li>We do not endorse, control, or assume responsibility for the content, privacy policies, or practices 
              of third-party sites.</li>
              <li>Any dealings you have with third-party services are at your own risk, and we are not liable for any 
              loss or damage arising from such interactions.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
            <p>To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, 
            consequential, or special damages resulting from:</p>
            <ul className="list-disc pl-6">
              <li>Your use of or inability to use HotelsRating.com.</li>
              <li>Errors, inaccuracies, or omissions in the content provided.</li>
              <li>User-generated content, including reviews and ratings.</li>
              <li>Transactions conducted with third-party services linked to or featured on HotelsRating.com.</li>
            </ul>
            <p className="mt-2">This limitation applies even if we have been advised of the possibility of such damages.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Availability and Performance</h2>
            <ul className="list-disc pl-6">
              <li>We do not guarantee that HotelsRating.com will be available at all times, free of errors, or secure.</li>
              <li>We reserve the right to modify, suspend, or discontinue the website or its features at any time without 
              notice.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">7. No Guarantees on Results</h2>
            <ul className="list-disc pl-6">
              <li>HotelsRating.com does not guarantee specific results or outcomes from using our platform, including but 
              not limited to hotel bookings, recommendations, or ratings.</li>
              <li>Any reliance you place on the information provided on this website is strictly at your own risk.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">8. Legal Compliance</h2>
            <p>Users are responsible for ensuring that their use of HotelsRating.com complies with all applicable laws 
            and regulations in their jurisdiction. This disclaimer is governed by the laws of the United Arab Emirates (UAE), 
            Dubai, and any disputes shall be subject to the exclusive jurisdiction of the courts in Dubai, UAE.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">9. Indemnification</h2>
            <p>By using HotelsRating.com, you agree to indemnify and hold harmless HotelsRating.com, its owners, employees, 
            affiliates, and agents from any claims, liabilities, damages, or expenses arising from:</p>
            <ul className="list-disc pl-6">
              <li>Your violation of this Disclaimer.</li>
              <li>Your misuse of the website.</li>
              <li>Any disputes with third parties linked to or featured on HotelsRating.com.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">10. Changes to This Disclaimer</h2>
            <p>We may update this Disclaimer from time to time to reflect changes in our practices or legal requirements. 
            Updates will be posted on this page with a revised "Effective Date." Your continued use of HotelsRating.com 
            constitutes your acceptance of the updated Disclaimer.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold mb-4">11. Contact Information</h2>
            <p>If you have questions or concerns about this Disclaimer, please contact us:</p>
            <div className="mt-4">
              <p>HotelsRating.com</p>
              <p>Email: abuse@hotelsrating.com</p>
            </div>
          </section>
  
          <div className="mt-8 text-sm text-gray-600">
            By accessing and using HotelsRating.com, you acknowledge that you have read, understood, and agreed to this 
            Disclaimer.
          </div>
        </div>
      </div>
    );
  };