// src/components/hotels/detail/LoadingState.jsx
import React from 'react';
import { Card, CardContent } from '../../ui/card';
import { Skeleton } from '../../ui/skeleton';

const HeaderSkeleton = () => (
 <div className="bg-white border-b">
   <div className="max-w-7xl mx-auto px-4 py-8">
     {/* Back button skeleton */}
     <Skeleton className="h-8 w-24 mb-4" />
     
     <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start gap-4">
       {/* Hotel info skeleton */}
       <div className="space-y-4">
         <Skeleton className="h-8 w-64" /> {/* Hotel name */}
         <div className="flex gap-4">
           <Skeleton className="h-4 w-32" /> {/* Address */}
           <Skeleton className="h-4 w-24" /> {/* Rating */}
         </div>
       </div>
       
       {/* Price skeleton */}
       <div className="text-right">
         <Skeleton className="h-4 w-32 ml-auto mb-2" />
         <Skeleton className="h-8 w-40 ml-auto" />
       </div>
     </div>
   </div>
 </div>
);

const GallerySkeleton = () => (
 <Card>
   <CardContent className="p-6">
     <div className="space-y-2">
       <Skeleton className="aspect-video w-full rounded-lg" />
       <div className="grid grid-cols-4 gap-2">
         {[...Array(4)].map((_, i) => (
           <Skeleton key={i} className="aspect-video w-full rounded-lg" />
         ))}
       </div>
     </div>
   </CardContent>
 </Card>
);

const TabsSkeleton = () => (
 <div className="space-y-4">
   {/* Tab buttons */}
   <div className="flex gap-2">
     {[...Array(5)].map((_, i) => (
       <Skeleton key={i} className="h-10 w-24" />
     ))}
   </div>
   
   {/* Tab content */}
   <Card>
     <CardContent className="p-6">
       <div className="space-y-4">
         <Skeleton className="h-6 w-3/4" />
         <Skeleton className="h-4 w-full" />
         <Skeleton className="h-4 w-full" />
         <Skeleton className="h-4 w-2/3" />
       </div>
     </CardContent>
   </Card>
 </div>
);

const SidebarSkeleton = () => (
 <div className="space-y-6">
   {/* Booking form skeleton */}
   <Card>
     <CardContent className="p-6">
       <div className="space-y-4">
         <Skeleton className="h-10 w-full" />
         <Skeleton className="h-10 w-full" />
         <Skeleton className="h-10 w-full" />
         <Skeleton className="h-20 w-full" />
         <Skeleton className="h-10 w-full" />
       </div>
     </CardContent>
   </Card>

   {/* Quick info skeleton */}
   <Card>
     <CardContent className="p-6">
       <Skeleton className="h-6 w-32 mb-4" />
       <div className="space-y-4">
         {[...Array(3)].map((_, i) => (
           <div key={i} className="flex items-center gap-2">
             <Skeleton className="h-8 w-8 rounded-full" />
             <div className="space-y-2 flex-1">
               <Skeleton className="h-3 w-24" />
               <Skeleton className="h-3 w-16" />
             </div>
           </div>
         ))}
       </div>
     </CardContent>
   </Card>

   {/* Contact info skeleton */}
   <Card>
     <CardContent className="p-6">
       <Skeleton className="h-6 w-32 mb-4" />
       <div className="space-y-4">
         {[...Array(3)].map((_, i) => (
           <div key={i} className="flex items-center gap-2">
             <Skeleton className="h-6 w-6 rounded-full" />
             <Skeleton className="h-3 w-40" />
           </div>
         ))}
       </div>
     </CardContent>
   </Card>
 </div>
);

const LoadingState = () => {
 return (
   <div className="min-h-screen bg-gray-50">
     {/* Header Skeleton */}
     <HeaderSkeleton />

     {/* Main Content */}
     <div className="max-w-7xl mx-auto px-4 py-8">
       <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
         {/* Left Column */}
         <div className="lg:col-span-2 space-y-8">
           <GallerySkeleton />
           <TabsSkeleton />
           
           {/* Similar Hotels Skeleton */}
           <div className="space-y-4">
             <Skeleton className="h-6 w-48" />
             <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
               {[...Array(3)].map((_, i) => (
                 <Card key={i}>
                   <CardContent className="p-4">
                     <Skeleton className="aspect-video w-full rounded-lg mb-4" />
                     <Skeleton className="h-5 w-3/4 mb-2" />
                     <Skeleton className="h-4 w-1/2 mb-4" />
                     <Skeleton className="h-8 w-full" />
                   </CardContent>
                 </Card>
               ))}
             </div>
           </div>
         </div>

         {/* Right Column */}
         <SidebarSkeleton />
       </div>
     </div>
   </div>
 );
};

export default LoadingState;