// src/components/admin/contact/MessageDetails.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  CheckCircle, 
  XCircle, 
  AlertTriangle, 
  ArrowLeft, 
  ExternalLink,
  Mail,
  Phone,
  Clock,
  User,
  MessageSquare,
  Shield
} from 'lucide-react';
import { getMessage } from '../../../services/contact/firestore';
import { handleModeratorAction } from '../../../services/contact/moderation';
import { MESSAGE_STATUS } from '../../../components/contact/constants';
import { Button } from '../../ui/button';
import { Card, CardHeader, CardContent } from '../../ui/card';

const STATUS_COLORS = {
  [MESSAGE_STATUS.NEW]: 'text-blue-500',
  [MESSAGE_STATUS.PENDING_REVIEW]: 'text-yellow-500',
  [MESSAGE_STATUS.APPROVED]: 'text-green-500',
  [MESSAGE_STATUS.REJECTED]: 'text-red-500',
  [MESSAGE_STATUS.SPAM]: 'text-red-600'
};

const STATUS_LABELS = {
  [MESSAGE_STATUS.NEW]: 'Новое',
  [MESSAGE_STATUS.PENDING_REVIEW]: 'На модерации',
  [MESSAGE_STATUS.APPROVED]: 'Одобрено',
  [MESSAGE_STATUS.REJECTED]: 'Отклонено',
  [MESSAGE_STATUS.SPAM]: 'Спам'
};

const MessageDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [moderationComment, setModerationComment] = useState('');
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    loadMessage();
  }, [id]);

  const loadMessage = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log('Loading message with ID:', id);
      const data = await getMessage(id);
      console.log('Loaded message data:', data);
      setMessage(data);
    } catch (error) {
      console.error('Error loading message:', error);
      setError('Не удалось загрузить сообщение');
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (action) => {
    try {
      setProcessing(true);
      await handleModeratorAction(id, {
        action,
        comment: moderationComment,
      });

      await loadMessage();
      setModerationComment('');
    } catch (error) {
      console.error('Error handling action:', error);
      setError('Не удалось выполнить действие');
    } finally {
      setProcessing(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Н/Д';
    return new Date(timestamp).toLocaleString();
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-blue"></div>
      </div>
    );
  }

  if (error) {
    return (
      <Card>
        <CardContent className="p-6">
          <div className="text-center">
            <AlertTriangle className="h-12 w-12 text-red-500 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">{error}</h3>
            <Button 
              onClick={() => navigate('/admin/contact/messages')}
              variant="outline"
              size="sm"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Вернуться к списку
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!message) {
    return (
      <Card>
        <CardContent className="p-6">
          <div className="text-center">
            <AlertTriangle className="h-12 w-12 text-yellow-500 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">Сообщение не найдено</h3>
            <Button 
              onClick={() => navigate('/admin/contact/messages')}
              variant="outline"
              size="sm"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Вернуться к списку
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      {/* Заголовок */}
      <div className="flex items-center justify-between">
        <Button 
          onClick={() => navigate('/admin/contact/messages')}
          variant="ghost"
          size="sm"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Назад к списку
        </Button>
        <span className="text-sm text-gray-500">ID: {id}</span>
      </div>

      {/* Основная информация */}
      <Card>
        <CardHeader>
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-xl font-semibold">{message.subject || 'Без темы'}</h2>
              <div className={`text-sm ${STATUS_COLORS[message.status]}`}>
                {STATUS_LABELS[message.status]}
              </div>
            </div>
            <div className="text-sm text-gray-500">
              {formatDate(message.createdAt)}
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Информация об отправителе */}
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <User className="w-4 h-4 text-gray-400" />
                <span>{message.name}</span>
              </div>
              <div className="flex items-center gap-2">
                <Mail className="w-4 h-4 text-gray-400" />
                <a href={`mailto:${message.email}`} className="text-brand-blue hover:underline">
                  {message.email}
                </a>
              </div>
              {message.phone && (
                <div className="flex items-center gap-2">
                  <Phone className="w-4 h-4 text-gray-400" />
                  <a href={`tel:${message.phone}`} className="text-brand-blue hover:underline">
                    {message.phone}
                  </a>
                </div>
              )}
            </div>

            {/* Сообщение */}
            <div>
              <h3 className="font-medium mb-2">Сообщение</h3>
              <div className="bg-gray-50 rounded-lg p-4 whitespace-pre-wrap">
                {message.message}
              </div>
            </div>

            {/* Действия модерации */}
            {message.status === MESSAGE_STATUS.PENDING_REVIEW && (
              <div className="space-y-4">
                <h3 className="font-medium">Действия модерации</h3>
                <textarea
                  value={moderationComment}
                  onChange={(e) => setModerationComment(e.target.value)}
                  placeholder="Комментарий модератора..."
                  className="w-full px-3 py-2 border rounded-lg"
                  rows={3}
                />
                <div className="flex gap-2">
                  <Button
                    onClick={() => handleAction('approve')}
                    disabled={processing}
                    className="bg-green-600 hover:bg-green-700"
                  >
                    <CheckCircle className="w-4 h-4 mr-2" />
                    Одобрить
                  </Button>
                  <Button
                    onClick={() => handleAction('reject')}
                    disabled={processing}
                    variant="destructive"
                  >
                    <XCircle className="w-4 h-4 mr-2" />
                    Отклонить
                  </Button>
                  <Button
                    onClick={() => handleAction('mark_spam')}
                    disabled={processing}
                    className="bg-yellow-600 hover:bg-yellow-700"
                  >
                    <AlertTriangle className="w-4 h-4 mr-2" />
                    Спам
                  </Button>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default MessageDetails;