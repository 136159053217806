// src/pages/admin/contact/MessageList.jsx
import React from 'react';
import MessageListComponent from '../../../components/admin/contact/MessageList';

const MessageListPage = () => {
  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">
        Сообщения пользователей
      </h1>
      <MessageListComponent />
    </div>
  );
};

export default MessageListPage;