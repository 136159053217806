// src/services/api/hotels/index.js
import { HotelService } from './HotelService.js';

// Создаем единственный экземпляр
const hotelService = new HotelService();

// Предотвращаем модификацию экземпляра
Object.freeze(hotelService);

// Экспортируем экземпляр как default
export default hotelService;

// Экспортируем класс для тестирования
export { HotelService };
