// src/services/api/amadeus/constants.js

// API Endpoints
export const API_ENDPOINTS = {
  AUTH: '/v1/security/oauth2/token',
  LOCATIONS: '/v1/reference-data/locations',
  HOTELS_BY_CITY: '/v1/reference-data/locations/hotels/by-city',
  HOTEL_SEARCH: '/v3/shopping/hotel-offers',
  HOTEL_DETAILS: '/v3/shopping/hotel-offers/by-hotel',
  HOTEL_OFFER: '/v3/shopping/hotel-offers',
  HOTEL_AMENITIES: '/v3/reference-data/locations/hotels/amenities',
  HOTEL_REVIEWS: '/v3/e-reputation/hotel-reviews',
  SIMILAR_HOTELS: '/v3/shopping/hotel-offers/similar',
  PRICE_HISTORY: '/v3/analytics/hotel-offers/price-history'
};

// API Credentials and Configuration
export const AMADEUS_API = {
  BASE_URL: import.meta.env.VITE_AMADEUS_API_URL || 'https://api.amadeus.com',
  VERSION: 'v3',
  ENDPOINTS: API_ENDPOINTS,
  CREDENTIALS: {
    CLIENT_ID: import.meta.env.VITE_AMADEUS_CLIENT_ID,
    CLIENT_SECRET: import.meta.env.VITE_AMADEUS_CLIENT_SECRET
  }
};

// Cache Configuration
export const CACHE_CONFIG = {
  TOKEN_TTL: 1800000,      // 30 minutes
  SEARCH_TTL: 300000,      // 5 minutes
  DETAILS_TTL: 1800000,    // 30 minutes
  LOCATIONS_TTL: 86400000  // 24 hours
};

// Error Codes
export const API_ERROR_CODES = {
  AUTH: {
    TOKEN_EXPIRED: 'INVALID_TOKEN',
    INVALID_CREDENTIALS: 'INVALID_CLIENT',
    UNAUTHORIZED: 'UNAUTHORIZED'
  },
  REQUEST: {
    RATE_LIMIT: 'RATE_LIMIT_EXCEEDED',
    INVALID_PARAMS: 'INVALID_PARAMETERS',
    NOT_FOUND: 'RESOURCE_NOT_FOUND',
    SERVER_ERROR: 'SERVER_ERROR'
  },
  DATA: {
    NO_AVAILABILITY: 'NO_AVAILABILITY',
    INVALID_HOTEL: 'INVALID_HOTEL_ID',
    INVALID_OFFER: 'INVALID_OFFER_ID'
  }
};

// Error Messages
export const ERROR_MESSAGES = {
  [API_ERROR_CODES.AUTH.TOKEN_EXPIRED]: 'Session expired. Please try again.',
  [API_ERROR_CODES.AUTH.INVALID_CREDENTIALS]: 'Invalid API credentials.',
  [API_ERROR_CODES.AUTH.UNAUTHORIZED]: 'Unauthorized access.',
  [API_ERROR_CODES.REQUEST.RATE_LIMIT]: 'Too many requests. Please try again later.',
  [API_ERROR_CODES.REQUEST.INVALID_PARAMS]: 'Invalid search parameters.',
  [API_ERROR_CODES.REQUEST.NOT_FOUND]: 'Requested resource not found.',
  [API_ERROR_CODES.REQUEST.SERVER_ERROR]: 'Server error. Please try again later.',
  [API_ERROR_CODES.DATA.NO_AVAILABILITY]: 'No availability for selected dates.',
  [API_ERROR_CODES.DATA.INVALID_HOTEL]: 'Invalid hotel identifier.',
  [API_ERROR_CODES.DATA.INVALID_OFFER]: 'Invalid offer identifier.'
};

// Default Parameters
export const DEFAULT_PARAMS = {
  CURRENCY: 'EUR',
  ADULTS: 2,
  ROOMS: 1,
  RADIUS: 50,
  RADIUS_UNIT: 'KM',
  SORT_OPTIONS: ['PRICE', 'DISTANCE', 'RATING'],
  VIEW: 'FULL'
};

// Available Amenities for Filtering
export const AMENITIES = {
  WIFI: 'WIFI',
  POOL: 'SWIMMING_POOL',
  PARKING: 'PARKING',
  RESTAURANT: 'RESTAURANT',
  FITNESS: 'FITNESS_CENTER',
  BUSINESS: 'BUSINESS_CENTER',
  SPA: 'SPA',
  PETS: 'PETS_ALLOWED',
  AIRPORT_SHUTTLE: 'AIRPORT_SHUTTLE',
  ROOM_SERVICE: 'ROOM_SERVICE',
  BAR: 'BAR',
  KIDS_CLUB: 'KIDS_CLUB'
};

// API Limits
export const API_LIMITS = {
  MAX_HOTELS_PER_REQUEST: 100,
  MAX_PRICE_RANGE: 10000,
  MAX_RADIUS: 100
};

// HTTP Status Codes
export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500
};

// Response Types
export const RESPONSE_TYPES = {
  JSON: 'application/vnd.amadeus+json',
  XML: 'application/xml',
  PDF: 'application/pdf'
};

// Time Constants
export const TIME = {
  SECOND: 1000,
  MINUTE: 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000
};

// Default Headers
export const DEFAULT_HEADERS = {
  'Accept': RESPONSE_TYPES.JSON,
  'Content-Type': 'application/json'
};