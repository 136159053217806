// src/pages/legal/CookiePolicyPage.jsx
import { CookiePolicy } from '../../components/legal/CookiePolicy';

const CookiePolicyPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <CookiePolicy />
    </div>
  );
};

export default CookiePolicyPage;