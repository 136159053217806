// src/components/hotels/detail/OverviewTab.jsx
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { 
 Clock, 
 Star, 
 Building, 
 Users,
 Calendar,
 BadgeCheck,
 Languages,
 CircleDollarSign,
 Shield
} from 'lucide-react';
import { Card, CardContent } from '../../ui/card';
import { Skeleton } from '../../ui/skeleton';
import { Button } from '../../ui/button';

// Ленивая загрузка компонента графика цен
const PriceHistoryChart = React.lazy(() => import('../PriceHistoryChart'));

const InfoItem = ({ icon: Icon, title, value, description }) => (
 <div className="flex items-start gap-3 p-3 bg-gray-50 rounded-lg">
   <div className="mt-0.5">
     <div className="w-8 h-8 rounded-full bg-brand-blue/10 flex items-center justify-center">
       <Icon className="w-4 h-4 text-brand-blue" />
     </div>
   </div>
   <div>
     <div className="text-sm font-medium">{title}</div>
     <div className="text-sm text-gray-600">{value}</div>
     {description && (
       <div className="text-xs text-gray-500 mt-1">{description}</div>
     )}
   </div>
 </div>
);

const HighlightBadge = ({ icon: Icon, text }) => (
 <div className="flex items-center gap-2 px-3 py-1.5 bg-gray-50 rounded-full">
   <Icon className="w-4 h-4 text-brand-blue" />
   <span className="text-sm">{text}</span>
 </div>
);

const PriceHistorySkeleton = () => (
 <div className="space-y-4">
   <Skeleton className="h-6 w-32" />
   <Skeleton className="h-[200px] w-full" />
 </div>
);

const OverviewTab = ({ hotel }) => {
 const mainHighlights = [
   hotel.rating?.stars && {
     icon: Star,
     text: `${hotel.rating.stars} Star Hotel`
   },
   hotel.details?.yearBuilt && {
     icon: Building,
     text: `Built in ${hotel.details.yearBuilt}`
   },
   hotel.details?.lastRenovated && {
     icon: Calendar,
     text: `Renovated in ${hotel.details.lastRenovated}`
   },
   hotel.details?.rooms && {
     icon: Users,
     text: `${hotel.details.rooms} Rooms`
   }
 ].filter(Boolean);

 const getCheckInOutTime = (time) => {
   // Преобразуем время в 12-часовой формат
   try {
     const [hours, minutes] = time.split(':');
     const date = new Date();
     date.setHours(parseInt(hours), parseInt(minutes));
     return date.toLocaleTimeString('en-US', {
       hour: 'numeric',
       minute: 'numeric',
       hour12: true
     });
   } catch (e) {
     return time;
   }
 };

 return (
   <Card>
     <CardContent className="p-6 space-y-8">
       {/* Main Description */}
       <div>
         <h3 className="text-xl font-semibold mb-4">About {hotel.name}</h3>
         <p className="text-gray-600 whitespace-pre-line">
           {hotel.description}
         </p>
         
         {/* Highlights */}
         {mainHighlights.length > 0 && (
           <div className="flex flex-wrap gap-2 mt-4">
             {mainHighlights.map((highlight, index) => (
               <HighlightBadge
                 key={index}
                 icon={highlight.icon}
                 text={highlight.text}
               />
             ))}
           </div>
         )}
       </div>

       {/* Check-in/Check-out Info */}
       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
         <InfoItem
           icon={Clock}
           title="Check-in Time"
           value={getCheckInOutTime(hotel.details?.checkIn || '15:00')}
           description={hotel.details?.checkInNotes}
         />
         <InfoItem
           icon={Clock}
           title="Check-out Time"
           value={getCheckInOutTime(hotel.details?.checkOut || '12:00')}
           description={hotel.details?.checkOutNotes}
         />
       </div>

       {/* Additional Information */}
       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
         {/* Languages */}
         {hotel.details?.languages?.length > 0 && (
           <InfoItem
             icon={Languages}
             title="Languages Spoken"
             value={hotel.details.languages.join(', ')}
           />
         )}

         {/* Payment Methods */}
         {hotel.details?.paymentMethods?.length > 0 && (
           <InfoItem
             icon={CircleDollarSign}
             title="Accepted Payment Methods"
             value={hotel.details.paymentMethods.join(', ')}
           />
         )}

         {/* Certifications */}
         {hotel.details?.certifications?.length > 0 && (
           <InfoItem
             icon={BadgeCheck}
             title="Hotel Certifications"
             value={hotel.details.certifications.join(', ')}
           />
         )}

         {/* Safety Measures */}
         {hotel.details?.safetyMeasures?.length > 0 && (
           <InfoItem
             icon={Shield}
             title="Safety & Hygiene"
             value={hotel.details.safetyMeasures.join(', ')}
           />
         )}
       </div>

       {/* Price History Chart */}
       <div>
         <h4 className="font-medium mb-4">Price History</h4>
         <Suspense fallback={<PriceHistorySkeleton />}>
           <PriceHistoryChart
             data={hotel.priceHistory}
             currentPrice={hotel.price?.current?.amount}
           />
         </Suspense>
       </div>

       {/* Additional Policies or Notes */}
       {hotel.details?.additionalNotes && (
         <div className="bg-gray-50 p-4 rounded-lg">
           <h4 className="font-medium mb-2">Additional Information</h4>
           <p className="text-sm text-gray-600">
             {hotel.details.additionalNotes}
           </p>
         </div>
       )}

       {/* Hotel Policies Link */}
       <div className="flex justify-end">
         <Button 
           variant="outline"
           className="text-sm"
           onClick={() => document.getElementById('policies-section')?.scrollIntoView({ behavior: 'smooth' })}
         >
           View Full Hotel Policies
         </Button>
       </div>
     </CardContent>
   </Card>
 );
};

InfoItem.propTypes = {
 icon: PropTypes.elementType.isRequired,
 title: PropTypes.string.isRequired,
 value: PropTypes.string.isRequired,
 description: PropTypes.string
};

HighlightBadge.propTypes = {
 icon: PropTypes.elementType.isRequired,
 text: PropTypes.string.isRequired
};

OverviewTab.propTypes = {
 hotel: PropTypes.shape({
   name: PropTypes.string.isRequired,
   description: PropTypes.string,
   rating: PropTypes.shape({
     stars: PropTypes.number
   }),
   details: PropTypes.shape({
     yearBuilt: PropTypes.string,
     lastRenovated: PropTypes.string,
     rooms: PropTypes.string,
     checkIn: PropTypes.string,
     checkOut: PropTypes.string,
     checkInNotes: PropTypes.string,
     checkOutNotes: PropTypes.string,
     languages: PropTypes.arrayOf(PropTypes.string),
     paymentMethods: PropTypes.arrayOf(PropTypes.string),
     certifications: PropTypes.arrayOf(PropTypes.string),
     safetyMeasures: PropTypes.arrayOf(PropTypes.string),
     additionalNotes: PropTypes.string
   }),
   price: PropTypes.shape({
     current: PropTypes.shape({
       amount: PropTypes.number
     })
   }),
   priceHistory: PropTypes.array
 }).isRequired
};

export default OverviewTab;