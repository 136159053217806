// src/components/booking/BookingConfirmation.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  CheckCircle,
  Calendar,
  Users,
  Building2,
  Mail,
  Download,
  Clock,
  CreditCard,
  Phone,
  MapPin
} from 'lucide-react';
import { Button } from '../ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Separator } from '../ui/separator';
import { ROUTES } from '../../config/paths';
import { Booking } from '../../types/booking';

interface BookingConfirmationProps {
  booking: Booking;
  onDownload?: () => Promise<void>;
  onPrint?: () => void;
  className?: string;
}

export const BookingConfirmation: React.FC<BookingConfirmationProps> = ({
  booking,
  onDownload,
  onPrint,
  className = ''
}) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'PPP');
  };

  const calculateNights = () => {
    const checkIn = new Date(booking.checkIn);
    const checkOut = new Date(booking.checkOut);
    return Math.ceil((checkOut.getTime() - checkIn.getTime()) / (1000 * 60 * 60 * 24));
  };

  const nights = calculateNights();

  return (
    <div className={`max-w-3xl mx-auto space-y-6 ${className}`}>
      {/* Success Message */}
      <Card>
        <CardContent className="p-6">
          <div className="flex flex-col items-center text-center space-y-4">
            <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
              <CheckCircle className="w-6 h-6 text-green-600" />
            </div>
            <div>
              <h2 className="text-xl font-semibold">Booking Confirmed</h2>
              <p className="text-gray-600">
                Your booking reference: <span className="font-medium">{booking.bookingReference}</span>
              </p>
            </div>
            {booking.userDetails?.email && (
              <p className="text-sm text-gray-500">
                A confirmation email has been sent to {booking.userDetails.email}
              </p>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Hotel Details */}
      <Card>
        <CardHeader>
          <CardTitle>Hotel Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex items-start gap-3">
            <Building2 className="w-5 h-5 text-gray-400 mt-1" />
            <div>
              <div className="font-medium">{booking.hotelDetails.name}</div>
              <div className="flex items-center gap-1 text-sm text-gray-600">
                <MapPin className="w-4 h-4" />
                {booking.hotelDetails.address}
              </div>
            </div>
          </div>

          {booking.hotelDetails.phone && (
            <div className="flex items-center gap-2">
              <Phone className="w-4 h-4 text-gray-400" />
              <a 
                href={`tel:${booking.hotelDetails.phone}`}
                className="text-sm text-brand-blue hover:underline"
              >
                {booking.hotelDetails.phone}
              </a>
            </div>
          )}

          <div className="flex items-start gap-3">
            <Clock className="w-5 h-5 text-gray-400 mt-1" />
            <div>
              <div className="font-medium">Check-in/Check-out</div>
              <div className="text-sm text-gray-600">
                Check-in: {booking.hotelDetails.checkIn || '15:00'}
                <br />
                Check-out: {booking.hotelDetails.checkOut || '12:00'}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Booking Details */}
      <Card>
        <CardHeader>
          <CardTitle>Booking Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {/* Dates */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-start gap-3">
              <Calendar className="w-5 h-5 text-gray-400 mt-1" />
              <div>
                <div className="font-medium">Check-in</div>
                <div className="text-sm text-gray-600">
                  {formatDate(booking.checkIn)}
                </div>
              </div>
            </div>

            <div className="flex items-start gap-3">
              <Calendar className="w-5 h-5 text-gray-400 mt-1" />
              <div>
                <div className="font-medium">Check-out</div>
                <div className="text-sm text-gray-600">
                  {formatDate(booking.checkOut)}
                </div>
              </div>
            </div>
          </div>

          {/* Guests */}
          <div className="flex items-start gap-3">
            <Users className="w-5 h-5 text-gray-400 mt-1" />
            <div>
              <div className="font-medium">Guests</div>
              <div className="text-sm text-gray-600">
                {booking.guests.adults} Adults
                {booking.guests.children > 0 && `, ${booking.guests.children} Children`}
              </div>
            </div>
          </div>

          {/* Room Details */}
          <div className="flex items-start gap-3">
            <Building2 className="w-5 h-5 text-gray-400 mt-1" />
            <div>
              <div className="font-medium">{booking.roomDetails.name}</div>
              <div className="text-sm text-gray-600">
                {booking.roomDetails.description}
              </div>
            </div>
          </div>

          {/* Price Breakdown */}
          <div>
            <Separator className="my-4" />
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span>Room rate × {nights} nights</span>
                <span>${booking.price.base}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Taxes & fees</span>
                <span>${booking.price.tax}</span>
              </div>
              <Separator className="my-2" />
              <div className="flex justify-between font-medium text-lg">
                <span>Total</span>
                <span className="text-brand-blue">${booking.price.total}</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Actions */}
      <div className="flex flex-col sm:flex-row gap-4">
        <Button 
          variant="outline" 
          className="flex-1"
          onClick={() => navigate(ROUTES.BOOKING_HISTORY)}
        >
          <Mail className="w-4 h-4 mr-2" />
          View All Bookings
        </Button>

        {onPrint && (
          <Button
            variant="outline"
            className="flex-1"
            onClick={onPrint}
          >
            <Download className="w-4 h-4 mr-2" />
            Print Confirmation
          </Button>
        )}

        {onDownload && (
          <Button
            variant="outline"
            className="flex-1"
            onClick={onDownload}
          >
            <CreditCard className="w-4 h-4 mr-2" />
            Download Invoice
          </Button>
        )}
      </div>

      {/* Cancellation Policy */}
      <Card>
        <CardContent className="p-4">
          <div className="text-sm text-gray-600 space-y-2">
            <p className="font-medium">Cancellation Policy</p>
            <p>
              Free cancellation until 24 hours before check-in. After this date,
              cancellation will incur a fee equivalent to the first night's stay.
            </p>
            <p className="text-xs text-gray-500">
              Please note that this is a general policy. Specific terms may apply to your booking.
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BookingConfirmation;