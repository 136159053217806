// src/App.jsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ROUTES } from './config/paths';
import { ToastContainer } from 'react-toastify';

// Layout Components
import Navigation from './components/layout/Navigation';
import Footer from './components/layout/Footer';
import ComingSoon from './components/shared/ComingSoon';
import NotFound from './components/shared/NotFound';
import PrivateRoute from './components/auth/PrivateRoute';
import PrivateAdminRoute from './components/auth/PrivateAdminRoute';
import BetaConsent from './components/shared/BetaConsent';

// Core Pages
import Homepage from './pages/Homepage';
import PersonalizationPage from './pages/PersonalizationPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ProfilePage from './pages/profile/ProfilePage';
import SearchResultsPage from './pages/search/SearchResultsPage';
import HotelDetailPage from './pages/hotel/HotelDetailPage';
import ContactPage from './pages/contact/ContactPage';

// Booking Pages
import BookingPage from './pages/booking/BookingPage';
import BookingConfirmationPage from './pages/booking/BookingConfirmationPage';
import BookingHistoryPage from './pages/booking/BookingHistoryPage';

// Admin Pages
import AdminContactPanel from './pages/admin/contact/AdminContactPanel';
import AdminMessageList from './pages/admin/contact/MessageList';
import AdminMessageDetails from './pages/admin/contact/MessageDetails';
import AdminSpamSettings from './pages/admin/contact/SpamSettings';
import AdminSetup from './components/admin/AdminSetup';
import AdminBookingsPage from './pages/admin/bookings/AdminBookingsPage';
import AdminBookingDetailsPage from './pages/admin/bookings/AdminBookingDetailsPage';

// Legal Pages
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import TermsOfServicePage from './pages/legal/TermsOfServicePage';
import CookiePolicyPage from './pages/legal/CookiePolicyPage';
import DisclaimerPage from './pages/legal/DisclaimerPage';
import AffiliateDisclosurePage from './pages/legal/AffiliateDisclosurePage';
import CommentPolicyPage from './pages/legal/CommentPolicyPage';
import RefundPolicyPage from './pages/legal/RefundPolicyPage';
import BetaTermsPage from './pages/legal/BetaTermsPage';

// Information Pages
import ForTravelersPage from './pages/information/ForTravelersPage';
import ForHotelsPage from './pages/information/ForHotelsPage';
import ForBloggersPage from './pages/information/ForBloggersPage';
import ForInvestorsPage from './pages/information/ForInvestorsPage';

// Support Pages
import HelpCenterPage from './pages/support/HelpCenterPage';

// Development Components
import TestFirebase from './components/TestFirebase';
import TestAmadeusApi from './components/TestAmadeusApi';

// Styles
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'head',
      }}
    >
      <AuthProvider>
        <BrowserRouter>
          <div className="flex flex-col min-h-screen">
            <Navigation />

            <main className="flex-grow bg-gray-50">
              <Routes>
                {/* Public Routes */}
                <Route path={ROUTES.HOME} element={<Homepage />} />
                <Route path={ROUTES.LOGIN} element={<LoginPage />} />
                <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
                <Route path={ROUTES.SEARCH} element={<SearchResultsPage />} />
                <Route path={ROUTES.HOTEL_DETAIL} element={<HotelDetailPage />} />
                <Route path={ROUTES.CONTACT} element={<ContactPage />} />

                {/* Booking Routes */}
                <Route
                  path={ROUTES.BOOKING}
                  element={
                    <PrivateRoute>
                      <BookingPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.BOOKING_CONFIRMATION}
                  element={
                    <PrivateRoute>
                      <BookingConfirmationPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.BOOKING_HISTORY}
                  element={
                    <PrivateRoute>
                      <BookingHistoryPage />
                    </PrivateRoute>
                  }
                />

                {/* Admin Booking Routes */}
                <Route
                  path={ROUTES.ADMIN_BOOKINGS}
                  element={
                    <PrivateAdminRoute>
                      <AdminBookingsPage />
                    </PrivateAdminRoute>
                  }
                />
                <Route
                  path={ROUTES.ADMIN_BOOKING_DETAILS}
                  element={
                    <PrivateAdminRoute>
                      <AdminBookingDetailsPage />
                    </PrivateAdminRoute>
                  }
                />

                {/* Protected Routes */}
                <Route
                  path={ROUTES.PROFILE}
                  element={
                    <PrivateRoute>
                      <ProfilePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.RATING}
                  element={
                    <PrivateRoute>
                      <PersonalizationPage />
                    </PrivateRoute>
                  }
                />

                {/* Admin Routes */}
                <Route
                  path={`${ROUTES.ADMIN_CONTACT}/*`}
                  element={
                    <PrivateAdminRoute>
                      <AdminContactPanel />
                    </PrivateAdminRoute>
                  }
                />
                <Route
                  path={ROUTES.ADMIN_MESSAGES}
                  element={
                    <PrivateAdminRoute>
                      <AdminMessageList />
                    </PrivateAdminRoute>
                  }
                />
                <Route
                  path={ROUTES.ADMIN_MESSAGE_DETAILS}
                  element={
                    <PrivateAdminRoute>
                      <AdminMessageDetails />
                    </PrivateAdminRoute>
                  }
                />
                <Route
                  path={ROUTES.ADMIN_SPAM_SETTINGS}
                  element={
                    <PrivateAdminRoute>
                      <AdminSpamSettings />
                    </PrivateAdminRoute>
                  }
                />

                {/* Feature Preview Routes */}
                <Route
                  path={ROUTES.REVIEWS}
                  element={
                    <PrivateRoute>
                      <ComingSoon title="Video Reviews" />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.MAP}
                  element={
                    <PrivateRoute>
                      <ComingSoon title="Interactive Map" />
                    </PrivateRoute>
                  }
                />

                {/* Legal Pages */}
                <Route path={ROUTES.PRIVACY} element={<PrivacyPolicyPage />} />
                <Route path={ROUTES.TERMS} element={<TermsOfServicePage />} />
                <Route path={ROUTES.COOKIES} element={<CookiePolicyPage />} />
                <Route path={ROUTES.DISCLAIMER} element={<DisclaimerPage />} />
                <Route path={ROUTES.AFFILIATE_DISCLOSURE} element={<AffiliateDisclosurePage />} />
                <Route path={ROUTES.COMMENT_POLICY} element={<CommentPolicyPage />} />
                <Route path={ROUTES.REFUND_POLICY} element={<RefundPolicyPage />} />
                <Route path={ROUTES.BETA_TERMS} element={<BetaTermsPage />} />

                {/* Information Pages */}
                <Route path={ROUTES.FOR_TRAVELERS} element={<ForTravelersPage />} />
                <Route path={ROUTES.FOR_HOTELS} element={<ForHotelsPage />} />
                <Route path={ROUTES.FOR_BLOGGERS} element={<ForBloggersPage />} />
                <Route path={ROUTES.FOR_INVESTORS} element={<ForInvestorsPage />} />

                {/* Support Pages */}
                <Route path={ROUTES.HELP} element={<HelpCenterPage />} />

                {/* Admin Setup */}
                <Route path="/admin/setup" element={<AdminSetup />} />

                {/* Development Routes */}
                {import.meta.env.DEV && (
                  <>
                    <Route path={ROUTES.TEST_FIREBASE} element={<TestFirebase />} />
                    <Route path={ROUTES.TEST_AMADEUS} element={<TestAmadeusApi />} />
                  </>
                )}

                {/* 404 Page */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>

            <Footer />
          </div>

          <BetaConsent />
          
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BrowserRouter>
      </AuthProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;