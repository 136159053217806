// src/components/search/SearchForm.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { MapPin, Calendar, Users, Search, Loader2 } from 'lucide-react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../ui/popover';
import { Calendar as CalendarComponent } from '../ui/calendar';
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "../ui/command";
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '../../hooks/useToast';

const DESTINATIONS = [
  { code: 'PAR', name: 'Paris, France', type: 'Popular' },
  { code: 'LON', name: 'London, United Kingdom', type: 'Popular' },
  { code: 'NYC', name: 'New York, USA', type: 'Popular' },
  { code: 'TYO', name: 'Tokyo, Japan', type: 'Popular' },
  { code: 'ROM', name: 'Rome, Italy', type: 'Popular' },
  { code: 'BCN', name: 'Barcelona, Spain', type: 'Popular' },
  { code: 'BER', name: 'Berlin, Germany', type: 'Popular' },
  { code: 'AMS', name: 'Amsterdam, Netherlands', type: 'Europe' },
  { code: 'SIN', name: 'Singapore', type: 'Asia' },
  { code: 'HKG', name: 'Hong Kong', type: 'Asia' },
  { code: 'DXB', name: 'Dubai, UAE', type: 'Middle East' },
  { code: 'SYD', name: 'Sydney, Australia', type: 'Australia' },
  { code: 'LAX', name: 'Los Angeles, USA', type: 'Americas' },
  { code: 'MIA', name: 'Miami, USA', type: 'Americas' },
  { code: 'MAD', name: 'Madrid, Spain', type: 'Europe' },
  { code: 'MIL', name: 'Milan, Italy', type: 'Europe' },
  { code: 'BKK', name: 'Bangkok, Thailand', type: 'Asia' },
  { code: 'IST', name: 'Istanbul, Turkey', type: 'Europe' },
  { code: 'SEL', name: 'Seoul, South Korea', type: 'Asia' },
  { code: 'CPH', name: 'Copenhagen, Denmark', type: 'Europe' }
];

const SearchForm = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();

  const [destinationInput, setDestinationInput] = useState('');
  const [filteredDestinations, setFilteredDestinations] = useState(
    DESTINATIONS.filter(dest => dest.type === 'Popular')
  );
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [isDestinationOpen, setIsDestinationOpen] = useState(false);

  const [formData, setFormData] = useState({
    destination: searchParams.get('destination') || '',
    checkIn: searchParams.get('checkIn') 
      ? new Date(searchParams.get('checkIn')) 
      : new Date(),
    checkOut: searchParams.get('checkOut') 
      ? new Date(searchParams.get('checkOut')) 
      : new Date(Date.now() + 24 * 60 * 60 * 1000),
    guests: parseInt(searchParams.get('guests')) || 2
  });

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const groupedDestinations = React.useMemo(() => {
    const grouped = filteredDestinations.reduce((acc, dest) => {
      if (!acc[dest.type]) {
        acc[dest.type] = [];
      }
      acc[dest.type].push(dest);
      return acc;
    }, {});

    const ordered = {};
    if (grouped['Popular']) {
      ordered['Popular'] = grouped['Popular'];
    }
    Object.keys(grouped)
      .filter(key => key !== 'Popular')
      .sort()
      .forEach(key => {
        ordered[key] = grouped[key];
      });

    return ordered;
  }, [filteredDestinations]);

  useEffect(() => {
    if (!destinationInput.trim()) {
      setFilteredDestinations(DESTINATIONS.filter(dest => dest.type === 'Popular'));
      return;
    }

    const searchTerm = destinationInput.toLowerCase();
    const filtered = DESTINATIONS.filter(dest =>
      dest.name.toLowerCase().includes(searchTerm) ||
      dest.code.toLowerCase().includes(searchTerm)
    );

    setFilteredDestinations(filtered);
  }, [destinationInput]);

  const handleDestinationSelect = useCallback((destination) => {
    console.log('Handling selection:', destination);
    if (!destination) return;
    
    setSelectedDestination(destination);
    setFormData(prev => ({
      ...prev,
      destination: destination.name
    }));
    setDestinationInput(destination.name);
    setIsDestinationOpen(false);
  }, []);

  const handleDateSelect = (range) => {
    if (range?.from) {
      const checkOut = range.to || new Date(range.from.getTime() + 24 * 60 * 60 * 1000);
      setFormData(prev => ({
        ...prev,
        checkIn: range.from,
        checkOut: checkOut
      }));

      if (range.to) {
        setCalendarOpen(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (formData.checkOut <= formData.checkIn) {
        throw new Error('Check-out date must be after check-in date');
      }

      if (!formData.destination) {
        throw new Error('Please select a destination');
      }

      const queryParams = new URLSearchParams({
        destination: formData.destination,
        destinationCode: selectedDestination?.code || '',
        checkIn: formData.checkIn.toISOString(),
        checkOut: formData.checkOut.toISOString(),
        guests: formData.guests.toString(),
        ...(currentUser?.preferences && {
          preferences: JSON.stringify(currentUser.preferences)
        })
      });

      navigate(`/search?${queryParams.toString()}`);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-4xl mx-auto homepage-search-form">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 p-4 bg-card rounded-xl shadow-lg border">
        {/* Destination Input */}
        <div className="space-y-2">
          <Label htmlFor="destination">Destination</Label>
          <Popover open={isDestinationOpen} onOpenChange={setIsDestinationOpen}>
            <PopoverTrigger asChild>
              <div className="relative">
                <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground w-4 h-4" />
                <Input
                  id="destination"
                  value={formData.destination}
                  onChange={(e) => {
                    const value = e.target.value;
                    setDestinationInput(value);
                    setFormData(prev => ({
                      ...prev,
                      destination: value
                    }));
                  }}
                  onClick={() => setIsDestinationOpen(true)}
                  placeholder="Where are you going?"
                  className="pl-10"
                  required
                />
              </div>
            </PopoverTrigger>
            <PopoverContent 
              className="p-0 w-[var(--radix-popover-trigger-width)] min-w-[240px]"
              align="start"
              sideOffset={8}
            >
              <Command>
                <CommandInput 
                  placeholder="Search destinations..." 
                  value={destinationInput}
                  onValueChange={setDestinationInput}
                />
                <CommandList className="command-list">
                  <CommandEmpty>
                    {isLoadingSuggestions ? (
                      <div className="flex items-center justify-center py-6">
                        <Loader2 className="w-4 h-4 animate-spin" />
                      </div>
                    ) : (
                      <p className="py-6 text-center text-sm text-muted-foreground">
                        No destinations found
                      </p>
                    )}
                  </CommandEmpty>
                  {Object.entries(groupedDestinations).map(([group, destinations]) => (
                    <CommandGroup key={group} heading={group}>
                      {destinations.map((destination) => (
                        <CommandItem
                          key={destination.code}
                          value={destination.name}
                          className="flex items-center gap-2 w-full p-2 cursor-pointer hover:bg-accent hover:text-accent-foreground"
                          onSelect={() => {
                            console.log('Click detected on:', destination.name);
                            handleDestinationSelect(destination);
                          }}
                          onClick={() => {
                            console.log('Click fallback on:', destination.name);
                            handleDestinationSelect(destination);
                          }}
                        >
                          <MapPin className="w-4 h-4 text-muted-foreground flex-shrink-0" />
                          <span className="flex-1 truncate">{destination.name}</span>
                          <span className="text-xs text-muted-foreground flex-shrink-0">
                            {destination.code}
                          </span>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  ))}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        {/* Dates Input */}
        <div className="space-y-2 md:col-span-2">
          <Label>Dates</Label>
          <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className="w-full justify-start text-left font-normal"
                type="button"
              >
                <Calendar className="mr-2 h-4 w-4" />
                {format(formData.checkIn, 'PPP')} - {format(formData.checkOut, 'PPP')}
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              className="w-auto p-0 calendar-container"
              align="start"
              sideOffset={8}
            >
              <CalendarComponent
                initialFocus
                mode="range"
                defaultMonth={formData.checkIn}
                selected={{
                  from: formData.checkIn,
                  to: formData.checkOut
                }}
                onSelect={handleDateSelect}
                numberOfMonths={2}
                disabled={(date) => date < new Date()}
              />
            </PopoverContent>
          </Popover>
        </div>

        {/* Guests Input */}
        <div className="space-y-2">
          <Label htmlFor="guests">Guests</Label>
          <div className="relative">
            <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground w-4 h-4" />
            <Input
              id="guests"
              type="number"
              min="1"
              max="10"
              className="pl-10 input-number-controls"
              value={formData.guests}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                guests: Math.max(1, Math.min(10, parseInt(e.target.value) || 1))
              }))}
            />
          </div>
        </div>

        {/* Search Button */}
        <div className="md:col-span-4">
          <Button 
            className="w-full h-11 text-base bg-brand-blue"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Searching...
              </>
            ) : (
              <>
                <Search className="mr-2 h-4 w-4" />
                Search Hotels
              </>
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SearchForm;