// src/pages/information/ForHotelsPage.jsx
import React from 'react';
import ForHotels from '../../components/information/ForHotels';

const ForHotelsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <ForHotels />
    </div>
  );
};

export default ForHotelsPage;