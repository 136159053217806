// src/pages/admin/bookings/AdminBookingsPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { 
  Search,
  Filter,
  Download,
  RefreshCcw,
  Calendar,
  Users,
  FileText,
  ArrowUpDown,
  MoreVertical,
  ClipboardList,
  Loader2
} from 'lucide-react';
import { useToast } from '../../../hooks/useToast';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import { Card, CardContent } from '../../../components/ui/card';
import { Separator } from '../../../components/ui/separator';
import BookingStatus from '../../../components/booking/BookingStatus';
import BookingService from '../../../services/booking/BookingService';
import { ROUTES } from '../../../config/paths';
import { Booking, BookingSearchParams } from '../../../types/booking';
import { BOOKING_STATUS } from '../../../components/booking/constants';

const SORT_OPTIONS = {
  DATE_DESC: 'date_desc',
  DATE_ASC: 'date_asc',
  PRICE_DESC: 'price_desc',
  PRICE_ASC: 'price_asc'
} as const;

export const AdminBookingsPage = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  // State
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [exporting, setExporting] = useState(false);

  // Filters
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<keyof typeof BOOKING_STATUS | 'all'>('all');
  const [sortBy, setSortBy] = useState<keyof typeof SORT_OPTIONS>('DATE_DESC');

  useEffect(() => {
    loadBookings();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [bookings, searchQuery, statusFilter, sortBy]);

  const loadBookings = async () => {
    try {
      setLoading(true);
      setError(null);

      const searchParams: BookingSearchParams = {
        sortBy: 'createdAt',
        sortOrder: 'desc'
      };

      const allBookings = await BookingService.getAllBookings(searchParams);
      setBookings(allBookings);

    } catch (error) {
      console.error('Error loading bookings:', error);
      setError(error instanceof Error ? error.message : 'Failed to load bookings');
      toast({
        title: "Error",
        description: "Failed to load bookings",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleExportData = async () => {
    try {
      setExporting(true);
      await BookingService.exportBookings({
        format: 'csv',
        filters: {
          status: statusFilter === 'all' ? undefined : statusFilter
        }
      });
      
      toast({
        title: "Success",
        description: "Bookings exported successfully"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to export bookings",
        variant: "destructive"
      });
    } finally {
      setExporting(false);
    }
  };

  const applyFilters = () => {
    let filtered = [...bookings];

    // Search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(booking => 
        booking.hotelDetails.name.toLowerCase().includes(query) ||
        booking.bookingReference.toLowerCase().includes(query) ||
        booking.userDetails?.email?.toLowerCase().includes(query)
      );
    }

    // Status filter
    if (statusFilter !== 'all') {
      filtered = filtered.filter(booking => booking.status === statusFilter);
    }

    // Sort
    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'DATE_ASC':
          return new Date(a.checkIn).getTime() - new Date(b.checkIn).getTime();
        case 'DATE_DESC':
          return new Date(b.checkIn).getTime() - new Date(a.checkIn).getTime();
        case 'PRICE_ASC':
          return a.price.total - b.price.total;
        case 'PRICE_DESC':
          return b.price.total - a.price.total;
        default:
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }
    });

    setFilteredBookings(filtered);
  };

  const BookingRow: React.FC<{ booking: Booking }> = ({ booking }) => {
    const nights = Math.ceil(
      (new Date(booking.checkOut).getTime() - new Date(booking.checkIn).getTime()) / 
      (1000 * 60 * 60 * 24)
    );

    return (
      <div className="p-4 hover:bg-gray-50 border-b last:border-b-0">
        <div className="flex justify-between items-start gap-4">
          <div className="flex-1 min-w-0">
            {/* Main Info */}
            <div className="flex items-center gap-2 mb-2">
              <h3 className="font-medium truncate">
                {booking.hotelDetails.name}
              </h3>
              <span className="text-sm text-gray-500">
                #{booking.bookingReference}
              </span>
            </div>

            {/* Dates & Guests */}
            <div className="flex flex-wrap gap-4 text-sm text-gray-600">
              <div className="flex items-center gap-1">
                <Calendar className="w-4 h-4" />
                <span>
                  {format(new Date(booking.checkIn), 'MMM d')} - {format(new Date(booking.checkOut), 'MMM d, yyyy')}
                  {' '}({nights} {nights === 1 ? 'night' : 'nights'})
                </span>
              </div>
              <div className="flex items-center gap-1">
                <Users className="w-4 h-4" />
                <span>
                  {booking.guests.adults} Adults
                  {booking.guests.children > 0 && `, ${booking.guests.children} Children`}
                </span>
              </div>
            </div>

            {/* Contact */}
            <div className="mt-2 text-sm text-gray-600">
              {booking.userDetails?.email}
            </div>
          </div>

          {/* Status & Price */}
          <div className="flex flex-col items-end gap-2">
            <BookingStatus 
              status={booking.status}
              className="min-w-[120px]"
              showTooltip={false}
            />
            <div className="font-medium text-brand-blue">
              ${booking.price.total}
            </div>
          </div>

          {/* Actions */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="sm">
                <MoreVertical className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => 
                navigate(ROUTES.getAdminBookingDetailsPath(booking.id))
              }>
                <FileText className="w-4 h-4 mr-2" />
                View Details
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleExportData([booking])}>
                <Download className="w-4 h-4 mr-2" />
                Export
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    );
  };

  if (loading && !bookings.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loader2 className="w-8 h-8 animate-spin text-brand-blue" />
      </div>
    );
  }

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-start mb-6">
        <div>
          <h1 className="text-2xl font-bold">Bookings</h1>
          <p className="text-gray-600 mt-1">
            {filteredBookings.length} bookings found
          </p>
        </div>
        <div className="flex gap-3">
          <Button
            variant="outline"
            onClick={handleExportData}
            disabled={exporting || !filteredBookings.length}
          >
            <Download className="w-4 h-4 mr-2" />
            {exporting ? 'Exporting...' : 'Export'}
          </Button>
          <Button
            variant="outline"
            onClick={loadBookings}
            disabled={loading}
          >
            <RefreshCcw className={`w-4 h-4 mr-2 ${loading ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        </div>
      </div>

      {/* Filters */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <Input
          placeholder="Search bookings..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="md:col-span-2"
        />

        <Select 
          value={statusFilter} 
          onValueChange={(value) => setStatusFilter(value as keyof typeof BOOKING_STATUS | 'all')}
        >
          <SelectTrigger>
            <Filter className="w-4 h-4 mr-2" />
            <SelectValue placeholder="Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Statuses</SelectItem>
            <SelectItem value="pending">Pending</SelectItem>
            <SelectItem value="confirmed">Confirmed</SelectItem>
            <SelectItem value="cancelled">Cancelled</SelectItem>
            <SelectItem value="completed">Completed</SelectItem>
          </SelectContent>
        </Select>

        <Select value={sortBy} onValueChange={(value) => setSortBy(value as keyof typeof SORT_OPTIONS)}>
          <SelectTrigger>
            <ArrowUpDown className="w-4 h-4 mr-2" />
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="DATE_DESC">Newest First</SelectItem>
            <SelectItem value="DATE_ASC">Oldest First</SelectItem>
            <SelectItem value="PRICE_DESC">Price High to Low</SelectItem>
            <SelectItem value="PRICE_ASC">Price Low to High</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* Bookings List */}
      <Card>
        {error ? (
          <CardContent className="p-6">
            <div className="text-center">
              <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
              <h3 className="text-lg font-medium mb-2">{error}</h3>
              <Button onClick={loadBookings} variant="outline">
                Try Again
              </Button>
            </div>
          </CardContent>
        ) : filteredBookings.length === 0 ? (
          <CardContent className="p-6">
            <div className="text-center">
              <ClipboardList className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium mb-2">No bookings found</h3>
              <p className="text-gray-500">
                {searchQuery || statusFilter !== 'all'
                  ? 'Try adjusting your filters'
                  : 'No bookings have been made yet'}
              </p>
            </div>
          </CardContent>
        ) : (
          <div className="divide-y divide-gray-200">
            {filteredBookings.map((booking) => (
              <BookingRow key={booking.id} booking={booking} />
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

export default AdminBookingsPage;