// src/components/admin/contact/AdminContactPanel.jsx

import React, { useState } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { BarChart, MessageSquare, Settings } from 'lucide-react';
import MessageList from './MessageList';
import MessageStats from './MessageStats';
import SpamSettings from './SpamSettings';
import MessageDetails from './MessageDetails';

const AdminContactPanel = () => {
 const location = useLocation();

 // Функция для проверки активности ссылки
 const isLinkActive = (path) => {
   return location.pathname === path;
 };

 // Компонент навигационной кнопки
 const NavLink = ({ to, icon: Icon, children }) => {
   const active = isLinkActive(to);
   return (
     <Link
       to={to}
       className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg ${
         active 
           ? 'bg-brand-blue text-white' 
           : 'text-gray-600 hover:bg-gray-100'
       }`}
     >
       <Icon className="w-5 h-5 mr-2" />
       {children}
     </Link>
   );
 };

 return (
   <div className="min-h-screen bg-gray-50">
     {/* Header */}
     <header className="bg-white border-b sticky top-0 z-30">
       <div className="max-w-7xl mx-auto px-4">
         <div className="flex items-center justify-between h-16">
           <h1 className="text-xl font-semibold text-gray-900">
             Управление сообщениями
           </h1>
         </div>
       </div>
     </header>

     {/* Main Content */}
     <main className="max-w-7xl mx-auto px-4">
       {/* Navigation Tabs */}
       <div className="flex space-x-4 py-4 mb-6">
         <NavLink to="/admin/contact" icon={BarChart}>
           Статистика
         </NavLink>
         <NavLink to="/admin/contact/messages" icon={MessageSquare}>
           Сообщения
         </NavLink>
         <NavLink to="/admin/contact/spam-settings" icon={Settings}>
           Настройки спам-фильтра
         </NavLink>
       </div>

       {/* Content Area */}
       <div className="space-y-6 pb-6">
         <Routes>
           <Route index element={<MessageStats />} />
           <Route path="messages" element={<MessageList />} />
           <Route path="messages/:id" element={<MessageDetails />} />
           <Route path="spam-settings" element={<SpamSettings />} />
         </Routes>
       </div>
     </main>

     {/* Footer */}
     <footer className="bg-white border-t mt-auto">
       <div className="max-w-7xl mx-auto px-4 py-4">
         <div className="text-center text-sm text-gray-500">
           © {new Date().getFullYear()} HotelsRating. Панель администратора.
         </div>
       </div>
     </footer>
   </div>
 );
};

export default AdminContactPanel;