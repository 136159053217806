// src/pages/information/ForBloggersPage.jsx
import React from 'react';
import ForBloggers from '../../components/information/ForBloggers';

const ForBloggersPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <ForBloggers />
    </div>
  );
};

export default ForBloggersPage;