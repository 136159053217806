import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { PRESET_CATEGORIES } from '../config/TravelPresets';

const PresetBanners = () => {
  const navigate = useNavigate();

  const handlePresetClick = (presetId) => {
    navigate(`/rating?preset=${presetId}`);
  };

  return (
    <div className="py-16 bg-gray-50">
      {Object.entries(PRESET_CATEGORIES).map(([categoryKey, category]) => (
        <div key={categoryKey} className="max-w-7xl mx-auto px-4 mb-16 last:mb-0">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">
            {category.title}
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {category.presets.map((preset) => {
              const Icon = preset.icon;
              return (
                <div
                  key={preset.id}
                  onClick={() => handlePresetClick(preset.id)}
                  className="group cursor-pointer relative overflow-hidden rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300"
                >
                  {/* Image Section */}
                  <div className="relative h-48 overflow-hidden bg-gray-200 animate-pulse">
                    <div className={`absolute inset-0 ${preset.color} opacity-10 group-hover:opacity-20 transition-opacity`} />
                    <img
                      src={preset.image}
                      alt={preset.title}
                      loading="lazy"
                      className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                      onLoad={(e) => {
                        e.target.parentElement.classList.remove('animate-pulse', 'bg-gray-200');
                      }}
                      onError={(e) => {
                        e.target.parentElement.classList.remove('animate-pulse', 'bg-gray-200');
                        e.target.src = "/api/placeholder/800/400";
                      }}
                    />
                    {/* Градиентный оверлей */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/30 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>

                  {/* Content Section */}
                  <div className="p-6 relative z-10">
                    <div className="flex items-center gap-3 mb-2">
                      <div className={`w-8 h-8 rounded-full ${preset.color} bg-opacity-10 flex items-center justify-center`}>
                        <Icon className={`w-4 h-4 ${preset.color.replace('bg-', 'text-')}`} />
                      </div>
                      <h3 className="text-xl font-bold text-gray-900 group-hover:text-brand-blue transition-colors">
                        {preset.title}
                      </h3>
                    </div>
                    <p className="text-gray-600 mb-4 line-clamp-2">
                      {preset.description}
                    </p>
                    
                    {/* Call to Action */}
                    <div className="flex items-center text-brand-blue group-hover:text-brand-blue/80 transition-colors">
                      <span className="font-medium">Customize preferences</span>
                      <ChevronRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                    </div>
                  </div>

                  {/* Hover Overlay */}
                  <div className="absolute inset-0 pointer-events-none bg-gradient-to-b from-transparent to-white/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                </div>
              );
            })}
          </div>
        </div>
      ))}

      {/* Custom Configuration CTA */}
      <div className="max-w-7xl mx-auto px-4 mt-12 text-center">
        <button
          onClick={() => navigate('/rating')}
          className="inline-flex items-center gap-2 bg-brand-blue text-white px-8 py-4 rounded-lg hover:bg-brand-blue/90 transition-colors text-lg font-semibold shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-300"
        >
          Create Custom Configuration
          <ChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

export default PresetBanners;