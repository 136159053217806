// src/services/api/amadeus/cache.js

const CACHE_DURATIONS = {
  ACCESS_TOKEN: 3 * 60 * 60 * 1000,    // 3 hours
  SEARCH: 24 * 60 * 60 * 1000,         // 24 hours
  DETAILS: 48 * 60 * 60 * 1000,        // 48 hours
  LOCATIONS: 96 * 60 * 60 * 1000,      // 96 hours
  OFFERS: 24 * 60 * 60 * 1000          // 24 hours
};

class AmadeusCache {
  constructor() {
    if (AmadeusCache._instance) {
      return AmadeusCache._instance;
    }

    this.cache = new Map();
    this.timeouts = new Map();
    this.stats = {
      hits: 0,
      misses: 0,
      evictions: 0
    };

    AmadeusCache._instance = this;
  }

  /**
   * Get item from cache
   * @param {string} key - Cache key
   * @param {string} type - Cache type (search, details, etc.)
   * @returns {any|null} Cached data or null
   */
  get(key, type = 'SEARCH') {
    try {
      if (!key) throw new Error('Cache key is required');
      
      const item = this.cache.get(key);
      if (!item) {
        this.stats.misses++;
        return null;
      }

      if (Date.now() > item.expiresAt) {
        this.delete(key);
        this.stats.evictions++;
        return null;
      }

      this.stats.hits++;
      return item.data;
    } catch (error) {
      console.error('Cache get error:', error);
      return null;
    }
  }

  /**
   * Set item in cache
   * @param {string} key - Cache key
   * @param {any} data - Data to cache
   * @param {string} type - Cache type (search, details, etc.)
   * @returns {boolean} Success status
   */
  set(key, data, type = 'SEARCH') {
    try {
      if (!key) throw new Error('Cache key is required');
      if (data === undefined) throw new Error('Cache data is required');
      
      const ttl = CACHE_DURATIONS[type.toUpperCase()] || CACHE_DURATIONS.SEARCH;

      // Clear existing timeout if any
      this.delete(key);

      // Set new cache item
      this.cache.set(key, {
        data,
        type,
        createdAt: Date.now(),
        expiresAt: Date.now() + ttl,
        accessCount: 0
      });

      // Set expiration timeout
      this.timeouts.set(key, setTimeout(() => {
        this.delete(key);
        this.stats.evictions++;
      }, ttl));

      return true;
    } catch (error) {
      console.error('Cache set error:', error);
      return false;
    }
  }

  /**
   * Delete item from cache
   * @param {string} key - Cache key
   */
  delete(key) {
    if (this.timeouts.has(key)) {
      clearTimeout(this.timeouts.get(key));
      this.timeouts.delete(key);
    }
    this.cache.delete(key);
  }

  /**
   * Clear entire cache
   */
  clear() {
    try {
      this.timeouts.forEach(timeout => clearTimeout(timeout));
      this.timeouts.clear();
      this.cache.clear();
      this.resetStats();
    } catch (error) {
      console.error('Cache clear error:', error);
    }
  }

  /**
   * Reset cache statistics
   */
  resetStats() {
    this.stats = {
      hits: 0,
      misses: 0,
      evictions: 0
    };
  }

  /**
   * Get cache statistics and health metrics
   */
  getStats() {
    try {
      const now = Date.now();
      const items = Array.from(this.cache.entries()).map(([key, value]) => ({
        key,
        type: value.type,
        age: now - value.createdAt,
        expiresIn: value.expiresAt - now,
        accessCount: value.accessCount
      }));

      return {
        size: this.cache.size,
        items,
        metrics: {
          ...this.stats,
          hitRatio: this.stats.hits / (this.stats.hits + this.stats.misses) || 0,
          memoryUsage: process.memoryUsage().heapUsed
        }
      };
    } catch (error) {
      console.error('Get stats error:', error);
      return null;
    }
  }

  /**
   * Check if key exists in cache
   */
  has(key) {
    return this.cache.has(key);
  }

  /**
   * Get all cache keys
   */
  get keys() {
    return Array.from(this.cache.keys());
  }

  /**
   * Get cache size
   */
  get size() {
    return this.cache.size;
  }
}

// Create and export singleton instance
const cacheInstance = new AmadeusCache();
Object.freeze(cacheInstance);

export { CACHE_DURATIONS };
export default cacheInstance;