// src/services/contact/moderation.js

import { db } from '../../config/firebase';
import { doc, collection, getDocs, query, where, updateDoc, serverTimestamp } from 'firebase/firestore';
import { MESSAGE_STATUS, MESSAGE_METADATA, FIRESTORE_PATHS, MODERATION_CONFIG } from '../../components/contact/constants';
import { checkForSpam } from '../../utils/contact/spam-detection';
import { sendStatusChangeNotifications } from './email';
import { updateMessageStatus, updateMessageMetadata } from './firestore';

/**
 * Безопасное преобразование временной метки
 */
const safeTimestampToDate = (timestamp) => {
  if (!timestamp) return null;
  return timestamp.toDate ? timestamp.toDate() : timestamp;
};

/**
 * Безопасно получает timestamp в миллисекундах
 */
const getTimestampInMillis = (timestamp) => {
  if (!timestamp) return 0;
  if (timestamp.toMillis) return timestamp.toMillis();
  if (timestamp instanceof Date) return timestamp.getTime();
  if (typeof timestamp === 'string') return new Date(timestamp).getTime();
  return 0;
};

/**
 * Проверяет сообщение на спам и нежелательный контент
 */
const moderateMessage = async (messageData) => {
  try {
    const missingFields = MODERATION_CONFIG.REQUIRED_FIELDS.filter(
      field => !messageData[field]
    );

    if (missingFields.length > 0) {
      return {
        approved: false,
        status: MESSAGE_STATUS.REJECTED,
        reason: `Отсутствуют обязательные поля: ${missingFields.join(', ')}`,
        score: 0
      };
    }

    const { isSpam, score, reasons } = await checkForSpam(messageData);

    if (score >= MODERATION_CONFIG.AUTO_APPROVE_SCORE) {
      return {
        approved: true,
        status: MESSAGE_STATUS.APPROVED,
        reason: 'Автоматически одобрено',
        score
      };
    }

    if (score <= MODERATION_CONFIG.AUTO_REJECT_SCORE || isSpam) {
      return {
        approved: false,
        status: MESSAGE_STATUS.SPAM,
        reason: `Обнаружен спам: ${reasons.join(', ')}`,
        score
      };
    }

    return {
      approved: false,
      status: MESSAGE_STATUS.PENDING_REVIEW,
      reason: 'Требуется ручная проверка',
      score
    };
  } catch (error) {
    console.error('Moderation error:', error);
    throw new Error('Ошибка при модерации сообщения');
  }
};

/**
 * Получает сообщения, ожидающие модерации
 */
const getPendingMessages = async (limit = 50) => {
  try {
    const q = query(
      collection(db, FIRESTORE_PATHS.MESSAGES),
      where(MESSAGE_METADATA.STATUS, '==', MESSAGE_STATUS.PENDING_REVIEW)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      [MESSAGE_METADATA.CREATED_AT]: safeTimestampToDate(doc.data()[MESSAGE_METADATA.CREATED_AT]),
      [MESSAGE_METADATA.UPDATED_AT]: safeTimestampToDate(doc.data()[MESSAGE_METADATA.UPDATED_AT]),
      [MESSAGE_METADATA.MODERATED_AT]: safeTimestampToDate(doc.data()[MESSAGE_METADATA.MODERATED_AT])
    }));
  } catch (error) {
    console.error('Error getting pending messages:', error);
    throw new Error('Не удалось получить сообщения для модерации');
  }
};

/**
 * Обрабатывает сообщение модератором
 */
const handleModeratorAction = async (messageId, moderationData, moderatorId = 'system') => {
  try {
    console.log('Processing moderation action:', { messageId, moderationData, moderatorId });
    
    const { action, comment } = moderationData;
    let newStatus;

    switch (action) {
      case MODERATION_CONFIG.MODERATION_ACTIONS.APPROVE:
        newStatus = MESSAGE_STATUS.APPROVED;
        break;
      case MODERATION_CONFIG.MODERATION_ACTIONS.REJECT:
        newStatus = MESSAGE_STATUS.REJECTED;
        break;
      case MODERATION_CONFIG.MODERATION_ACTIONS.MARK_SPAM:
        newStatus = MESSAGE_STATUS.SPAM;
        break;
      default:
        throw new Error(`Некорректное действие модерации: ${action}`);
    }

    await updateMessageStatus(messageId, newStatus, moderatorId);

    const metadata = {
      [MESSAGE_METADATA.MODERATED_BY]: moderatorId || 'system',
      [MESSAGE_METADATA.MODERATED_AT]: serverTimestamp(),
      [MESSAGE_METADATA.MODERATION_COMMENT]: comment || '',
      moderationAction: action
    };

    await updateMessageMetadata(messageId, metadata);

    console.log('Moderation action completed successfully');
    return true;
  } catch (error) {
    console.error('Moderation action error:', error);
    throw new Error('Ошибка при обработке действия модератора');
  }
};

/**
 * Получает статистику модерации
 */
const getModerationStats = async () => {
  try {
    const stats = {
      total: 0,
      pending: 0,
      approved: 0,
      rejected: 0,
      spam: 0,
      averageResponseTime: 0,
      moderatedToday: 0
    };

    const snapshot = await getDocs(collection(db, FIRESTORE_PATHS.MESSAGES));
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let totalResponseTime = 0;
    let messagesWithResponse = 0;

    snapshot.forEach(doc => {
      const data = doc.data();
      stats.total++;

      switch (data[MESSAGE_METADATA.STATUS]) {
        case MESSAGE_STATUS.PENDING_REVIEW:
          stats.pending++;
          break;
        case MESSAGE_STATUS.APPROVED:
          stats.approved++;
          break;
        case MESSAGE_STATUS.REJECTED:
          stats.rejected++;
          break;
        case MESSAGE_STATUS.SPAM:
          stats.spam++;
          break;
      }

      const moderatedAt = safeTimestampToDate(data[MESSAGE_METADATA.MODERATED_AT]);
      if (moderatedAt && moderatedAt >= today) {
        stats.moderatedToday++;
      }

      if (data[MESSAGE_METADATA.CREATED_AT] && data[MESSAGE_METADATA.MODERATED_AT]) {
        const createdTime = getTimestampInMillis(data[MESSAGE_METADATA.CREATED_AT]);
        const moderatedTime = getTimestampInMillis(data[MESSAGE_METADATA.MODERATED_AT]);
        
        if (createdTime && moderatedTime) {
          totalResponseTime += moderatedTime - createdTime;
          messagesWithResponse++;
        }
      }
    });

    if (messagesWithResponse > 0) {
      stats.averageResponseTime = Math.round(totalResponseTime / messagesWithResponse);
    }

    return stats;
  } catch (error) {
    console.error('Error getting moderation stats:', error);
    throw new Error('Не удалось получить статистику модерации');
  }
};

export {
  moderateMessage,
  getPendingMessages,
  handleModeratorAction,
  getModerationStats
};