// src/pages/admin/contact/MessageStats.jsx

import React from 'react';
import MessageStatsComponent from '../../../components/admin/contact/MessageStats';

const MessageStatsPage = () => {
  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">
        Статистика сообщений
      </h1>
      <MessageStatsComponent />
    </div>
  );
};

export default MessageStatsPage;