// src/services/contactService.js
import { db } from '../config/firebase';
import { 
  collection, 
  addDoc, 
  getDoc, 
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  doc, 
  updateDoc,
  serverTimestamp 
} from 'firebase/firestore';

class ContactService {
  constructor() {
    this.collection = 'contact_messages';
  }

  // Создание нового сообщения
  async submitMessage(messageData) {
    try {
      console.log('ContactService: Submitting message', messageData);

      if (!messageData.name || !messageData.email || !messageData.message) {
        throw new Error('Required fields are missing');
      }

      const docRef = await addDoc(collection(db, this.collection), {
        ...messageData,
        status: 'new',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      console.log('ContactService: Message created with ID:', docRef.id);

      return {
        success: true,
        messageId: docRef.id
      };
    } catch (error) {
      console.error('ContactService: Error submitting message:', error);
      throw error;
    }
  }

  // Получение статуса сообщения
  async getMessageStatus(messageId) {
    try {
      const docRef = doc(db, this.collection, messageId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        return docSnap.data().status;
      }
      throw new Error('Message not found');
    } catch (error) {
      console.error('ContactService: Error getting message status:', error);
      throw error;
    }
  }

  // Получение списка сообщений для админки
  async getMessages(params = {}) {
    try {
      const {
        status = null,
        lastDoc = null,
        pageSize = 10,
        orderDirection = 'desc'
      } = params;

      let q = query(
        collection(db, this.collection),
        orderBy('createdAt', orderDirection),
        limit(pageSize)
      );

      // Добавляем фильтр по статусу, если указан
      if (status && status !== 'all') {
        q = query(q, where('status', '==', status));
      }

      // Добавляем пагинацию, если указан последний документ
      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const snapshot = await getDocs(q);
      
      return {
        messages: snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        lastDoc: snapshot.docs[snapshot.docs.length - 1],
        hasMore: snapshot.docs.length === pageSize
      };
    } catch (error) {
      console.error('ContactService: Error getting messages:', error);
      throw error;
    }
  }

  // Получение одного сообщения по ID
  async getMessage(messageId) {
    try {
      const docRef = doc(db, this.collection, messageId);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        throw new Error('Message not found');
      }

      return {
        id: docSnap.id,
        ...docSnap.data()
      };
    } catch (error) {
      console.error('ContactService: Error getting message:', error);
      throw error;
    }
  }

  // Обновление статуса сообщения
  async updateMessageStatus(messageId, status, moderatorComment = '') {
    try {
      const docRef = doc(db, this.collection, messageId);
      await updateDoc(docRef, {
        status,
        moderatorComment,
        updatedAt: serverTimestamp(),
        moderatedAt: serverTimestamp()
      });

      return {
        success: true
      };
    } catch (error) {
      console.error('ContactService: Error updating message status:', error);
      throw error;
    }
  }

  // Получение статистики по сообщениям
  async getStatistics() {
    try {
      const stats = {
        total: 0,
        byStatus: {},
        lastMessage: null
      };

      const q = query(collection(db, this.collection));
      const snapshot = await getDocs(q);

      snapshot.forEach(doc => {
        const data = doc.data();
        stats.total++;
        
        // Подсчет по статусам
        const status = data.status || 'new';
        stats.byStatus[status] = (stats.byStatus[status] || 0) + 1;

        // Определение последнего сообщения
        if (!stats.lastMessage || data.createdAt > stats.lastMessage.createdAt) {
          stats.lastMessage = {
            id: doc.id,
            ...data
          };
        }
      });

      return stats;
    } catch (error) {
      console.error('ContactService: Error getting statistics:', error);
      throw error;
    }
  }
}

export default new ContactService();