// src/services/api/hotelImageService.js

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

class HotelImageService {
  async getHotelImages(hotelData) {
    console.log('Getting images for hotel:', hotelData);

    try {
      // Формируем поисковый запрос
      const searchQuery = this._buildSearchQuery(hotelData);
      const placeId = await this._findPlaceId(searchQuery, hotelData.location?.coordinates);
      
      if (!placeId) {
        console.warn('Place ID not found for hotel:', hotelData.name);
        return ["/api/placeholder/400/300"];
      }

      // Получаем детали места с фотографиями
      const placeDetails = await this._getPlaceDetails(placeId);
      if (!placeDetails?.photos?.length) {
        console.warn('No photos found for hotel:', hotelData.name);
        return ["/api/placeholder/400/300"];
      }

      // Формируем URL'ы фотографий
      const photoUrls = await this._getPhotoUrls(placeDetails.photos);
      console.log('Found photo URLs:', photoUrls);

      return photoUrls.length > 0 ? photoUrls : ["/api/placeholder/400/300"];
    } catch (error) {
      console.error('Error getting hotel images:', error);
      return ["/api/placeholder/400/300"];
    }
  }

  async _findPlaceId(query, coordinates) {
    const params = new URLSearchParams({
      input: query,
      inputtype: 'textquery',
      fields: 'place_id,name,formatted_address',
      key: GOOGLE_MAPS_API_KEY
    });

    if (coordinates?.latitude && coordinates?.longitude) {
      params.append('locationbias', `circle:5000@${coordinates.latitude},${coordinates.longitude}`);
    }

    try {
      const response = await fetch(`/google-places/place/findplacefromtext/json?${params}`);
      const data = await response.json();

      if (data.status === 'OK' && data.candidates?.length > 0) {
        return data.candidates[0].place_id;
      }
      return null;
    } catch (error) {
      console.error('Error finding place ID:', error);
      return null;
    }
  }

  async _getPlaceDetails(placeId) {
    const response = await fetch(
      `/google-places/place/details/json?` +
      `place_id=${placeId}&` +
      `fields=photo&` +
      `key=${GOOGLE_MAPS_API_KEY}`
    );
    
    const data = await response.json();
    return data.status === 'OK' ? data.result : null;
  }

  async _getPhotoUrls(photos) {
    return photos.slice(0, 6).map(photo => 
      `/google-places/place/photo?` +
      `maxwidth=800&` +
      `maxheight=600&` +
      `photo_reference=${photo.photo_reference}&` +
      `key=${GOOGLE_MAPS_API_KEY}`
    );
  }

  _buildSearchQuery(hotelData) {
    const parts = [
      hotelData.name,
      'hotel',
      hotelData.location?.address?.city,
      hotelData.location?.address?.country
    ];

    return parts.filter(Boolean).join(' ');
  }
}

export default new HotelImageService();