// src/components/contact/constants.js

import { 
  HelpCircle, 
  Bug, 
  MessageSquare, 
  Flag, 
  Coffee, 
  Star 
} from 'lucide-react';

// Типы обращений
export const INQUIRY_TYPES = {
  GENERAL: {
    value: 'general',
    label: 'Общий вопрос',
    icon: HelpCircle,
    description: 'Общие вопросы о наших услугах'
  },
  SUPPORT: {
    value: 'support',
    label: 'Техническая поддержка',
    icon: Bug,
    description: 'Проблемы с работой сайта'
  },
  FEEDBACK: {
    value: 'feedback',
    label: 'Отзыв и предложения',
    icon: MessageSquare,
    description: 'Поделитесь вашими идеями'
  },
  REPORT: {
    value: 'report',
    label: 'Сообщить о проблеме',
    icon: Flag,
    description: 'Сообщить о некорректном контенте'
  },
  BUSINESS: {
    value: 'business',
    label: 'Бизнес-запрос',
    icon: Coffee,
    description: 'Партнерство и бизнес-возможности'
  },
  REVIEW: {
    value: 'review',
    label: 'Апелляция отзыва',
    icon: Star,
    description: 'Обжалование решения по отзыву'
  }
};

// Статусы сообщений
export const MESSAGE_STATUS = {
  NEW: 'new',
  PENDING_REVIEW: 'pending_review',
  SPAM: 'spam',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  IN_PROGRESS: 'in_progress',
  RESOLVED: 'resolved'
};

export const STATUS_LABELS = {
  [MESSAGE_STATUS.NEW]: 'Новое',
  [MESSAGE_STATUS.PENDING_REVIEW]: 'На модерации',
  [MESSAGE_STATUS.SPAM]: 'Спам',
  [MESSAGE_STATUS.APPROVED]: 'Одобрено',
  [MESSAGE_STATUS.REJECTED]: 'Отклонено',
  [MESSAGE_STATUS.IN_PROGRESS]: 'В обработке',
  [MESSAGE_STATUS.RESOLVED]: 'Решено'
};

// Лимиты и ограничения
export const FORM_LIMITS = {
  MAX_MESSAGE_LENGTH: 5000,
  MIN_MESSAGE_LENGTH: 10,
  MAX_SUBJECT_LENGTH: 200,
  MAX_FILE_SIZE: 5 * 1024 * 1024, // 5MB
  ALLOWED_FILE_TYPES: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  MAX_FILES: 3,
  SUBMISSION_COOLDOWN: 5 * 60 * 1000, // 5 минут
  MAX_ATTEMPTS_PER_DAY: 10,
  ATTEMPTS_RESET_TIME: 24 * 60 * 60 * 1000, // 24 часа
  BLOCK_DURATION: 24 * 60 * 60 * 1000, // 24 часа
};

// Шаблоны email-уведомлений
export const EMAIL_TEMPLATES = {
  ADMIN_NOTIFICATION: 'admin-new-message',
  USER_CONFIRMATION: 'user-confirmation',
  SPAM_NOTIFICATION: 'spam-notification',
  STATUS_UPDATE: 'status-update'
};

// Конфигурация reCAPTCHA
export const RECAPTCHA_CONFIG = {
  SITE_KEY: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
  MIN_SCORE: 0.5,
  ACTION: 'contact_form_submit'
};

// Пути в Firestore
export const FIRESTORE_PATHS = {
  MESSAGES: 'contact_messages',
  SPAM_PATTERNS: 'spam_patterns',
  MESSAGE_COUNTERS: 'message_counters'
};

// Конфигурация модерации
export const MODERATION_CONFIG = {
  AUTO_APPROVE_SCORE: 0.8,
  AUTO_REJECT_SCORE: 0.2,
  REQUIRED_FIELDS: ['name', 'email', 'message', 'subject', 'type'],
  SPAM_KEYWORDS: [
    'casino',
    'lottery',
    'viagra',
    'crypto',
    'bitcoin',
    'investment opportunity',
    'make money fast',
    'work from home',
    'earn extra income',
    'best rates',
    'best prices',
    'click here',
    'free offer',
    'limited time'
  ],
  MAX_LINKS_COUNT: 3,
  MAX_REPEATED_CHARS: 4,
  MAX_CAPS_RATIO: 0.7,
  MAX_REPEATED_WORDS: 3,
  MODERATION_ACTIONS: {
    APPROVE: 'approve',
    REJECT: 'reject',
    MARK_SPAM: 'mark_spam'
  }
};

// Метаданные сообщений
export const MESSAGE_METADATA = {
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  STATUS: 'status',
  MODERATED_BY: 'moderatedBy',
  MODERATED_AT: 'moderatedAt',
  SPAM_SCORE: 'spamScore',
  IP_ADDRESS: 'ipAddress',
  USER_AGENT: 'userAgent',
  RECAPTCHA_SCORE: 'recaptchaScore',
  MODERATION_COMMENT: 'moderationComment',
  MODERATION_REASON: 'moderationReason'
};

// Статусы API
export const API_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  PENDING: 'pending'
};

// API endpoints
export const API_ENDPOINTS = {
  SUBMIT: '/api/contact/submit',
  VERIFY_RECAPTCHA: '/api/contact/verify-recaptcha',
  STATUS: '/api/contact/status'
};

// Конфигурация уведомлений
export const NOTIFICATION_CONFIG = {
  DURATION: 5000,
  POSITION: 'bottom-right'
};

export const ERROR_MESSAGES = {
  REQUIRED_FIELD: 'Это поле обязательно для заполнения',
  INVALID_EMAIL: 'Введите корректный email адрес',
  MESSAGE_TOO_SHORT: `Сообщение должно содержать не менее ${FORM_LIMITS.MIN_MESSAGE_LENGTH} символов`,
  MESSAGE_TOO_LONG: `Сообщение не должно превышать ${FORM_LIMITS.MAX_MESSAGE_LENGTH} символов`,
  SUBJECT_TOO_LONG: `Тема не должна превышать ${FORM_LIMITS.MAX_SUBJECT_LENGTH} символов`,
  FILE_TOO_LARGE: `Размер файла не должен превышать ${FORM_LIMITS.MAX_FILE_SIZE / (1024 * 1024)}MB`,
  TOO_MANY_FILES: `Можно прикрепить не более ${FORM_LIMITS.MAX_FILES} файлов`,
  INVALID_FILE_TYPE: 'Неподдерживаемый тип файла',
  SUBMISSION_FAILED: 'Не удалось отправить сообщение. Попробуйте позже.',
  RATE_LIMIT: 'Слишком много попыток. Попробуйте позже.',
  RECAPTCHA_FAILED: 'Проверка reCAPTCHA не пройдена'
};