// src/components/hotels/detail/LocationTab.jsx
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { 
 MapPin, 
 Navigation, 
 Train, 
 Plane, 
 Bus, 
 Building, 
 Coffee,
 UtensilsCrossed,
 ShoppingBag,
 Trees,
 Waves
} from 'lucide-react';
import { Card, CardContent } from '../../ui/card';
import { Skeleton } from '../../ui/skeleton';
import { Button } from '../../ui/button';

// Импортируем компонент карты через lazy loading
const GoogleMap = React.lazy(() => import('../../maps/GoogleMap'));

// Иконки для разных типов мест
const PLACE_ICONS = {
 // Транспорт
 airport: Plane,
 trainStation: Train,
 busStation: Bus,
 subway: Train,
 // Места рядом
 restaurants: UtensilsCrossed,
 cafes: Coffee,
 shopping: ShoppingBag,
 parks: Trees,
 beach: Waves,
 // Основные места
 cityCenter: Building
};

const NearbyPlace = ({ type, details, icon: Icon = MapPin }) => {
 // Форматирование значения
 const formatValue = () => {
   if (typeof details === 'string') return details;
   if (typeof details === 'number') return `${details} km`;
   if (details?.distance) {
     return `${details.distance} ${details.unit || 'km'}`;
   }
   return 'N/A';
 };

 return (
   <div className="flex items-start gap-2">
     <Icon className="w-4 h-4 text-gray-400 mt-1" />
     <div>
       <div className="text-sm font-medium capitalize">
         {type.replace(/([A-Z])/g, ' $1').trim()}
       </div>
       <div className="text-sm text-gray-600">
         {formatValue()}
       </div>
       {details?.description && (
         <div className="text-xs text-gray-500 mt-0.5">
           {details.description}
         </div>
       )}
     </div>
   </div>
 );
};

const AddressSection = ({ address }) => {
 if (!address) return null;

 const getFormattedAddress = () => {
   if (typeof address === 'string') return address;

   const parts = [];
   if (address.lines?.length) {
     parts.push(address.lines.join(', '));
   }
   if (address.city) parts.push(address.city);
   if (address.postal) parts.push(address.postal);
   if (address.country) parts.push(address.country);

   return parts.length > 0 ? parts.join(', ') : 'Address not available';
 };

 return (
   <div className="space-y-2">
     <div className="font-medium">Hotel Address</div>
     <div className="flex items-start gap-2">
       <MapPin className="w-4 h-4 text-gray-400 mt-1" />
       <div className="text-sm text-gray-600">{getFormattedAddress()}</div>
     </div>
   </div>
 );
};

const LocationTab = ({ hotel }) => {
 const { location } = hotel;

 if (!location) {
   return (
     <Card>
       <CardContent className="p-6 text-center text-gray-500">
         Location information not available
       </CardContent>
     </Card>
   );
 }

 // Группируем места поблизости по категориям
 const nearbyPlaces = React.useMemo(() => {
   if (!location.nearby) return {};

   return Object.entries(location.nearby).reduce((acc, [key, value]) => {
     if (value == null) return acc;

     // Определяем категорию
     let category = 'other';
     if (key === 'cityCenter' || key === 'downtown') {
       category = 'main';
     } else if (['airport', 'trainStation', 'busStation', 'subway'].includes(key)) {
       category = 'transportation';
     } else if (['restaurants', 'cafes', 'shopping', 'parks', 'beach'].includes(key)) {
       category = 'attractions';
     }

     if (!acc[category]) acc[category] = {};
     acc[category][key] = value;
     return acc;
   }, {});
 }, [location.nearby]);

 return (
   <Card>
     <CardContent className="p-6 space-y-6">
       {/* Map */}
       <div className="relative">
         <Suspense fallback={<Skeleton className="aspect-video rounded-lg" />}>
           <GoogleMap 
             location={{
               latitude: location?.coordinates?.latitude,
               longitude: location?.coordinates?.longitude
             }}
             className="aspect-video rounded-lg"
           />
         </Suspense>
         {location?.coordinates && (
           <Button 
             variant="ghost"
             size="sm"
             className="absolute bottom-4 right-4 bg-white shadow-md hover:bg-gray-100"
             onClick={() => {
               const { latitude, longitude } = location.coordinates;
               window.open(
                 `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`,
                 '_blank',
                 'noopener,noreferrer'
               );
             }}
           >
             <Navigation className="w-4 h-4 mr-2" />
             Get Directions
           </Button>
         )}
       </div>

       {/* Address */}
       <AddressSection address={location.address} />

       {/* Nearby Places */}
       <div className="space-y-6">
         {/* Main Locations (City Center) */}
         {nearbyPlaces.main && Object.keys(nearbyPlaces.main).length > 0 && (
           <div className="space-y-4">
             {Object.entries(nearbyPlaces.main).map(([key, value]) => (
               <NearbyPlace
                 key={key}
                 type={key}
                 details={value}
                 icon={PLACE_ICONS[key]}
               />
             ))}
           </div>
         )}

         {/* Transportation */}
         {nearbyPlaces.transportation && Object.keys(nearbyPlaces.transportation).length > 0 && (
           <div className="space-y-4">
             <h4 className="font-medium">Transportation</h4>
             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
               {Object.entries(nearbyPlaces.transportation).map(([key, value]) => (
                 <NearbyPlace
                   key={key}
                   type={key}
                   details={value}
                   icon={PLACE_ICONS[key]}
                 />
               ))}
             </div>
           </div>
         )}

         {/* Attractions */}
         {nearbyPlaces.attractions && Object.keys(nearbyPlaces.attractions).length > 0 && (
           <div className="space-y-4">
             <h4 className="font-medium">Nearby Attractions</h4>
             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
               {Object.entries(nearbyPlaces.attractions).map(([key, value]) => (
                 <NearbyPlace
                   key={key}
                   type={key}
                   details={value}
                   icon={PLACE_ICONS[key]}
                 />
               ))}
             </div>
           </div>
         )}

         {/* Other Places */}
         {nearbyPlaces.other && Object.keys(nearbyPlaces.other).length > 0 && (
           <div className="space-y-4">
             <h4 className="font-medium">Other Nearby Places</h4>
             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
               {Object.entries(nearbyPlaces.other).map(([key, value]) => (
                 <NearbyPlace
                   key={key}
                   type={key}
                   details={value}
                 />
               ))}
             </div>
           </div>
         )}
       </div>

       {/* Area Information */}
       {location.areaInfo && (
         <div className="bg-gray-50 rounded-lg p-4">
           <h4 className="font-medium mb-2">About the Area</h4>
           <p className="text-sm text-gray-600">{location.areaInfo}</p>
         </div>
       )}
     </CardContent>
   </Card>
 );
};

// PropTypes
NearbyPlace.propTypes = {
 type: PropTypes.string.isRequired,
 details: PropTypes.oneOfType([
   PropTypes.string,
   PropTypes.number,
   PropTypes.shape({
     distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
     unit: PropTypes.string,
     description: PropTypes.string
   })
 ]).isRequired,
 icon: PropTypes.elementType
};

AddressSection.propTypes = {
 address: PropTypes.oneOfType([
   PropTypes.string,
   PropTypes.shape({
     lines: PropTypes.arrayOf(PropTypes.string),
     city: PropTypes.string,
     postal: PropTypes.string,
     country: PropTypes.string
   })
 ])
};

LocationTab.propTypes = {
 hotel: PropTypes.shape({
   location: PropTypes.shape({
     coordinates: PropTypes.shape({
       latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
       longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
     }),
     address: PropTypes.oneOfType([
       PropTypes.string,
       PropTypes.shape({
         lines: PropTypes.arrayOf(PropTypes.string),
         city: PropTypes.string,
         postal: PropTypes.string,
         country: PropTypes.string
       })
     ]),
     nearby: PropTypes.object,
     areaInfo: PropTypes.string
   })
 }).isRequired
};

export default LocationTab;