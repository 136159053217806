// src/components/legal/TermsOfService.jsx
export const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Terms and Conditions of Use for HotelsRating.com</h1>
      <div className="text-sm text-gray-600 mb-8">
        Effective Date: 25/11/2024
      </div>
      
      <div className="prose prose-blue max-w-none space-y-6">
        <p>Welcome to HotelsRating.com. These Terms and Conditions ("Terms") govern your access to and use of our website, services, and any associated content (collectively, the "Service"). By using HotelsRating.com, you agree to these Terms. If you do not agree, you must not use our Service.</p>

        <section>
          <h2 className="text-2xl font-semibold mb-4">1. Definitions</h2>
          <ul className="list-disc pl-6">
            <li>"We," "us," and "our" refer to HotelsRating.com, its owners, and operators.</li>
            <li>"You" refers to any user accessing or using the Service.</li>
            <li>"Content" includes reviews, ratings, articles, images, videos, and other materials on the website.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. Eligibility</h2>
          <p>You must be at least 16 years old to use HotelsRating.com. By using our Service, you confirm that you are legally capable of entering into these Terms.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">3. Use of Service</h2>
          <h3 className="text-xl font-medium mt-4 mb-2">A. Acceptable Use</h3>
          <p>You agree to use HotelsRating.com for lawful purposes only and in compliance with these Terms. You must not:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Post or transmit harmful, defamatory, obscene, or illegal content.</li>
            <li>Use the Service for unauthorized commercial purposes.</li>
            <li>Attempt to hack, disrupt, or compromise the Service's functionality.</li>
            <li>Upload viruses or malicious software.</li>
          </ul>

          <h3 className="text-xl font-medium mt-4 mb-2">B. Account Responsibility</h3>
          <ul className="list-disc pl-6">
            <li>If you create an account, you are responsible for maintaining its confidentiality and for all activities that occur under your account.</li>
            <li>Notify us immediately of any unauthorized use of your account.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property</h2>
          <h3 className="text-xl font-medium mt-4 mb-2">A. Ownership</h3>
          <ul className="list-disc pl-6 mb-4">
            <li>All content, trademarks, logos, and intellectual property on HotelsRating.com are owned by or licensed to us.</li>
            <li>You may not copy, reproduce, distribute, or modify any part of our Service without prior written consent.</li>
          </ul>

          <h3 className="text-xl font-medium mt-4 mb-2">B. User Content</h3>
          <ul className="list-disc pl-6">
            <li>By posting content on HotelsRating.com, you grant us a non-exclusive, royalty-free, perpetual license to use, modify, and display your content.</li>
            <li>You warrant that your content does not infringe any third-party rights.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">5. Booking and Transactions</h2>
          <h3 className="text-xl font-medium mt-4 mb-2">A. Third-Party Services</h3>
          <ul className="list-disc pl-6 mb-4">
            <li>HotelsRating.com facilitates access to third-party services, including hotel bookings. These services are subject to the terms of the respective third parties.</li>
            <li>We are not responsible for disputes or issues arising from transactions with third parties.</li>
          </ul>

          <h3 className="text-xl font-medium mt-4 mb-2">B. Accuracy</h3>
          <p>While we strive to provide accurate information, we do not guarantee the completeness or accuracy of listings, prices, or availability displayed on the website.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">6. Disclaimer of Warranties</h2>
          <h3 className="text-xl font-medium mt-4 mb-2">A. No Guarantees</h3>
          <p>The Service is provided "as is" and "as available." We do not guarantee:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Uninterrupted or error-free operation of the website.</li>
            <li>The accuracy or reliability of any content or third-party services.</li>
          </ul>

          <h3 className="text-xl font-medium mt-4 mb-2">B. Limitation of Liability</h3>
          <ul className="list-disc pl-6">
            <li>Direct, indirect, incidental, or consequential damages arising from your use of the Service.</li>
            <li>Loss of data, profits, or goodwill resulting from service interruptions or errors.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">7. Indemnification</h2>
          <p>You agree to indemnify and hold us harmless from any claims, damages, or liabilities arising from your breach of these Terms or misuse of the Service.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">8. Termination</h2>
          <p>We reserve the right to suspend or terminate your access to HotelsRating.com at our sole discretion if you violate these Terms or engage in prohibited conduct.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">9. Governing Law</h2>
          <p>These Terms are governed by and construed in accordance with the laws of the United Arab Emirates (UAE), Dubai, without regard to its conflict of law principles. Any disputes will be subject to the exclusive jurisdiction of the courts in Dubai, UAE.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">10. Changes to These Terms</h2>
          <p>We may modify these Terms at any time. Changes will be effective upon posting the updated Terms on this page. Your continued use of HotelsRating.com signifies your acceptance of the updated Terms.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">11. Contact Information</h2>
          <p>If you have questions or concerns about these Terms, please contact us at:</p>
          <div className="mt-4">
            <p>HotelsRating.com</p>
            <p>Email: support@hotelsrating.com</p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">12. Miscellaneous</h2>
          <ul className="list-disc pl-6">
            <li><strong>Severability:</strong> If any provision of these Terms is found to be unenforceable, the remaining provisions will remain in effect.</li>
            <li><strong>No Waiver:</strong> Failure to enforce any provision of these Terms does not constitute a waiver of that provision.</li>
            <li><strong>Entire Agreement:</strong> These Terms constitute the entire agreement between you and HotelsRating.com regarding the use of the Service.</li>
          </ul>
        </section>

        <div className="mt-8 text-sm text-gray-600">
          By using HotelsRating.com, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
        </div>
      </div>
    </div>
  );
};