// src/pages/hotel/HotelDetailPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import { Button } from '../../components/ui/button';
import hotelService from '../../services/api/hotels/HotelService';
import { ROUTES } from '../../config/paths';

import {
  HeaderInfo,
  MainContent,
  Sidebar,
  LoadingState,
} from '../../components/hotels/detail';

const HotelDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();

  const [hotel, setHotel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const searchData = {
    checkIn: searchParams.get('checkIn'),
    checkOut: searchParams.get('checkOut'),
    adults: parseInt(searchParams.get('adults'), 10) || 2,
    children: parseInt(searchParams.get('children'), 10) || 0
  };

  useEffect(() => {
    loadHotelDetails();
  }, [id]);

  const loadHotelDetails = async () => {
    if (!id) return;
    
    try {
      setLoading(true);
      setError(null);
      const data = await hotelService.getHotelDetails(id, searchData);
      
      if (!data) {
        throw new Error('Hotel not found');
      }
      
      setHotel(data);
    } catch (err) {
      console.error('Error fetching hotel details:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: "Unable to load hotel details",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBookingSubmit = async (bookingData) => {
    try {
      // Проверяем наличие всех необходимых данных
      if (!hotel) {
        throw new Error('Hotel details not available');
      }

      if (!bookingData.checkIn || !bookingData.checkOut || !bookingData.roomId || !bookingData.guests) {
        throw new Error('Please select all required booking details');
      }

      // Формируем параметры для URL
      const bookingParams = new URLSearchParams({
        hotelId: hotel.id,
        roomId: bookingData.roomId,
        checkIn: bookingData.checkIn.toISOString(),
        checkOut: bookingData.checkOut.toISOString(),
        adults: bookingData.guests.adults,
        children: bookingData.guests.children,
        price: bookingData.price.total
      });

      // Перенаправляем на страницу оформления бронирования
      navigate(`${ROUTES.BOOKING}?${bookingParams.toString()}`);

    } catch (error) {
      console.error('Booking error:', error);
      toast({
        title: "Booking Error",
        description: error.message,
        variant: "destructive"
      });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return <LoadingState />;
  }

  if (error || !hotel) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            {error || 'Hotel not found'}
          </h2>
          <Button onClick={handleBack} variant="outline">
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Шапка с основной информацией об отеле */}
      <HeaderInfo hotel={hotel} onBack={handleBack} />
      
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Основной контент с информацией об отеле */}
          <div className="lg:col-span-2">
            <MainContent 
              hotel={hotel}
              searchData={searchData}
            />
          </div>

          {/* Сайдбар с формой бронирования */}
          <div>
            <Sidebar 
              hotel={hotel}
              onBookingSubmit={handleBookingSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelDetailPage;