// src/components/hotels/detail/ReviewsTab.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { 
 Star,
 ThumbsUp,
 MessageCircle,
 User,
 Calendar,
 Flag,
 BadgeCheck,
 Filter,
 ArrowUpDown
} from 'lucide-react';
import { Card, CardContent } from '../../ui/card';
import { Button } from '../../ui/button';
import { Skeleton } from '../../ui/skeleton';
import {
 Select,
 SelectContent,
 SelectItem,
 SelectTrigger,
 SelectValue,
} from "../../ui/select";
import {
 Sheet,
 SheetContent,
 SheetDescription,
 SheetHeader,
 SheetTitle,
 SheetTrigger,
 SheetFooter,
} from '../../ui/sheet';

const SORT_OPTIONS = {
 RECENT: 'recent',
 RATING_HIGH: 'rating_high',
 RATING_LOW: 'rating_low',
 HELPFUL: 'helpful'
};

const FILTER_OPTIONS = {
 ALL: 'all',
 POSITIVE: 'positive',
 NEGATIVE: 'negative',
 VERIFIED: 'verified',
 WITH_PHOTOS: 'with_photos',
 WITH_RESPONSE: 'with_response'
};

const RatingSummary = ({ ratings, totalReviews }) => {
 const calculatePercentage = (count) => (count / totalReviews) * 100;

 return (
   <div className="space-y-4">
     <div className="flex items-center gap-2">
       <div className="text-4xl font-bold text-brand-blue">
         {ratings.average.toFixed(1)}
       </div>
       <div className="text-sm text-gray-600">
         <div>out of 5</div>
         <div>{totalReviews} reviews</div>
       </div>
     </div>

     <div className="space-y-2">
       {[5, 4, 3, 2, 1].map(rating => (
         <div key={rating} className="flex items-center gap-2">
           <div className="w-12 text-sm text-gray-600">
             {rating} stars
           </div>
           <div className="flex-1 h-2 bg-gray-100 rounded-full overflow-hidden">
             <div 
               className="h-full bg-brand-blue"
               style={{ width: `${calculatePercentage(ratings[rating])}%` }}
             />
           </div>
           <div className="w-12 text-sm text-right text-gray-600">
             {ratings[rating]}
           </div>
         </div>
       ))}
     </div>
   </div>
 );
};

const ReviewCard = ({ review, onHelpful, onReport }) => {
 const { 
   author,
   rating,
   title,
   content,
   date,
   helpful,
   verified,
   response,
   photos = [],
   stayDetails
 } = review;

 return (
   <Card>
     <CardContent className="p-6 space-y-4">
       {/* Author and Rating */}
       <div className="flex justify-between items-start">
         <div className="flex items-center gap-3">
           <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
             <User className="w-5 h-5 text-gray-400" />
           </div>
           <div>
             <div className="font-medium">{author.name}</div>
             {stayDetails && (
               <div className="text-sm text-gray-600">{stayDetails}</div>
             )}
           </div>
         </div>
         <div className="flex items-center gap-1">
           <Star className="w-4 h-4 text-yellow-400" />
           <span className="font-medium">{rating}</span>
         </div>
       </div>

       {/* Review Content */}
       <div>
         {title && (
           <h4 className="font-medium mb-2">{title}</h4>
         )}
         <p className="text-gray-600 whitespace-pre-line">{content}</p>
       </div>

       {/* Photos */}
       {photos.length > 0 && (
         <div className="grid grid-cols-4 gap-2">
           {photos.map((photo, index) => (
             <img
               key={index}
               src={photo}
               alt={`Review photo ${index + 1}`}
               className="rounded-lg object-cover w-full h-24"
             />
           ))}
         </div>
       )}

       {/* Metadata */}
       <div className="flex flex-wrap items-center gap-4 text-sm text-gray-600">
         <div className="flex items-center gap-1">
           <Calendar className="w-4 h-4" />
           <span>{format(new Date(date), 'PP')}</span>
         </div>
         {verified && (
           <div className="flex items-center gap-1 text-green-600">
             <BadgeCheck className="w-4 h-4" />
             <span>Verified Stay</span>
           </div>
         )}
       </div>

       {/* Actions */}
       <div className="flex items-center justify-between pt-4 border-t">
         <Button
           variant="ghost"
           size="sm"
           onClick={() => onHelpful(review.id)}
           className="text-gray-600"
         >
           <ThumbsUp className="w-4 h-4 mr-2" />
           Helpful ({helpful})
         </Button>
         <Button
           variant="ghost"
           size="sm"
           onClick={() => onReport(review.id)}
           className="text-gray-600"
         >
           <Flag className="w-4 h-4 mr-2" />
           Report
         </Button>
       </div>

       {/* Hotel Response */}
       {response && (
         <div className="mt-4 p-4 bg-gray-50 rounded-lg">
           <div className="flex items-center gap-2 mb-2">
             <MessageCircle className="w-4 h-4 text-gray-600" />
             <span className="font-medium">Hotel Response</span>
           </div>
           <p className="text-sm text-gray-600">{response.content}</p>
           <div className="text-sm text-gray-500 mt-2">
             Responded on {format(new Date(response.date), 'PP')}
           </div>
         </div>
       )}
     </CardContent>
   </Card>
 );
};

const ReviewsTab = ({ hotel, reviews = [], onLoadMore, loading }) => {
 const [sortBy, setSortBy] = useState(SORT_OPTIONS.RECENT);
 const [filters, setFilters] = useState([]);
 const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);

 // Функции для работы с отзывами
 const handleHelpful = (reviewId) => {
   // Логика отметки отзыва как полезного
   console.log('Marked as helpful:', reviewId);
 };

 const handleReport = (reviewId) => {
   // Логика отправки жалобы на отзыв
   console.log('Reported review:', reviewId);
 };

 if (!reviews.length) {
   return (
     <Card>
       <CardContent className="p-6">
         <div className="text-center text-gray-500 py-8">
           No reviews available for this hotel yet
         </div>
       </CardContent>
     </Card>
   );
 }

 return (
   <div className="space-y-6">
     {/* Reviews Summary */}
     <Card>
       <CardContent className="p-6">
         <RatingSummary ratings={hotel.ratings} totalReviews={reviews.length} />
       </CardContent>
     </Card>

     {/* Controls */}
     <div className="flex justify-between items-center">
       <Select value={sortBy} onValueChange={setSortBy}>
         <SelectTrigger className="w-[200px]">
           <ArrowUpDown className="w-4 h-4 mr-2" />
           <SelectValue />
         </SelectTrigger>
         <SelectContent>
           <SelectItem value={SORT_OPTIONS.RECENT}>Most Recent</SelectItem>
           <SelectItem value={SORT_OPTIONS.RATING_HIGH}>Highest Rated</SelectItem>
           <SelectItem value={SORT_OPTIONS.RATING_LOW}>Lowest Rated</SelectItem>
           <SelectItem value={SORT_OPTIONS.HELPFUL}>Most Helpful</SelectItem>
         </SelectContent>
       </Select>

       <Sheet open={isFilterSheetOpen} onOpenChange={setIsFilterSheetOpen}>
         <SheetTrigger asChild>
           <Button variant="outline">
             <Filter className="w-4 h-4 mr-2" />
             Filter Reviews
           </Button>
         </SheetTrigger>
         <SheetContent>
           <SheetHeader>
             <SheetTitle>Filter Reviews</SheetTitle>
             <SheetDescription>
               Select filters to refine the reviews
             </SheetDescription>
           </SheetHeader>
           {/* Filter options */}
           <div className="py-4 space-y-4">
             {Object.entries(FILTER_OPTIONS).map(([key, value]) => (
               <div key={key} className="flex items-center gap-2">
                 <input
                   type="checkbox"
                   id={value}
                   checked={filters.includes(value)}
                   onChange={(e) => {
                     if (e.target.checked) {
                       setFilters([...filters, value]);
                     } else {
                       setFilters(filters.filter(f => f !== value));
                     }
                   }}
                   className="rounded"
                 />
                 <label htmlFor={value} className="text-sm">
                   {key.replace(/_/g, ' ').toLowerCase()}
                 </label>
               </div>
             ))}
           </div>
           <SheetFooter>
             <Button 
               variant="outline" 
               onClick={() => setFilters([])}
             >
               Reset Filters
             </Button>
           </SheetFooter>
         </SheetContent>
       </Sheet>
     </div>

     {/* Reviews List */}
     <div className="space-y-4">
       {reviews.map((review) => (
         <ReviewCard
           key={review.id}
           review={review}
           onHelpful={handleHelpful}
           onReport={handleReport}
         />
       ))}
     </div>

     {/* Load More */}
     {reviews.length >= 10 && (
       <div className="text-center">
         <Button
           variant="outline"
           onClick={onLoadMore}
           disabled={loading}
         >
           {loading ? 'Loading...' : 'Load More Reviews'}
         </Button>
       </div>
     )}
   </div>
 );
};

RatingSummary.propTypes = {
 ratings: PropTypes.shape({
   average: PropTypes.number.isRequired,
   1: PropTypes.number.isRequired,
   2: PropTypes.number.isRequired,
   3: PropTypes.number.isRequired,
   4: PropTypes.number.isRequired,
   5: PropTypes.number.isRequired,
 }).isRequired,
 totalReviews: PropTypes.number.isRequired
};

ReviewCard.propTypes = {
 review: PropTypes.shape({
   id: PropTypes.string.isRequired,
   author: PropTypes.shape({
     name: PropTypes.string.isRequired,
   }).isRequired,
   rating: PropTypes.number.isRequired,
   title: PropTypes.string,
   content: PropTypes.string.isRequired,
   date: PropTypes.string.isRequired,
   helpful: PropTypes.number.isRequired,
   verified: PropTypes.bool,
   response: PropTypes.shape({
     content: PropTypes.string.isRequired,
     date: PropTypes.string.isRequired,
   }),
   photos: PropTypes.arrayOf(PropTypes.string),
   stayDetails: PropTypes.string
 }).isRequired,
 onHelpful: PropTypes.func.isRequired,
 onReport: PropTypes.func.isRequired
};

ReviewsTab.propTypes = {
 hotel: PropTypes.shape({
   ratings: PropTypes.shape({
     average: PropTypes.number.isRequired,
     1: PropTypes.number.isRequired,
     2: PropTypes.number.isRequired,
     3: PropTypes.number.isRequired,
     4: PropTypes.number.isRequired,
     5: PropTypes.number.isRequired,
   }).isRequired
 }).isRequired,
 reviews: PropTypes.arrayOf(PropTypes.object),
 onLoadMore: PropTypes.func.isRequired,
 loading: PropTypes.bool
};

export default ReviewsTab;