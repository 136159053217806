// src/components/hotels/detail/MainContent.jsx
import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { Clock, Info } from 'lucide-react';
import { Card, CardContent } from '../../ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { Skeleton } from '../../ui/skeleton';
import { Button } from '../../ui/button';
import { MapPin } from 'lucide-react';

// Импортируем компоненты через lazy loading
const HotelGallery = React.lazy(() => import('../HotelGallery'));
const GoogleMap = React.lazy(() => import('../../maps/GoogleMap'));
const HotelReviews = React.lazy(() => import('../HotelReviews'));
const SimilarHotels = React.lazy(() => import('../SimilarHotels'));
const PriceHistoryChart = React.lazy(() => import('../PriceHistoryChart'));

// Компонент для отображения скелетона галереи
const GallerySkeleton = () => (
 <div className="space-y-2">
   <Skeleton className="h-[400px] w-full rounded-lg" />
   <div className="grid grid-cols-4 gap-2">
     {[...Array(4)].map((_, i) => (
       <Skeleton key={i} className="aspect-video w-full rounded-lg" />
     ))}
   </div>
 </div>
);

const MainContent = ({ hotel }) => {
 const [activeTab, setActiveTab] = useState('overview');

 return (
   <div className="lg:col-span-2 space-y-8">
     {/* Gallery */}
     <Card>
       <CardContent className="p-6">
          <Suspense fallback={<GallerySkeleton />}>
           <HotelGallery images={hotel.images} />
         </Suspense>
       </CardContent>
     </Card>

     {/* Tabs */}
     <Tabs value={activeTab} onValueChange={setActiveTab}>
       <TabsList className="w-full justify-start overflow-x-auto">
         <TabsTrigger value="overview">Overview</TabsTrigger>
         <TabsTrigger value="rooms">Rooms</TabsTrigger>
         <TabsTrigger value="amenities">Amenities</TabsTrigger>
         <TabsTrigger value="location">Location</TabsTrigger>
         <TabsTrigger value="reviews">Reviews</TabsTrigger>
       </TabsList>

       {/* Overview Tab */}
       <TabsContent value="overview" className="mt-4">
         <Card>
           <CardContent className="p-6">
             <h3 className="text-xl font-semibold mb-4">About {hotel.name}</h3>
             <p className="text-gray-600 mb-6">{hotel.description}</p>
             
             <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
               <div className="space-y-4">
                 <h4 className="font-medium">Check-in/out</h4>
                 <div className="grid grid-cols-2 gap-4">
                   <div className="flex items-center gap-2">
                     <Clock className="w-4 h-4 text-gray-400" />
                     <div>
                       <div className="text-sm font-medium">Check-in</div>
                       <div className="text-sm text-gray-600">
                         {hotel.details?.checkIn || '15:00'}
                       </div>
                     </div>
                   </div>
                   <div className="flex items-center gap-2">
                     <Clock className="w-4 h-4 text-gray-400" />
                     <div>
                       <div className="text-sm font-medium">Check-out</div>
                       <div className="text-sm text-gray-600">
                         {hotel.details?.checkOut || '12:00'}
                       </div>
                     </div>
                   </div>
                 </div>
               </div>

               <Suspense fallback={<Skeleton className="h-[200px]" />}>
                 <PriceHistoryChart
                   data={hotel.priceHistory}
                   currentPrice={hotel.price?.current?.amount}
                 />
               </Suspense>
             </div>
           </CardContent>
         </Card>
       </TabsContent>

       {/* Rooms Tab */}
       <TabsContent value="rooms" className="mt-4">
         <Card>
           <CardContent className="p-6">
             <div className="space-y-6">
               {hotel.details?.roomTypes?.map((room, index) => (
                 <RoomCard key={index} room={room} currency={hotel.price?.current?.currency} />
               ))}
               {(!hotel.details?.roomTypes || hotel.details.roomTypes.length === 0) && (
                 <EmptyState message="No rooms information available" />
               )}
             </div>
           </CardContent>
         </Card>
       </TabsContent>

       {/* Amenities Tab */}
       <TabsContent value="amenities" className="mt-4">
         <Card>
           <CardContent className="p-6">
             <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
               {Object.entries(hotel.features || {}).map(([key, value]) => {
                 if (!value) return null;
                 return (
                   <div key={key} className="flex items-center gap-3">
                     <div className="w-10 h-10 rounded-full bg-brand-blue/10 flex items-center justify-center">
                       <Info className="w-5 h-5 text-brand-blue" />
                     </div>
                     <span className="capitalize">
                       {key.replace(/([A-Z])/g, ' $1').trim()}
                     </span>
                   </div>
                 );
               })}
               {Object.values(hotel.features || {}).every(v => !v) && (
                 <EmptyState message="No amenities information available" />
               )}
             </div>
           </CardContent>
         </Card>
       </TabsContent>

       {/* Location Tab */}
       <TabsContent value="location" className="mt-4">
         <Card>
           <CardContent className="p-6">
             <Suspense fallback={<Skeleton className="aspect-video rounded-lg" />}>
               <GoogleMap 
                 location={hotel.location?.coordinates}
                 className="aspect-video rounded-lg mb-6"
               />
             </Suspense>
             <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
               <LocationDetails location={hotel.location} />
             </div>
           </CardContent>
         </Card>
       </TabsContent>

       {/* Reviews Tab */}
       <TabsContent value="reviews" className="mt-4">
         <Suspense fallback={<Skeleton className="h-[400px]" />}>
           <HotelReviews hotelId={hotel.id} />
         </Suspense>
       </TabsContent>
     </Tabs>

     {/* Similar Hotels */}
     <div className="space-y-4">
       <h2 className="text-xl font-semibold">Similar Hotels Nearby</h2>
       <Suspense fallback={<Skeleton className="h-[300px]" />}>
         <SimilarHotels 
           currentHotel={hotel}
           location={hotel.location}
           limit={3}
           className="grid grid-cols-1 md:grid-cols-3 gap-6"
         />
       </Suspense>
     </div>
   </div>
 );
};

// Вспомогательные компоненты
const RoomCard = ({ room, currency }) => (
 <div className="flex flex-col md:flex-row justify-between items-start md:items-center p-4 bg-gray-50 rounded-lg">
   <div className="mb-4 md:mb-0">
     <h4 className="font-medium">{room.name}</h4>
     <div className="text-sm text-gray-600">
       {room.size} • {room.beds}
     </div>
     <div className="mt-2 flex flex-wrap gap-2">
       {['WiFi', 'TV', 'Air conditioning'].map((amenity) => (
         <span key={amenity} className="text-xs bg-white px-2 py-1 rounded">
           {amenity}
         </span>
       ))}
     </div>
   </div>
   <div className="text-right">
     <div className="font-bold text-lg text-brand-blue mb-2">
       {`${currency || 'EUR'} ${room.price || 'N/A'}`}
     </div>
     <Button variant="outline" size="sm">Select</Button>
   </div>
 </div>
);

const LocationDetails = ({ location }) => (
 <>
   {Object.entries(location?.nearby || {}).map(([key, value]) => (
     <div key={key} className="flex items-center gap-2">
       <MapPin className="w-4 h-4 text-gray-400" />
       <div>
         <div className="text-sm font-medium capitalize">
           {key.replace(/([A-Z])/g, ' $1').trim()}
         </div>
         <div className="text-sm text-gray-600">{value || 'N/A'}</div>
       </div>
     </div>
   ))}
   {Object.keys(location?.nearby || {}).length === 0 && (
     <EmptyState message="No nearby locations information available" />
   )}
 </>
);

const EmptyState = ({ message }) => (
 <div className="col-span-full text-center text-gray-500 py-8">
   {message}
 </div>
);

// PropTypes
MainContent.propTypes = {
 hotel: PropTypes.shape({
   id: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   description: PropTypes.string,
   images: PropTypes.arrayOf(PropTypes.string),
   details: PropTypes.shape({
     checkIn: PropTypes.string,
     checkOut: PropTypes.string,
     roomTypes: PropTypes.arrayOf(PropTypes.shape({
       name: PropTypes.string,
       size: PropTypes.string,
       beds: PropTypes.string,
       price: PropTypes.number,
     })),
   }),
   features: PropTypes.object,
   location: PropTypes.shape({
     coordinates: PropTypes.shape({
       latitude: PropTypes.number,
       longitude: PropTypes.number,
     }),
     nearby: PropTypes.object,
   }),
   price: PropTypes.shape({
     current: PropTypes.shape({
       amount: PropTypes.number,
       currency: PropTypes.string,
     }),
   }),
   priceHistory: PropTypes.array,
 }).isRequired,
};

export default MainContent;