// src/components/hotels/detail/AmenitiesTab.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '../../ui/card';
import {
 Wifi,
 Car,
 Dumbbell,
 Utensils,
 Coffee,
 Bath,
 Tv,
 Wind,
 Snowflake,
 BadgeCheck,
 Building2,
 Baby,
 Briefcase,
 Dog,
 Waves
} from 'lucide-react';

// Маппинг иконок для разных удобств
const AMENITY_ICONS = {
 wifi: Wifi,
 parking: Car,
 pool: Waves,
 gym: Dumbbell,
 restaurant: Utensils,
 roomService: Coffee,
 spa: Bath,
 tv: Tv,
 laundry: Wind,
 airConditioning: Snowflake,
 businessCenter: Briefcase,
 familyFriendly: Baby,
 petFriendly: Dog,
 beachAccess: Waves,
 premiumAmenities: BadgeCheck,
 conferenceRoom: Building2
};

// Группы удобств
const AMENITY_GROUPS = {
 essential: {
   title: 'Essential Amenities',
   items: ['wifi', 'parking', 'airConditioning', 'tv']
 },
 recreation: {
   title: 'Recreation & Wellness',
   items: ['pool', 'gym', 'spa', 'beachAccess']
 },
 dining: {
   title: 'Food & Dining',
   items: ['restaurant', 'roomService']
 },
 services: {
   title: 'Services',
   items: ['laundry', 'businessCenter']
 },
 special: {
   title: 'Special Features',
   items: ['familyFriendly', 'petFriendly', 'premiumAmenities', 'conferenceRoom']
 }
};

const AmenityItem = ({ name, icon: Icon, isAvailable }) => {
 if (!isAvailable) return null;

 return (
   <div className="flex items-center gap-3 p-3 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors">
     <div className="w-10 h-10 rounded-full bg-brand-blue/10 flex items-center justify-center">
       <Icon className="w-5 h-5 text-brand-blue" />
     </div>
     <span className="capitalize">
       {name.replace(/([A-Z])/g, ' $1').trim()}
     </span>
   </div>
 );
};

const AmenityGroup = ({ title, amenities, features }) => {
 const hasAmenities = amenities.some(amenity => features[amenity]);

 if (!hasAmenities) return null;

 return (
   <div className="space-y-3">
     <h4 className="font-medium text-gray-900">{title}</h4>
     <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
       {amenities.map(amenityKey => (
         <AmenityItem
           key={amenityKey}
           name={amenityKey}
           icon={AMENITY_ICONS[amenityKey]}
           isAvailable={features[amenityKey]}
         />
       ))}
     </div>
   </div>
 );
};

const AmenitiesTab = ({ hotel }) => {
 const { features = {} } = hotel;
 
 // Проверяем, есть ли хоть одно доступное удобство
 const hasAnyAmenities = Object.values(features).some(value => value);

 if (!hasAnyAmenities) {
   return (
     <Card>
       <CardContent className="p-6">
         <div className="text-center text-gray-500 py-8">
           No amenities information available
         </div>
       </CardContent>
     </Card>
   );
 }

 return (
   <Card>
     <CardContent className="p-6">
       {/* Popular Amenities */}
       <div className="space-y-6">
         {Object.entries(AMENITY_GROUPS).map(([groupKey, group]) => (
           <AmenityGroup
             key={groupKey}
             title={group.title}
             amenities={group.items}
             features={features}
           />
         ))}
       </div>

       {/* Additional Amenities */}
       {hotel.details?.additionalAmenities && (
         <div className="mt-8">
           <h4 className="font-medium text-gray-900 mb-3">Additional Amenities</h4>
           <div className="grid grid-cols-2 md:grid-cols-3 gap-y-2 text-sm text-gray-600">
             {hotel.details.additionalAmenities.map((amenity, index) => (
               <div key={index} className="flex items-center gap-2">
                 <BadgeCheck className="w-4 h-4 text-brand-blue" />
                 <span>{amenity}</span>
               </div>
             ))}
           </div>
         </div>
       )}

       {/* Amenities Notes */}
       {hotel.details?.amenitiesNotes && (
         <div className="mt-6 p-4 bg-gray-50 rounded-lg text-sm text-gray-600">
           <p className="font-medium mb-2">Please Note:</p>
           <p>{hotel.details.amenitiesNotes}</p>
         </div>
       )}
     </CardContent>
   </Card>
 );
};

AmenityItem.propTypes = {
 name: PropTypes.string.isRequired,
 icon: PropTypes.elementType.isRequired,
 isAvailable: PropTypes.bool.isRequired
};

AmenityGroup.propTypes = {
 title: PropTypes.string.isRequired,
 amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
 features: PropTypes.object.isRequired
};

AmenitiesTab.propTypes = {
 hotel: PropTypes.shape({
   features: PropTypes.object,
   details: PropTypes.shape({
     additionalAmenities: PropTypes.arrayOf(PropTypes.string),
     amenitiesNotes: PropTypes.string
   })
 }).isRequired
};

export default AmenitiesTab;