// src/services/contact/email.js

import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { EMAIL_TEMPLATES } from '../../components/contact/constants';

const functions = getFunctions();

/**
 * Отправляет email используя Firebase Cloud Functions
 * @param {string} templateId - ID шаблона
 * @param {Object} data - Данные для шаблона
 * @returns {Promise<Object>} Результат отправки
 */
const sendEmail = async (templateId, data) => {
  try {
    const sendEmailFunction = httpsCallable(functions, 'sendEmail');
    const result = await sendEmailFunction({ templateId, data });
    return result.data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw new Error('Не удалось отправить email');
  }
};

/**
 * Подготавливает данные для шаблона письма
 * @param {string} templateId - ID шаблона
 * @param {Object} messageData - Данные сообщения
 * @param {Object} additionalData - Дополнительные данные
 * @returns {Object} Подготовленные данные
 */
const prepareEmailData = (templateId, messageData, additionalData = {}) => {
  const baseData = {
    timestamp: new Date().toLocaleString('ru-RU'),
    messageId: messageData.id,
    ...messageData,
    ...additionalData
  };

  switch (templateId) {
    case EMAIL_TEMPLATES.ADMIN_NOTIFICATION:
      return {
        ...baseData,
        subject: `Новое сообщение от ${messageData.name}`,
        adminDashboardUrl: `${window.location.origin}/admin/messages/${messageData.id}`
      };

    case EMAIL_TEMPLATES.USER_CONFIRMATION:
      return {
        ...baseData,
        subject: 'Ваше сообщение получено',
        supportEmail: 'support@example.com',
        trackingUrl: `${window.location.origin}/contact/status/${messageData.id}`
      };

    case EMAIL_TEMPLATES.SPAM_NOTIFICATION:
      return {
        ...baseData,
        subject: 'Сообщение помечено как спам',
        spamReason: additionalData.spamReason || 'Не указано',
        appealUrl: `${window.location.origin}/contact/appeal/${messageData.id}`
      };

    case EMAIL_TEMPLATES.STATUS_UPDATE:
      return {
        ...baseData,
        subject: `Обновление статуса вашего сообщения`,
        newStatus: additionalData.newStatus,
        statusDescription: additionalData.statusDescription,
        moderatorComment: additionalData.moderatorComment
      };

    default:
      throw new Error(`Неизвестный шаблон email: ${templateId}`);
  }
};

/**
 * Отправляет уведомление администратору о новом сообщении
 * @param {Object} messageData - Данные сообщения
 * @returns {Promise<void>}
 */
const sendAdminNotification = async (messageData) => {
  const emailData = prepareEmailData(EMAIL_TEMPLATES.ADMIN_NOTIFICATION, messageData);
  await sendEmail(EMAIL_TEMPLATES.ADMIN_NOTIFICATION, emailData);
};

/**
 * Отправляет подтверждение получения сообщения пользователю
 * @param {Object} messageData - Данные сообщения
 * @returns {Promise<void>}
 */
const sendUserConfirmation = async (messageData) => {
  const emailData = prepareEmailData(EMAIL_TEMPLATES.USER_CONFIRMATION, messageData);
  await sendEmail(EMAIL_TEMPLATES.USER_CONFIRMATION, emailData);
};

/**
 * Отправляет уведомление о том, что сообщение помечено как спам
 * @param {Object} messageData - Данные сообщения
 * @param {string} spamReason - Причина пометки как спам
 * @returns {Promise<void>}
 */
const sendSpamNotification = async (messageData, spamReason) => {
  const emailData = prepareEmailData(EMAIL_TEMPLATES.SPAM_NOTIFICATION, messageData, { spamReason });
  await sendEmail(EMAIL_TEMPLATES.SPAM_NOTIFICATION, emailData);
};

/**
 * Отправляет уведомление об изменении статуса сообщения
 * @param {Object} messageData - Данные сообщения
 * @param {Object} statusData - Данные о статусе
 * @returns {Promise<void>}
 */
const sendStatusUpdate = async (messageData, statusData) => {
  const emailData = prepareEmailData(EMAIL_TEMPLATES.STATUS_UPDATE, messageData, statusData);
  await sendEmail(EMAIL_TEMPLATES.STATUS_UPDATE, emailData);
};

/**
 * Отправляет все необходимые уведомления при создании нового сообщения
 * @param {Object} messageData - Данные сообщения
 * @returns {Promise<void>}
 */
const sendNewMessageNotifications = async (messageData) => {
  try {
    // Отправляем уведомления параллельно
    await Promise.all([
      sendAdminNotification(messageData),
      sendUserConfirmation(messageData)
    ]);
  } catch (error) {
    console.error('Error sending new message notifications:', error);
    throw new Error('Не удалось отправить уведомления');
  }
};

/**
 * Отправляет все необходимые уведомления при изменении статуса
 * @param {Object} messageData - Данные сообщения
 * @param {Object} statusData - Данные о статусе
 * @returns {Promise<void>}
 */
const sendStatusChangeNotifications = async (messageData, statusData) => {
  try {
    const notifications = [sendStatusUpdate(messageData, statusData)];

    // Добавляем специальные уведомления в зависимости от статуса
    if (statusData.newStatus === 'spam') {
      notifications.push(sendSpamNotification(messageData, statusData.spamReason));
    }

    await Promise.all(notifications);
  } catch (error) {
    console.error('Error sending status change notifications:', error);
    throw new Error('Не удалось отправить уведомления об изменении статуса');
  }
};

export {
  sendNewMessageNotifications,
  sendStatusChangeNotifications,
  sendAdminNotification,
  sendUserConfirmation,
  sendSpamNotification,
  sendStatusUpdate
};

/*
Для работы сервиса необходимо:

1. Настроить Firebase Cloud Functions:
   - Создать функцию sendEmail
   - Настроить Nodemailer или другой сервис отправки email
   - Создать HTML шаблоны для всех типов писем

2. Шаблоны должны быть размещены в:
   /src/email-templates/admin-notification.html
   /src/email-templates/user-confirmation.html
   /src/email-templates/spam-notification.html
   /src/email-templates/status-update.html

3. Пример использования:

import { sendNewMessageNotifications } from '../services/contact/email';

const handleSubmit = async (formData) => {
  // Сохраняем сообщение
  const messageData = await saveMessage(formData);
  
  // Отправляем уведомления
  await sendNewMessageNotifications(messageData);
};

4. Пример обработки изменения статуса:

const handleStatusChange = async (messageId, newStatus, comment) => {
  // Обновляем статус
  const messageData = await updateMessageStatus(messageId, newStatus);
  
  // Отправляем уведомления
  await sendStatusChangeNotifications(messageData, {
    newStatus,
    moderatorComment: comment,
    statusDescription: getStatusDescription(newStatus)
  });
};
*/