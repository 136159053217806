// src/pages/legal/PrivacyPolicyPage.jsx
import { PrivacyPolicy } from '../../components/legal/PrivacyPolicy';

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPolicyPage;