// src/components/admin/contact/MessageStats.jsx

import React, { useState, useEffect } from 'react';
import { 
  BarChart,
  PieChart,
  MessagesSquare,
  TrendingUp,
  Clock,
  RefreshCcw,
  AlertTriangle,
  CheckCircle,
  XCircle,
  Loader
} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getModerationStats } from '../../../services/contact/moderation';
import { getMessagesStats } from '../../../services/contact/firestore';
import { MESSAGE_STATUS } from '../../../components/contact/constants';

const STATUS_COLORS = {
  [MESSAGE_STATUS.NEW]: '#3B82F6', // blue
  [MESSAGE_STATUS.PENDING_REVIEW]: '#F59E0B', // yellow
  [MESSAGE_STATUS.APPROVED]: '#10B981', // green
  [MESSAGE_STATUS.REJECTED]: '#EF4444', // red
  [MESSAGE_STATUS.SPAM]: '#DC2626' // dark red
};

const STATUS_ICONS = {
  [MESSAGE_STATUS.NEW]: Clock,
  [MESSAGE_STATUS.PENDING_REVIEW]: AlertTriangle,
  [MESSAGE_STATUS.APPROVED]: CheckCircle,
  [MESSAGE_STATUS.REJECTED]: XCircle,
  [MESSAGE_STATUS.SPAM]: AlertTriangle
};

const STATUS_LABELS = {
  [MESSAGE_STATUS.NEW]: 'Новые',
  [MESSAGE_STATUS.PENDING_REVIEW]: 'На модерации',
  [MESSAGE_STATUS.APPROVED]: 'Одобренные',
  [MESSAGE_STATUS.REJECTED]: 'Отклоненные',
  [MESSAGE_STATUS.SPAM]: 'Спам'
};

const StatCard = ({ title, value, description, icon: Icon, color, loading }) => (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <div className="flex items-start justify-between">
      <div>
        <p className="text-sm font-medium text-gray-600">{title}</p>
        {loading ? (
          <div className="animate-pulse h-8 w-24 bg-gray-200 rounded mt-1"></div>
        ) : (
          <p className="mt-1 text-2xl font-semibold" style={{ color }}>
            {value}
          </p>
        )}
        {description && (
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        )}
      </div>
      {Icon && <Icon className="w-6 h-6" style={{ color }} />}
    </div>
  </div>
);

const MessageStats = () => {
  const [stats, setStats] = useState({
    total: 0,
    byStatus: {},
    averageResponseTime: 0,
    dailyStats: [],
    spamPercentage: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    loadStats();
  }, []);

  const loadStats = async () => {
    try {
      setLoading(true);
      setError(null);

      // Загружаем статистику из обоих сервисов
      const [messageStats, moderationStats] = await Promise.all([
        getMessagesStats(),
        getModerationStats()
      ]);

      // Объединяем статистику
      const combinedStats = {
        ...messageStats,
        ...moderationStats,
        spamPercentage: (moderationStats.spam / messageStats.total * 100).toFixed(1)
      };

      setStats(combinedStats);
      setLastUpdate(new Date());
    } catch (error) {
      console.error('Error loading stats:', error);
      setError('Не удалось загрузить статистику');
    } finally {
      setLoading(false);
    }
  };

  const formatDuration = (ms) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}ч ${minutes}м`;
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('ru-RU').format(num);
  };

  return (
    <div className="space-y-6">
      {/* Заголовок и кнопка обновления */}
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900 flex items-center">
          <BarChart className="w-5 h-5 mr-2" />
          Статистика сообщений
        </h2>
        <div className="flex items-center space-x-4">
          {lastUpdate && (
            <span className="text-sm text-gray-500">
              Обновлено: {lastUpdate.toLocaleTimeString()}
            </span>
          )}
          <button
            onClick={loadStats}
            disabled={loading}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <RefreshCcw className="w-4 h-4 mr-2" />
            Обновить
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      {/* Основные метрики */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Всего сообщений"
          value={formatNumber(stats.total)}
          icon={MessagesSquare}
          color="#3B82F6"
          loading={loading}
        />
        <StatCard
          title="Требуют модерации"
          value={formatNumber(stats.byStatus[MESSAGE_STATUS.PENDING_REVIEW] || 0)}
          description="Ожидают проверки"
          icon={AlertTriangle}
          color="#F59E0B"
          loading={loading}
        />
        <StatCard
          title="Среднее время ответа"
          value={formatDuration(stats.averageResponseTime)}
          description="От создания до модерации"
          icon={Clock}
          color="#8B5CF6"
          loading={loading}
        />
        <StatCard
          title="Спам"
          value={`${stats.spamPercentage}%`}
          description={`${formatNumber(stats.byStatus[MESSAGE_STATUS.SPAM] || 0)} сообщений`}
          icon={AlertTriangle}
          color="#DC2626"
          loading={loading}
        />
      </div>

      {/* График и статистика по статусам */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* График трендов */}
        <div className="lg:col-span-2">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-sm font-medium text-gray-900 mb-4 flex items-center">
              <TrendingUp className="w-4 h-4 mr-2" />
              Тренды сообщений
            </h3>
            <div className="h-80">
              {loading ? (
                <div className="flex items-center justify-center h-full">
                  <Loader className="w-6 h-6 text-blue-500 animate-spin" />
                </div>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={stats.dailyStats}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="total" stroke="#3B82F6" name="Всего" />
                    <Line type="monotone" dataKey="approved" stroke="#10B981" name="Одобрено" />
                    <Line type="monotone" dataKey="spam" stroke="#DC2626" name="Спам" />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>

        {/* Распределение по статусам */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-sm font-medium text-gray-900 mb-4 flex items-center">
            <PieChart className="w-4 h-4 mr-2" />
            По статусам
          </h3>
          {loading ? (
            <div className="animate-pulse space-y-4">
              {[1, 2, 3, 4, 5].map((i) => (
                <div key={i} className="h-8 bg-gray-200 rounded"></div>
              ))}
            </div>
          ) : (
            <div className="space-y-4">
              {Object.entries(STATUS_LABELS).map(([status, label]) => {
                const count = stats.byStatus[status] || 0;
                const percentage = stats.total ? ((count / stats.total) * 100).toFixed(1) : 0;
                const Icon = STATUS_ICONS[status];
                
                return (
                  <div key={status} className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Icon className="w-4 h-4 mr-2" style={{ color: STATUS_COLORS[status] }} />
                      <span className="text-sm text-gray-600">{label}</span>
                    </div>
                    <div className="text-sm font-medium">
                      {formatNumber(count)}
                      <span className="text-gray-500 ml-1">({percentage}%)</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageStats;