import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { 
  User, 
  Settings, 
  History, 
  Heart, 
  Edit2, 
  Camera
} from 'lucide-react';
import ProfileInfo from '../../components/profile/ProfileInfo';
import ProfilePreferences from '../../components/profile/ProfilePreferences';
import ProfileHistory from '../../components/profile/ProfileHistory';
import ProfileFavorites from '../../components/profile/ProfileFavorites';

const TABS = {
  INFO: 'info',
  PREFERENCES: 'preferences',
  HISTORY: 'history',
  FAVORITES: 'favorites'
};

const ProfilePage = () => {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState(TABS.INFO);
  const [isEditMode, setIsEditMode] = useState(false);

  const tabComponents = {
    [TABS.INFO]: <ProfileInfo isEditMode={isEditMode} setIsEditMode={setIsEditMode} />,
    [TABS.PREFERENCES]: <ProfilePreferences />,
    [TABS.HISTORY]: <ProfileHistory />,
    [TABS.FAVORITES]: <ProfileFavorites />
  };

  const tabs = [
    { id: TABS.INFO, label: 'Personal Info', icon: User },
    { id: TABS.PREFERENCES, label: 'Preferences', icon: Settings },
    { id: TABS.HISTORY, label: 'Search History', icon: History },
    { id: TABS.FAVORITES, label: 'Favorites', icon: Heart }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Profile Header */}
        <div className="bg-white rounded-lg shadow overflow-hidden mb-8">
          <div className="relative h-32 bg-brand-blue">
            {/* Cover Photo Edit Button */}
            <button className="absolute top-4 right-4 bg-white/10 hover:bg-white/20 text-white p-2 rounded-full transition-colors">
              <Camera className="w-5 h-5" />
            </button>
          </div>
          
          <div className="relative px-4 py-5 sm:px-6">
            {/* Avatar */}
            <div className="absolute -top-16 left-4">
              <div className="relative">
                <div className="w-32 h-32 rounded-full bg-white p-1">
                  <div className="w-full h-full rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
                    {currentUser?.photoURL ? (
                      <img 
                        src={currentUser.photoURL} 
                        alt={currentUser.displayName} 
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <User className="w-12 h-12 text-gray-400" />
                    )}
                  </div>
                </div>
                {/* Avatar Edit Button */}
                <button className="absolute bottom-0 right-0 bg-white rounded-full p-2 shadow-lg hover:bg-gray-50 transition-colors">
                  <Edit2 className="w-4 h-4 text-gray-600" />
                </button>
              </div>
            </div>

            {/* User Info */}
            <div className="ml-40 flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {currentUser?.displayName || 'User'}
                </h1>
                <p className="text-sm text-gray-500">
                  {currentUser?.email}
                </p>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <div className="border-t border-gray-200">
            <nav className="flex overflow-x-auto">
              {tabs.map(({ id, label, icon: Icon }) => (
                <button
                  key={id}
                  onClick={() => setActiveTab(id)}
                  className={`
                    flex-1 px-4 py-4 text-sm font-medium text-center 
                    ${activeTab === id 
                      ? 'text-brand-blue border-b-2 border-brand-blue' 
                      : 'text-gray-500 hover:text-gray-700 hover:border-b-2 hover:border-gray-300'
                    }
                    transition-colors duration-200
                  `}
                >
                  <div className="flex items-center justify-center gap-2">
                    <Icon className="w-5 h-5" />
                    <span>{label}</span>
                  </div>
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Tab Content */}
        <div className="bg-white rounded-lg shadow p-6">
          {tabComponents[activeTab]}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;