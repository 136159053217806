// src/services/api/hotels/HotelService.js

import amadeusCache from '../amadeus/cache';
import amadeusApi from '../amadeus';
import { calculateHotelScore, filterHotels } from './methods/scoringMethods';
import { processResults } from './utils/resultProcessor';
import { extractCityCode } from './utils/cityUtils';
import hotelImageService from '../hotelImageService';
import { AMADEUS_API } from '../../../config/apiConstants';

class HotelService {
  constructor() {
    if (HotelService._instance) return HotelService._instance;
    this.initialize();
    HotelService._instance = this;
    return this;
  }

  initialize() {
    try {
      this.cache = amadeusCache;
      this.amadeus = amadeusApi;
    } catch (error) {
      console.error('Error initializing HotelService:', error);
      this.initializeEmptyCache();
    }
  }

  initializeEmptyCache() {
    this.cache = {
      get: () => null,
      set: () => {},
      clear: () => {},
      size: 0,
      keys: [],
      durations: {}
    };
  }

  async searchHotels(params) {
    const {
      destination,
      checkIn,
      checkOut,
      guests = AMADEUS_API.DEFAULTS.ADULTS,
      rooms = AMADEUS_API.DEFAULTS.ROOMS,
      preferences,
      filters = {}
    } = params;

    try {
      const cityCode = await this.extractCityCode(destination);
      const cacheKey = this.generateCacheKey({ cityCode, checkIn, checkOut, guests, rooms, filters });
      
      const cached = this.cache.get(cacheKey, 'search');
      if (cached) return this.processResults(cached, preferences, filters);

      const response = await this.amadeus.searchHotels({
        cityCode,
        checkInDate: checkIn,
        checkOutDate: checkOut,
        adults: guests,
        rooms,
        currency: filters.currency || AMADEUS_API.DEFAULTS.CURRENCY,
        ratings: filters.ratings || [],
        amenities: filters.amenities || [],
        priceRange: filters.priceRange,
        radius: filters.radius || AMADEUS_API.DEFAULTS.RADIUS
      });

      const hotels = response?.data ? 
        (Array.isArray(response.data) ? response.data : [response.data]) : 
        [];

      const enrichedHotels = await Promise.all(
        hotels.map(async (item) => {
          try {
            const hotel = item.hotel || item;
            const offers = item.offers || [];
            const bestOffer = offers[0] || {};

            const images = await hotelImageService.getHotelImages({
              name: hotel.name,
              location: {
                address: hotel.address,
                coordinates: {
                  latitude: hotel.latitude,
                  longitude: hotel.longitude
                }
              }
            });

            return {
              id: hotel.hotelId || hotel.id,
              name: hotel.name || 'Unknown Hotel',
              description: hotel.description?.text || '',
              rating: {
                stars: parseFloat(hotel.rating) || 0,
                reviews: hotel.numberOfReviews || 0,
                score: hotel.rating?.score
              },
              location: {
                address: hotel.address || {},
                coordinates: {
                  latitude: hotel.latitude,
                  longitude: hotel.longitude
                },
                city: cityCode,
                country: hotel.address?.countryCode
              },
              price: {
                current: {
                  amount: parseFloat(bestOffer.price?.total) || 0,
                  currency: bestOffer.price?.currency || 'EUR'
                },
                original: bestOffer.price?.base && {
                  amount: parseFloat(bestOffer.price.base),
                  currency: bestOffer.price.currency
                }
              },
              images: images.length > 0 ? images : ["/api/placeholder/400/300"],
              amenities: hotel.amenities || [],
              features: this.convertFeatures(hotel.amenities || []),
              offers,
              available: true
            };
          } catch (error) {
            console.error('Error processing hotel:', error);
            return null;
          }
        })
      );

      const validHotels = enrichedHotels
        .filter(Boolean)
        .filter(hotel => hotel.name && hotel.price.current.amount > 0);

      this.cache.set(cacheKey, validHotels, 'search');
      return this.processResults(validHotels, preferences, filters);

    } catch (error) {
      console.error('Error in searchHotels:', error);
      throw error;
    }
  }

  async getHotelDetails(hotelId, params = {}) {
    try {
      const cacheKey = `hotel_${hotelId}_${JSON.stringify(params)}`;
      const cached = this.cache.get(cacheKey, 'details');
      if (cached) return cached;
  
      const searchParams = new URLSearchParams({
        hotelIds: hotelId,
        adults: String(params.adults || 2),
        roomQuantity: String(params.rooms || 1),
        currency: params.currency || 'EUR',
        bestRateOnly: 'false',
        view: 'FULL'
      });
  
      if (params.checkInDate && params.checkOutDate) {
        searchParams.append('checkInDate', this.formatDate(params.checkInDate));
        searchParams.append('checkOutDate', this.formatDate(params.checkOutDate));
      }
  
      const response = await this.amadeus.makeRequest(`/v3/shopping/hotel-offers?${searchParams}`);
      if (!response?.data?.[0]) {
        throw new Error('Hotel not found');
      }
  
      const hotelData = response.data[0];
      const hotel = hotelData.hotel;
      const offers = hotelData.offers || [];
      const bestOffer = offers[0] || {};
  
      const images = await hotelImageService.getHotelImages({
        name: hotel.name,
        location: {
          address: hotel.address,
          coordinates: {
            latitude: hotel.latitude,
            longitude: hotel.longitude
          }
        }
      });
      
      const generateUniqueId = (prefix, index) => `${prefix}_${hotelId}_${index}_${Date.now()}`;

      const createRoomTypes = () => {
        return offers.map((offer, idx) => ({
          id: generateUniqueId('room', idx),
          roomId: generateUniqueId('select', idx), 
          name: offer.room?.type || 'Standard Room',
          description: offer.room?.description?.text || '',
          amenities: offer.room?.amenities || [],
          price: parseFloat(offer.price?.total) || 0,
          currency: offer.price?.currency || 'EUR',
          capacity: {
            adults: parseInt(offer.guests?.adults) || 2,
            children: parseInt(offer.guests?.children) || 0
          }
        })).filter(room => room.price > 0);
      };
  
      const enrichedData = {
        id: hotelId,
        name: hotel.name,
        description: hotel.description?.text || '',
        rating: {
          stars: parseFloat(hotel.rating) || 0,
          reviews: hotel.numberOfReviews || 0,
          score: hotel.rating?.score
        },
        location: {
          address: hotel.address || {},
          coordinates: {
            latitude: hotel.latitude,
            longitude: hotel.longitude
          },
          city: hotel.cityCode,
          country: hotel.countryCode
        },
        price: {
          current: {
            amount: parseFloat(bestOffer.price?.total) || 0,
            currency: bestOffer.price?.currency || 'EUR'
          },
          original: bestOffer.price?.base && {
            amount: parseFloat(bestOffer.price.base),
            currency: bestOffer.price.currency
          }
        },
        details: {
          checkIn: hotel.checkInTime || '15:00',
          checkOut: hotel.checkOutTime || '12:00',
          roomTypes: createRoomTypes(),
          policies: {
            cancellation: bestOffer.policies?.cancellation?.description || 'Contact hotel for cancellation policy',
            checkIn: hotel.checkInPolicy || 'Standard check-in time',
            checkOut: hotel.checkOutPolicy || 'Standard check-out time',
            pets: hotel.petPolicy || 'Contact hotel for pet policy',
            children: hotel.childrenPolicy || 'Contact hotel for children policy'
          }
        },
        images: images.length > 0 ? images : ["/api/placeholder/400/300"],
        amenities: hotel.amenities || [],
        features: this.convertFeatures(hotel.amenities || []),
        offers,
        available: true
      };
  
      this.cache.set(cacheKey, enrichedData, 'details');
      return enrichedData;
  
    } catch (error) {
      console.error('Error in getHotelDetails:', error);
      throw error;
    }
  }

  formatDate(date) {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  }

  async getHotelOffers(hotelId, params = {}) {
    try {
      const cacheKey = `offers_${hotelId}_${JSON.stringify(params)}`;
      const cached = this.cache.get(cacheKey, 'offers');
      if (cached) return cached;

      const response = await this.amadeus.getHotelOffers(hotelId, params);
      this.cache.set(cacheKey, response, 'offers');
      return response;
    } catch (error) {
      console.error('Error in getHotelOffers:', error);
      throw error;
    }
  }

  convertFeatures(amenities = []) {
    const amenityMap = {
      WIFI: 'wifi',
      INTERNET: 'wifi',
      PARKING: 'parking',
      POOL: 'pool',
      SWIMMING_POOL: 'pool',
      SPA: 'spa',
      FITNESS_CENTER: 'gym',
      FITNESS: 'gym',
      RESTAURANT: 'restaurant',
      ROOM_SERVICE: 'roomService',
      BAR: 'bar',
      BUSINESS_CENTER: 'businessCenter',
      MEETING_ROOMS: 'conferenceRoom',
      PETS_ALLOWED: 'petFriendly',
      KIDS_CLUB: 'familyFriendly',
      CHILDREN_ACTIVITIES: 'familyFriendly',
      BEACH_ACCESS: 'beach',
      AIRPORT_TRANSFER: 'airportTransfer',
      CAR_RENTAL: 'carRental',
      BICYCLE_RENTAL: 'bikeRental',
      LAUNDRY: 'laundry',
      CONCIERGE: 'concierge',
      '24HOUR_FRONT_DESK': 'frontdesk24h'
    };

    const features = {};
    amenities.forEach(amenity => {
      const feature = amenityMap[amenity];
      if (feature) features[feature] = true;
    });
    return features;
  }

  calculateHotelScore(hotel, preferences) {
    try {
      return calculateHotelScore(hotel, preferences);
    } catch (error) {
      console.error('Error in calculateHotelScore:', error);
      return 0;
    }
  }

  filterHotels(hotels, filters) {
    try {
      return filterHotels(hotels, filters);
    } catch (error) {
      console.error('Error in filterHotels:', error);
      return hotels;
    }
  }

  async processResults(hotels, preferences, filters) {
    try {
      return await processResults(hotels, preferences, filters);
    } catch (error) {
      console.error('Error in processResults:', error);
      return { 
        hotels: hotels || [], 
        totalCount: hotels?.length || 0,
        filteredCount: hotels?.length || 0
      };
    }
  }

  extractCityCode(input) {
    try {
      return extractCityCode(input);
    } catch (error) {
      console.error('Error in extractCityCode:', error);
      throw error;
    }
  }

  generateCacheKey(params) {
    return JSON.stringify({
      ...params,
      checkIn: params.checkIn ? new Date(params.checkIn).toISOString().split('T')[0] : null,
      checkOut: params.checkOut ? new Date(params.checkOut).toISOString().split('T')[0] : null
    });
  }

  clearCache() {
    if (this.cache?.clear) {
      this.cache.clear();
      console.log('Hotel service cache cleared');
    }
  }

  getServiceStats() {
    return {
      cacheSize: this.cache?.size || 0,
      cacheKeys: this.cache?.keys || [],
      cacheDurations: this.cache?.durations || {}
    };
  }
}

const hotelService = new HotelService();
Object.freeze(hotelService);

export { HotelService };
export default hotelService;