// src/pages/legal/TermsOfServicePage.jsx
import { TermsOfService } from '../../components/legal/TermsOfService';

const TermsOfServicePage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <TermsOfService />
    </div>
  );
};

export default TermsOfServicePage;