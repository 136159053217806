// src/components/legal/PrivacyPolicy.jsx
export const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Privacy Policy for HotelsRating.com</h1>
      <div className="text-sm text-gray-600 mb-8">
        Effective Date: 25/11/2024
      </div>
      
      <div className="prose prose-blue max-w-none space-y-6">
        <p>
          At HotelsRating.com, your privacy is our priority. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our website and services. By accessing or using HotelsRating.com, you agree to this Privacy Policy. If you do not agree, please do not use our website.
        </p>

        <section>
          <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
          
          <h3 className="text-xl font-medium mb-3">A. Personal Information You Provide</h3>
          <ul className="list-disc pl-6 mb-4">
            <li>Account Information: Name, email address, phone number, username, password.</li>
            <li>Payment Information: Billing address, credit card details.</li>
            <li>Profile Information: Preferences, interests, and demographic details.</li>
            <li>Content You Provide: Reviews, comments, and other content.</li>
          </ul>

          <h3 className="text-xl font-medium mb-3">B. Information We Collect Automatically</h3>
          <ul className="list-disc pl-6 mb-4">
            <li>Device and Usage Data: IP address, browser type, operating system, referring URLs.</li>
            <li>Location Data: Approximate geolocation based on IP address.</li>
          </ul>

          <h3 className="text-xl font-medium mb-3">C. Information from Third Parties</h3>
          <ul className="list-disc pl-6 mb-4">
            <li>Social Media Platforms: Data from platforms like Google or Facebook.</li>
            <li>Partners and Affiliates: Data provided by business partners.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
          <ul className="list-disc pl-6">
            <li>To Provide Services: Enabling hotel searches and recommendations.</li>
            <li>Customer Support: Responding to inquiries and providing assistance.</li>
            <li>Personalization: Tailoring content and recommendations.</li>
            <li>Marketing and Communications: Sending promotional materials.</li>
            <li>Legal Compliance: Ensuring compliance with legal obligations.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">3. Sharing Your Information</h2>
          <p>We may share your information with:</p>
          <ul className="list-disc pl-6">
            <li>Service Providers: Third-party vendors for various services.</li>
            <li>Business Partners: Hotels and affiliates for booking services.</li>
            <li>Legal and Regulatory Authorities: When required by law.</li>
            <li>Corporate Transactions: In case of merger or acquisition.</li>
          </ul>
          <p className="mt-4">We do not sell your personal information.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. Your Rights and Choices</h2>
          
          <h3 className="text-xl font-medium mb-3">Under GDPR (EU Residents):</h3>
          <ul className="list-disc pl-6 mb-4">
            <li>Access: Request a copy of your personal data.</li>
            <li>Correction: Update inaccurate information.</li>
            <li>Erasure: Request deletion of your data.</li>
            <li>Restriction: Limit processing of your data.</li>
            <li>Portability: Receive your data in a portable format.</li>
            <li>Objection: Opt-out of direct marketing.</li>
          </ul>

          <h3 className="text-xl font-medium mb-3">Under CCPA (California Residents):</h3>
          <ul className="list-disc pl-6">
            <li>Right to Know: Access details about collected information.</li>
            <li>Right to Delete: Request deletion of personal information.</li>
            <li>Right to Opt-Out: Decline the sale of personal data.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
          <p>We implement industry-standard measures to protect your data:</p>
          <ul className="list-disc pl-6">
            <li>Encryption during data transmission.</li>
            <li>Secure servers and firewalls.</li>
            <li>Regular security assessments.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">6. Data Retention</h2>
          <p>We retain your data only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Upon request, we will delete your personal data unless needed for legal compliance.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">7. Cookies and Tracking Technologies</h2>
          <p>We use cookies to enhance your experience:</p>
          <ul className="list-disc pl-6">
            <li>Essential Cookies: Required for website functionality.</li>
            <li>Performance Cookies: Analyze website usage.</li>
            <li>Targeting Cookies: Deliver personalized advertisements.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">8. International Data Transfers</h2>
          <p>If you are located outside the United Arab Emirates, your data may be transferred to and processed in the UAE or other countries. We take appropriate safeguards to ensure your information is treated securely.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">9. Children's Privacy</h2>
          <p>HotelsRating.com is not intended for individuals under 16 years of age. We do not knowingly collect personal information from children. If we learn that a child's information has been collected, we will delete it promptly.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">10. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. Changes will be effective upon posting to this page, with the "Effective Date" updated. We encourage you to review this page periodically.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">11. Contact Us</h2>
          <p>If you have questions or concerns about this Privacy Policy or how your data is handled, please contact us at:</p>
          <div className="mt-4">
            <p>HotelsRating.com</p>
            <p>Email: dataprotection@hotelsrating.com</p>
          </div>
        </section>
      </div>
    </div>
  );
};