// src/config/paths.js

const ACCESS_LEVELS = {
  PUBLIC: 'public',
  AUTHENTICATED: 'authenticated',
  ADMIN: 'admin',
  MODERATOR: 'moderator'
};

// Images paths
export const IMAGES = {
  LOGO: '/images/logo.svg',
  LOGO_DARK: '/images/logo-dark.svg',
  DEFAULT_AVATAR: '/images/default-avatar.png',
  HOTEL_PLACEHOLDER: '/images/hotel-placeholder.jpg',
  EMPTY_STATE: '/images/empty-state.svg',
  ERROR_404: '/images/404.svg',
  LOADING: '/images/loading.svg',
  BACKGROUNDS: {
    AUTH: '/images/backgrounds/auth.jpg',
    HERO: '/images/backgrounds/hero.jpg'
  },
  ICONS: {
    GOOGLE: '/images/icons/google.svg',
    FACEBOOK: '/images/icons/facebook.svg',
    GITHUB: '/images/icons/github.svg'
  }
};

export const ROUTES = {
  // Общие маршруты
  HOME: '/',
  SEARCH: '/search',
  HOTEL_DETAIL: '/hotels/:id',

  // Бронирование
  BOOKING: '/booking',
  BOOKING_CONFIRMATION: '/booking/confirmation/:bookingId',
  BOOKING_PAYMENT: '/booking/payment/:bookingId',
  BOOKING_HISTORY: '/profile/bookings',
  BOOKING_DETAILS: '/profile/bookings/:bookingId',
  BOOKING_CANCEL: '/profile/bookings/:bookingId/cancel',
  ADMIN_BOOKINGS: '/admin/bookings',
  ADMIN_BOOKING_DETAILS: '/admin/bookings/:bookingId',
  
  // Аутентификация
  LOGIN: '/login',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password',
  
  // Профиль и пользовательские функции
  PROFILE: '/profile',
  RATING: '/rating',
  REVIEWS: '/reviews',
  MAP: '/map',

  // Информационные страницы
  HELP: '/help',
  CONTACT: '/contact',
  FOR_TRAVELERS: '/for-travelers',
  FOR_HOTELS: '/for-hotels',
  FOR_BLOGGERS: '/for-bloggers',
  FOR_INVESTORS: '/for-investors',

  // Правовые страницы
  PRIVACY: '/privacy',
  TERMS: '/terms',
  COOKIES: '/cookies',
  DISCLAIMER: '/disclaimer',
  AFFILIATE_DISCLOSURE: '/affiliate-disclosure',
  COMMENT_POLICY: '/comment-policy',
  REFUND_POLICY: '/refund-policy',
  BETA_TERMS: '/beta-terms',

  // Тестовые маршруты
  TEST_FIREBASE: '/test/firebase',
  TEST_AMADEUS: '/test/amadeus',

  // Административные маршруты
  ADMIN_SETUP: '/admin/setup',
  ADMIN_CONTACT: '/admin/contact',
  ADMIN_CONTACT_MESSAGES: '/admin/contact/messages',
  ADMIN_CONTACT_MESSAGE_DETAILS: '/admin/contact/messages/:id',
  ADMIN_CONTACT_SPAM_SETTINGS: '/admin/contact/spam-settings',

  // Вспомогательные функции для динамических маршрутов
  getHotelPath: (id) => `/hotels/${id}`,
  getMessageDetailsPath: (id) => `/admin/contact/messages/${id}`,
  getBookingPath: (bookingId) => `/booking/confirmation/${bookingId}`,
  getBookingDetailsPath: (bookingId) => `/profile/bookings/${bookingId}`,
  getAdminBookingPath: (bookingId) => `/admin/bookings/${bookingId}`
};

// Сгруппированные административные маршруты
export const ADMIN_ROUTES = {
  CONTACT: {
    ROOT: '/admin/contact',
    MESSAGES: '/admin/contact/messages',
    MESSAGE_DETAILS: '/admin/contact/messages/:id',
    SPAM_SETTINGS: '/admin/contact/spam-settings',
  },
  HOTELS: {
    ROOT: '/admin/hotels',
    LIST: '/admin/hotels/list',
    CREATE: '/admin/hotels/create',
    EDIT: '/admin/hotels/edit/:id',
  },
  USERS: {
    ROOT: '/admin/users',
    LIST: '/admin/users/list',
    EDIT: '/admin/users/edit/:id',
  },
  BOOKINGS: {
    ROOT: '/admin/bookings',
    LIST: '/admin/bookings/list',
    DETAILS: '/admin/bookings/:bookingId',
    EDIT: '/admin/bookings/edit/:bookingId',
  }
};

// Конфигурация доступа к маршрутам
export const ROUTE_ACCESS = {
  // Публичные маршруты
  [ROUTES.HOME]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.SEARCH]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.HOTEL_DETAIL]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.LOGIN]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.REGISTER]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.RESET_PASSWORD]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.HELP]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.CONTACT]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.FOR_TRAVELERS]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.FOR_HOTELS]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.FOR_BLOGGERS]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.FOR_INVESTORS]: ACCESS_LEVELS.PUBLIC,
  
  // Правовые страницы (публичные)
  [ROUTES.PRIVACY]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.TERMS]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.COOKIES]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.DISCLAIMER]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.AFFILIATE_DISCLOSURE]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.COMMENT_POLICY]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.REFUND_POLICY]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.BETA_TERMS]: ACCESS_LEVELS.PUBLIC,

  // Маршруты для аутентифицированных пользователей
  [ROUTES.PROFILE]: ACCESS_LEVELS.AUTHENTICATED,
  [ROUTES.RATING]: ACCESS_LEVELS.AUTHENTICATED,
  [ROUTES.REVIEWS]: ACCESS_LEVELS.AUTHENTICATED,
  [ROUTES.MAP]: ACCESS_LEVELS.AUTHENTICATED,
  
  // Маршруты бронирования
  [ROUTES.BOOKING]: ACCESS_LEVELS.AUTHENTICATED,
  [ROUTES.BOOKING_CONFIRMATION]: ACCESS_LEVELS.AUTHENTICATED,
  [ROUTES.BOOKING_PAYMENT]: ACCESS_LEVELS.AUTHENTICATED,
  [ROUTES.BOOKING_HISTORY]: ACCESS_LEVELS.AUTHENTICATED,
  [ROUTES.BOOKING_DETAILS]: ACCESS_LEVELS.AUTHENTICATED,
  [ROUTES.BOOKING_CANCEL]: ACCESS_LEVELS.AUTHENTICATED,

  // Административные маршруты
  [ROUTES.ADMIN_SETUP]: ACCESS_LEVELS.PUBLIC,
  [ROUTES.ADMIN_CONTACT]: ACCESS_LEVELS.ADMIN,
  [ROUTES.ADMIN_CONTACT_MESSAGES]: ACCESS_LEVELS.ADMIN,
  [ROUTES.ADMIN_CONTACT_MESSAGE_DETAILS]: ACCESS_LEVELS.ADMIN,
  [ROUTES.ADMIN_CONTACT_SPAM_SETTINGS]: ACCESS_LEVELS.ADMIN,
  [ROUTES.ADMIN_BOOKINGS]: ACCESS_LEVELS.ADMIN,
  [ROUTES.ADMIN_BOOKING_DETAILS]: ACCESS_LEVELS.ADMIN,

  // Тестовые маршруты (только для разработки)
  [ROUTES.TEST_FIREBASE]: ACCESS_LEVELS.ADMIN,
  [ROUTES.TEST_AMADEUS]: ACCESS_LEVELS.ADMIN,
};

// Вспомогательные функции для работы с маршрутами
export const isPublicRoute = (path) => ROUTE_ACCESS[path] === ACCESS_LEVELS.PUBLIC;
export const isAdminRoute = (path) => ROUTE_ACCESS[path] === ACCESS_LEVELS.ADMIN;
export const requiresAuth = (path) => ROUTE_ACCESS[path] !== ACCESS_LEVELS.PUBLIC;

// Экспортируем всё необходимое
export { ACCESS_LEVELS };