import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { IMAGES, ROUTES } from '../../config/paths';
import LoginForm from '../../components/auth/LoginForm';

const LoginPage = () => {
  const { currentUser } = useAuth();

  // Если пользователь уже авторизован, перенаправляем на главную
  if (currentUser) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* Logo */}
        <div className="flex justify-center">
          <div className="flex items-center gap-2">
            <img src={IMAGES.LOGO} alt="HotelsRating Logo" className="w-10 h-10" />
            <div className="flex items-baseline">
              <span className="text-2xl font-bold text-brand-red">Hotels</span>
              <span className="text-2xl font-bold text-brand-blue">Rating</span>
            </div>
          </div>
        </div>

        <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
          Welcome back
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Sign in to access your personalized hotel ratings
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <LoginForm />
        </div>

        {/* Дополнительные опции входа */}
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gray-50 text-gray-500">
                New to HotelsRating?
              </span>
            </div>
          </div>

          <div className="mt-6 text-center">
            <a
              href={ROUTES.REGISTER}
              className="text-brand-blue hover:text-brand-blue/80 font-medium"
            >
              Create an account
            </a>
          </div>
        </div>

        {/* Footer Links */}
        <div className="mt-8 text-center text-sm">
          <div className="space-x-4 text-gray-600">
            <a href={ROUTES.TERMS} className="hover:text-gray-900">
              Terms
            </a>
            <a href={ROUTES.PRIVACY} className="hover:text-gray-900">
              Privacy
            </a>
            <a href={ROUTES.HELP} className="hover:text-gray-900">
              Help Center
            </a>
          </div>
        </div>
      </div>

      {/* Версия для разработки */}
      {import.meta.env.DEV && (
        <div className="mt-8 text-center text-sm text-gray-500">
          <p>Test credentials:</p>
          <p>Email: test@example.com</p>
          <p>Password: password123</p>
        </div>
      )}
    </div>
  );
};

export default LoginPage;