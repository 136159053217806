// src/components/hotels/HotelCard.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MapPin, Star, Info, Heart, ChevronRight, ChevronLeft } from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

const ImageCarousel = ({ images = [], onImageError }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touching, setTouching] = useState(false);
  const [touchStart, setTouchStart] = useState(0);

  const showNavigation = images.length > 1;

  const nextImage = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const prevImage = (e) => {
    e.stopPropagation();
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const handleTouchStart = (e) => {
    setTouching(true);
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touching) return;
    
    const touchEnd = e.touches[0].clientX;
    const diff = touchStart - touchEnd;
    
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        nextImage(e);
      } else {
        prevImage(e);
      }
      setTouching(false);
    }
  };

  const handleTouchEnd = () => {
    setTouching(false);
  };

  return (
    <div 
      className="relative h-48 bg-gray-100 overflow-hidden rounded-lg"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="absolute inset-0">
        <img
          src={images[currentIndex] || "/api/placeholder/400/300"}
          alt="Hotel view"
          className="w-full h-full object-cover transition-opacity duration-300"
          onError={onImageError}
        />
      </div>

      {showNavigation && (
        <>
          <button
            onClick={prevImage}
            className="absolute left-2 top-1/2 -translate-y-1/2 p-1 rounded-full bg-black/30 text-white hover:bg-black/50 transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={nextImage}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1 rounded-full bg-black/30 text-white hover:bg-black/50 transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>

          {/* Dots navigation */}
          <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-1">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentIndex(index);
                }}
                className={`w-1.5 h-1.5 rounded-full transition-colors ${
                  index === currentIndex ? 'bg-white' : 'bg-white/50'
                }`}
              />
            ))}
          </div>
        </>
      )}

      {/* Image counter */}
      {showNavigation && (
        <div className="absolute bottom-2 right-2 bg-black/60 text-white text-xs px-2 py-1 rounded-full">
          {currentIndex + 1}/{images.length}
        </div>
      )}
    </div>
  );
};

const HotelCard = ({ 
  hotel, 
  searchParams = {},
  onFavoriteClick,
  isFavorite = false 
}) => {
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);

  const formatPrice = (price) => {
    if (!price?.current?.amount) return 'Price not available';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: price.current.currency || 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(price.current.amount);
  };

  const formatRating = () => {
    if (typeof hotel.rating === 'number') {
      return hotel.rating.toFixed(1);
    }
    if (hotel.rating?.stars) {
      return hotel.rating.stars.toFixed(1);
    }
    return 'N/A';
  };

  const formatLocation = () => {
    if (typeof hotel.location === 'string') return hotel.location;
    
    if (typeof hotel.location === 'object') {
      const parts = [];
      if (hotel.location.address?.lines?.length) {
        parts.push(hotel.location.address.lines[0]);
      }
      if (hotel.location.address?.city) {
        parts.push(hotel.location.address.city);
      }
      if (hotel.location.address?.country) {
        parts.push(hotel.location.address.country);
      }
      return parts.length > 0 ? parts.join(', ') : 'Location not available';
    }

    return 'Location not available';
  };

  const handleViewDetails = () => {
    const queryParams = new URLSearchParams(searchParams).toString();
    navigate(`/hotels/${hotel.id}${queryParams ? `?${queryParams}` : ''}`);
  };

  const getMainFeatures = () => {
    if (!hotel.features) return [];
    return Object.entries(hotel.features)
      .filter(([_, value]) => value)
      .slice(0, 4);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Card className="overflow-hidden hover:shadow-lg transition-shadow">
      {/* Image Carousel Section */}
      <ImageCarousel 
        images={imageError ? ["/api/placeholder/400/300"] : hotel.images} 
        onImageError={handleImageError}
      />

      <CardContent className="p-4">
        {/* Title and Rating */}
        <div className="flex justify-between items-start mb-2">
          <h3 className="font-semibold text-lg line-clamp-1">
            {hotel.name || 'Unnamed Hotel'}
          </h3>
          <div className="flex items-center gap-1">
            <Star className="w-4 h-4 text-yellow-400" />
            <span>{formatRating()}</span>
          </div>
        </div>

        {/* Location */}
        <div className="flex items-start gap-1 text-gray-600 mb-2">
          <MapPin className="w-4 h-4 mt-0.5 flex-shrink-0" />
          <span className="text-sm line-clamp-2">{formatLocation()}</span>
        </div>

        {/* Features */}
        <div className="flex flex-wrap gap-2 mb-4">
          {getMainFeatures().map(([key, value], index) => (
            <TooltipProvider key={index}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center gap-1 text-xs bg-gray-100 rounded-full px-2 py-1">
                    <Info className="w-3 h-3" />
                    <span>{key.replace(/([A-Z])/g, ' $1').trim()}</span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="text-sm">{value}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>

        {/* Description */}
        {hotel.description && (
          <p className="text-sm text-gray-600 mb-4 line-clamp-2">
            {hotel.description}
          </p>
        )}

        {/* Price and Actions */}
        <div className="flex items-end justify-between pt-4 border-t">
          <div>
            <div className="text-xs text-gray-600">From</div>
            <div className="text-xl font-bold text-brand-blue">
              {formatPrice(hotel.price)}
            </div>
            <div className="text-xs text-gray-600">per night</div>
          </div>

          <div className="space-x-2">
            <Button 
              variant="outline" 
              size="sm"
              onClick={handleViewDetails}
              className="hover:bg-brand-blue hover:text-white"
            >
              Details
            </Button>
            <Button 
              size="sm"
              onClick={handleViewDetails}
            >
              Book Now
            </Button>
          </div>
        </div>

        {/* Favorite Button */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            onFavoriteClick?.(hotel);
          }}
          className={`absolute top-4 left-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors ${
            isFavorite ? 'text-red-500' : 'text-gray-600'
          }`}
        >
          <Heart className="w-5 h-5" fill={isFavorite ? 'currentColor' : 'none'} />
        </button>

        {/* Personal Score Badge */}
        {hotel.personalScore && (
          <div className="absolute top-4 right-4 bg-brand-blue text-white px-3 py-1 rounded-full text-sm shadow-lg">
            {hotel.personalScore.toFixed(1)}% match
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default HotelCard;