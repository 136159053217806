// src/components/legal/CookiePolicy.jsx
export const CookiePolicy = () => {
  const browserGuides = {
    chrome: "https://support.google.com/chrome/answer/95647",
    firefox: "https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox",
    safari: "https://support.apple.com/guide/safari/manage-cookies-sfri11471",
    edge: "https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Cookie Policy for HotelsRating.com</h1>
      <div className="text-sm text-gray-600 mb-8">
        Effective Date: 25/11/2024
      </div>
      
      <div className="prose prose-blue max-w-none space-y-6">
        <p>
          HotelsRating.com ("we," "us," or "our") uses cookies and similar technologies to enhance your experience on our website. This Cookie Policy explains what cookies are, how we use them, and your options for managing your cookie preferences. By continuing to use our website, you consent to the use of cookies as described in this policy.
        </p>

        <section>
          <h2 className="text-2xl font-semibold mb-4">1. What Are Cookies?</h2>
          <p>
            Cookies are small text files stored on your device (computer, tablet, smartphone) when you visit a website. They serve various purposes, such as remembering your preferences, enabling website functionality, and providing insights into how users interact with the site.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. Types of Cookies We Use</h2>
          
          <h3 className="text-xl font-medium mt-4 mb-2">A. Strictly Necessary Cookies</h3>
          <p>These cookies are essential for the operation of our website. Without them, certain features (e.g., account login, security settings) cannot function properly.</p>

          <h3 className="text-xl font-medium mt-4 mb-2">B. Performance Cookies</h3>
          <p>These cookies collect anonymous data on how users interact with our website (e.g., pages visited, time spent). This helps us improve our website's performance and user experience.</p>

          <h3 className="text-xl font-medium mt-4 mb-2">C. Functional Cookies</h3>
          <p>These cookies enable enhanced functionality and personalization, such as remembering your language preferences or saved searches.</p>

          <h3 className="text-xl font-medium mt-4 mb-2">D. Targeting and Advertising Cookies</h3>
          <p>These cookies track your online activity to deliver relevant advertisements and marketing content. They may be placed by third-party advertising networks with our permission.</p>

          <h3 className="text-xl font-medium mt-4 mb-2">E. Analytics Cookies</h3>
          <p>We use cookies to gather aggregated data about website traffic and user behavior, using tools such as Google Analytics. This helps us analyze and improve our website.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">3. Third-Party Cookies</h2>
          <p>We may allow third-party service providers to place cookies on your device for the following purposes:</p>
          <ul className="list-disc pl-6">
            <li>Analytics: To measure website performance and analyze user behavior.</li>
            <li>Advertising: To deliver personalized advertisements.</li>
            <li>Social Media Integration: To enable sharing and engagement with social media platforms.</li>
          </ul>
          <p className="mt-4">These third parties have their own privacy policies governing their use of cookies, and we are not responsible for their practices.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. How We Use Cookies</h2>
          <p>We use cookies to:</p>
          <ul className="list-disc pl-6">
            <li>Ensure the website functions correctly.</li>
            <li>Save your preferences and settings.</li>
            <li>Analyze website traffic and improve performance.</li>
            <li>Deliver targeted advertisements.</li>
            <li>Provide a personalized user experience.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">5. Your Cookie Choices</h2>
          
          <h3 className="text-xl font-medium mt-4 mb-2">A. Managing Cookies Through Your Browser</h3>
          <p>Most web browsers allow you to manage your cookie settings. You can block or delete cookies using the browser settings. Instructions for popular browsers:</p>
          <ul className="list-disc pl-6">
            <li><a href={browserGuides.chrome} target="_blank" rel="noopener noreferrer" className="text-brand-blue hover:underline">Google Chrome</a></li>
            <li><a href={browserGuides.firefox} target="_blank" rel="noopener noreferrer" className="text-brand-blue hover:underline">Mozilla Firefox</a></li>
            <li><a href={browserGuides.safari} target="_blank" rel="noopener noreferrer" className="text-brand-blue hover:underline">Safari</a></li>
            <li><a href={browserGuides.edge} target="_blank" rel="noopener noreferrer" className="text-brand-blue hover:underline">Microsoft Edge</a></li>
          </ul>

          <h3 className="text-xl font-medium mt-4 mb-2">B. Cookie Management Tools</h3>
          <p>We provide a cookie management tool on our website where you can set your preferences for different types of cookies.</p>

          <h3 className="text-xl font-medium mt-4 mb-2">C. Opt-Out of Targeted Advertising</h3>
          <p>You can opt out of personalized advertising by visiting:</p>
          <ul className="list-disc pl-6">
            <li>Your Online Choices</li>
            <li>Network Advertising Initiative</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">6. Data Collected Through Cookies</h2>
          <p>The data collected through cookies may include:</p>
          <ul className="list-disc pl-6">
            <li>Your IP address.</li>
            <li>Browser type and version.</li>
            <li>Device information (e.g., operating system).</li>
            <li>Pages visited and time spent on each page.</li>
            <li>Referring URL.</li>
          </ul>
          <p className="mt-4">This data is used solely for the purposes described in this policy and is not linked to personally identifiable information unless you have explicitly provided it to us.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">7. Retention of Cookie Data</h2>
          <p>Cookies remain on your device for the duration specified below:</p>
          <ul className="list-disc pl-6">
            <li>Session Cookies: Deleted when you close your browser.</li>
            <li>Persistent Cookies: Remain on your device until their expiration date or until you delete them.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">8. Legal Basis for Using Cookies</h2>
          <p>We process cookies under the following legal bases:</p>
          <ul className="list-disc pl-6">
            <li>Strictly Necessary Cookies: Legitimate interest to ensure website functionality.</li>
            <li>Other Cookies: Your explicit consent, collected via the cookie management tool.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">9. Updates to This Cookie Policy</h2>
          <p>We may update this Cookie Policy to reflect changes in technology, legal requirements, or our practices. Updates will be posted on this page with a revised "Effective Date." We encourage you to review this page periodically.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
          <p>If you have questions about this Cookie Policy or our use of cookies, please contact us at:</p>
          <div className="mt-4">
            <p>HotelsRating.com</p>
            <p>Email: dataprotection@hotelsrating.com</p>
          </div>
        </section>

        <div className="mt-8 text-sm text-gray-600">
          By continuing to use HotelsRating.com, you consent to the use of cookies as described in this Cookie Policy.
        </div>
      </div>
    </div>
  );
};